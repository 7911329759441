import React, { useEffect } from 'react';

import {
  Box,
  EmptyState,
  PieChart
} from 'components';
import { hooks, screen } from 'helpers';
import { Images } from 'consts';

import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { convertPercentage } from './helpers';

import { renderTitleBox } from '../../index';

type BoxAgeProps = {
  widthBox: number;
};

const BoxAge: React.FC<BoxAgeProps> = ({ widthBox }) => {
  
  const dispatch = hooks.useAppDispatch();
  
  const getStatsAge = dispatch(actions.getStatsAge);
  const statsAge = hooks.useAppSelector(selectors.stats.statsAge);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const leftLegend = (widthBox - 40) / 1.8;

  const pieChartProps = {
    width: widthBox - 40,
    height: windowDimensions.width < screen.isMobile ? 180 : 200,
    legendWrapper: { left: leftLegend }
  };

  useEffect(() => {
      getStatsAge();
  }, []);

  const chartConverted = () => {
    const dataChart = statsAge || [];
    return convertPercentage(dataChart);
  };

  const renderEmptyData = () => {
    return (
      <div className='mt4' style={ { 'width': widthBox } }>
        <EmptyState
          background={ { height: 285 } }
          image={ {
            src: Images.emptyState.batteryStations,
            width: 56,
            height: 56
          } }
          text='No User Age Behaviour Data'
        />
      </div>
    );
  };

  const dataChartConverted = chartConverted();
  
  return (
    <Box
      mt={ 20 }
      padding={ 20 }
      width={ widthBox }
    >
      { renderTitleBox('User Age Behaviour Stats') }
      <div className='col center-content'>
      { Object.values(statsAge)?.length ?
        <PieChart
          data={ dataChartConverted }
          dataKey={ {
            category: 'range',
            value: 'value'
          } }
          outerRadiusData='400%'
          { ...pieChartProps }
        /> : renderEmptyData() }
      </div>
    </Box>
  );
};

export default BoxAge;
