import React from 'react';

import { Images } from 'consts';

import Image from '../Image';

import { LogoContainer } from './style';

type LogoProps = {
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  width?: string | number;
  height?: string | number;
  type?: 'logo' | 'logo_text';
};

const Logo: React.FC<LogoProps> = ({
  mt,
  mb,
  ml,
  mr,
  width,
  height,
  type = 'logo'
}) => {
  return (
    <LogoContainer
      mt={ mt }
      mb={ mb }
      ml={ ml }
      mr={ mr }
    >
      <Image
        src={ Images[type] }
        width={ width }
        height={ height }
        className='img-logo'
      />
    </LogoContainer>
  );
};

export default Logo;
