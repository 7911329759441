import Styled from 'styled-components';

type FilterMenuStyleProps = {
  visibleFilter?: any;
  activeFilter?: string;
};

export const FilterMenuStyle: any = Styled.div < FilterMenuStyleProps > `
  display: flex;
  flex-direction: column;

  .search-container {
    margin-right: -17px;
    margin-left: 20px;
  }

  .ic-search {
    width: 14px;
    height: 14px;
  }

  .filter-container {
    padding: 20px 20px 1px 20px;
  }
`;