import { Colors } from 'consts';
import { screen } from 'helpers';
import Styled from 'styled-components';

export const ModalDetailStyles: any = Styled.div`
	.customer {
		display: flex;
		gap: 20px;
		margin-top: 35px;
		align-items: center;
		justify-content: space-between;

		.flex {
			display: flex;
			gap: 20px;
			align-items: center;
		}

		@media ${ screen.sizesMax.sm } {
			flex-direction: column;
			align-items: start;
		}
	}

	.scooter-image {
		margin-top: 30px;
		display: flex;
		justify-content: center;
	}

	.scooter-information {
		margin-top: 20px;
	}

	.list {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.item {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid ${ Colors.grey.isLighterGrey };
			padding-bottom: 10px;
		}
	}

	.btn {
		margin-top: 20px;
	}

	.solved-images {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		column-gap: 13px;
		row-gap: 10px;
		width: 100%;
		height: 100%;
	}

	.case-solving-steps {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}

	.container-solving-description {
		display: flex;
		align-items: center;
		border-radius: 8px;
		padding: 14px 15px;
		background: ${ Colors.grey.isBgLightGrey };
	}
`;
