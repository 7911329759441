import Styled from 'styled-components';

import { Colors } from 'consts';

type DropdownCellStyleProps = {
  visible: boolean;
};

export const DropdownCellStyle: any = Styled.div < DropdownCellStyleProps > `
  .dropdown-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 44px;
  }

  .menu-dropdown-cell {
    width: 175px;
  }

  .arrow-dropdown-cell {
    ${props => props.visible ? 'transform: rotateX(180deg);' : ''}
    fill: ${Colors.blue.isBlue};
  }
`;