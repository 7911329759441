import { Dispatch } from 'redux';

import { AppActions, RootState } from 'store';
import { toastify } from 'helpers';
import { ApiData, Pagination } from 'interfaces/common';
import {
  FormUser,
  FormShowroomAssignment,
  FormScooterAssignment,
  User,
  FormUploadLegal,
  UserApiParams
} from 'interfaces/user';
import * as userServices from 'services/user';

import { GET_ALL_USER, GET_USER_DETAIL } from './actionTypes';
import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';
import { setPaginationData } from '../Pagination/action';

export const getAllUser = (dispatch: Dispatch<AppActions>, getState: () => RootState) => (
  params: UserApiParams,
  showroomId?: string,
  callback?: (users: { data: User[], pagination: Pagination; }) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('allUser'));

      const adminShowroomId = await getState().auth.user.showroom_id;
      let payloadShowroomId = '';

      if (showroomId) {
        payloadShowroomId = showroomId;
      } else if (!showroomId && adminShowroomId > 0) {
        payloadShowroomId = `${ adminShowroomId }`;
      }

      const apiParams: UserApiParams = await {
        ...params,
        showroom_id: payloadShowroomId
      };

      const {
        code,
        data,
        pagination
      }: ApiData<User[]> = await userServices.getAllUser(apiParams);

      if (code === 200) {
        await dispatch({
          type: GET_ALL_USER,
          data: {
            data,
            pagination
          }
        });

        await dispatch(setPaginationData('users', pagination));

        callback
          ? callback({
            data,
            pagination
          })
          : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('allUser'));
  });
};

export const addUser = (dispatch: Dispatch<AppActions>) => (formAddUser: FormUser, callback?: (message: string) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('addUser'));

      const { code, stat_msg }: ApiData<any> = await userServices.addUser(formAddUser);

      if (code === 200 && stat_msg === 'Success') {
        callback ? callback('User berhasil ditambahkan') : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('addUser'));
  });
};

export const editUser = (dispatch: Dispatch<AppActions>) => (
  userId: string,
  formEditUser: FormUser,
  callback?: (message: string) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('editUser'));

      const { code, data }: ApiData<string> = await userServices.editUser(userId, formEditUser);

      if (code === 200) {
        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('editUser'));
  });
};

export const getUserDetail = (dispatch: Dispatch<AppActions>) => (userId: string, callback?: (user: User) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('userDetail'));

      const { code, data }: ApiData<User> = await userServices.getUserDetail(userId);

      if (code === 200) {
        await dispatch({
          type: GET_USER_DETAIL,
          data
        });

        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('userDetail'));
  });
};

export const showroomAssignment = (dispatch: Dispatch<AppActions>) => (
  userId: string,
  formShowroom: FormShowroomAssignment,
  callback?: (message: string) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('showroomAssignment'));

      const { code, data }: ApiData<string> = await userServices.showroomAssignment(userId, formShowroom);

      if (code === 200) {
        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('showroomAssignment'));
  });
};

export const editShowroomAssignment = (dispatch: Dispatch<AppActions>) => (
  userId: string,
  formShowroom: FormShowroomAssignment,
  callback?: (message: string) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('showroomAssignment'));

      const { code, data }: ApiData<string> = await userServices.editShowroomAssignment(userId, formShowroom);

      if (code === 200) {
        if (callback) callback(data);

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('showroomAssignment'));
  });
};

export const scooterAssignment = (dispatch: Dispatch<AppActions>) => (
  parentId: string,
  formScooterAssignment: FormScooterAssignment,
  callback?: (message: string) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('scooterAssignment'));

      const { code, data }: ApiData<string> = await userServices.scooterAssignment(parentId, formScooterAssignment);

      if (code === 200) {
        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('scooterAssignment'));
  });
};

export const uploadLegalInfo = (dispatch: Dispatch<AppActions>) => (
  userId: string,
  formUploadLegal: FormUploadLegal,
  callback?: (message: string) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('uploadLegalInfo'));

      const { code, data }: ApiData<string> = await userServices.uploadLegalInfo(userId, formUploadLegal);

      if (code === 200) {
        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('uploadLegalInfo'));
  });
};

export const verifyLegalInfo = (dispatch: Dispatch<AppActions>) => (userId: string, callback?: (message: string) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('verifyLegal'));

      const { code, data }: ApiData<string> = await userServices.verifyLegalInfo(userId);

      if (code === 200) {
        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('verifyLegal'));
  });
};
