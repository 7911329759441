
export const convertFilterToReportDate = (activeFilter: string) => {
	switch (activeFilter) {
		case 'Monthly':

			const monthlyReport = new Date();
			const getMonth = new Date(monthlyReport.setMonth((monthlyReport.getMonth() + 1) - 5));
			const getMonthlyReport = new Date().getFullYear() + '-' + String(getMonth.getMonth()).padStart(2, '0') + '-' + '01';

			return getMonthlyReport;

		case 'Weekly':
			const weeklyReport = new Date();
			const day = weeklyReport.getDate() - 35;
			weeklyReport.setDate(day);

			const getWeeklyReport = weeklyReport.getFullYear() + '-' + String(weeklyReport.getMonth() + 1).padStart(2, '0') + '-' + String(weeklyReport.getDate()).padStart(2, '0');

			return getWeeklyReport;

		case 'Daily':
			const dailyReport = new Date();
			dailyReport.setDate(dailyReport.getDate() - 7);
			const getDailyReport = dailyReport.getFullYear() + '-' + String(dailyReport.getMonth() + 1).padStart(2, '0') + '-' + String(dailyReport.getDate()).padStart(2, '0');

			return getDailyReport;
		default:
			activeFilter.toLowerCase();
	}
};

export const dateChartFormatter = (activeFilter: string) => {
	switch (activeFilter) {
		case 'Yearly':
			return {
				short: 'YYYY',
				long: 'MMM YYYY'
			};
		case 'Monthly':
			return {
				short: 'M/YYYY',
				long: 'MMM YYYY'
			};
		case 'Weekly':
			return {
				short: 'YYYY-MM-DD',
				long: 'DD MMM YYYY'
			};
		case 'Daily':
		default:
			return {
				short: 'D/M/YYYY',
				long: 'DD MMM YYYY'
			};
	}
};

const get_date_parts = (iso_string: { split: (arg0: RegExp) => [any, any, any]; }) => {
	const [
		year,
		month,
		day,
	] = iso_string.split(/\D/g);

	return {
		year,
		month,
		day,
	};
};

const monthName = (monthNumber: string | number) => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];

	return months[Number(monthNumber) - 1];
};

export const group_by_month = (arr: any[]) => {

	return Object.values(arr?.reduce((a, { created_date: date_string, battery_usage: battery_usage }) => {
		const { year, month } = get_date_parts(date_string.split(' ')[0]);
		const label = monthName(month) + '/' + year;
		const key = `${ year }/${ month }`;
		if (a[key] === undefined) {
			a[key] = {
				date_x: label,
				battery_usage: 0,
				created_date: key
			};
		}

		a[key].battery_usage += battery_usage;

		return a;
	}, {}),);
};

const getSumValueByRangeDate = (
	range: any[],
	arr: any[],
	val: string
) => {
	let sum = 0;
	range.forEach((element: any) => {

		const obj = arr?.find((o: { created_date: any; }) => o.created_date.split(' ')[0] === element.replace('/', '-').replace('/', '-'));
		if (obj) {
			sum += obj[val];
		}
	});
	return sum;
};

export const getWeeks = (arr: any[],
	fieldSum: string) => {

	arr?.sort((a, b) => new Date(b.created_date).valueOf() - new Date(a.created_date).valueOf());

	const startDate = arr[arr?.length - 1]?.created_date;
	const endDate = arr[0]?.created_date;

	const sd = new Date(startDate);
	const weeks = [] as any[];
	let week = [] as any[];
	let allDate = 0;
	let noDate = 0;

	for (let d = sd; d <= new Date(endDate); d.setDate(d.getDate() + 1)) {

		week?.push(new Date(d).getFullYear() + '/' + String((new Date(d).getMonth() + 1)).padStart(2, '0') + '/' + String(new Date(d).getDate()).padStart(2, '0'));

		const existData = arr?.find(item => item?.created_date?.split(' ')[0] === new Date(d).getFullYear() + '-' + String((new Date(d).getMonth() + 1)).padStart(2, '0') + '-' + String(new Date(d).getDate()).padStart(2, '0'));

		if (!existData) {
			noDate++;
		}

		if (new Date(d).getDay() === 0) {
			allDate += (week.length - noDate);
			weeks.push({
				date_x: week[0] + '-<br>' + week[week.length - 1],
				battery_usage: getSumValueByRangeDate(
					week, arr, fieldSum
				),
				created_date: week[0] + '-' + week[week.length - 1]
			});
			week = [];
		}

	}

	// Create point untuk sisa hari yang belum genap 1 minggu
	if (allDate < arr?.length) {
		week = [];

		// Looping untuk sisa tanggal
		for (let index = (arr?.length - allDate) - 1; index >= 0; index--) {
			const d = arr[index]?.created_date?.split(' ')[0];
			week.push(new Date(d).getFullYear() + '/' + String((new Date(d).getMonth() + 1)).padStart(2, '0') + '/' + String(new Date(d).getDate()).padStart(2, '0'));
		}

		weeks.push({
			date_x: week[0] + '-<br>' + week[week.length - 1],
			battery_usage: getSumValueByRangeDate(
				week.reverse(), arr, fieldSum
			),
			created_date: week[0] + '-' + week[week.length - 1]
		});

		week = [];
	}

	return weeks;
};