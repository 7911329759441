import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as dotenv from 'dotenv';

import Router from 'router';
import {
  history,
  store,
  persistor
} from 'store';
import { ToastContainer } from 'components';
import { RESET_ALL_LOADING } from 'store/Misc/actionTypes';

// Import CSS
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import 'react-toastify/dist/ReactToastify.css';

dotenv.config({ path: '../.env' });

const App: React.FC = () => {
  useEffect(() => {
    const beforeUnload = () => {
      store.dispatch({ type: RESET_ALL_LOADING });
    };

    window.addEventListener(
      'beforeunload',
      beforeUnload,
      false
    );

    return () => {
      window.addEventListener(
        'beforeunload',
        beforeUnload,
        false
      );
    };
  }, []);

  return (
    <Provider store={ store }>
      <PersistGate persistor={ persistor }>
        <Suspense fallback={ <></> }>
          <ToastContainer />

          <Router history={ history } />
        </Suspense>
      </PersistGate>
    </Provider>
  );
};
export default App;
