import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { ColumnsType } from 'antd/es/table';

import {
  Icon,
  Input,
  Select,
  SearchInput,
  Dashboard,
  TopLoaderBar,
  ModalLoader,
  ModalReport
} from 'components';
import { renderText, renderButtonAction } from 'components/DataTable';
import { convertColorStatus } from 'components/DataTable/helpers';
import { DataOption } from 'components/Input/SearchInput';
import { Colors } from 'consts';
import {
  hooks,
  misc,
  navigation,
  roleHelper,
  toastify
} from 'helpers';
import { MenuEntity } from 'components/Dropdown';
import {
  CurrentPagination,
  FormPagination,
  ReducerList,
  SelectOptionValue,
  SorterInfo
} from 'interfaces/common';
import {
  Scooter,
  FormScooter,
  ErrorFormScooter,
  ScooterInfo,
  ScooterApiParams
} from 'interfaces/scooter';
import { Showroom, ShowroomApiParams } from 'interfaces/showroom';
import { Permission } from 'interfaces/role';
import { ActionModalForm, DetailNavProps } from 'typings';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';
import { language } from 'language';

import { TableDropdownCellStyle } from './style';
import {
  inputPropsData,
  scooterFilterStatus,
  scooterFilterLocation,
  dropdownsFilter,
} from './data';

type Filter =
  'showroom_id' |
  'status' |
  'location';
type ActiveFilter = {
  [key in Filter]: string;
};

type Selection = 'status' | 'variant_id';
type VisibleSelect = {
  [key in Selection]: boolean;
};

type ModalReportState = {
  visible: boolean;
  type: 'missing' | 'found';
};

const { placeholder, errorFormMessage } = language;
const {
  header,
  scooterStatus,
  scooterLocation
} = language.scooters;

const renderItemSelectScooterStatus = (status: string, recordStatus: string) => {
  const checked = recordStatus.toLowerCase() === status.toLowerCase();

  return (
    <TableDropdownCellStyle>
      <div className='justify-align-center menu-dropdown-cell'>
        { renderText(status, convertColorStatus(status)) }
        {
          checked && (
            <Icon
              iconName='check'
              size={ 14 }
              fill={ Colors.blue.isBlue }
            />
          )
        }
      </div>
    </TableDropdownCellStyle>
  );
};

export const overlayDataScooterStatus = (recordStatus: string) => {
  return scooterStatus.map((status: string) => {
    return {
      key: status,
      item: () => renderItemSelectScooterStatus(status, recordStatus)
    };
  });
};

const Scooters: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const getAllScooter = dispatch(actions.getAllScooter);
  const addScooter = dispatch(actions.addScooter);
  const editScooter = dispatch(actions.editScooter);
  const getScooterDetail = dispatch(actions.getScooterDetail);
  const getAllScooterVariant = dispatch(actions.getAllScooterVariant);
  const getAllShowroom = dispatch(actions.getAllShowroom);
  const getShowroomDetail = dispatch(actions.getShowroomDetail);

  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);
  const adminShowroomId = hooks.useAppSelector(selectors.auth.adminShowroomId);
  const scooters = hooks.useAppSelector(selectors.scooter.scooters);
  const variantScooters = hooks.useAppSelector(selectors.scooter.variantScooters);

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<ActiveFilter>({
    showroom_id: '',
    status: scooterFilterStatus[0],
    location: scooterFilterLocation[0]
  });
  const [search, setSearch] = useState<string>('');
  const [formPagination, setFormPagination] = useState<FormPagination>({
    limit: 10,
    page: 1
  });
  const [sortOrder, setSortOrder] = useState<SorterInfo>({
    sort: null,
    order: ''
  });
  const [actionModalForm, setActionModalForm] = useState<ActionModalForm>(null);
  const [modalFormVisible, setModalFormVisible] = useState<boolean>(false);
  const [modalLoaderVisible, setModalLoaderVisible] = useState<boolean>(false);
  const [modalReportState, setModalReportState] = useState<ModalReportState>({
    visible: false,
    type: 'missing'
  });
  const [form, setForm] = useState<FormScooter>({
    vin: '',
    plat_number: '',
    status: placeholder.scooterStatus,
    showroom_id: 0,
    variant_id: placeholder.scooterType,
    location: placeholder.location
  });
  const [errorForm, setErrorForm] = useState<ErrorFormScooter>({
    vin: '',
    plat_number: '',
    status: '',
    showroom_id: '',
    variant_id: '',
    location: ''
  });
  const [editedVin, setEditedVin] = useState<string>('');
  const [visibleSelect, setVisibleSelect] = useState<VisibleSelect>({
    status: false,
    variant_id: false
  });
  const [searchShowroom, setSearchShowroom] = useState<string>('');
  const [dataSearchShowroom, setDataSearchShowroom] = useState<DataOption[]>([]);
  const [openDropdownShowroom, setOpenDropdownShowroom] = useState<boolean>(false);
  const [goToScooterDetail, setGoToScooterDetail] = useState<DetailNavProps>({
    progress: -1,
    id: '',
    route: ''
  });

  const debouncedSearch = hooks.useDebounce(search, 1200);
  const debouncedSearchShowroom = hooks.useDebounce(searchShowroom, 1200);
  const loadingScooters = misc.isLazyLoading(lazyLoad, 'allScooter');

  const columns: ColumnsType<Scooter> = [
    {
      title: 'VIN',
      dataIndex: 'vin',
      key: 'vin',
      sorter: true,
      render: text => renderText(text),
    },
    {
      title: 'Type',
      dataIndex: 'variant_type',
      key: 'variant_type',
      render: text => renderText(text),
    },
    {
      title: 'Plat Number',
      dataIndex: 'plat_number',
      key: 'plat_number',
      sorter: true,
      render: text => renderText(text),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: text => renderText(text, convertColorStatus(text)),
      onCell: (record: Scooter) => ({
        title: 'Status',
        dataIndex: 'status',
        record,
        isDropdownCell: roleHelper.isPermissible(adminPermissions, Permission.inventory_change_status),
        overlayDropdown: overlayDataScooterStatus(record?.status),
        onVisibleChange: onVisibleDropdownStatusChange,
        onClickOpenDropdown: onClickOpenDropdownStatus,
        onClickMenu: onClickMenuDropdownCellStatus,
      }),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: text => renderText(text)
    },
    {
      title: 'Showroom',
      dataIndex: 'showroom_name',
      key: 'showroom_name',
      render: text => renderText(text || '-')
    },
    {
      title: 'User',
      dataIndex: 'username',
      key: 'username',
      sorter: true,
      render: text => renderText(text)
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 180,
      render: (_: any, record: Scooter) => renderButtonAction(buttonsDataTable, record)
    },
  ];

  const buttonsDataTable = [
    {
      iconName: 'alert',
      color: 'yellow',
      onClick: (record: Scooter) => {
        setEditedVin(record?.vin);
        setModalReportState({
          visible: true,
          type: record?.status === 'Missing' ? 'found' : 'missing'
        });
      },
      permission: Permission.inventory_report_missing
    },
    {
      iconName: 'location',
      color: 'black',
      onClick: (record: Scooter) => {
        if (record?.username) {
          onClickRow(record, '/live-tracking');
        } else {
          toastify.error('Live tracking tidak tersedia');
        }
      },
      permission: Permission.inventory_detail
    },
    {
      iconName: 'search',
      color: 'blue',
      onClick: (record: Scooter) => onClickRow(record, ''),
      permission: [Permission.inventory_detail, Permission.showroom_detail],
      // onClick: (record: Scooter) => onClickOpenModal('detail', record), // note: jika fitur modal detail + edit diadakan seperti di halaman showroom, uncomment line ini, dan comment line onClick diatas
    }
  ];

  const getDataScooters = () => {
    const params: ScooterApiParams = {
      ...formPagination,
      search: debouncedSearch,
      sort: (sortOrder.sort || 'asc'),
      order: (sortOrder.order || 'vin'),
      status: misc.changeSelectFilterValue(activeFilter.status, 'status'),
      location: misc.changeSelectFilterValue(activeFilter.location, 'location')
    };
    const showroomId: string = activeFilter.showroom_id && activeFilter.showroom_id !== 'All Showroom'
      ? activeFilter.showroom_id
      : '';

    getAllScooter(params, showroomId);
  };

  const getDataShowroom = async(search: string): Promise<SelectOptionValue[]> => {
    const formPaginationShowroom: FormPagination = {
      limit: 7,
      page: 1
    };
    const params: ShowroomApiParams = {
      ...formPaginationShowroom,
      search,
      sort: 'asc'
    };

    let dataShowroom: SelectOptionValue[] = [];

    await getAllShowroom(params, (updatedShowrooms: ReducerList<Showroom[]>) => {

      if (updatedShowrooms?.data) {
        dataShowroom = [
          {
            key: '',
            label: 'All Showroom',
            value: 'All Showroom'
          }, ...updatedShowrooms?.data?.map(showroom => ({
            key: `${ showroom?.id }`,
            value: `${ showroom?.id }`,
            label: showroom?.name
          }))
        ];
      }
    });

    return dataShowroom;
  };

  const dropdownsFilterFetch = !adminShowroomId && roleHelper.isPermissible(adminPermissions, Permission.showroom_list)
    ? [
      {
        key: 'showroom_id',
        value: activeFilter?.showroom_id || 'All Showroom',
        defaultOptions: [
          {
            key: '',
            label: 'All Showroom',
            value: 'All Showroom'
          }
        ],
        fetchOptions: getDataShowroom,
        onChangeSearch: newValue => {
          setActiveFilter(prevActive => ({
            ...prevActive,
            showroom_id: `${ newValue.value }`
          }));
          setInitialPage();
        }
      }
    ]
    : undefined;

  // Handle perubahan payload
  useEffect(() => {
    getDataScooters();
  }, [
    formPagination.page,
    formPagination.limit,
    debouncedSearch,
    sortOrder.sort,
    sortOrder.order,
    activeFilter.showroom_id,
    activeFilter.location,
    activeFilter.status
  ]);

  // Handle refreshing, ex: after add/edit/delete
  useEffect(() => {
    if (refreshing) {
      getDataScooters();

      setRefreshing(false);
    }
  }, [refreshing]);

  // Handle search & select showroom in the form add scooter
  useEffect(() => {
    if (openDropdownShowroom) {
      const formPaginationShowroom: FormPagination = {
        limit: 7,
        page: 1
      };
      const params: ShowroomApiParams = {
        ...formPaginationShowroom,
        search: debouncedSearchShowroom,
        sort: 'asc'
      };

      getAllShowroom(params, (updatedShowrooms: ReducerList<Showroom[]>) => {
        if (updatedShowrooms?.data) {
          const dataShowroom = updatedShowrooms?.data?.map(showroom => ({
            value: showroom?.id,
            text: showroom?.name
          }));

          setDataSearchShowroom(dataShowroom);
        } else {
          setDataSearchShowroom([]);
        }
      });
    }
  }, [debouncedSearchShowroom, openDropdownShowroom]);

  // When edited vin is assigned, get all scooter variant for displaying selected variant scooter type name.
  // Then, get scooter detail.
  // After get showroom ID, dispatch get showroom detail for displaying showroom name. (for edit on modal form)
  useEffect(() => {
    if (editedVin) {
      getAllScooterVariant(() => {
        getScooterDetail(editedVin, (scooter: ScooterInfo) => handleCbGetScooterDetail(scooter));
      });
    }
  }, [editedVin, visibleSelect.status]);

  useEffect(() => {
    if (visibleSelect.variant_id) {
      getAllScooterVariant();
    }
  }, [visibleSelect.variant_id]);

  const setInitialPage = () => {
    setFormPagination(prevFormPagination => ({
      ...prevFormPagination,
      page: 1
    }));
  };

  const handleCbGetScooterDetail = (scooter: ScooterInfo) => {
    setForm(prevForm => ({
      ...prevForm,
      vin: Number(scooter?.vin),
      plat_number: scooter?.plat_number,
      status: scooter?.status,
      showroom_id: scooter?.showroom_id,
      variant_id: `${ scooter?.type } - ${ scooter?.color }`,
      location: scooter?.location
    }));

    if (actionModalForm === 'detail') {
      getShowroomDetail(
        (scooter?.showroom_id || 0).toString(),
        'showroomDetail',
        (showroomDetail: Showroom) => { // callback success
          setSearchShowroom(showroomDetail?.name);

          setModalLoaderVisible(false);
        },
        () => setModalLoaderVisible(false)
      );
    }
  };

  const editScooterStatusAction = (key: string, cb: any) => {
    const selectedVariant = variantScooters?.data?.find(variant => `${ variant?.type } - ${ variant?.color ?? '' }` === form.variant_id);
    const formPayload = {
      ...form,
      plat_number: form.plat_number || '-',
      variant_id: selectedVariant ? selectedVariant.id : 1,
      status: key
    };

    editScooter(
      editedVin,
      formPayload,
      cb
    );
  };

  const onClickMenuDropdownCellStatus = ({ key }) => {
    if (key !== 'Missing' && modalReportState.type !== 'found') {
      setModalLoaderVisible(true);

      editScooterStatusAction(key, handleCbFormScooter);
    } else {
      setModalReportState(prevState => ({
        ...prevState,
        visible: true
      }));
    }
  };

  const onClickOpenDropdownStatus = (record: Scooter) => {
    setEditedVin(record?.vin);
    setModalReportState(prevState => ({
      ...prevState,
      type: record?.status === 'Missing' ? 'found' : 'missing'
    }));
  };

  const onVisibleDropdownStatusChange = (visible: boolean) => {
    if (!visible) {
      setEditedVin('');
    }
  };

  const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    setInitialPage();
  }, []);

  const onClickMenuItem = ({ key: keyItem }, keyFilter) => {
    setActiveFilter(prevState => ({
      ...prevState,
      [keyFilter]: keyItem
    }));

    setInitialPage();
  };

  const getDataBeforeToDetailPage = async(vin: string) => {
    try {
      getScooterDetail(vin, () => {
        handleReadyGoToScooterDetail();
      });
    } catch (error) {
      toastify.errorDefaultApi();
    }
  };

  const onClickRow = (record: Scooter, route: string) => {
    setGoToScooterDetail({
      progress: 0,
      id: record?.vin, // VIN is Scooter ID
      route,
    });

    getDataBeforeToDetailPage(record?.vin);
  };

  const handleReadyGoToScooterDetail = () => {
    setGoToScooterDetail(prevState => ({
      ...prevState,
      progress: 100
    }));
  };

  const onChangeTable = (sorterInfo: SorterInfo) => {
    setSortOrder(sorterInfo);

    setInitialPage();
  };

  const getCurrentPagination = (currentPagination: CurrentPagination<Scooter[]>) => {
    setFormPagination(prevPagination => ({
      ...prevPagination,
      page: currentPagination.currentPage,
      limit: currentPagination.elementsPerPage
    }));
  };

  const onClickOpenModal = (actionModal: ActionModalForm, record?: Scooter) => {
    setActionModalForm(actionModal);

    if (record) {
      if (actionModal !== 'delete') {
        setModalLoaderVisible(true);

        setEditedVin(record?.vin);
      }
    }

    if (actionModal === 'add') {
      onClearState();
      setModalFormVisible(true);
    }
  };

  const onClearState = () => {
    setForm(prevForm => ({
      ...prevForm,
      vin: '',
      plat_number: '',
      status: placeholder.scooterStatus,
      showroom_id: 0,
      variant_id: placeholder.scooterType,
      location: placeholder.location
    }));

    setErrorForm({
      vin: '',
      plat_number: '',
      status: '',
      showroom_id: '',
      location: ''
    });

    setSearchShowroom('');
    setDataSearchShowroom([]);
  };

  const onCloseModalForm = () => {
    setModalFormVisible(false);

    setEditedVin('');

    onClearState();

    setActionModalForm(null);
    setModalLoaderVisible(false);
  };

  const onChangeFormText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevForm => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      [e.target.name]: ''
    }));
  }, []);

  const onDropdownShowroomVisibleChange = (open: boolean) => {
    handleInitSearchShowroom(form.showroom_id);
    setOpenDropdownShowroom(open);
  };

  const renderInput = (
    label: string,
    placeholder: string,
    key: string,
    type: string
  ) => {
    const inputDisabled = actionModalForm === 'detail' || (key === 'vin' && actionModalForm !== 'add');

    if (type === 'select') {
      return renderSelect(
        label,
        placeholder,
        key
      );
    } else if (type === 'search') {
      return renderSearchShowroom(
        label,
        placeholder,
        key
      );
    }

    return (
      <Input
        label={ label }
        placeholder={ placeholder }
        labelColor={ Colors.grey.isGrey }
        value={ form[key] }
        name={ key }
        errorMessage={ errorForm[key] }
        onChange={ onChangeFormText }
        mb={ 20 }
        backgroundColor={ Colors.white.default }
        disabled={ inputDisabled }
      />
    );
  };

  const onClickSelectItem = ({ key }: { key: string; }, keyForm: string) => {
    setVisibleSelect(prevState => ({
      ...prevState,
      [keyForm]: false
    }));

    setTimeout(() => {
      setForm(prevForm => ({
        ...prevForm,
        [keyForm]: key
      }));

      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        [keyForm]: ''
      }));
    }, 300);
  };

  const onVisibleSelectChange = (visible: boolean, keyForm: string) => {
    setVisibleSelect(prevState => ({
      ...prevState,
      [keyForm]: visible
    }));
  };

  const setSelectOptions = (key: string) => {
    const variantScootersType = variantScooters && variantScooters?.data?.length
      ? variantScooters?.data?.map(variant => `${ variant?.type } - ${ variant?.color ?? '' }`)
      : [];

    switch (key) {
      case 'status':
        return scooterStatus;
      case 'variant_id':
        return variantScootersType;
      case 'location':
        return scooterLocation;
      default:
        return [];
    }
  };

  const renderSelect = (
    label: string,
    placeholder: string,
    key: string
  ) => {
    const selectOptions = setSelectOptions(key);
    const loadingSelect = misc.isLazyLoading(lazyLoad, 'allVariantScooter');

    return (
      <Select
        type='form'
        visible={ visibleSelect[key] }
        onVisibleChange={ (visible: boolean) => onVisibleSelectChange(visible, key) }
        selectTitle={ form[key] }
        label={ label }
        placeholder={ placeholder }
        onClickSelectItem={ (params: MenuEntity) => onClickSelectItem(params, key) }
        selectOptions={ selectOptions }
        backgroundColor={ Colors.white.default }
        isDisabled={ actionModalForm === 'detail' }
        errorMessage={ errorForm[key] }
        loading={ loadingSelect }
      />
    );
  };

  const renderSearchShowroom = (
    label: string,
    placeholder: string,
    key: string
  ) => {
    const loadingGetShowroomBySearch = misc.isLazyLoading(lazyLoad, 'allShowroom');

    return (
      <SearchInput
        label={ label }
        labelColor={ Colors.grey.isGrey }
        value={ form.showroom_id > 0 ? form.showroom_id : '' }
        placeholder={ placeholder }
        data={ dataSearchShowroom }
        loading={ loadingGetShowroomBySearch }
        searchValue={ searchShowroom }
        onSearch={ handleSearchShowroom }
        onChange={ handleChangeSearchShowroom }
        onDropdownVisibleChange={ onDropdownShowroomVisibleChange }
        disabled={ actionModalForm === 'detail' }
        errorMessage={ errorForm[key] }
      />
    );
  };

  const handleSearchShowroom = useCallback((query: string) => {
    setSearchShowroom(query);
  }, []);

  const handleInitSearchShowroom = value => {
    const selectedShowroom = dataSearchShowroom?.find(showroom => showroom?.value?.toString() === value?.toString());
    const textOnBoxInput = selectedShowroom ? selectedShowroom.text : '';

    setSearchShowroom(textOnBoxInput);
  };

  const handleChangeSearchShowroom = value => {
    handleInitSearchShowroom(value);

    setForm(prevForm => ({
      ...prevForm,
      showroom_id: + value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      showroom_id: ''
    }));
  };

  const renderContentModalForm = () => {
    return inputPropsData.map((input, index) => {
      return (
        <div key={ `form-add-scooter-${ index }` }>
          { renderInput(
            input.label,
            input.placeholder,
            input.key,
            input.type
          ) }
        </div>
      );
    });
  };

  const handleCbFormScooter = async(message: string) => {
    await onCloseModalForm();

    await toastify.success(message);

    setRefreshing(true);
  };

  const onClickButtonModalForm = () => {
    const emptyForm = misc.getMultipleKeyByArrOfValue(form, [
      '',
      placeholder.scooterStatus,
      placeholder.scooterType,
    ]).filter(keyForm => keyForm !== 'plat_number');

    if (!emptyForm.length) {
      const selectedVariant = variantScooters?.data?.find(variant => `${ variant?.type } - ${ variant?.color ?? '' }` === form.variant_id);

      const formPayload = {
        ...form,
        vin: Number(form.vin),
        plat_number: form.plat_number || '-',
        variant_id: selectedVariant ? selectedVariant.id : 1
      };

      if (actionModalForm === 'add') {
        addScooter(formPayload, handleCbFormScooter);
      } else if (actionModalForm === 'edit') {
        editScooter(
          editedVin,
          formPayload,
          handleCbFormScooter
        );
      }
    } else {
      emptyForm.forEach(key => {
        inputPropsData.forEach(input => {
          if (key === input.key) {
            setErrorForm(prevErrorForm => ({
              ...prevErrorForm,
              [key]: errorFormMessage.form(input.label.toLowerCase())
            }));
          }
        });
      });
    }
  };

  const renderModalLoader = () => {
    return (
      <ModalLoader
        visible={ modalLoaderVisible }
        onCloseModal={ () => setModalLoaderVisible(false) }
        afterClose={ () => {
          if (actionModalForm) {
            setModalFormVisible(true);
          }
        } }
      />
    );
  };

  const renderTopLoadingBar = () => {
    return (
      <TopLoaderBar
        progress={ goToScooterDetail?.progress }
        onLoaderFinished={ () => navigation.push(`/dashboard/inventory/${ goToScooterDetail?.id }${ goToScooterDetail?.route }`) }
      />
    );
  };

  const onCloseModalReport = () => {
    setModalReportState(prevState => ({
      ...prevState,
      visible: false
    }));

    setEditedVin('');
  };

  const onClickReportMissing = () => {
    if (modalReportState.type === 'missing') {
      editScooterStatusAction(language.scooters.missing, handleCbReportScooter);
    } else {
      const scooterEdit = scooters?.data?.find(scooter => + scooter?.vin === + editedVin);
      let nextStatus = '';
      if (scooterEdit) {
        if (scooterEdit?.username) {
          nextStatus = 'Commission';
        } else {
          nextStatus = 'Uncommission';
        }
      }

      editScooterStatusAction(nextStatus, handleCbReportScooter);
    }
  };

  const handleCbReportScooter = async(message: string) => {
    await onCloseModalReport();

    await toastify.success(message);

    setRefreshing(true);
  };

  const renderModalReportMissing = () => {
    const loadingReport = misc.isLazyLoading(lazyLoad, 'editScooter');

    return (
      <ModalReport
        visible={ modalReportState.visible }
        onCloseModal={ onCloseModalReport }
        onClickConfirm={ onClickReportMissing }
        type={ modalReportState.type }
        loadingConfirm={ loadingReport }
      />
    );
  };

  return (
    <Dashboard
      container={ {
        selectedMenu: 3,
        headerContent: {
          textHeader: header.title,
          textBtn: header.textBtn,
          iconNameBtn: 'add',
          onClickBtn: () => onClickOpenModal('add'),
          permissionBtn: Permission.inventory_create
        },
        openSubmenu: true
      } }
      filterMenu={ {
        activeFilter,
        dropdownsFilter,
        dropdownsFilterFetch,
        onClickMenuItem,
        search,
        placeholderSearch: 'Search',
        onChangeSearch
      } }
      data={ {
        currentData: scooters?.data,
        loading: loadingScooters,
        fieldName: 'scooters',
        getCurrentPagination
      } }
      table={ {
        columns,
        buttons: buttonsDataTable,
        permissionClickRow: Permission.inventory_detail,
        onClickRow: (record: Scooter) => onClickRow(record, ''),
        onChange: onChangeTable
      } }
      modalForm={ {
        title: 'scooter',
        visible: modalFormVisible,
        actionModal: actionModalForm,
        onCloseModal: onCloseModalForm,
        footer: {
          fieldName: 'scooter',
          onClickEdit: () => onClickOpenModal('edit'),
          onClickButtonSubmit: onClickButtonModalForm,
        },
        contentModal: renderContentModalForm(),
        permissions: { edit: Permission.inventory_update }
      } }
      topLoadingBar={ renderTopLoadingBar() }
      modalLoader={ renderModalLoader() }
    >
      { renderModalReportMissing() }
    </Dashboard>
  );
};

export default Scooters;
