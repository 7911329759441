import React, {
  useState, useCallback, useEffect
} from 'react';

import {
  Box,
  Text,
  Icon,
  Modal,
  EmptyState,
  Loader,
  Pagination
} from 'components';
import { Colors, Images } from 'consts';
import {
  hooks,
  misc,
  moment
} from 'helpers';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';
import { CurrentPagination } from 'interfaces/common';
import { VinLog } from 'interfaces/battery';

type BoxVinLogProps = {
  widthBox: number;
  routeId: string;
};

const BoxVinLog: React.FC<BoxVinLogProps> = ({
  widthBox,
  routeId
}) => {
  const dispatch = hooks.useAppDispatch();
  const getSwapLog = dispatch(actions.getBatteryVinLog);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const vinLog = hooks.useAppSelector(selectors.battery.vinLog);
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (routeId) {
      getSwapLog({
        limit: 10,
        page: currentPage,
        sort: 'desc',
        order: 'created_date',
        transaction_id: String(routeId),
        log_type: 'battery'
      });
    }
  }, [currentPage]);

  const renderEmptyData = () => {
    return (
      <div className='mt4'>
        <EmptyState
          background={ { height: 285 } }
          image={ {
            src: Images.emptyState.batteryStations,
            width: 56,
            height: 56
          } }
          text='No VIN Log Data'
        />
      </div>
    );
  };

  const renderTitleSubContent = (
    titleLeft: string,
    titleRight?: string,
    action?: () => void
  ) => {
    return (
      <div className='justify-align-center'>
        <Text
          size='m'
          weight={ 700 }
          lineHeight={ 19 }
        >{ titleLeft }</Text>
        {
          titleRight ? (
            <Text
              weight={ 700 }
              color={ Colors.blue.isBlue }
              align='right'
              onClick={ action }
            >{ titleRight }</Text>
          ) : null
        }
      </div>
    );
  };

  const renderDataLocation = (allData: boolean) => {
    const sliceDataVinLog = allData || vinLog?.data?.length <= 5 ? vinLog?.data : vinLog?.data?.slice(0, 5);
    const loadingVinLog = misc.isLazyLoading(lazyLoad, 'vinLogBatteryPack');

    if (loadingVinLog && !allData) {
      return <Loader className='col center-content' style={ { marginTop: 25 } } />;
    }

    if (vinLog?.data?.length) {
      return sliceDataVinLog?.map((data, index) => {
        return (
          <div key={ index } className={ `flex row align-center ${ index === 0 ? 'mt2' : 'mt4' }` }>
            <Icon
              size={ 17 }
              iconName='location_2'
              fill={ Colors.black.isBlack }
              container='circle'
              containerSize={ 36 }
            />
            <div className='ml3'>
              <Text mb={ 5 }>{ data.description }</Text>
              <Text size='xs' color={ Colors.grey.isGrey }> { moment.utcToLocal(data?.created_date, 'llll') }</Text>
            </div>
          </div>
        );
      });
    }

    return renderEmptyData();
  };

  const onCloseModal = useCallback(() => setModalVisible(false), []);

  const getCurrentPaginationVinLog = (currentPagination: CurrentPagination<VinLog[]>) => {
    if (currentPagination.currentPage <= currentPagination.pagesCount) {
      setCurrentPage(currentPagination?.currentPage);
    }
  };

  const renderPaginationVinLog = () => {
    const loadingVinLog = misc.isLazyLoading(lazyLoad, 'vinLogBatteryPack');

    if (loadingVinLog) {
      return <Loader className='col center-content' style={ { marginTop: 25 } } />;
    }

    if (currentPage < vinLog?.pagination?.page_total) {
      return (
        <div className='mt5'>
          <Pagination
            type='lazyLoad'
            fieldName='batteryVinLog'
            data={ vinLog?.data }
            getCurrentPagination={ getCurrentPaginationVinLog }
          />
        </div>
      );
    }
  };

  const renderModalSeeAll = () => {
    return (
      <Modal
        visible={ modalVisible }
        onCloseModal={ onCloseModal }
        modalType='fixed-scroll'
        title={ () => renderTitleSubContent('VIN Log') }
      >
        { renderDataLocation(true) }
        { renderPaginationVinLog() }
      </Modal>
    );
  };

  return (
    <>
      <Box
        mt={ 20 }
        mr={ 20 }
        padding={ 20 }
        width={ widthBox }
      >
        <div className='mb6'>
          { renderTitleSubContent(
            'VIN Log',
            vinLog?.data?.length > 5 ? 'See All' : '',
            () => vinLog?.data?.length > 5 ? setModalVisible(true) : null
          ) }
        </div>

        { renderDataLocation(false) }
      </Box>
      { renderModalSeeAll() }
    </>
  );
};

export default BoxVinLog;
