import Styled from 'styled-components';

import { Colors } from 'consts';

export const DataTableStyle: any = Styled.div `
  thead > tr > th {
    color: ${Colors.grey.isGrey};
    font-family: InterMedium;
    font-size: 14px;
    line-height: 19px;
    background-color: ${Colors.grey.isGreyOpacity('0.05')};
  }

  .loader-spin {
    position: relative;
    display: inline-block;
  }

  .spin-wrapper > div > loader-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px;
  }
`;
