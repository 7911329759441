import { language } from 'language';

const {
  label,
  placeholder,
  subscriptions: subsLang
} = language;

export const setInputPropsData = (subsType: string) => {
  const isTimeBase = subsType === subsLang.type.timeBase;

  return [
    {
      label: label.type,
      placeholder: placeholder.type,
      key: 'subscription_type',
      type: 'radio_group',
      data: [subsLang.type.timeBase, subsLang.type.swapBase]
    },
    {
      label: label.subscriptionName,
      placeholder: placeholder.subscriptionName,
      key: 'name',
      type: 'text'
    },
    {
      label: label.description,
      placeholder: placeholder.description,
      key: 'description',
      type: 'textArea'
    },
    {
      label: isTimeBase ? 'City' : '',
      placeholder: 'Choose Cities',
      key: 'cities',
      type: isTimeBase ? 'multiSelect' : 'hidden',
    },
    {
      label: isTimeBase ? label.duration : label.swap,
      placeholder: isTimeBase ? placeholder.duration : placeholder.swap,
      key: isTimeBase ? 'duration' : 'swap_amount',
      type: 'number'
    },
    {
      label: label.price,
      placeholder: placeholder.price,
      key: 'price',
      type: 'currency'
    },
    {
      label: label.setAvailability,
      placeholder: placeholder.setAvailability,
      key: 'is_active',
      type: 'radio_group',
      data: [subsLang.status.inactive, subsLang.status.active]
    },
  ];
};

export const availabilityOptions = [
  subsLang.status.allStatus,
  subsLang.status.active,
  subsLang.status.inactive
];

export const subscriptionTypeOptions = [
  subsLang.type.allType,
  subsLang.type.timeBase,
  subsLang.type.swapBase
];

export const dropdownsFilter = [
  {
    key: 'type',
    selectOptions: subscriptionTypeOptions
  }, {
    key: 'status',
    selectOptions: availabilityOptions
  }
];
