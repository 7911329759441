import Styled from 'styled-components';

import { screen } from 'helpers';
import { Colors } from 'consts';

type SubsStyleProps = {
  isActiveSubs?: boolean;
};
export const ItemDropdown: any = Styled.div`
	display: flex;
	padding: 10px 10px 10px 0;

	.ant-select-item-option-content {
		white-space: normal !important;
	}

	.container-icon {
		flex-shrink: 0;
		margin-right: 11px;
	}

	.item-text-wrapper {
		display: flex;
		width: 100%;
		flex-direction: column;
		row-gap: 10px;
	}

	.description-text {
		overflow-wrap: break-word;
		white-space: normal;
	}
`;
export const SubsStyle: any = Styled.div<SubsStyleProps>`
  .subs-status-title {
    background: ${ Colors.grey.isBgGrey };
    padding: 15px;
    border-radius: 10px 10px 0 0;
    height: 47px;
  }

  .subs-status-desc {
    padding: 15px;
    
    ${ props => props.isActiveSubs ? `
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
    ` : `
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    ` }
  }

  .subs-status-name {
    ${ props => props.isActiveSubs ? 'grid-column: span 2 / span 2;' : '' }
  }
`;

export const BoxUserInfoStyle: any = Styled.div`
  .text-showroom-right {
    width: 200px;
  }

  .img-legal {
    margin-right: 15px;
  }

  .btn-verify {
    padding: 7px 10px;
  }

  .btn-assign-showroom {
    padding: 9px 20px;
  }

  .divider-showroom {
    border-width: 3px;
  }

  .user-data-container {
    width: 60%;
  }

  .box-last-trip-skeleton > .ant-skeleton-avatar {
    border-radius: 8px !important;
    height: 42px !important;
    width: 100% !important;
  }

  @media ${ screen.sizesMax.tablet } {
    .user-data-container {
      width: 100%;
    }
  }

  @media ${ screen.sizesMax.xs } {
    .img-user-detail {
      width: 60px;
      height: 60px;
    }

    .user-data {
      margin-bottom: 3px;
    }
  }

  @media screen and (max-width: 400px) {
    .user-data-container {
      width: 65%;
    }
  }

  @media ${ screen.sizesMax.xxs } {
    .img-user-detail {
      width: 40px;
      height: 40px;
    }

    .user-data {
      margin-bottom: 0px;
    }

    .user-data-container {
      margin-left: 5px;
    }
  }
`;

export const ModalAssignShowroom: any = Styled.div`
  .content {
    padding: 0px 10px;
    margin-top: 10px;
  }

  .search-container {
    padding: 0 10px;
    margin: 0 -18px 25px 16px;
  }

  .floating-btn {
    padding: 20px;
  }
`;

type WrapperLinkedUsersStyle = {
  useAllData: boolean;
};

export const WrapperLinkedUsersStyle: any = Styled.div<WrapperLinkedUsersStyle>`
  display: flex;
  flex-direction: ${ props => !props.useAllData ? 'row' : 'column' };
  flex-wrap: wrap;
  margin-top: 20px;
  ${ props => !props.useAllData
    ? `
      column-gap: 20px;
      row-gap: 15px;
    `
    : '' }
`;