import React from 'react';
import { Divider } from 'antd';

import { DividerStyle } from './style';

type DividerProps = {
  className?: string;
  dashed?: boolean;
  orientation?: 'left' | 'center' | 'right';
  children?: React.ReactNode;
  type?: 'vertical' | 'horizontal';
  plain?: boolean;
  marginVertical?: number;
  marginHorizontal?: number;
  color?: string;
};

const CustomDivider: React.FC<DividerProps> = ({
  children,
  className = '',
  dashed = false,
  orientation,
  type,
  plain,
  marginHorizontal,
  marginVertical,
  color
}) => {
  return (
    <DividerStyle
      marginHorizontal={ marginHorizontal }
      marginVertical={ marginVertical }
      color={ color }
    >
      <Divider
        className={ `margin-divider ${ className }` }
        dashed={ dashed }
        orientation={ orientation }
        type={ type }
        plain={ plain }
      >
        { children && children }
      </Divider>
    </DividerStyle>
  );
};

CustomDivider.defaultProps = {
  dashed: false,
  type: 'horizontal',
  plain: true,
  marginHorizontal: 0,
  marginVertical: 0
};

export default CustomDivider;
