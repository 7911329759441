import Styled from 'styled-components';

import { screen } from 'helpers';

export const ButtonActionStyle: any = Styled.div`
	display: flex;
	align-items: center;
	column-gap: 10px;
	row-gap: 5px;

	@media ${ screen.sizesMax.xs } {
		flex-wrap: wrap;
		justify-content: flex-end;
		column-gap: 5px;
	}
`;