import React, { useState } from 'react';
import { Popover } from 'antd';

import { Colors } from 'consts';
import { language } from 'language';

import Button from '../Button';
import Text from '../Text';

type PopconfirmProps = {
	text?: string;
	onConfirm?: () => void;
	onCancel?: () => void;
	children: React.ReactNode;
};

const CustomPopconfirm: React.FC<PopconfirmProps> = ({
	text,
	onConfirm,
	onCancel,
	children
}) => {
	const [visible, setVisible] = useState<boolean>(false);

	const hidePopconfirm = () => {
		setVisible(false);
	};

	const handleVisibleChange = (newVisible: boolean) => {
		setVisible(newVisible);
	};

	const onClickCancel = () => {
		hidePopconfirm();

		if (onCancel) onCancel();
	};

	const onClickConfirm = () => {
		hidePopconfirm();

		if (onConfirm) onConfirm();
	};

	const contentPopConfirmDelete = () => {
		return (
			<div className='flex col'>
				<Text color={ Colors.black.isBlackOpacity('0.6') }>{ text || language.confirmDelete }</Text>

				<div className='flex align-center row-end mt3 gap2'>
					<Button
						onClick={ onClickCancel }
						bgColor='grey'
						borderColor={ Colors.grey.isLightGrey }
						width={ 42 }
						height={ 30 }
						text={ language.no }
					/>

					<Button
						width={ 42 }
						height={ 30 }
						onClick={ onClickConfirm }
						text={ language.yes }
					/>
				</div>
			</div>
		);
	};

	return (
		<Popover
			trigger='click'
			visible={ visible }
			onVisibleChange={ handleVisibleChange }
			content={ contentPopConfirmDelete }
		>
			{ children }
		</Popover>
	);
};

export default CustomPopconfirm;
