import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

import { handleBackgroundColor, handleBorder } from '../style';

type TextAreaStyleProps = {
  isFocused?: boolean;
  fontSize?: number;
  fontFamily?: string;
  lineHeight?: number;
  backgroundColor?: string;
  disabled?: boolean;
  iconName?: any;
  iconPosition?: 'left' | 'right';
};

export const TextAreaStyle: any = Styled.textarea<TextAreaStyleProps>`
  width: 100%;
  min-height: 44px;
  max-height: 90px;
  padding: 10px;
  outline: none;
  border: 1px solid ${ props => handleBorder(props.isFocused) };
  border-radius: 10px;
  resize: none;

  font-family: ${ props => props.fontFamily };
  font-size: ${ props => props.fontSize }px;
  color: ${ Colors.black.isBlack };
  line-height: ${ props => props.lineHeight }px;

  background-color: ${ props => handleBackgroundColor(
  props.isFocused,
  props.backgroundColor,
  props.disabled
) };

  ${ props => props.iconName && props.iconPosition === 'left' ? 'padding-left: 40px;' : '' }

  ::placeholder {
    font-type: InterMedium;
    font-size: ${ props => props.fontSize }px;
    color: ${ Colors.grey.isGrey };
  }

  @media ${ screen.sizesMax.xs } {
    font-size: ${ props => props.fontSize && props.fontSize > 12 ? props.fontSize - 2 : props.fontSize }px;

    ::placeholder {
      font-size: ${ props => props.fontSize && props.fontSize > 12 ? props.fontSize - 2 : props.fontSize }px;
    }
  }

  @media ${ screen.sizesMax.xxs } {
    ${ props => props.fontSize ? `font-size: ${ props.fontSize - 4 }px;` : '' };

    ::placeholder {
      ${ props => props.fontSize ? `font-size: ${ props.fontSize - 4 }px;` : '' };
    }
  }
`;
