import { Pagination } from 'interfaces/common';
import {
	DataTicketing,
	PastSimiliarCase,
	TicketingDetail
} from 'interfaces/ticketing';

import {
	GET_ALL_CASE,
	GET_DETAIL_CASE,
	GET_PAST_SIMILIAR_CASE,
	LOGOUT,
	CaseActionTypes
} from './actionTypes';

const initCaseDetail: TicketingDetail = {
	id: 0,
	case_code: '',
	cust_name: '',
	cs_name: '',
	vin: '',
	created_date: '',
	note: '',
	description: '',
	is_notified: true,
	vehicle_detail: {
		cust_name: '',
		cust_image: '',
		cust_email: '',
		cust_phone: '',
		image: [],
		vin: '',
		type: '',
		plat_no: '',
		battery_status: ''
	},
	images: []
};

export interface CaseState {
	cases: {
		open: {
			data: DataTicketing[];
			pagination: Pagination;
		};
		closed: {
			data: DataTicketing[];
			pagination: Pagination;
		};
		pending: {
			data: DataTicketing[];
			pagination: Pagination;
		};
	};
	case: TicketingDetail;
	pastSimiliarCase: PastSimiliarCase[];
}

const initPagination: Pagination = {
	count: 0,
	limit: 0,
	offset: 0,
	order: '',
	page: 0,
	page_total: 0,
	search: '',
	sort: 'asc'
};

const initState: CaseState = {
	cases: {
		open: {
			data: [],
			pagination: initPagination
		},
		pending: {
			data: [],
			pagination: initPagination
		},
		closed: {
			data: [],
			pagination: initPagination
		}
	},
	case: initCaseDetail,
	pastSimiliarCase: []
};

const caseReducer = (state = initState, action: CaseActionTypes): CaseState => {
	switch (action.type) {
		case GET_ALL_CASE:
			return {
				...state,
				cases: {
					...state.cases,
					[action.data.status]: {
						data: action.data.data,
						pagination: action.data.pagination
					}
				}
			};
		case GET_DETAIL_CASE:
			return {
				...state,
				case: !action.data
					? initCaseDetail
					: action.data,
			};
		case GET_PAST_SIMILIAR_CASE:
			return {
				...state,
				pastSimiliarCase: action.data,
			};
		case LOGOUT:
			return initState;
		default:
			return state;
	}
};

export default caseReducer;
