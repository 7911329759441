import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

type InputStyleProps = {
  isFocus?: boolean;
  backgroundColor?: string;
  borderRadius?: number;
  width?: number | string;
  iconName?: any;
  iconPosition?: 'left' | 'right';
  fontSize?: number;
  lineHeight?: number;
  fontFamily?: string;
  disabled?: boolean;
};

type IconInputStyleProps = {
  iconName?: string;
  iconPosition?: 'left' | 'right';
  type?: string;
};

export const handleBorder = isFocus => {
  if (isFocus) {
    return Colors.blue.isBlue;
  }

  return Colors.grey.isLighterGrey;
};

export const handleBackgroundColor = (
  isFocus,
  backgroundColor,
  disabled
) => {
  if (isFocus) {
    return Colors.white.default;
  }

  if (backgroundColor && !disabled) {
    return backgroundColor;
  }

  return Colors.grey.isBgLightGrey;
};

export const InputStyle: any = Styled.input < InputStyleProps > `
  height: 44px;
  border-radius: ${ props => props.borderRadius }px;
  border: 1px solid ${ props => handleBorder(props.isFocus) };
  padding: 0px 10px;
  outline: none;
  width: ${ props => typeof props.width === 'string' ? props.width : props.width + 'px' };
  font-family: ${ props => props.fontFamily };
  font-size: ${ props => props.fontSize }px;
  color: ${ Colors.black.isBlack };
  line-height: ${ props => props.lineHeight }px;
  background-color: ${ props => handleBackgroundColor(
  props.isFocus,
  props.backgroundColor,
  props.disabled
) };
  ${ props => props.iconName && props.iconPosition === 'left' ? 'padding-left: 40px;' : '' }

  ::placeholder {
    font-family: InterMedium;
    font-size: ${ props => props.fontSize }px;
    color: ${ Colors.grey.isGrey };
  }

  @media ${ screen.sizesMax.xs } {
    font-size: ${ props => props.fontSize && props.fontSize > 12 ? props.fontSize - 2 : props.fontSize }px;

    ::placeholder {
      font-size: ${ props => props.fontSize && props.fontSize > 12 ? props.fontSize - 2 : props.fontSize }px;
    }
  }

  @media ${ screen.sizesMax.xxs } {
    ${ props => props.fontSize ? `font-size: ${ props.fontSize - 4 }px;` : '' };

    ::placeholder {
      ${ props => props.fontSize ? `font-size: ${ props.fontSize - 4 }px;` : '' };
    }
  }
`;

export const RadioGroupStyle: any = Styled.div < InputStyleProps > `
  .ant-radio-checked .ant-radio-inner{
    border-color: #39AAB4 !important ;
  }

  .ant-radio-checked .ant-radio-inner:after{
    background-color: #39AAB4;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #39AAB4 ;
  }
  
  .ant-radio-checked .ant-radio-inner:focus{
    border-color: #39AAB4;
  }

  .radio-label {
    font-family: ${ props => props.fontFamily };
    font-size: ${ props => props.fontSize }px;
    color: ${ Colors.black.default };
  }
`;

export const DatePickerStyle: any = Styled.div < InputStyleProps > `
  .ant-picker-input > input::placeholder {
    font-family: ${ props => props.fontFamily };
    font-size: ${ props => props.fontSize }px;
    color: ${ Colors.grey.isGrey };
  }

  .ant-picker.ant-picker-focused {
    border: 1px solid ${ props => handleBorder(props.isFocus) } !important;
    box-shadow: none !important;
  }

  .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: ${ Colors.blue.isBlue } !important;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today::before .ant-picker-cell-inner::before {
    border: 1px solid ${ props => handleBorder(props.isFocus) } !important;
    box-shadow: none !important;
  }

  .ant-picker {
    height: 44px;
    border-radius: ${ props => props.borderRadius }px;
    border: 1px solid ${ props => handleBorder(props.isFocus) };
    padding: 0px 10px;
    outline: none;
    width: ${ props => typeof props.width === 'string' ? props.width : props.width + 'px' };
    font-family: ${ props => props.fontFamily };
    font-size: ${ props => props.fontSize }px;
    color: ${ Colors.black.isBlack };
    line-height: ${ props => props.lineHeight }px;
    background-color: ${ props => handleBackgroundColor(
  props.isFocus,
  props.backgroundColor,
  props.disabled
) } !important;
    cursor: ${ props => props.disabled ? 'default' : 'text' } !important;
  }

  .ant-picker-input > input[disabled] {
    font-family: ${ props => props.fontFamily };
    font-size: ${ props => props.fontSize }px;
    color: ${ Colors.black.isBlack };
    cursor: ${ props => props.disabled ? 'default' : 'text' } !important;
  }
`;

export const IconInputStyle: any = Styled.div<IconInputStyleProps>`
  z-index: 100;
  
  padding-top: ${ props => props.type === 'textArea' && props.iconPosition === 'left' ? '10px' : '0' };
  align-items: ${ props => props.type === 'textArea' && props.iconPosition === 'left' ? 'start' : 'center' };
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: ${ props => props.iconPosition === 'left' ? 'none' : '' };

  padding-left: ${ props => props.iconPosition === 'left'
    ? props.type === 'textArea'
      ? '12px'
      : '15px'
    : 0 };
  ${ props => props.iconPosition === 'left' ? 'left: 0;' : 'right: 0; padding-right: 15px;' }
`;