import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container, Text } from 'components';
import { Colors } from 'consts';
import {
  navigation,
  hooks,
  screen,
  toastify
} from 'helpers';
import { PairedScooter } from 'interfaces/user';
import { Permission } from 'interfaces/role';
import { language } from 'language';
import * as actions from 'store/actions';

import { UserDetailStyle } from './style';
import {
  BoxStations,
  BoxUserInfo,
  BoxScooter,
  BoxLegalInfo
} from './components';

interface RouteParams {
  userId: string;
}

type WidthBox = {
  userInfo: number | string;
  legalInfo: number | string;
  scooter: number | string;
  stations: number | string;
};

const initActiveScooterCarousel: PairedScooter = {
  vin: '',
  status: '',
  plat_number: '',
  scooter_status: '',
  variant_type: '',
  scooter_color: '',
  scooter_image: '',
  user_scooter: [],
  subscription: {
    name: '',
    end_date: '',
    subscription_type: '',
    swap_amount: 0
  }
};

export const renderTitleSubContent = (
  titleLeft: string,
  titleRight?: string,
  action?: () => void
) => {
  return (
    <div className='justify-align-center'>
      <Text
        size='m'
        weight={ 700 }
        lineHeight={ 19 }
      >{ titleLeft }</Text>
      {
        titleRight ? (
          <Text
            weight={ 700 }
            color={ Colors.blue.isBlue }
            lineHeight={ 17 }
            align='right'
            onClick={ action }
          >{ titleRight }</Text>
        ) : null
      }
    </div>
  );
};

const UserDetail: React.FC = () => {
  const params = useParams<RouteParams>();
  const dispatch = hooks.useAppDispatch();

  const getUserDetail = dispatch(actions.getUserDetail);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const [activeScooterCarousel, setActiveScooterCarousel] = useState<PairedScooter>(initActiveScooterCarousel);
  const [refreshing, setRefreshing] = useState<boolean>(true);

  useEffect(() => {
    if (refreshing) {
      getUserDetail(params?.userId);

      setRefreshing(false);
    }
  }, [refreshing]);

  const onClickGoBack = () => navigation.goBack();

  const onActiveScooter = (scooter: PairedScooter) => {
    if (scooter) {

      setActiveScooterCarousel(scooter);
    } else {
      setActiveScooterCarousel(initActiveScooterCarousel);
    }
  };

  const renderBoxUserInfo = (widthBox: number | string) => {
    return (
      <BoxUserInfo
        widthBox={ widthBox }
        activeScooter={ activeScooterCarousel }
        setRefreshing={ setRefreshing }
      />
    );
  };

  const renderBoxLegalInfo = (widthBox: number | string) => {
    return <BoxLegalInfo widthBox={ widthBox } setRefreshing={ setRefreshing } />;
  };

  const renderBoxScooter = (widthBox: number | string) => {
    return (
      <BoxScooter
        widthBox={ widthBox }
        onActiveScooter={ onActiveScooter }
        setRefreshing={ setRefreshing }
      />
    );
  };

  const renderBoxStations = (widthBox: number | string) => {
    return <BoxStations widthBox={ widthBox } activeScooter={ activeScooterCarousel } />;
  };

  const renderContent = () => {
    const { width: screenWidth } = windowDimensions;
    const widthContent = screenWidth - 240;

    let widthBox: WidthBox = {
      userInfo: widthContent / 2.5,
      legalInfo: (widthContent / 1.675) - 40,
      scooter: (widthContent / 2.85) - 40,
      stations: (widthContent / 4) - 20,
    };

    if (screenWidth >= screen.sizes.md2) {
      return (
        <div className='flex row'>
          <div className='mr4'>
            { renderBoxUserInfo(widthBox.userInfo) }
          </div>

          <div className='outer-container-right'>
            { renderBoxLegalInfo(widthBox.legalInfo) }
            <div className='flex row'>
              <div className='mr4'>
                { renderBoxScooter(widthBox.scooter) }
              </div>
              <div>
                { renderBoxStations(widthBox.stations) }
              </div>
            </div>
          </div>
        </div>
      );
    } else if (screenWidth > screen.sizes.tablet && screenWidth < screen.sizes.md2) {
      widthBox = {
        userInfo: (widthContent / 2) - 40,
        legalInfo: widthContent / 2.01,
        scooter: widthContent / 2.01,
        stations: widthContent - 20,
      };

      return (
        <div>
          <div className='flex row'>
            <div className='mr4'>
              { renderBoxUserInfo(widthBox.userInfo) }
            </div>
            <div>
              { renderBoxLegalInfo(widthBox.legalInfo) }
              { renderBoxScooter(widthBox.scooter) }
            </div>
          </div>
          { renderBoxStations(widthBox.stations) }
        </div>
      );
    } else if (screenWidth <= screen.sizes.tablet) {
      widthBox = {
        userInfo: '100%',
        legalInfo: '100%',
        scooter: '100%',
        stations: '100%',
      };
    }

    return (
      <div className='mb4'>
        { renderBoxUserInfo(widthBox.userInfo) }
        { renderBoxLegalInfo(widthBox.legalInfo) }
        { renderBoxScooter(widthBox.scooter) }
        { renderBoxStations(widthBox.stations) }
      </div>
    );
  };

  const onClickLiveTracking = () => {
    const vin = activeScooterCarousel?.vin;

    if (vin) {
      navigation.push(`/dashboard/inventory/${ vin }/live-tracking`);
    } else {
      toastify.error('Live tracking tidak tersedia');
    }
  };

  return (
    <Container
      navbar={ {
        backButton: 'circle',
        onClickBack: onClickGoBack
      } }
      selectedMenu={ 4 }
      headerContent={ {
        textHeader: language.userDetail.header.title,
        textBtn: 'Live Tracking',
        iconNameBtn: 'location',
        btnDisabled: !activeScooterCarousel?.vin,
        permissionBtn: [Permission.user_profile],
        onClickBtn: onClickLiveTracking
      } }
      withDrawer
      openSubmenu
    >
      <UserDetailStyle>
        { renderContent() }
      </UserDetailStyle>
    </Container>
  );
};

export default UserDetail;
