import React from 'react';

import { Colors } from 'consts';

import { BadgeStyle } from './style';

type BadgeProps = {
  children?: React.ReactNode;
  color?: string;
  border?: string;
  offset?: [number, number]; // [left, top]
  size?: number;
};

const Badge: React.FC<BadgeProps> = ({
  children,
  color = Colors.blue.isBlue,
  border = `2px solid ${Colors.white.default}`,
  offset = [0, 0],
  size = 14
}) => {
  return (
  <div className='flex row'>
    { children }
    <BadgeStyle
      color={ color }
      border={ border }
      offset={ offset }
      size={ size }
    />
  </div>
  );
};

export default Badge;
