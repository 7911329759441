import { Endpoints } from 'consts';
import { auth, customFetch } from 'helpers';
import { ApiData, CommonResponseData } from 'interfaces/common';
import {
  FormLogin,
  FormForgotPassword,
  FormResetPassword,
  AuthCredential,
  AdminLogin
} from 'interfaces/auth';

export const login = async(formLogin: FormLogin) => {
  try {
    const response: ApiData<AdminLogin> = await customFetch(Endpoints.login(), formLogin);

    return response;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async(formForgotPass: FormForgotPassword) => {
  try {
    const response: ApiData<CommonResponseData> = await customFetch(Endpoints.forgotPassword(), formForgotPass);

    return response;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async(formResetPassword: FormResetPassword) => {
  try {
    const response: ApiData<CommonResponseData> = await customFetch(Endpoints.resetPassword(), formResetPassword);

    return response;
  } catch (error) {
    throw error;
  }
};

export const refreshAccessToken = async() => {
  try {
    const response: ApiData<AuthCredential> = await customFetch(Endpoints.refreshToken());

    await auth.setAuthCredentials(response.data.access_token, response.data.refresh_token);

    return response.data.access_token;
  } catch (error) {
    throw error;
  }
};