import Styled from 'styled-components';

export type ScrollContainerStyleProps = {
	width?: number;
	yAxisWidth?: number;
	height?: number;
	heightAxis?: number;
};

export const ScrollContainerStyle: any = Styled.div<ScrollContainerStyleProps>`
	display: inline-block;
	overflow-x: auto;
	overflow-y: hidden;
	width: ${ props => (props.width || 200) - (props.yAxisWidth || 60) };
	height: ${ props => props.height ? `${ props.height }px` : 'auto' };
	margin-bottom: ${ props => -(props.heightAxis || 40) }px;
`;