import { Permission } from 'interfaces/role';
import { Topic } from 'interfaces/common';

export const topics: Topic[] = [
	{
		key: 1,
		title: 'Stats',
		route: '/dashboard/stats',
		icName: 'stats',
		submenus: [],
		permissions: [Permission.stats_all]
	},
	{
		key: 2,
		title: 'SAVART',
		route: '/dashboard/inventory',
		icName: 'scooter',
		submenus: [
			{
				key: 3,
				title: 'Inventory',
				route: '/dashboard/inventory',
				icName: '',
				submenus: [],
				permissions: [Permission.inventory_list]
			},
			{
				key: 4,
				title: 'Customer Profile',
				route: '/dashboard/users',
				icName: '',
				submenus: [],
				permissions: [Permission.users_list]
			},
			{
				key: 5,
				title: 'Showroom',
				route: '/dashboard/showroom',
				icName: '',
				submenus: [],
				permissions: [Permission.showroom_list]
			},
		]
	},
	{
		key: 6,
		title: 'Battery Packs',
		route: '/dashboard/battery-packs',
		icName: 'battery',
		submenus: [],
		permissions: [Permission.battery_list]
	},
	{
		key: 7,
		title: 'Battery Stations',
		route: '/dashboard/battery-stations',
		icName: 'batteryStations',
		submenus: [],
		permissions: [Permission.battery_station_list]
	},
	// {
	// 	key: 8,
	// 	title: 'Analytic Profile',
	// 	route: '/dashboard/analytic-profile',
	// 	icName: 'profile',
	// 	submenus: [],
	// 	permissions: []
	// },
	{
		key: 9,
		title: 'User Management',
		route: '/dashboard/user-management',
		icName: 'multiUsers',
		submenus: [],
		permissions: [Permission.admin_list]
	},
	{
		key: 10,
		title: 'Subscriptions',
		route: '/dashboard/subscriptions',
		icName: 'subs',
		submenus: [],
		permissions: [Permission.subscription_list]
	},
	{
		key: 11,
		title: 'Customer Services',
		route: '/dashboard/customer-services',
		icName: 'customerService',
		submenus: [],
		permissions: [Permission.call_me_list, Permission.case_list]
	},
];