import Styled from 'styled-components';

import { Colors } from 'consts';

export const ModalSolveStyles: any = Styled.div`
	.list {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		gap: 20px;

		.item {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid ${ Colors.grey.isLighterGrey };
			padding-bottom: 15px;
		}
	}

	.wrapper-upload-image {
		width: 100%;
		height: 100%;
		display: flex;
	}

	.upload-image {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		gap: 10px;

		.label {
			height: 220px;
			background: ${ Colors.grey.lighter };
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 10px;

			.images {
				display: flex;
				flex-wrap: wrap;
				gap: 6px;
				align-self: self-start;
				justify-self: self-start;
				height: 100%;
				width: 100%;

				.list {
					width: 100px;
					height: 100px;
				}

				.pick-file {
					position: relative;
					width: 100px;
					height: 100px;

					.text {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						display: flex;
						align-items: end;
					}
				}
			}
		}
	}
`;
