import { Pagination } from 'interfaces/common';
import { Admin, AdminDetail } from 'interfaces/admin';

import {
  GET_ALL_ADMIN,
  GET_ADMIN_DETAIL,
  LOGOUT,
  AdminActionTypes,
} from './actionTypes';

export interface AdminState {
  admins: {
    data: Admin[];
    pagination: Pagination;
  };
  adminDetail: AdminDetail;
}

const initState: AdminState = {
  admins: {
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
      order: '',
      page: 0,
      page_total: 0,
      search: '',
      sort: 'asc'
    }
  },
  adminDetail: {
    id: -1,
    name: '',
    email: '',
    username: '',
    phone_number: '',
    role_id: 0,
    showroom_id: 0,
    is_active: false,
    image: ''
  }
};

const adminReducer = (state = initState, action: AdminActionTypes): AdminState => {
  switch (action.type) {
    case GET_ALL_ADMIN:
			return {
        ...state,
        admins: action.data
      };

    case GET_ADMIN_DETAIL:
      return {
        ...state,
        adminDetail: action.data
      };

    case LOGOUT:
			return initState;

		default:
			return state;
  }
};

export default adminReducer;
