import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';
import colors from 'consts/colors';

type NavbarStyleProps = {
  hoverText?: boolean;
  backIconName?: string;
  screenWidth: number;
};

type NavbarDrawerStyleProps = {
  openSubmenu?: boolean;
  hoverText?: boolean;
  windowWidth?: number;
};

export const NavbarStyle: any = Styled.div < NavbarStyleProps > `
  height: 70px;
  background-color: ${ Colors.white.default };
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${ Colors.grey.isLighterGrey };

  .left-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: ${ props => props.screenWidth / 3 }px;
  }

  .mid-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: ${ props => props.screenWidth / 3 }px;
  }

  .right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    width: ${ props => props.screenWidth / 3 }px;
  }

  .back-container {
    margin-right: 10px;
    ${ props => props.hoverText ? `
      background-color: ${ Colors.blue.isBlueOpacity('0.1') };
      opacity: 0.8;
    ` : '' }
  }

  .back-icon {
    ${ props => props.backIconName === 'arrowDown' ? 'transform: rotate(90deg);' : '' }
    ${ props => props.hoverText ? `
      fill: ${ Colors.blue.isBlue };
      opacity: 0.8;
    ` : '' }
  }

  .back-text {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      color: ${ Colors.blue.isBlue } 
    }
  }
`;

export const NavbarDrawerStyle: any = Styled.div < NavbarDrawerStyleProps > `
  .navbar {
    padding: 0.6rem 2rem;
    background-color: ${ Colors.white.default };
    height: 70px;
    border-bottom: 1px solid ${ Colors.grey.isLighterGrey };
  }

  .menu-content-web {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    ${ props => props.windowWidth ? `width: ${ props.windowWidth - 245 }px;` : '' }
  }

  .left-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }

  .back-container {
    margin-right: 10px;
    ${ props => props.hoverText ? `
      background-color: ${ Colors.blue.isBlueOpacity('0.1') };
      opacity: 0.8;
    ` : '' }
  }

  .back-icon {
    transform: rotate(90deg);
    ${ props => props.hoverText ? `
      fill: ${ Colors.blue.isBlue };
      opacity: 0.8;
    ` : '' }
  }

  .back-text {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      color: ${ Colors.blue.isBlue } 
    }
  }

  .profile-container {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      object-fit: cover;
    }
  }

  .menu-content-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-mobile {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .menu-mobile:hover {
    opacity: 0.8;
  }

  .profile {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-left: 12px;
    padding: 5px;
  }

  .ic-arrow {
    margin-left: 67px;
    ${ props => props.openSubmenu ? 'transform: rotateX(180deg);' : '' }
  }

  @media screen ${ screen.sizesMin.sx } {
    .menu-content-mobile {
      display: none;
    }

    .navbar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 2rem;
    }
  }

  @media ${ screen.sizesMax.sx } {
    .menu-content-web {
      display: none;
    }
  }
`;

export const PopoverStyle: any = Styled.div`
  .content {
    display: flex;
    flex-direction: row;
    align-items: start;
    // border: 1px solid black;
    padding: 10px 5px;
    cursor: pointer;

    &:hover {
      background-color: ${ colors.blue.isBlueOpacity('0.05') };
    }
  }

  .popover-inner {
    max-height: 400px !important;
    overflow-y: auto !important;
    scrollbar-color: rgba(57, 170, 180, 0.4) rgba(57, 170, 180, 0.2);
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 4px;
      border-radius: 50px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      background: rgba(57, 170, 180, 0.2);
      border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(57, 170, 180, 0.4);
      border-radius: 50px;
    }

    ::-webkit-scrollbar-corner {
      border-radius: 50px;
    }
  }
`;