import { Showroom } from 'interfaces/showroom';
import { Pagination } from 'interfaces/common';

import {
  GET_SHOWROOMS,
  GET_SHOWROOM_DETAIL,
  LOGOUT,
  ShowroomActionTypes
} from './actionTypes';

interface ShowroomState {
  showrooms: {
    data: Showroom[];
    pagination: Pagination;
  };
  showroomDetail: Showroom;
}

const initState: ShowroomState = {
  showrooms: {
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
      order: '',
      page: 0,
      page_total: 0,
      search: '',
      sort: 'asc'
    }
  },
  showroomDetail: {
		id: 0,
		name: '',
		address: '',
		email: '',
		phone_number: '',
		pic_name: '',
		latitude: 0,
		longitude: 0,
		zip_code: '',
		city: '',
    is_pinned: false,
	}
};

const showroomReducer = (state = initState, action: ShowroomActionTypes): ShowroomState => {
  switch (action.type) {
    case GET_SHOWROOMS:
      return {
        ...state,
        showrooms: action.data
      };

    case GET_SHOWROOM_DETAIL:
      return {
        ...state,
        showroomDetail: action.data
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

export default showroomReducer;
