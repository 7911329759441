import Styled from 'styled-components';

import { screen } from 'helpers';

type BoxStyleProps = {
  hover?: boolean;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  padding?: number | string;
  borderRadius?: number;
  width?: number | string;
  height?: number | string;
  adjustWidth?: boolean;
  shadow?: string | false;
  bgColor?: string;
  border?: string | false;
};

const handleAdjustWidth = (adjustWidth, width) => {
  if (adjustWidth) {
    return `
      @media ${screen.sizesMax.sm} {
        width: ${(width || 200) - 75 + 'px'};
      }
    
      @media ${screen.sizesMax.st} {
        width: ${(width || 200) - 150 + 'px'};
      }
    `;
  }
};

export const BoxStyle: any = Styled.div < BoxStyleProps > `
  ${props => props.shadow ? `box-shadow: ${props.shadow};` : ''};
  ${props => props.border ? `border: ${props.border};` : ''};
  border-radius: ${props => (props.borderRadius || 10) + 'px'};
  ${props => typeof props.padding === 'number' ? `padding: ${props.padding}px` : `padding: ${props.padding}` };
  cursor: ${props => (props.hover ? 'pointer' : 'default')};
  margin-top: ${props => (props.mt || 0) + 'px'};
  margin-bottom: ${props => (props.mb || 0) + 'px'};
  margin-left: ${props => (props.ml || 0) + 'px'};
  margin-right: ${props => (props.mr || 0) + 'px'};
  ${props => typeof props.width === 'number' ? `width: ${props.width}px;` : `width: ${props.width};` };
  ${props => typeof props.height === 'number' ? `height: ${props.height}px;` : `height: ${props.height};` };
  background-color: ${props => props.bgColor };

  ${props => handleAdjustWidth(props.adjustWidth, props.width)}

  @media ${screen.sizesMax.xs} {
    ${props => typeof props.padding === 'number' ? `padding: ${props.padding / 2}px;` : '' };
  } 
`;
