import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { convertToFormData } from 'helpers/api';
import { ApiData } from 'interfaces/common';
import {
	CaseStatus,
	FormAssignCase,
	FormReassignCS,
	FormSolveCase,
	TicketingApiParams
} from 'interfaces/ticketing';

export const getAllCase = async(params: TicketingApiParams) => {
	try {
		const queryParams: TicketingApiParams = {
			...params,
			status: params.status !== CaseStatus.close ? params.status : '',
			action: params.status === CaseStatus.close ? params.status : ''
		};

		const response: ApiData<any> = await customFetch(Endpoints.getAllCase(), queryParams);

		return response;
	} catch (error) {
		throw error;
	}
};

export const assignCS = async(caseId: string, formAssign: FormAssignCase) => {
	try {
		const body = await convertToFormData(formAssign);

		const response: ApiData<any> = await customFetch(Endpoints.assignCS(caseId), body);

		return response;
	} catch (error) {
		throw error;
	}
};

export const takeCase = async(caseId: string) => {
	try {
		const response: ApiData<any> = await customFetch(Endpoints.takeCase(caseId));

		return response;
	} catch (error) {
		throw error;
	}
};

export const reassignCS = async(caseId: string, formReassign: FormReassignCS) => {
	try {
		const response: ApiData<any> = await customFetch(Endpoints.reassignCS(caseId), formReassign);

		return response;
	} catch (error) {
		throw error;
	}
};

export const solveCase = async(caseId: string, formSolveCase: FormSolveCase) => {
	try {
		const response: ApiData<any> = await customFetch(Endpoints.solveCase(caseId), formSolveCase);

		return response;
	} catch (error) {
		throw error;
	}
};

export const getDetailCase = async(caseId: string) => {
	try {
		const response: ApiData<any> = await customFetch(Endpoints.getDetailCase(caseId));

		return response;
	} catch (error) {
		throw error;
	}
};

export const getPastSimiliarCase = async(caseId: string) => {
	try {
		const response: ApiData<any> = await customFetch(Endpoints.getPastSimiliarCase(caseId));

		return response;
	} catch (error) {
		throw error;
	}
};
