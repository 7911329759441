import React, { useState, useEffect } from 'react';

import { Colors } from 'consts';
import { hooks, screen } from 'helpers';
import { selectors } from 'store/selectors';

import Dropdown, { MenuEntity } from '../Dropdown';
import Icon from '../Icon';
import Skeleton from '../Skeleton';
import Text from '../Text';

import { PaginationStyle } from './style';

type PaginationProps = {
  data: any;
  getCurrentPageElements?: (currentData?: any) => void;
  getCurrentPagination?: (currentPagination?: any) => void;
  type?: 'table' | 'lazyLoad';
  fieldName?: string;
  loading?: boolean;
};

const Pagination: React.FC<PaginationProps> = ({
  data,
  fieldName,
  getCurrentPageElements,
  getCurrentPagination,
  type,
  loading
}) => {
  const paginationProps = hooks.useAppSelector(selectors.pagination.pagination);
  const paginationField = hooks.useAppSelector(selectors.pagination.paginationField);

  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
  const [pagination, setPagination] = hooks.useStateCallback({
    offset: paginationProps.offset,
    currentPageElements: data ? data : [],
    elementsPerPage: paginationProps.limit,
    pagesCount: paginationProps.page_total,
    currentPage: paginationProps.page,
    allElements: data ? data : [],
    totalElementsCount: paginationProps.count
  });

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  useEffect(() => {
    if (getCurrentPagination) {
      if (fieldName === paginationField) {
        setPagination(prevState => ({
          ...prevState,
          offset: paginationProps.offset,
          currentPageElements: data ? data : [],
          elementsPerPage: paginationProps.limit,
          pagesCount: paginationProps.page_total < 1 ? 1 : paginationProps.page_total,
          currentPage: paginationProps.page,
          allElements: data ? data : [],
          totalElementsCount: paginationProps.count
        }));
      } else {
        if (loading) {
          setPagination(prevState => ({
            ...prevState,
            offset: 0,
            elementsPerPage: 10,
            pagesCount: 1,
            currentPage: 1,
            totalElementsCount: 0
          }));
        }
      }
    }
  }, [paginationProps, paginationField, loading]); // eslint-disable-line array-bracket-newline, array-element-newline

  useEffect(() => {
    if (getCurrentPageElements) {
      const { offset, elementsPerPage } = pagination;
      const currentPageElements = data.slice(offset, offset + elementsPerPage);
      const pagesCount = Math.ceil(data.length / elementsPerPage);
  
      setPagination(prevState => ({
        ...prevState,
        allElements: data,
        totalElementsCount: data.length,
        currentPageElements,
        pagesCount
      }),
        updatedData => {
          getCurrentPagination && getCurrentPagination(updatedData);
          setElementsForCurrentPage(updatedData);
        });
    }
  }, [pagination.elementsPerPage]);

  useEffect(() => {
    const closeDropdown = () => setVisibleFilter(false);

    window.addEventListener(
      'scroll',
      closeDropdown,
      true
    );

    return () => window.removeEventListener(
      'scroll',
      closeDropdown,
      true
    );
  }, []);

  const renderOptionEPP = option => {
    const disabled = (pagination.totalElementsCount < Number(option) && option > 10) || loading;
  
    return (
      <div className={ disabled ? 'not-allowed' : 'pointer' }>
        <Text
          lineHeight={ 30 }
          weight={ 700 }
          align='center'
        >{ option }</Text>
      </div>
    );
  };

  const setElementsForCurrentPage = updatedData => {
    const {
      allElements,
      offset,
      elementsPerPage
    } = updatedData;
    const currentPageElements = allElements.slice(offset, offset + elementsPerPage);

    setPagination(prevState => ({
      ...prevState,
      currentPageElements
    }));

    getCurrentPageElements && getCurrentPageElements(currentPageElements);
  };

  const handlePageClick = action => {
    const {
      elementsPerPage,
      currentPage,
      pagesCount,
      offset
    } = pagination;
    let nextPage: number = currentPage + 1;
    let nextOffset: number = offset + elementsPerPage;

    switch (action) {
      case 'previous':
        nextPage = currentPage - 1;
        nextOffset = offset - elementsPerPage;
        break;
      case 'next':
        nextPage = currentPage + 1;
        nextOffset = offset + elementsPerPage;
        break;
      case 'first':
        nextPage = 1;
        nextOffset = 0;
        break;
      case 'last':
        nextPage = pagesCount;
        nextOffset = (pagesCount - 1) * elementsPerPage;
        break;
    }

    if (nextPage > 0 && nextPage <= pagesCount) {
      setPagination(prevState => ({
        ...prevState,
        offset: nextOffset < 0 ? 0 : nextOffset,
        currentPage: nextPage
      }),
        updatedData => {
          getCurrentPagination && getCurrentPagination(updatedData);
          setElementsForCurrentPage(updatedData);
        });
    } else {
      if (type === 'lazyLoad') {
        getCurrentPagination && getCurrentPagination(pagination);
      }
    }
  };

  const onClickChangeEPP = (eMenu: MenuEntity) => {
    const key = eMenu.key;

    setVisibleFilter(false);

    const disabled = (pagination.totalElementsCount < Number(key) && Number(key) > 10) || loading;

    setTimeout(() => {
      if (!disabled) {
        setPagination(prevState => ({
          ...prevState,
          elementsPerPage: Number(key),
          offset: 0,
          currentPage: 1
        }),
          updatedData => getCurrentPagination && getCurrentPagination(updatedData));
      }
    }, 500);
  };

  const handleOnVisibleChange = (visible: boolean) => {
    setVisibleFilter(visible);
  };

  const overlayDataEPP = [
    '10',
    '25',
    '50',
    '100'
  ].map((pageSize: string) => {
    return {
      key: pageSize,
      item: () => renderOptionEPP(pageSize)
    };
  });

  const renderTextShow = () => {
    const lowerLimit = pagination.totalElementsCount > 0
    ? pagination.offset + 1
    : pagination.totalElementsCount;
    const upperLimit = pagination.offset + pagination.elementsPerPage <= pagination.totalElementsCount
    ? pagination.offset + pagination.elementsPerPage
    : pagination.totalElementsCount;
    const infoCountData = upperLimit === lowerLimit
    ? lowerLimit
    : `${lowerLimit}-${upperLimit}`;

    return (
      <Skeleton
        loading={ (loading || false) }
        avatar={ { shape: 'square' } }
        className='skeleton-text-show'
        multiple={ false }
      >
        <Text
          size={ windowDimensions.width >= 952 ? 's' : 'xs' }
          weight={ 400 }
          lineHeight={ 24 }
          color={ Colors.grey.isGrey }
        >Showing { infoCountData } of { pagination.totalElementsCount } results</Text>
      </Skeleton>
    );
  };

  const renderNavigationPage = () => {
    return (
      <Skeleton
        loading={ (loading || false) }
        avatar={ { shape: 'square' } }
        className='skeleton-text-show'
        multiple={ false }
      >
        <Icon
          iconName='doubleArrow'
          size={ 20 }
          fill={ Colors.grey.isDarkGrey }
          className='ic-double-arrow-left'
          onClick={ () => handlePageClick('first') }
        />
        <Icon
          iconName='singleArrow'
          size={ 12 }
          fill={ Colors.grey.isDarkGrey }
          className='ic-single-arrow-left'
          onClick={ () => handlePageClick('previous') }
        />
        <Text weight={ 700 } color={ Colors.blue.isBlue }>{ pagination.currentPage }</Text>
        <Text weight={ 700 } color={ Colors.grey.isDarkGrey }>/{ pagination.pagesCount }</Text>
        <Icon
          iconName='singleArrow'
          size={ 12 }
          fill={ Colors.grey.isDarkGrey }
          className='ic-single-arrow-right'
          onClick={ () => handlePageClick('next') }
        />
        <Icon
          iconName='doubleArrow'
          size={ 20 }
          fill={ Colors.grey.isDarkGrey }
          className='ic-double-arrow-right'
          onClick={ () => handlePageClick('last') }
        />
      </Skeleton>
    );
  };

  const renderResultPerPage = () => {
    return (
      <Skeleton
        loading={ (loading || false) }
        avatar={ { shape: 'square' } }
        className='skeleton-text-show'
        multiple={ false }
      >
        {
          windowDimensions.width > screen.isMobile ? (
            <Text
              size={ windowDimensions.width >= 952 ? 's' : 'xs' }
              weight={ 400 }
              lineHeight={ 24 }
              color={ Colors.grey.isGrey }
              mr={ 15 }
              align='right'
            >Results per page</Text>
          ) : null
        }
        <Dropdown
          overlayData={ overlayDataEPP }
          onClickMenu={ onClickChangeEPP }
          visible={ visibleFilter }
          onVisibleChange={ visible => handleOnVisibleChange(visible) }
        >
          <div className='dropdown-EPP' onClick={ e => e.preventDefault() }>
            <Text weight={ 700 } mr={ 10 }>{ pagination.elementsPerPage }</Text>
            <Icon
              iconName='arrowDown'
              size={ 13 }
              fill={ Colors.blue.isBlue }
              className='arrow-EPP'
            />
          </div>
        </Dropdown>
      </Skeleton>
    );
  };

  if (type === 'lazyLoad') {
    return (
      <Text
        color={ Colors.blue.isBlue }
        align='center'
        onClick={ () => handlePageClick('next') }
      >Load More</Text>
    );
  }

  return (
    <PaginationStyle visibleFilter={ visibleFilter } screenWidth={ windowDimensions.width }>
      <div className='flex row justify-between align-center mt8 mb16'>
        <div className='container-text-show'>
          { renderTextShow() }
        </div>

        <div className='flex row align-center container-navigation-page'>
          { renderNavigationPage() }
        </div>

        <div className='flex row align-center container-EPP'>
          { renderResultPerPage() }
        </div>
      </div>
    </PaginationStyle>
  );
};

Pagination.defaultProps = { type: 'table' };

export default Pagination;
