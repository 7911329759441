import { Notification } from 'interfaces/notification';

import {
	GET_NOTIFICATION_LIST,
	ADD_NOTIFICATION,
	LOGOUT,
	NotifActionTypes,
} from './actionTypes';

interface ScooterState {
	notifList: Notification[];
	newUnreadMessage: boolean;
}

const initState: ScooterState = {
	notifList: [],
	newUnreadMessage: false
};

const notifReducer = (state = initState, action: NotifActionTypes): ScooterState => {
	switch (action.type) {
		case GET_NOTIFICATION_LIST:
			return {
				...state,
				notifList: action.data,
				newUnreadMessage: action.data?.findIndex(notif => !notif.has_been_read) > -1
			};

		case ADD_NOTIFICATION:
			const isNewMessage = !state.notifList.find(notif => notif.id === action.data.id) && action.data.id > 0;
			const updatedNotifList = isNewMessage
				? [action.data, ...state.notifList]
				: state.notifList;

			return {
				...state,
				notifList: updatedNotifList,
				newUnreadMessage: updatedNotifList?.findIndex(notif => !notif.has_been_read) > -1
			};

		case LOGOUT:
			return initState;

		default:
			return state;
	}
};

export default notifReducer;
