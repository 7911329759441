import React from 'react';
import {
  Dropdown,
  Menu,
  Empty
} from 'antd';

import { Colors } from 'consts';

import Loader from '../Loader';

export type Placement = 'bottom' | 'topLeft' | 'top' | 'topRight' | 'bottomLeft' | 'bottomRight';
export type Trigger = 'click' | 'hover' | 'contextMenu';
export type MenuEntity = {
  key: string;
  item: React.ReactNode;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
  keyPath: string[];
};

type CustomDropdownProps = {
  children: React.ReactNode;
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  overlayData?: any;
  overlay?: any;
  onClickMenu?: (params: MenuEntity) => void;
  placement?: Placement;
  trigger?: Trigger[];
  borderRadius?: number;
  disabled?: boolean;
  loading?: boolean;
  selectedValue?: string;
  className?: string;
  arrow?: boolean;
};

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  children,
  visible,
  onVisibleChange,
  overlayData,
  overlay,
  onClickMenu,
  placement,
  trigger,
  borderRadius,
  disabled,
  loading,
  selectedValue,
  className,
  arrow
}) => {
  const renderMenuItem = () => {
    if (loading) {
      return (
        <Loader className='col center-content' style={ { height: 100 } } />
      );
    } else {
      if (overlayData?.length) {
        return overlayData.map(data => (
          <Menu.Item key={ data.key } style={ selectedValue === data.key ? { backgroundColor: Colors.blue.isBlueOpacity('0.1') } : {} }>
            { data.item() }
          </Menu.Item>
        ));
      }

      return <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />;
    }
  };

  const renderOverlay = (
    <Menu onClick={ onClickMenu } style={ { borderRadius } }>
      { renderMenuItem() }
    </Menu>
  );

  return (
    <Dropdown
      visible={ visible }
      onVisibleChange={ onVisibleChange }
      overlay={ overlay ? overlay : renderOverlay }
      placement={ placement }
      trigger={ trigger }
      disabled={ disabled }
      className={ className }
      arrow={ arrow }
    >
      { children }
    </Dropdown>
  );
};

CustomDropdown.defaultProps = {
  borderRadius: 8,
  placement: 'bottom',
  trigger: ['click'],
  arrow: false
};

export default CustomDropdown;
