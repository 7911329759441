/* eslint-disable no-undef */
import React, {
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import {
	MapContainer,
	Marker,
	Polyline,
	TileLayer,
	useMap,
	useMapEvents,
	Popup
} from 'react-leaflet';

import { Image } from 'antd';

import L from 'leaflet';

import { MapsLocation, ValueType } from 'typings';
import {
	Colors,
	Images,
	MapConfig
} from 'consts';
import { MarkerProps } from 'interfaces/common';
import { hooks } from 'helpers';

import Text from '../Text';
import Autocomplete from '../Autocomplete';
import Icon from '../Icon';
import Skeleton from '../Skeleton';

import { SearchBoxStyle, WrapperAutocompleteAddress } from './style';
import OSMCustomProvider, { getReverseGeocoding, ResultReverse } from './OSMProvider';

type MapsProps = {
	className?: string;
	zoom?: number;
	mapKey?: string;
	openModal?: boolean;
	containerStyle?: React.CSSProperties;
	width?: string;
	height?: string;
	onChangeLocation?: (result: ValueType) => void;
	center?: MapsLocation;
	searchBox?: boolean;
	initValueGeoSearch?: ValueType;
	renderItemSearchBox?: (title: string, description?: string) => void;
	draggable?: boolean;
	iconRetinaUrl?: any;
	iconUrl?: any;
	iconPolylineUrl?: any;
	multipleMarkerPaths?: MarkerProps[] | [];
	polylinePath?: MapsLocation[];
	withMarkerCenter?: boolean;
	withGeocodingReverse?: boolean;
	multiplePolylinePath?: {
		color?: string;
		position: MapsLocation[];
	}[];
};

const ZOOM = 15;

const MapsLeaflet: React.FC<MapsProps> = ({
	zoom,
	center,
	containerStyle,
	width = '100%',
	height = '172px',
	searchBox = false,
	onChangeLocation,
	mapKey,
	initValueGeoSearch,
	renderItemSearchBox,
	draggable,
	iconRetinaUrl,
	iconUrl,
	iconPolylineUrl,
	multipleMarkerPaths,
	polylinePath,
	withMarkerCenter = true,
	withGeocodingReverse = true,
	multiplePolylinePath
}) => {
	const markerRef = useRef<any>(null);

	const [zoomLevel] = useState<number>(zoom || ZOOM);
	const [position, setPosition] = useState<MapsLocation>(center || MapConfig.defaultCenter);

	const [loadingPosition, setLoadingPosition] = useState<boolean>(false);
	const [resultGeosearch, setResultGeosearch] = useState<ValueType[]>([]);
	const [valueGeosearch, setValueGeosearch] = useState<ValueType>({
		shortLabel: initValueGeoSearch?.shortLabel || '',
		label: initValueGeoSearch?.label || '',
		value: initValueGeoSearch?.value || '',
		postcode: initValueGeoSearch?.postcode || '',
		city: initValueGeoSearch?.city || '',
		valueDetail: initValueGeoSearch?.valueDetail || MapConfig.defaultCenter,
	});
	const [provider, setProvider] = useState<any>(null);
	const [isMarkerDragged, setIsMarkerDragged] = useState<boolean>(false);
	const [loadingGeoSearch, setLoadingGeoSearch] = useState<boolean>(false);
	const [isFindAddress, setIsFindAddress] = useState<boolean>((!!center) && (draggable || searchBox || withGeocodingReverse));

	const debouncedPosition = hooks.useDebounce(position, 2000);

	useEffect(() => {
		if (center?.lat && center?.lng) {
			setPosition(center);
		}
	}, [center?.lat, center?.lng]);

	const getReverseGeocodingFn = () => {
		getReverseGeocoding(debouncedPosition, (res: ResultReverse) => {

			const resultReverseGeocoding = {
				label: res.label,
				shortLabel: res.shortLabel,
				value: res.label,
				postcode: res.postcode,
				city: res.city,
				valueDetail: debouncedPosition
			};

			setValueGeosearch(resultReverseGeocoding);

			onChangeLocation && onChangeLocation(resultReverseGeocoding);

			if (isMarkerDragged) setIsMarkerDragged(false);

			if (isFindAddress) setIsFindAddress(false);

			if (loadingPosition) setLoadingPosition(false);
		});
	};

	useEffect(() => {
		if (isFindAddress) {
			getReverseGeocodingFn();
		}
	}, [debouncedPosition.lat, debouncedPosition.lng]);

	const markerEventHandlers = useMemo(() => ({
		dragend() {
			const marker = markerRef.current;

			if (marker) {
				setIsMarkerDragged(true);

				setPosition(marker.getLatLng());

				setIsFindAddress(true);

				setLoadingPosition(true);
			}
		},
	}), []);

	useEffect(() => {
		if (window) {
			L.Icon.Default.mergeOptions({
				iconRetinaUrl: iconRetinaUrl || Images.maps.marker_icon_2x,
				iconUrl: iconUrl || Images.maps.marker_icon,
				shadowUrl: Images.maps.marker_shadow,
				iconSize: new L.Point(41, 41)
			});
		}
	}, []);

	useEffect(() => {
		const provider = new OSMCustomProvider();

		setProvider(provider);
	}, []);

	const promiseSelectGeosearch = (inputValue: string): Promise<ValueType[]> => {
		return new Promise(async resolve => {
			if (provider) {
				try {
					await setLoadingGeoSearch(true);

					if (inputValue) {
						const results = await provider?.search({ query: inputValue });

						const resultsSelect = await results?.map(res => ({
							label: res.label,
							value: res.label,
							shortLabel: res.shortLabel,
							valueDetail: {
								lat: res.y,
								lng: res.x,
							}
						}));

						await setResultGeosearch(resultsSelect);

						resolve(resultsSelect);
					} else {
						setResultGeosearch([]);
						resolve([]);
					}
				} catch (e) {
					resolve([]);
				} finally {
					setLoadingGeoSearch(false);
				}
			} else {
				resolve([]);
			}
		});
	};

	const ChangeView = ({ center }: { center: L.LatLngExpression; }) => {
		const map = useMap();

		if (map) {
			map?.setView(center);
		}

		return null;
	};

	const ComponentResize = () => {
		const map = useMap();

		useEffect(() => {
			setTimeout(() => {
				if (map && position) map.invalidateSize();
			}, 1000);
		}, []);

		return null;
	};

	const CustomMarker = () => {
		const map = useMapEvents({ // eslint-disable-line @typescript-eslint/no-unused-vars
			dragend: (e: L.DragEndEvent) => {
				if (draggable) {
					if (!isMarkerDragged) {
						setPosition(e.target.getCenter());

						setIsFindAddress(true);

						setLoadingPosition(true);
					}
				}
			}
		});

		return (
			<Marker
				draggable={ draggable }
				eventHandlers={ markerEventHandlers }
				position={ position }
				ref={ markerRef }
			/>
		);
	};

	const resolveIconMarker = (item: MarkerProps) => {
		if (item.iconHtml) {
			return L.divIcon({ html: item.iconHtml });
		}

		if (item.iconUrl) {
			return L.icon({
				iconUrl: item.iconUrl,
				iconSize: item.iconSize ?? [40, 40],
			});
		}

		return null;
	};

	const renderMapLeaflet = () => {
		const mapContainerStyle = {
			zIndex: 10,
			...containerStyle,
			width,
			height,
		};

		const pointIcon = L.icon({
			iconUrl: iconPolylineUrl,
			iconSize: [40, 40],
		});

		return (
			<MapContainer
				key={ mapKey }
				center={ position }
				zoom={ zoomLevel }
				attributionControl={ false }
				style={ mapContainerStyle }
			>
				<ChangeView center={ position } />

				<ComponentResize />

				<TileLayer
					attribution={ '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' }
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				/>

				{
					multipleMarkerPaths ? multipleMarkerPaths?.map((items: MarkerProps, index: React.Key | null | undefined) => (
						<Marker
							icon={ resolveIconMarker(items) ?? pointIcon }
							key={ index }
							position={ [items.lat, items.lng] }
						>
							<Popup>
								<div className='flex'>
									<div>
										{ items.iconHtml
											? <span dangerouslySetInnerHTML={ { __html: items.iconHtml } } />
											: (
												<Image
													width={ 40 }
													src={ items.iconUrl ?? Images.maps.marker_scooter_2x }
												/>
											) }
									</div>
									<div className='flex flex-col' style={ { paddingLeft: '10px' } }>
										<Text weight={ 700 }>{ items.label }</Text>
										<Text mt={ 3 }>{ items.detail }</Text>
									</div>
								</div>
							</Popup>
						</Marker>
					))
						: <></> }

				{ (polylinePath && polylinePath?.length) && (
					<Polyline
						pathOptions={ {
							stroke: true,
							color: Colors.blue.isBlue,
							opacity: 1
						} }
						positions={ polylinePath }
					/>
				) }

				{ multiplePolylinePath && multiplePolylinePath?.length
					&& (
						<>
							{ multiplePolylinePath?.map((listPolyline, listPolylineIdx) => {
								return (
									<Polyline
										key={ `multipoly-${ listPolylineIdx }` }
										pathOptions={ {
											stroke: true,
											color: listPolyline?.color ?? Colors.blue.isBlue,
											opacity: 1
										} }
										positions={ listPolyline.position }
									/>
								);
							}) }
						</>
					) }

				{ withMarkerCenter && <CustomMarker /> }
			</MapContainer>
		);
	};

	const renderSearchAddress = () => {
		return (
			<div className='outer'>
				<div className='icon-search-outer'>
					<Icon
						iconName='search'
						fill={ Colors.grey.isGrey }
						hoverOpacity={ 1 }
					/>
				</div>

				<div className='wrapper-autocomplete'>
					<Autocomplete
						placeholder='Search address'
						onSelect={ (_: string, selected: ValueType) => {
							setValueGeosearch(selected);
							setPosition(selected.valueDetail);

							setIsFindAddress(false);

							onChangeLocation && onChangeLocation(selected);
						} }
						defaultOptions={ resultGeosearch }
						loadOptions={ promiseSelectGeosearch }
						loading={ loadingGeoSearch }
						renderItem={ renderItemSearchBox }
						withPrefix
					/>
				</div>
			</div>
		);
	};

	const renderContentMap = () => {
		if (searchBox) {
			const titleAddress = valueGeosearch?.shortLabel;
			const detailAddress = valueGeosearch?.value;

			return (
				<SearchBoxStyle>
					<WrapperAutocompleteAddress>
						{ renderSearchAddress() }
					</WrapperAutocompleteAddress>

					<div className='wrapper-card-maps'>
						{ renderMapLeaflet() }

						<div className={ loadingPosition ? 'address-selected-container' : '' }>
							<Skeleton loading={ loadingPosition } paragraph={ { rows: 2 } }>
								{ (detailAddress || titleAddress) && (
									<div className='address-selected-container'>
										{ titleAddress && <Text size='m' weight={ 700 }>{ titleAddress }</Text> }

										{ detailAddress && <Text color={ Colors.grey.isGreyGreen }>{ detailAddress }</Text> }
									</div>
								) }
							</Skeleton>
						</div>

					</div>
				</SearchBoxStyle>
			);
		}

		return renderMapLeaflet();
	};

	return renderContentMap();
};

export default MapsLeaflet;