import { Permission } from 'interfaces/role';

// dummy hingga BE memberikan response permissions di API login
export const setPermissions = (roleName: string): string[] => {
	const role = roleName?.toLowerCase() || '';

	switch (role) {
		case 'showroom admin':
			return [
				Permission.inventory_change_status,
				Permission.inventory_create,
				Permission.inventory_detail,
				Permission.inventory_list,
				Permission.inventory_update,
				Permission.inventory_report_missing,

				Permission.users_list,
				Permission.user_create,
				Permission.user_update,
				Permission.user_profile,
				Permission.user_assign_showroom,
				Permission.user_detail_legal_info,
				Permission.user_verify,
				Permission.user_pairing_scooter,

				Permission.showroom_list,
				Permission.showroom_detail,
				Permission.showroom_update
			];

		case 'customer service':
			return [
				Permission.call_me_list,
				Permission.call_me_take_issue,
				Permission.call_me_reassign_cs,

				Permission.case_list,
				Permission.case_take,
				Permission.case_solve,

				Permission.inventory_detail,
				Permission.inventory_list,

				Permission.users_list,
				Permission.user_profile,

				Permission.showroom_list,
				Permission.showroom_detail
			];

		case 'super admin':
		default:
			return [
				Permission.stats_all,

				Permission.inventory_change_status,
				Permission.inventory_create,
				Permission.inventory_detail,
				Permission.inventory_list,
				Permission.inventory_update,
				Permission.inventory_report_missing,

				Permission.users_list,
				Permission.user_create,
				Permission.user_update,
				Permission.user_profile,
				Permission.user_assign_showroom,
				Permission.user_detail_legal_info,
				Permission.user_verify,
				Permission.user_pairing_scooter,

				Permission.showroom_list,
				Permission.showroom_create,
				Permission.showroom_detail,
				Permission.showroom_update,
				Permission.showroom_delete,

				Permission.battery_change_status,
				Permission.battery_create,
				Permission.battery_detail,
				Permission.battery_list,

				Permission.battery_station_change_status,
				Permission.battery_station_create,
				Permission.battery_station_list,
				Permission.battery_station_update,
				Permission.battery_station_detail,

				Permission.admin_change_password,
				Permission.admin_create,
				Permission.admin_detail,
				Permission.admin_list,
				Permission.admin_update,

				Permission.subscription_change_status,
				Permission.subscription_create,
				Permission.subscription_detail,
				Permission.subscription_list,
				Permission.subscription_update,
				Permission.subscription_delete,

				Permission.call_me_assign_cs,
				Permission.call_me_reassign_cs,
				Permission.call_me_list,
				Permission.case_list
			];
	}
};

export const isPermissible = (permissions?: string[], allowedPermission?: string[] | string | boolean) => {
	if (permissions && allowedPermission) {
		if (typeof allowedPermission === 'boolean') return allowedPermission;

		if (Array.isArray(allowedPermission)) {
			return permissions?.filter((permission: string) => allowedPermission?.includes(permission)).length > 0;
		}

		if (typeof allowedPermission === 'string') {
			const permissionIndex = permissions?.findIndex((permission: string) => permission?.toLowerCase() === allowedPermission?.toLowerCase());

			return permissionIndex > -1;
		}
	}

	return false;
};
