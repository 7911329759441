import moment, { MomentInput } from 'moment';

export const dateStrToMoment = (dateTime: string | Date, format: string) => {
  return moment(moment(dateTime, format).toDate());
};

export const dateConverted = (
  date: string | Date,
  oldFormat: string,
  newFormat = ''
) => {
  if (newFormat) {
    
    return dateStrToMoment(date, oldFormat).format(newFormat);
  }

  return moment(date, oldFormat).toDate();
};

export const dateTimeToTimestamp = (dateTime: string, format: string) => {
  return dateStrToMoment(dateTime, format).valueOf();
};

export const timestampToMoment = (fullTimestamp: number) => moment(moment.unix(fullTimestamp / 1000).toDate());

export const timestampToDateTime = (fullTimestamp: number, format: string) => {
  return timestampToMoment(fullTimestamp).format(format);
};

export const lessThanOneHourAgo = (date: MomentInput) => moment(date).isAfter(moment().subtract(1, 'hours'));

export const fromNow = (date: MomentInput) => moment(date).fromNow();

export const utcToLocal = (dataUtc: MomentInput, format?: string) => {
  return moment
    .utc(dataUtc)
    .local()
    .format(format);
};
