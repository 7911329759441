import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { ApiData, CommonResponseData } from 'interfaces/common';
import {
	Subscription,
	SubscriptionApiParams,
	FormSubscription
} from 'interfaces/subscription';

export const getAllSubscription = async(queryParams: SubscriptionApiParams) => {
	try {
		const response: ApiData<Subscription[]> = await customFetch(Endpoints.getAllSubscription(), queryParams);

		return response;
	} catch (error) {
		throw error;
	}
};

export const addSubscription = async(formAddSubscription: FormSubscription) => {
	try {
		const response: ApiData<CommonResponseData> = await customFetch(Endpoints.addSubscription(), formAddSubscription);

		return response;
	} catch (error) {
		throw error;
	}
};

export const editSubscription = async(subsId: string, formEditSubscription: FormSubscription) => {
	try {
		const response: ApiData<CommonResponseData> = await customFetch(Endpoints.editSubscription(subsId), formEditSubscription);

		return response;
	} catch (error) {
		throw error;
	}
};

export const deleteSubscription = async(subsId: string) => {
	try {
		const response: ApiData<CommonResponseData> = await customFetch(Endpoints.deleteSubscription(subsId));

		return response;
	} catch (error) {
		throw error;
	}
};