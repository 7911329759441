import React from 'react';
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  ResponsiveContainer
} from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemePastel2 } from 'd3-scale-chromatic';

import { Colors } from 'consts';
import { hooks, screen } from 'helpers';

import { LegendStyle } from './style';

import Text from '../../Text';

const colorsPieChart = scaleOrdinal(schemePastel2).range();

export type PieChartProps = {
  width?: number;
  height?: number;
  data: any;
  dataKey: { category: string; value: string; };
  cy?: number;
  outerRadiusData?: string;
  legendWrapper?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  }
};

const CustomPieChart: React.FC<PieChartProps> = ({
  width,
  height,
  data,
  dataKey,
  cy,
  legendWrapper,
  outerRadiusData
}) => {
  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const propsFont = {
      fontFamily: 'InterBold',
      fontSize: 14,
      lineHeight: 17
    };

    if (percent > 0) {
      return (
        <text
          x={ x }
          y={ y }
          fill={ Colors.black.isBlack }
          textAnchor='middle'
          dominantBaseline='central'
          style={ propsFont }
        >
          { `${(percent * 100).toFixed(0)}%` }
        </text>
      );
    }

  };

  const renderLegend = (props: any) => {
    const { payload } = props;
    const widthLegend = windowDimensions.width > 475
    ? (width || 200) - (legendWrapper?.left || 0) - 20
    : 130;

    return payload.map((entry, index) => (
      <LegendStyle
        key={ `item-${index}` }
        bgColor={ entry.color }
        style={ { width: widthLegend } }
        className='ml1'
      >
        <div className={ `justify-align-center ${index === 0 ? '' : 'mt3'}` }>
          <div className='flex row align-center'>
            <div className='circle' />
            
            <div style={ { maxWidth: 100 } } className='wrap'>
              <Text size='xs' className='p1 wrap'>{ entry.id }</Text>
            </div>
          </div>
          {
            windowDimensions.width <= screen.sizes.xxxs
            ? null
            : <Text weight={ 700 } align='right'>{ entry.value }%</Text>
          }
        </div>
      </LegendStyle>
    ));
  };
  
  return (
    <ResponsiveContainer width='100%' height={ height }>
      <PieChart>
        <Legend
          content={ renderLegend }
          payload={
            data.map((item, index) => ({
              id: item[dataKey.category],
              value: `${item[dataKey.value]}`,
              color: colorsPieChart[`${index % colorsPieChart.length}`] as string
            }))
          }
          wrapperStyle={ {
            top: (cy || 100) / 2,
            ...legendWrapper
          } }
        />
        <Pie
          data={ data }
          dataKey={ dataKey.value }
          cx={ width ? width / 4.5 : 25 }
          cy={ cy }
          startAngle={ 180 }
          endAngle={ -180 }
          outerRadius={ outerRadiusData || '150%' }
          labelLine={ false }
          label={ renderCustomizedLabel }
        >
          {
            data.map((entry, index) => (
              <Cell key={ `slice-${index}` } fill={ colorsPieChart[`${index % colorsPieChart.length}`] as string }/>
            ))
          }
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

CustomPieChart.defaultProps = {
  cy: 100,
  height: 200,
  width: 200
};

export default CustomPieChart;
