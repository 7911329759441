import { Colors } from 'consts';

export const propsFont = {
  fontFamily: 'InterMedium',
  fontSize: 12,
  letterSpacing: -0.3
};

export const propsDefaultAxis = {
  stroke: Colors.grey.isGrey,
  strokeOpacity: 0.2,
  axisLine: false
};