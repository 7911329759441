import { Pagination } from 'interfaces/common';
import { User } from 'interfaces/user';
import { Showroom } from 'interfaces/showroom';

export const GET_ALL_USER = 'GET_ALL_USER';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_SHOWROOMS = 'GET_USER_SHOWROOMS';
export const LOGOUT = 'LOGOUT';

export interface SetAllUser {
  type: typeof GET_ALL_USER;
  data: {
    data: User[],
    pagination: Pagination
  }
}

export interface SetUserDetail {
  type: typeof GET_USER_DETAIL;
  data: User;
}

export interface SetUserShowroom {
  type: typeof GET_USER_SHOWROOMS;
  data: Showroom;
}

export interface Logout {
	type: typeof LOGOUT;
}

export type UserActionTypes =
  SetAllUser |
  SetUserDetail |
  SetUserShowroom |
  Logout;