import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Container,
  Skeleton,
  Spacer,
  Text
} from 'components';
import { Colors } from 'consts';
import {
  hooks,
  misc,
  navigation,
  screen
} from 'helpers';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import {
  BoxBusyTime,
  BoxHoleStation,
  BoxLocation,
  BoxSwapLog,
  BoxVisitors
} from './components';
import { BatteryStationStyle } from './style';

interface RouteParams {
  stationId: string;
}

type Filter = 'busyTime';

type ActiveFilter = {
  [key in Filter]: string;
};

type VisibleFilter = {
  [key in Filter]: boolean;
};

const BatteryStationDetail: React.FC = () => {
  const params = useParams<RouteParams>();
  const dispatch = hooks.useAppDispatch();

  const getStationDetail = dispatch(actions.getStationDetail);
  const stationDetail = hooks.useAppSelector(selectors.station.stationDetail);
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const [visibleFilter, setVisibleFilter] = useState<VisibleFilter>({ busyTime: false });
  const [activeFilter, setActiveFilter] = useState<ActiveFilter>({ busyTime: 'Daily' });

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();
  const isHomeCharging = stationDetail?.station_type === 'home_station';

  useEffect(() => {
    if (params?.stationId) {
      getStationDetail(params?.stationId);
    }
  }, []);

  const onClickFilterMenu = ({ key }: { key: string; }) => {
    const filterOpen = misc.getKeyByValue(visibleFilter, true);

    setVisibleFilter(prevFilter => ({
      ...prevFilter,
      [`${ filterOpen }`]: false
    }));
    setActiveFilter(prevActive => ({
      ...prevActive,
      [`${ filterOpen }`]: key
    }));
  };

  const onVisibleChange = (visible: boolean, key: string) => {
    setVisibleFilter(prevFilter => ({
      ...prevFilter,
      [key]: visible
    }));
  };

  const filterMenuProps = {
    visibleFilter,
    activeFilter,
    onVisibleChange,
    onClickFilterMenu
  };

  const renderBoxSummaryInfo = () => {
    const isLoading = misc.isLazyLoading(lazyLoad, 'stationDetail');

    const dataSummary = [
      {
        name: 'Available Battery',
        key: 'available_battery'
      },
      {
        name: 'Charging Battery',
        key: 'charging_battery'
      },
      {
        name: 'Total Battery',
        key: 'total_battery'
      }
    ];

    return dataSummary.map((data: any, index: number) => {
      return (
        <Box
          key={ index }
          mt={ 20 }
          ml={ index > 0 ? 20 : 0 }
          padding={ 0 }
          shadow={ `0px 2px 10px ${ Colors.black.isShadow }` }
          className='box-summary'
        >
          <div className='box-summary-inner'>
            <Text color={ Colors.grey.isGrey } mb={ 10 }>{ data.name }</Text>
            <Skeleton
              loading={ isLoading }
              paragraph={ {
                rows: 1,
                width: '100%'
              } }
              title={ false }
            >
              <Text size='l' weight={ 700 }>
                { stationDetail
                  ? misc.setMultiplierWording(stationDetail[data.key] ?? 0)
                  : '' }
              </Text>
            </Skeleton>
          </div>
        </Box>
      );
    });
  };

  const renderBoxBusyTime = (widthBox: number) => {
    const filterMenu = {
      ...filterMenuProps,
      keys: ['busyTime'],
      selectOptions: [
        [
          'Daily',
          'Weekly',
          'Monthly'
        ]
      ]
    };

    return <BoxBusyTime widthBox={ widthBox } filterMenu={ filterMenu } />;
  };

  const renderContent = () => {
    const { width: screenWidth } = windowDimensions;
    let widthBox = !isHomeCharging
      ? ((screenWidth - 240) / 2) - 20
      : screenWidth - 240;

    const spacer = screenWidth <= 1120 ? 0 : 20;

    if (screenWidth <= 1120 && screenWidth >= screen.sizes.sx) {
      widthBox = (screenWidth - 240) - 20;
    } else if (screenWidth < screen.sizes.sx) {
      widthBox = screenWidth - 60;
    }
    return (
      <>
        <div className='justify-align-center'>
          { renderBoxSummaryInfo() }
        </div>
        { !isHomeCharging && (
          <div className='box-section-container flex' style={ { alignItems: 'start' } }>
            <BoxSwapLog widthBox={ widthBox } statId={ params?.stationId } />
            <Spacer ml={ spacer }>
              { renderBoxBusyTime(widthBox) }
            </Spacer>
          </div>
        ) }
        <div className='box-section-container'>
          <BoxLocation
            widthBox={ widthBox }
            latitude={ stationDetail?.latitude }
            longitude={ stationDetail?.longitude }
            address={ stationDetail?.address }
            label={ stationDetail?.name }
            id={ stationDetail?.id }
          />
          { !isHomeCharging && (
            <Spacer ml={ spacer }>
              <BoxVisitors widthBox={ widthBox } />
            </Spacer>
          ) }
        </div>

        <BoxHoleStation totalSlot={ stationDetail?.total_slot } />
      </>
    );
  };

  return (
    <Container
      selectedMenu={ 7 }
      headerContent={ { textHeader: stationDetail?.name ?? params?.stationId } }
      navbar={ {
        backButton: 'circle',
        onClickBack: () => navigation.goBack()
      } }
      withDrawer
    >
      <BatteryStationStyle>
        { renderContent() }
      </BatteryStationStyle>
    </Container>
  );
};

export default BatteryStationDetail;
