import Styled from 'styled-components';

type ButtonsStyleProps = {
	isAllPermissible?: boolean;
};

export const ButtonsStyle: any = Styled.div<ButtonsStyleProps>`
	display: grid;
	grid-template-columns: ${ props => props.isAllPermissible ? 'repeat(2, minmax(0, 1fr))' : 'none' };
	gap: 20px;
	margin-top: 30px;
`;