import React, { useEffect, useState } from 'react';

import {
  Box,
  Text,
  BarChart,
  Select,
  EmptyState,
  Skeleton
} from 'components';
import { Colors, Images } from 'consts';
import {
  hooks,
  misc,
  moment
} from 'helpers';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import {
  convertFilterToReportDate,
  dateChartFormatter,
  getWeeks,
  group_by_month
} from './helpers';
import { BoxStatsStyle, LineChartStyle } from './style';

type BoxStatsProps = {
  widthBox: number;
  routeId?: string;
};

const defaultDateFormat = 'YYYY-MM-DD hh:mm:ss';

const BoxStats: React.FC<BoxStatsProps> = ({ widthBox, routeId }) => {
  const dispatch = hooks.useAppDispatch();

  const getScooterBatteryUsages = dispatch(actions.getScooterBatteryUsages);
  const batteryUsage = hooks.useAppSelector(selectors.scooter.batteryUsage);
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<string>('Monthly');

  const loadingScooter = misc.isLazyLoading(lazyLoad, 'BatteryUsage');
  const dateFormattedShort = dateChartFormatter(activeFilter).short;
  const dateFormattedLong = dateChartFormatter(activeFilter).long;

  useEffect(() => {
    const report_date = convertFilterToReportDate(activeFilter);

    getScooterBatteryUsages(routeId || '', { report_date });
  }, [activeFilter]);

  const onClickFilterMenu = ({ key }) => {
    setVisibleFilter(false);
    setActiveFilter(key);
  };

  const renderEmptyData = () => {
    return (
      <EmptyState
        background={ { height: 240 } }
        image={ {
          src: Images.emptyState.chart,
          width: 56,
          height: 56
        } }
        text='No Stats Available'
      />
    );
  };

  const convertDataChart = () => {

    const dataChart = batteryUsage.energy_usage_per_day || [];
            
    if (activeFilter === 'Monthly') {
      return group_by_month(dataChart).slice(0, 5)
        .reverse();
    }
    
    if (activeFilter === 'Weekly') {
      return getWeeks(dataChart, 'usage').slice(0, 5);
    }

    if (activeFilter === 'Daily') {
      
      // Grouping date same in array data
      return Object.values(dataChart?.reduce((a, { usage, date }) => {
        a[String(date)?.slice(0, 10)] = (a[String(date)?.slice(0, 10)] || {
          date: String(date)?.slice(0, 10),
          usage: 0
        });
        a[String(date)?.slice(0, 10)].usage = String(Number(a[String(date)?.slice(0, 10)].usage) + Number(usage));
        return a;
        }, {})).slice(0, 7);

    }
  };

  const convertPeriodDate = (
    arr: any[],
    index: number,
    field: string
    ) => {
    return moment.dateConverted(
      arr[index][field],
      defaultDateFormat,
      activeFilter === 'Yearly' ? dateFormattedShort : dateFormattedLong,
    );
  };

  const setPeriodDate = () => {
    const dataChart = batteryUsage.energy_usage_per_day || [];

    const firstDate = convertPeriodDate(
      dataChart,
      0,
      'date'
    );
    const lastIdx = Object.values(dataChart)?.length - 1;
    const lastDate = convertPeriodDate(
      dataChart,
      lastIdx,
      'date'
    );

    // if (activeFilter === 'Weekly') return 'This Weeks Data';
    // if (activeFilter === 'Monthly') return 'This Mmonths Data';

    if (lastIdx === 0) return lastDate;

    else return `${ firstDate } - ${ lastDate }`;
  };

  const renderContentBatteryUsages = () => {
    const dataChart = batteryUsage.energy_usage_per_day || [];
    
    const dataChartConverted = convertDataChart();
    const widthChart = widthBox - 40;
    const dataKeyXAxis = activeFilter === 'Daily' ? 'date' : 'date_x';
    
    return (
      <LineChartStyle width={ widthChart } height={ 279 }>
        <Skeleton
          loading={ loadingScooter }
          avatar={ { shape: 'square' } }
          className='chart-skeleton'
          multiple={ false }
        >
          {
            dataChart && Object.values(dataChart)?.length ? (
              <div className='col center-content'>
                <BarChart
                  data={ dataChartConverted }
                  dataKey={ {
                    xAxis: dataKeyXAxis,
                    bar: 'usage'
                  } }
                  unit=' kWH'
                  margin={ { left: 0 } }
                  width={ widthChart }
                  height={ 279 }
                  heightAxis={ 55 }
                  transformLabel={ activeFilter === 'Daily' ? 'rotate(20deg)' : undefined }
                  sizeFont={ 10 }
                />
               </div>
            ) : renderEmptyData()
          }
        </Skeleton>
      </LineChartStyle>
    );
  };

  const renderTopSection = () => {
    const isDataExist = batteryUsage.energy_usage_per_day && Object.values(batteryUsage.energy_usage_per_day)?.length;
    
    return (
      <div className='justify-align-center'>
        <div>
          <Text
            size='m'
            weight={ 700 }
            lineHeight={ 19 }
          >Battery Consumption Stats</Text>
          {
            isDataExist && !loadingScooter ? (
              <Text
                lineHeight={ 19 }
                color={ Colors.grey.isGrey }
                spacing={ -0.5 }
                mt={ 8 }
              >{ setPeriodDate() }</Text>
            ) : null
          }
        </div>

        <Select
          visible={ visibleFilter }
          onVisibleChange={ visible => setVisibleFilter(visible) }
          selectTitle={ activeFilter }
          onClickSelectItem={ onClickFilterMenu }
          selectOptions={ [
            'Monthly',
            'Weekly',
            'Daily'
          ] }
        />
      </div>
    );
  };

  return (
    <BoxStatsStyle isFilterActive={ visibleFilter }>
      <Box
        mt={ 20 }
        padding={ 20 }
        width={ widthBox }
        height='100%'
      >
        { renderTopSection() }

        <div className='mt4'>
          { renderContentBatteryUsages() }
        </div>
      </Box>
    </BoxStatsStyle>
  );
};

export default BoxStats;
