import React from 'react';

import { Colors } from 'consts';

import {
  Label,
  Input,
  Indicator
} from './style';

import Skeleton from '../Skeleton';
import Text from '../Text';

type RadioButtonProps = {
  value?: string | number | readonly string[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
  label?: string | React.ReactNode;
  labelSubtitle?: string;
  disabled?: boolean;
  checked?: string | number | readonly string[];
  loading?: boolean;
  inverse?: boolean;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  onChange,
  name,
  id,
  label,
  labelSubtitle,
  disabled,
  checked,
  loading,
  inverse
}) => {
  const renderLabel = () => {
    return (
      <div className='ml6'>
        <Skeleton
          loading={ (loading || false) }
          paragraph={ {
            rows: 1,
            width: 100
          } }
          title={ false }
        >
          <Text
            weight={ 700 }
            align='justify'
          >{ label }</Text>
        </Skeleton>

        {
          labelSubtitle ? (
            <>
              <Skeleton
                loading={ (loading || false) }
                paragraph={ { rows: 3 } }
                title={ false }
              >
                <Text
                  size='xs'
                  align='justify'
                  mt={ 10 }
                  color={ Colors.black.isBlackGreen }
                >{ labelSubtitle }</Text>
              </Skeleton>
            </>
          ) : null
        }
      </div>
    );
  };

  return (
    <Label htmlFor={ id } disabled={ disabled }>
      { renderLabel() }
      <Input
        id={ id }
        type='radio'
        role='radio'
        name={ name }
        value={ value }
        checked={ checked === value }
        disabled={ disabled }
        onChange={ onChange }
      />
      <Indicator checked={ checked === value } inverse={ inverse } />
    </Label>
  );
};

export default RadioButton;
