import React from 'react';
import { Tag } from 'antd';

import { Colors } from 'consts';

import { TagStyle } from './style';

import Text from '../../../Text';

export type TagTableProps = {
  text: string;
  mb?: number;
  keyIndex?: number;
}

const TagTable: React.FC<TagTableProps> = ({
  text,
  mb = 0,
  keyIndex
}) => {
  const isVerified = text === 'true';
  const textColor = isVerified ? Colors.blue.isBlue : Colors.red.isRed;
  const bgColor = isVerified ? Colors.blue.isBlueOpacity('0.1') : Colors.red.isRedOpacity('0.1');
  const title = isVerified ? 'Verified' : 'Not Yet Verified';

  return (
    <div key={ `tag-verify-${keyIndex}` } style={ { marginTop: -mb } }>
      <TagStyle>
        <Tag color={ bgColor } className='tag-table'>
          <Text
            weight={ 700 }
            lineHeight={ 17 }
            color={ textColor }
          >{ title }</Text>
        </Tag>
      </TagStyle>
    </div>
  );
};

export default TagTable;
