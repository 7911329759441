import { User } from 'interfaces/user';
import { Pagination } from 'interfaces/common';
import { Showroom } from 'interfaces/showroom';

import {
  GET_ALL_USER,
  GET_USER_DETAIL,
  GET_USER_SHOWROOMS,
  LOGOUT,
  UserActionTypes
} from './actionTypes';

interface UserState {
  users: {
    data: User[];
    pagination: Pagination;
  };
  userDetail: User;
  userShowroom: Showroom;
}

const initState: UserState = {
  users: {
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
      order: '',
      page: 0,
      page_total: 0,
      search: '',
      sort: 'asc'
    }
  },
  userDetail: {
    id: 0,
    username: '',
    email: '',
    phone_number: '',
    is_active: false,
    is_completed: false,
    x_player: '',
    name: '',
    dob: '',
    address: '',
		zip_code: '',
		city: '',
    provider: '',
    id_img: '',
    selfie_img: '',
    driver_lic_img: '',
    status: '',
    showroom: null,
    showroom_assigned: 0
  },
  userShowroom: {
		id: 0,
		name: '',
		address: '',
		email: '',
		phone_number: '',
		pic_name: '',
		latitude: 0,
		longitude: 0,
		zip_code: '',
		city: '',
    is_pinned: false,
	}
};

const userReducer = (state = initState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case GET_ALL_USER:
      return {
        ...state,
        users: action.data
      };

    case GET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.data
      };

    case GET_USER_SHOWROOMS:
      return {
        ...state,
        userShowroom: action.data
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

export default userReducer;
