import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { Popover, RadioChangeEvent } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
  Input,
  Dashboard,
  Icon,
  Text,
  Switch,
  ModalLoader,
  DebounceSelect
} from 'components';
import { renderText, renderButtonAction } from 'components/DataTable';
import { Colors } from 'consts';
import {
  hooks,
  misc,
  roleHelper,
  toastify
} from 'helpers';
import {
  CurrentPagination,
  FormPagination,
  ReducerList,
  SelectOptionValue,
  SorterInfo
} from 'interfaces/common';
import {
  FormSubscription,
  Subscription,
  SubscriptionType,
  CitiesForm
} from 'interfaces/subscription';
import { Permission } from 'interfaces/role';
import { ActionModalForm } from 'typings';
import { language } from 'language';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import {
  dropdownsFilter,
  subscriptionTypeOptions,
  availabilityOptions,
  setInputPropsData
} from './data';
import {
  convertTypeFilterValue,
  convertSubscriptionAvailability,
  convertSubscriptionType
} from './helpers';
import { ContentPopoverTypeStyle, SuffixLabelStyle } from './style';
import { Cities, CitiesApiParams } from 'interfaces/location';

type Filter = 'type' | 'status';
type ActiveFilter = {
  [key in Filter]: string;
};

const { errorFormMessage, subscriptions: subsLang } = language;

const Subscriptions: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const getAllCities = dispatch(actions.getAllCities);
  const getAllSubscription = dispatch(actions.getAllSubscription);
  const addSubscription = dispatch(actions.addSubscription);
  const editSubscription = dispatch(actions.editSubscription);
  const deleteSubscription = dispatch(actions.deleteSubscription);

  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);
  const subscriptions = hooks.useAppSelector(selectors.subscription.subscriptions);

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [modalLoaderVisible, setModalLoaderVisible] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<ActiveFilter>({
    type: subscriptionTypeOptions[0],
    status: availabilityOptions[0]
  });
  const [sortOrder, setSortOrder] = useState<SorterInfo>({
    sort: null,
    order: ''
  });
  const [editedId, setEditedId] = useState<number>(-1);
  const [actionModalForm, setActionModalForm] = useState<ActionModalForm>(null);
  const [modalFormVisible, setModalFormVisible] = useState<boolean>(false);
  const [dataSelectedCities, setDataSelectedCities] = useState<SelectOptionValue[]>([]);
  const [citiesForm, setCitiesForm] = useState<CitiesForm[]>([]);

  const [openSelect, setOpenSelect] = useState<boolean>(false);

  const [form, setForm] = useState<FormSubscription>({
    name: '',
    subscription_type: subscriptionTypeOptions[1],
    description: '',
    duration: '',
    swap_amount: '',
    price: '',
    is_active: '',
    cities: [],
  });
  const [errorForm, setErrorForm] = useState<FormSubscription>({
    name: '',
    subscription_type: '',
    description: '',
    duration: '',
    swap_amount: '',
    price: '',
    is_active: '',
    cities: [],
  });
  const [formPagination, setFormPagination] = useState<FormPagination>({
    limit: 10,
    page: 1
  });
  const [search, setSearch] = useState<string>('');

  const debouncedSearch = hooks.useDebounce(search, 1200);
  const loadingSubscriptions = misc.isLazyLoading(lazyLoad, 'allSubscription');

  const buttonsDataTable = [
    {
      iconName: 'edit',
      color: 'blue',
      permission: Permission.subscription_detail,
      onClick: (record: Subscription) => onClickOpenModal('delete-edit', record)
    }
  ];

  const renderTextTableSubscription = (text: string, is_active: boolean) => {
    const textColor = !is_active ? Colors.black.isTextBlack2Opacity('0.4') : Colors.black.isTextBlack2;

    return renderText(text, textColor);
  };

  const columns: ColumnsType<Subscription> = [
    {
      title: 'Subscription Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text: string, record: Subscription) => renderTextTableSubscription(text, record?.is_active)
    },
    {
      title: 'Type',
      dataIndex: 'subscription_type',
      key: 'subscription_type',
      sorter: true,
      render: (text: string, record: Subscription) => renderTextTableSubscription(convertSubscriptionType(text), record?.is_active),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      sorter: true,
      render: (_: any, record: Subscription) => {
        const isTimeBase = record?.subscription_type === SubscriptionType.time_base;
        const value = isTimeBase ? record?.duration : record?.swap_amount;
        const suffix = isTimeBase ? subsLang.duration.days : subsLang.duration.timesSwap;

        return renderTextTableSubscription(`${ value } ${ suffix }`, record?.is_active);
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (text: string, record: Subscription) => renderTextTableSubscription(misc.currencyFormat(text?.toString() ?? ''), record?.is_active),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'is_active',
      sorter: true,
      render: (_: any, record: Subscription) => {
        return (
          <Switch
            size='sm'
            checked={ record?.is_active }
            onChange={ () => onChangeStatusSubscription(record) }
          />
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_: any, record: Subscription) => renderButtonAction(buttonsDataTable, record)
    },
  ];

  const getDataSubscriptions = () => {
    getAllSubscription({
      ...formPagination,
      page: refreshing ? 1 : formPagination.page,
      search: debouncedSearch,
      sort: sortOrder.sort || 'asc',
      order: sortOrder.order || 'id',
      type: convertTypeFilterValue(activeFilter.type),
      is_active: convertSubscriptionAvailability(activeFilter.status)
    });
  };

  useEffect(() => {
    getDataSubscriptions();
  }, [
    formPagination.page,
    formPagination.limit,
    debouncedSearch,
    sortOrder.sort,
    sortOrder.order,
    activeFilter.status,
    activeFilter.type,
  ]);

  // Handle refreshing, ex: after add/edit/delete
  useEffect(() => {
    if (refreshing) {
      getDataSubscriptions();

      setRefreshing(false);
    }
  }, [refreshing]);

  const onChangeStatusSubscription = (record: Subscription) => {
    if (roleHelper.isPermissible(adminPermissions, Permission.subscription_update)) {
      const formChangeStatus: FormSubscription = {
        ...record,
        is_active: !record?.is_active
      };

      setModalLoaderVisible(true);

      editSubscription(
        record?.id.toString(),
        formChangeStatus,
        handleCbChangeStatus
      );
    } else {
      toastify.error('Sorry, you are not authorized to update status');
    }
  };

  const findProvinceByCity = async(city: string) => {

    const params: CitiesApiParams = {
      search: city ? city : '',
      sort: 'asc',
      limit: 50,
      offset: '',
      order: '',
      page: ''
    };

    const newOptions = await getCities(params);
    const index = newOptions.findIndex((item: { value: string; }) => {
      return item.value === city;
    });
    return newOptions[index].label;
  };

  const fetchSearchCity = async(search: string): Promise<SelectOptionValue[]> => {

    const params: CitiesApiParams = {
      search: search,
      sort: 'asc',
      limit: 50,
      offset: '',
      order: '',
      page: ''
    };

    return getCities(params);

  };

  const getCities = async params => {
    let dataCities;

    await getAllCities(params,
      (cities: ReducerList<Cities[]>) => {
        if (cities?.data) {

          dataCities = cities?.data?.map(list => ({
            key: list.city,
            value: list.city,
            label: list.province_name + '-' + list.city
          }));
        } else {
          dataCities = [];
        }
      });

    return dataCities;
  };

  const changeCities = (val: React.SetStateAction<SelectOptionValue[]>) => {
    setDataSelectedCities(val);
    const data = [] as any[];
    Object.values(val).map(input => {
      data.push({ city: input.value });
    });

    setCitiesForm(data);
  };

  const renderSearchInputCity = () => {
    return (
      <DebounceSelect
        mode='multiple'
        value={ dataSelectedCities }
        fetchOptions={ fetchSearchCity }
        onChange={ newValue => changeCities(newValue) }
        label='Cities'
        labelColor={ Colors.grey.isGrey }
        placeholder='Enter Cities'
        backgroundColor={ Colors.white.default }
        open={ openSelect }
        onDropdownVisibleChange={ visible => setOpenSelect(visible) }
      />
    );
  };

  const handleCbChangeStatus = (message: string) => {
    toastify.success(message);

    setModalLoaderVisible(false);

    setRefreshing(true);
  };

  const onChangeFormText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (e.target.name === 'duration' || e.target.name === 'swap_amount') {
      value = e.target.value.replace(/\D|^0+/g, '');
    }

    setForm(prevForm => ({
      ...prevForm,
      [e.target.name]: value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      [e.target.name]: ''
    }));
  }, []);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, key: string) => {
    if (e.key === '.' && (key === 'duration' || key === 'swap_amount')) {
      e.preventDefault();
    }
  };

  const onChangeFormRadio = useCallback((e: RadioChangeEvent) => {
    setForm(prevForm => ({
      ...prevForm,
      [e.target.name ?? '']: e.target.value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      [e.target.name ?? '']: ''
    }));
  }, []);

  const setInitialPage = () => {
    setFormPagination(prevForm => ({
      ...prevForm,
      page: 1
    }));
  };

  const contentPopoverTypeDesc = () => {
    return (
      <ContentPopoverTypeStyle>
        <ul className='wrapper-list'>
          <li><Text>Time base : Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Text></li>
          <li><Text>Swap base : Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Text></li>
        </ul>
      </ContentPopoverTypeStyle>
    );
  };

  const renderSuffixLabelInput = (key: string) => {
    if (key === 'subscription_type') {
      return (
        <Popover
          trigger={ ['hover', 'click'] }
          content={ contentPopoverTypeDesc }
          placement='bottomLeft'
        >
          <SuffixLabelStyle>
            <Icon
              size={ 17 }
              iconName='question'
              fill={ Colors.grey.isGrey }
              containerClassName='icon-label-input'
            />
          </SuffixLabelStyle>
        </Popover>
      );
    }

    return <></>;
  };

  const renderInput = (
    label: string,
    placeholder: string,
    key: string,
    type: string,
    data?: string[]
  ) => {
    const inputDisabled = !roleHelper.isPermissible(adminPermissions, Permission.subscription_update)
      || (key === 'subscription_type' && (actionModalForm === 'delete-edit' || actionModalForm === 'edit'));

    if (type === 'multiSelect' && key === 'cities') {
      return renderSearchInputCity();
    }

    return (
      <Input
        radioValue={ data }
        type={ type }
        label={ label }
        weightLabel={ type === 'radio_group' ? 700 : undefined }
        placeholder={ placeholder }
        labelColor={ type === 'radio_group' ? Colors.black.isBlack : Colors.grey.isGrey }
        value={ form[key] }
        errorMessage={ errorForm[key] }
        name={ key }
        width={ key === 'duration' || key === 'swap_amount' ? 130 : '100%' }
        onChange={ onChangeFormText }
        onChangeRadio={ onChangeFormRadio }
        onKeyDown={ (e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(e, key) }
        mb={ 20 }
        backgroundColor={ Colors.white.default }
        disabled={ inputDisabled }
        renderSuffixLabel={ () => renderSuffixLabelInput(key) }
      />
    );
  };

  const onClickOpenModal = (actionModal: ActionModalForm, record?: Subscription) => {
    setActionModalForm(actionModal);
    setDataSelectedCities([]);
    if (record) {
      setEditedId(+ record?.id);

      setForm(prevForm => ({
        ...prevForm,
        name: record?.name,
        subscription_type: convertSubscriptionType(record?.subscription_type),
        description: record?.description,
        price: misc.currencyFormat(record?.price?.toString() ?? ''),
        duration: + record?.duration,
        swap_amount: + record?.swap_amount,
        is_active: convertSubscriptionAvailability(record?.is_active),
        cities: record?.cities
      }));
      const data = [] as any[];
      record?.cities?.map(async item => {
        findProvinceByCity(item?.city).then(newOptions => {
          setDataSelectedCities(prevState => [
            ...prevState, {
              value: item.city,
              key: item.city,
              label: newOptions
            }
          ]);
          data.push({ city: item.city });
        });
      });

      setCitiesForm(data);

    }

    setModalFormVisible(true);
  };

  const onCloseModalForm = () => {
    setModalFormVisible(false);
    setActionModalForm(null);

    setEditedId(-1);

    const emptyState: FormSubscription = {
      name: '',
      subscription_type: subscriptionTypeOptions[1],
      description: '',
      duration: '',
      swap_amount: '',
      price: '',
      is_active: '',
      cities: null,
    };

    setForm(emptyState);

    setErrorForm({
      ...emptyState,
      subscription_type: ''
    });
  };

  const handleFormValidation = () => {
    const emptyForm = misc.getMultipleKeyByArrOfValue(misc.removeProperties(
      form,
      'description',
      convertSubscriptionType(form.subscription_type) === SubscriptionType.swap_base ? 'duration' : 'swap_amount'
    ), ['', 0]);
    const inputPropsData = setInputPropsData(form.subscription_type);

    const minimalPrice = (form?.price as string)?.split(' ')[1].replace(',', '');

    if (Number(minimalPrice) < 10000) {
      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        price: 'Price must be more than IDR 10,000'
      }));
      return false;
    }

    if (emptyForm.length) {
      emptyForm.forEach(key => {
        inputPropsData.forEach(input => {
          if (key === input.key) {
            setErrorForm(prevErrorForm => ({
              ...prevErrorForm,
              [key]: errorFormMessage.form(input.label.toLowerCase())
            }));
          }
        });
      });

      return false;
    }

    return true;
  };

  const onClickButtonModalForm = (actionModal: ActionModalForm) => {
    if (actionModal === 'delete') {
      deleteSubscription(editedId.toString(), handleCbFormSubscription);
    } else {

      if (handleFormValidation()) {
        const subscriptionType = convertSubscriptionType(form.subscription_type);
        const isTimeBase = subscriptionType === SubscriptionType.time_base;

        const payload: FormSubscription = {
          ...form,
          duration: isTimeBase ? + form.duration : 0,
          swap_amount: isTimeBase ? 0 : + form.swap_amount,
          price: misc.currencyToNumber(form.price.toString()),
          subscription_type: subscriptionType,
          cities: citiesForm,
          is_active: convertSubscriptionAvailability(form.is_active)
        };

        if (actionModal === 'add') {
          addSubscription(payload, handleCbFormSubscription);
        } else if (actionModal === 'edit') {
          editSubscription(
            editedId.toString(),
            payload,
            handleCbFormSubscription
          );
        }
      }
    }
  };

  const handleCbFormSubscription = async(message: string) => {
    await onCloseModalForm();

    await toastify.success(message);

    setRefreshing(true);
  };

  const renderContentModalForm = () => {
    const inputPropsData = setInputPropsData(form.subscription_type);
    const subscriptionType = convertSubscriptionType(form.subscription_type);
    const isTimeBase = subscriptionType === SubscriptionType.time_base;

    return inputPropsData.map((input, index) => {
      const isDurationInput = input.key === 'duration' || input.key === 'swap_amount';

      return (
        <div key={ index } className={ isDurationInput ? 'align-center' : '' }>
          { renderInput(
            input.label,
            input.placeholder,
            input.key,
            input.type,
            input.data
          ) }

          { isDurationInput
            ? (
              <Text
                size='s'
                ml={ 15 }
                color={ Colors.grey.isGrey }
              >{ isTimeBase ? subsLang.duration.days : subsLang.duration.timesSwap }</Text>
            ) : null }
        </div>
      );
    });
  };

  const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    setInitialPage();
  }, []);

  const onClickMenuItem = ({ key: keyItem }: any, keyFilter: string) => {
    setActiveFilter(prevState => ({
      ...prevState,
      [keyFilter]: keyItem
    }));

    setInitialPage();
  };

  const onChangeTable = (sorterInfo: SorterInfo) => {
    setSortOrder(sorterInfo);
    setInitialPage();
  };

  const getCurrentPagination = (currentPagination: CurrentPagination<Subscription[]>) => {
    setFormPagination(prevPagination => ({
      ...prevPagination,
      page: currentPagination.currentPage,
      limit: currentPagination.elementsPerPage
    }));
  };

  const renderModalLoader = () => {
    return <ModalLoader visible={ modalLoaderVisible } onCloseModal={ () => setModalLoaderVisible(false) } />;
  };

  return (
    <Dashboard
      container={ {
        selectedMenu: 10,
        headerContent: {
          textHeader: subsLang.header.title,
          textBtn: subsLang.header.textBtn,
          iconNameBtn: 'add',
          onClickBtn: () => onClickOpenModal('add'),
          permissionBtn: Permission.subscription_create
        }
      } }
      filterMenu={ {
        dropdownsFilter,
        activeFilter,
        onClickMenuItem,
        search,
        placeholderSearch: 'Search by name',
        onChangeSearch
      } }
      data={ {
        currentData: subscriptions?.data || [],
        loading: loadingSubscriptions,
        fieldName: 'subscriptions',
        getCurrentPagination
      } }
      table={ {
        columns,
        buttons: buttonsDataTable,
        onChange: onChangeTable
      } }
      modalForm={ {
        title: 'subscription',
        customText: actionModalForm === 'add'
          ? {
            title: subsLang.header.textBtn,
            button: subsLang.btnForm.save
          }
          : undefined,
        visible: modalFormVisible,
        actionModal: actionModalForm,
        onCloseModal: onCloseModalForm,
        footer: {
          fieldName: 'subscription',
          onClickButtonSubmit: onClickButtonModalForm,
        },
        contentModal: renderContentModalForm(),
        permissions: {
          edit: Permission.subscription_update,
          delete: Permission.subscription_delete
        }
      } }
    >
      { renderModalLoader() }
    </Dashboard>
  );
};

export default Subscriptions;
