import { createSelector } from 'reselect';

import { RootState } from '../index';

const stationSelector = (state: RootState) => state.station;

export const station = createSelector(stationSelector, station => station.station);
export const stationDetail = createSelector(stationSelector, station => station.stationDetail);
export const holeStation = createSelector(stationSelector, station => station.holeStation);
export const listBusyTime = createSelector(stationSelector, station => station.listBusyTime);
export const stationVisitor = createSelector(stationSelector, station => station.stationVisitor);
