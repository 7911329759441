import { Showroom } from 'interfaces/showroom';
import { Pagination } from 'interfaces/common';

export const GET_SHOWROOMS = 'GET_SHOWROOMS';
export const GET_SHOWROOM_DETAIL = 'GET_SHOWROOM_DETAIL';
export const LOGOUT = 'LOGOUT';

export interface SetAllShowroom {
  type: typeof GET_SHOWROOMS;
  data: {
    data: Showroom[],
    pagination: Pagination
  }
}

export interface SetShowroom {
  type: typeof GET_SHOWROOM_DETAIL;
  data: Showroom;
}

export interface Logout {
	type: typeof LOGOUT;
}

export type ShowroomActionTypes =
  SetAllShowroom |
  SetShowroom |
  Logout;
