import React, { useState, useEffect } from 'react';

import {
  Button,
  Container,
  Image,
  Line,
  Text
} from 'components';
import { Colors, Images } from 'consts';
import {
  hooks,
  navigation,
  screen
} from 'helpers';

import { NotFoundStyle } from './style';

const NotFound: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  useEffect(() => {
    if (windowDimensions.width <= screen.isMobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowDimensions.width]);

  const renderContent = () => {
    return (
      <div>
        <Text
          size={ isMobile ? 'xl6' : 'xl7' }
          weight={ 700 }
          text='404'
        />
        <Text
          size={ isMobile ? 'xl2' : 'xl4' }
          mt={ 20 }
          weight={ 700 }
          text='Something is missing...'
        />
        <Line margin='40px 0' borderRadius={ 100 } />
        <Text
          size={ isMobile ? 'l' : 'xl' }
          weight={ 400 }
          color={ Colors.grey.isGreyGreen }
          mb={ 40 }
          text='Sorry, the page you are looking for could not be found'
        />
        <Button
          fontWeight={ 700 }
          styleType='inverse'
          width={ 150 }
          size='m'
          borderRadius={ 100 }
          onClick={ () => navigation.push('/') }
          text='Back to Home'
        />
      </div>
    );
  };

  return (
    <Container backgroundColor={ Colors.white.default }>
      <NotFoundStyle windowDimensions={ windowDimensions }>
        <div className='content'>
          <div className='center-content half-container'>
            <div className='inner-container col center-content'>
              { renderContent() }
            </div>
          </div>
          <div className='half-container'>
            <Image src={ isMobile ? Images.bgLoginMobile : Images.bgLogin } className='half-background-image' />
          </div>
        </div>
      </NotFoundStyle>
    </Container>
  );
};

export default NotFound;
