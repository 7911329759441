import React from 'react';

import {
	Icon,
	Spacer,
	Text
} from 'components';

const FailurePayment: React.FC = () => {
	return (
		<div className='col center-content'>
			<Spacer mb={ 5 } mt={ 100 }>
				<Icon type='swal-icon' iconName='error' />
			</Spacer>
			<Text
				size='xl'
				weight={ 700 }
				align='center'
				mb={ 10 }
			>Payment Failed</Text>
			<Text
				weight={ 400 }
				size='m'
				align='center'
			>Sorry, we aren&apos;t able to process your payment.</Text>
		</div>
	);
};

export default FailurePayment;
