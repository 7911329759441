import React from 'react';
import { Tooltip } from 'antd';

import { TooltipPlacement } from 'typings';

type CustomTooltipProps = {
  placement?: TooltipPlacement;
  title: () => JSX.Element; // eslint-disable-line no-undef
  children: React.ReactNode;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  placement = 'topLeft',
  title,
  children
}) => {
  return (
    <Tooltip
      placement={ placement }
      title={ title }
      trigger={ ['hover', 'click'] }
    >
      <span>{ children }</span>
    </Tooltip>
  );
};

export default CustomTooltip;
