import React, { useState, useCallback } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  CartesianGrid,
  XAxis,
  YAxis,
  LabelList,
  Tooltip
} from 'recharts';

import { Colors } from 'consts';

import CustomTooltip, { CustomTooltipProps } from '../Tooltip';
import CustomVerticalGrid from '../VerticalGrid';
import CustomizedAxisTick from '../AxisTick';
import { propsFont, propsDefaultAxis } from '../index';

export type BarChartProps = {
  layout?: 'vertical' | 'horizontal';
  width?: number;
  height?: number;
  data: any;
  valueDomain?: number[];
  unit?: string;
  convertLabel?: (label: string) => void;
  tooltipProps?: CustomTooltipProps;
  dataKey: {
    xAxis?: string;
    yAxis?: string;
    bar: string;
  };
  margin?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  barRadius?: number;
  barSize?: number;
  fill?: string;
  focusFill?: string;
  multipleFill?: (value: number) => string[] | false;
  heightAxis?: number;
  transformLabel?: string;
  sizeFont?: number;
};

const CustomBarChart: React.FC<BarChartProps> = ({
  layout,
  width,
  height,
  data,
  valueDomain,
  unit,
  convertLabel,
  dataKey,
  margin,
  barRadius,
  barSize,
  fill,
  focusFill,
  tooltipProps,
  multipleFill,
  heightAxis,
  transformLabel,
  sizeFont,
}) => {
  const [focusBar, setFocusBar] = useState<number>(-1);

  const renderCustomTooltip = (props: any) => {
    const {
      active,
      payload,
      label
    } = props;
    
    if (active && payload) {
      const valueTooltip = tooltipProps ? tooltipProps?.value : `${payload[0]?.value}${(unit || '')}`;
      const labelTooltip = tooltipProps && tooltipProps.label
      ? tooltipProps.label
      : convertLabel
      ? convertLabel(label)
      : label;
      
      const labelLineBreak = labelTooltip?.replace('<br>', '');

      return <CustomTooltip value={ valueTooltip } label={ labelLineBreak ? labelLineBreak : labelTooltip } />;
    }
  
    return null;
  };

  const renderCustomizedLabel = (props: any) => {
    const {
      x,
      y,
      height,
      value
    } = props;
  
    const fireOffset = value.toString().length < 5;
    const offset = fireOffset ? -40 : 5;
    
    return (
      <text
        x={ x + (width ? width - 100 : 0) - offset }
        y={ y + height - 5 }
        fill={ Colors.grey.isGrey }
        textAnchor='end'
        style={ propsFont }
      >
        { value }{ unit }
      </text>
    );
  };

  const onMouseMove = useCallback(state => {
    if (state.isTooltipActive) {
      setFocusBar(state.activeTooltipIndex);
    } else {
      setFocusBar(-1);
    }
  }, []);

  const setFillCell = (index: number, value: number) => {
    if (multipleFill) {
      return focusBar === index ? multipleFill(value)[1] : multipleFill(value)[0];
    } else {
      return focusBar === index ? focusFill : fill;
    }
  };

  const renderVerticalGrid = (props: any) => {
    return (
      <CustomVerticalGrid
        key={ props.key }
        props={ props }
        offsetPoint={ props.xAxis.bandSize / 2 }
      />
    );
  };
  
  return (
    <ResponsiveContainer width='100%' height={ height }>
      <BarChart
        width={ width }
        data={ data }
        margin={ margin }
        onMouseMove={ onMouseMove }
        onMouseLeave={ () => setFocusBar(-1) }
        layout={ layout }
      >
        {
        /* {
          layout === 'horizontal' &&
          <CartesianGrid
            horizontal={ false }
            stroke={ Colors.grey.isLightGrey }
            strokeWidth={ 0.5 }
            vertical={ renderVerticalGrid }
          />
        } */
        }

        <XAxis
          unit={ layout === 'vertical' ? unit : '' }
          dataKey={ dataKey.xAxis }
          domain={ valueDomain }
          type={ layout === 'vertical' ? 'number' : 'category' }
          hide={ layout === 'vertical' }
          padding={ layout === 'horizontal' ? { left: 15 } : undefined }
          interval={ 0 }
          height={ heightAxis }
          tick={ props =>
            <CustomizedAxisTick
              props={ props }
              maxChars={ false }
              customTransform={ transformLabel }
              fontSize={ sizeFont }
            />
          }
          { ...propsDefaultAxis }
          { ...propsFont }
        />
        <YAxis
          unit={ layout === 'vertical' ? '' : unit }
          dataKey={ dataKey.yAxis }
          domain={ valueDomain }
          type={ layout === 'vertical' ? 'category' : 'number' }
          padding={ { top: 10 } }
          interval={ 0 }
          { ...propsDefaultAxis }
          { ...propsFont }
        />
        
        <Tooltip
          wrapperStyle={ { outline: 'none' } }
          content={ renderCustomTooltip }
          cursor={ { fill: Colors.grey.isBgLightGrey } }
        />

        <Bar
          dataKey={ dataKey.bar }
          radius={ barRadius }
          maxBarSize={ barSize }
        >
          {
            data?.map((entry, index) => (
              <Cell key={ `cell-${index}` } fill={ setFillCell(index, entry[dataKey.bar]) }/>
            ))
          }
          {
            layout === 'vertical' && <LabelList position='insideRight' content={ renderCustomizedLabel } />
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

CustomBarChart.defaultProps = {
  layout: 'horizontal',
  barRadius: 50,
  barSize: 35,
  valueDomain: [0, 100],
  height: 300,
  fill: Colors.grey.isGreyBlue,
  focusFill: Colors.blue.isBlue,
  heightAxis: 40
};

export default CustomBarChart;
