import Styled from 'styled-components';

export const ModalStyle: any = Styled.div `
  .inner-container {
    max-height: 325px;
    padding-right: 15px;
    overflow-y: auto;
    scrollbar-color: rgba(57, 170, 180, 0.4) rgba(57, 170, 180, 0.2);
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 4px;
      border-radius: 50px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      background: rgba(57, 170, 180, 0.2);
      border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(57, 170, 180, 0.4);
      border-radius: 50px;
    }

    ::-webkit-scrollbar-corner {
      border-radius: 50px;
    }
  }
`;