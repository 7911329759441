import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { ColumnsType } from 'antd/es/table';

import { SelectOptionValue, SorterInfo } from 'interfaces/common';
import {
  Dashboard,
  Input,
  MapsLeaflet,
  ModalLoader,
  SearchInput,
  Select,
  SelectSearchBar,
  TopLoaderBar,
  Text,
  Icon,
} from 'components';
import { renderText, renderButtonAction } from 'components/DataTable';
import { MenuEntity } from 'components/Dropdown';
import { DataOption } from 'components/Input/SearchInput';
import { Colors, MapConfig } from 'consts';
import {
  hooks,
  misc,
  navigation,
  toastify,
  roleHelper,
} from 'helpers';
import {
  CurrentPagination,
  FormPagination,
  ReducerList
} from 'interfaces/common';
import { Permission } from 'interfaces/role';
import { BatteryStation, FormBatteryStation } from 'interfaces/station';
import {
  ActionModalForm,
  DetailNavProps,
  ValueType
} from 'typings';
import { language } from 'language';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';
import { User, UserApiParams } from 'interfaces/user';
import { Cities, CitiesApiParams } from 'interfaces/location';

import {
  dropdownsFilter,
  inputPropsData,
  batteryStationFilterType
} from './data';
import { convertTypeFilterValue } from './helpers';
import { ItemDropdown, RenderInputs } from './style';

type Filter = 'city' | 'station_type';

type ActiveFilter = {
  [key in Filter]: string;
};

type Selection = 'station_type';
type VisibleSelect = {
  [key in Selection]: boolean;
};

type FilterCity = 'city';

type VisibleFilter = {
  [key in FilterCity]: boolean;
};

const { errorFormMessage } = language;

const convertColorTextStationName = (text: string) => {
  if (text[0] === 'H') {
    return Colors.grey.isGreyGreen;
  }

  return Colors.black.isTextBlack2;
};

const BatteryStations: React.FC = () => {

  const [visibleFilter, setVisibleFilter] = useState<VisibleFilter>({ city: false });

  const [dataSelectedProvinces, setDataSelectedProvinces] = useState<SelectOptionValue[]>([]);

  const dispatch = hooks.useAppDispatch();
  const getAllBatteryStations = dispatch(actions.getAllBatteryStations);
  const addBatteryStations = dispatch(actions.addBatteryStations);
  const getAllUser = dispatch(actions.getAllUser);
  const getAllCities = dispatch(actions.getAllCities);

  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);
  const station = hooks.useAppSelector(selectors.station.station);

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<ActiveFilter>({
    city: '',
    station_type: batteryStationFilterType[0],
  });
  const [sortOrder, setSortOrder] = useState<SorterInfo>({
    sort: null,
    order: ''
  });
  const [visibleSelect, setVisibleSelect] = useState<VisibleSelect>({ station_type: false });
  const [actionModalForm, setActionModalForm] = useState<ActionModalForm>(null);
  const [modalFormVisible, setModalFormVisible] = useState<boolean>(false);
  const [modalLoaderVisible, setModalLoaderVisible] = useState<boolean>(false);
  const [form, setForm] = useState<FormBatteryStation>({
    id: '',
    name: '',
    station_type: 'Choose station type',
    // user_id: 0,
    address: '',
    pic_number: '',
    pic_name: '',
    latitude: 0,
    longitude: 0,
    zip_code: '',
    city: '',
  });

  // Jika user_id digunakan maka pakai typep arameter ini <Omit<FormBatteryStation, 'user_id'> & { user_id: string; }>
  const [errorForm, setErrorForm] = useState<FormBatteryStation>({
    id: '',
    name: '',
    station_type: '',
    // user_id: '',
    address: '',
    pic_number: '',
    pic_name: '',
    latitude: '',
    longitude: '',
    zip_code: '',
    city: '',
  });
  const [formPagination, setFormPagination] = useState<FormPagination>({
    limit: 10,
    page: 1
  });
  const [search, setSearch] = useState<string>('');
  const [goToStationDetail, setGoToStationDetail] = useState<DetailNavProps>({
    progress: -1,
    id: ''
  });
  const defaultOptionsProvinces = [
    {
      label: 'Choose City',
      value: '',
      key: 'Choose City'
    },
  ];
  const [searchUser, setSearchUser] = useState<string>('');
  const [dataSearchUser, setDataSearchUser] = useState<DataOption[]>([]);
  const [isContentPinpoint, setIsContentPinpoint] = useState<boolean>(false);
  const [valueGeosearch, setValueGeosearch] = useState<ValueType>({
    shortLabel: '',
    label: '',
    value: '',
    postcode: '',
    valueDetail: MapConfig.defaultCenter
  });
  const debouncedSearch = hooks.useDebounce(search, 1200);
  const [openDropdownUser, setOpenDropdownUser] = useState<boolean>(false);

  const debouncedSearchUser = hooks.useDebounce(searchUser, 1200);
  const loadingBatteryStations = misc.isLazyLoading(lazyLoad, 'allBatteryStation');

  const buttonsDataTable = [
    {
      iconName: 'search',
      color: 'blue',
      onClick: (record: BatteryStation) => onClickRow(record),
      permission: Permission.battery_station_detail,
      // onClick: (record: BatteryStation) => onClickOpenModal('detail', record), // note: jika fitur modal detail + edit diadakan seperti di halaman showroom, uncomment line ini, dan comment line onClick diatas
    }
  ];

  const columns: ColumnsType<BatteryStation> = [
    {
      title: 'Station ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: text => renderText(text.toString(), convertColorTextStationName(text.toString()))
    },
    {
      title: 'Station Name',
      dataIndex: 'name',
      key: 'name',
      width: 160,
      render: text => renderText(text)
    },
    {
      title: 'Avg. Swap Transaction',
      dataIndex: 'average_swap',
      key: 'average_swap',
      width: 120,
      render: text => renderText(String(text.toFixed(2))),
    },
    {
      title: 'Avg Power Consumption',
      dataIndex: 'avg_power_consumption',
      key: 'avg_power_consumption',
      width: 124,
      render: text => renderText(String(text.toFixed(2))),
    },
    {
      title: 'Available Battery',
      dataIndex: 'available_battery',
      key: 'available_battery',
      width: 120,
      render: text => renderText(misc.setMultiplierWording(text))
    },
    {
      title: 'Charging Battery',
      dataIndex: 'charging_battery',
      key: 'charging_battery',
      width: 120,
      render: text => renderText(misc.setMultiplierWording(text))
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (_: any, record: BatteryStation) => renderButtonAction(buttonsDataTable, record)
    }
  ];

  // Handle search & select user in the form add scooter
  useEffect(() => {
    if (openDropdownUser) {
      const formPaginationUser: FormPagination = {
        limit: 20,
        page: 1
      };
      const params: UserApiParams = {
        ...formPaginationUser,
        search: debouncedSearchUser,
        sort: 'asc'
      };

      getAllUser(
        params,
        '',
        (users: ReducerList<User[]>) => {
          if (users?.data) {
            const dataShowroom = users?.data?.map(showroom => ({
              value: showroom?.id,
              text: showroom?.name
            }));

            setDataSearchUser(dataShowroom);
          } else {
            setDataSearchUser([]);
          }
        }
      );
    }
  }, [debouncedSearchUser, openDropdownUser]);

  useEffect(() => {
    getDataBatteryStations();
  }, [
    formPagination.page,
    formPagination.limit,
    debouncedSearch,
    sortOrder.sort,
    sortOrder.order,
    activeFilter.station_type,
    activeFilter.city,
    refreshing
  ]);

  const getDataBatteryStations = () => {
    getAllBatteryStations({
      ...formPagination,
      page: refreshing ? 1 : formPagination.page,
      search: debouncedSearch,
      sort: sortOrder.sort || 'desc',
      order: sortOrder.order || 'created_date',
      type: convertTypeFilterValue(activeFilter.station_type),
      city: activeFilter.city
    });
  };

  const getCities = async params => {
    let dataCities;

    await getAllCities(params,
      (cities: ReducerList<Cities[]>) => {
        if (cities?.data) {

          dataCities = cities?.data?.map(list => ({
            key: list?.city,
            value: list?.city,
            label: list?.province_name + '-' + list?.city
          }));
        } else {
          dataCities = [];
        }
      });

    return dataCities;
  };

  const setInitialPage = () => {
    setFormPagination(prevFormPagination => ({
      ...prevFormPagination,
      page: 1
    }));
  };

  const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    setInitialPage();
  }, []);

  const onClickMenuItem = ({ key: keyItem }, keyFilter) => {
    setActiveFilter(prevState => ({
      ...prevState,
      [keyFilter]: keyItem
    }));

    setInitialPage();
  };

  const findProvinceByCity = async(city: string) => {
    const params: CitiesApiParams = {
      search: city ? city : '',
      sort: 'asc',
      limit: 50,
      offset: '',
      order: '',
      page: ''
    };

    const newOptions = await getCities(params);
    const index = newOptions?.findIndex(item => item?.value === city);
    return newOptions[index]?.label;
  };

  const onClickOpenModal = (actionModal: ActionModalForm, record?: BatteryStation) => {
    setActionModalForm(actionModal);
    setRefreshing(false);
    if (record) {
      setForm(prevForm => ({
        ...prevForm,
        id: record?.id,
        name: record?.name,
        station_type: record?.station_type,
        address: record?.address,
        pic_number: record?.pic_number,
        pic_name: record?.pic_name,
        // user_id: record?.user_id,
        latitude: + record?.latitude,
        longitude: + record?.longitude,
        zip_code: record?.zip_code,
        city: record?.city,
      }));

      findProvinceByCity(record?.city).then(newOptions => {
        setDataSelectedProvinces([
          {
            value: record?.city,
            key: record?.city,
            label: newOptions
          }
        ]);
      });

      if (record?.station_type === 'Home Station') {
        setModalLoaderVisible(true);
      } else {
        setModalFormVisible(true);
      }
    }

    if (actionModal === 'add') {
      setModalFormVisible(true);
    }
  };

  const onVisibleChange = (visible: boolean, key: string) => {
    setVisibleFilter(prevFilter => ({
      ...prevFilter,
      [key]: visible
    }));
  };

  const onCloseModalForm = () => {
    setModalFormVisible(false);
    setIsContentPinpoint(false);
    setActionModalForm(null);

    setDataSelectedProvinces([]);

    const emptyState = {
      id: '',
      name: '',
      station_type: '',
      address: '',
      pic_name: '',
      pic_number: '',
      latitude: '',
      longitude: '',
      zip_code: '',
      city: '',
    };

    setForm({
      ...emptyState,
      station_type: 'Choose station type',
      // user_id: 0
    });

    setSearchUser('');
    setDataSearchUser([]);

    setErrorForm({
      ...emptyState,
      // user_id: ''
    });
  };

  const handleFormValidation = () => {
    const emptyForm = misc.getMultipleKeyByArrOfValue(form, [
      '', 'Choose station type',
      // form.station_type === 'Home Station' ? 'Search and select linked user' : ''
    ]);

    if (emptyForm.length) {
      emptyForm.forEach(key => {
        inputPropsData.forEach(input => {
          if (key === input.key) {
            setErrorForm(prevErrorForm => ({
              ...prevErrorForm,
              [key]: errorFormMessage.form(input.label.toLowerCase())
            }));
          }
        });
      });

      return false;
    }

    return true;
  };

  const onClickButtonModalForm = () => {
    if (isContentPinpoint) {

      setForm(prevForm => ({
        ...prevForm,
        address: valueGeosearch.value,
        zip_code: valueGeosearch.postcode,
        longitude: valueGeosearch.valueDetail.lng,
        latitude: valueGeosearch.valueDetail.lat
      }));

      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        address: '',
        zip_code: '',
        latitude: '',
        longitude: ''
      }));

      setIsContentPinpoint(false);
    } else {

      if (handleFormValidation()) {
        if (actionModalForm === 'add') {
          const formPayload = {
            ...form,
            id: Number(form.id),
            pic_number: misc.phoneNumberFormat(form.pic_number),
            station_type: convertTypeFilterValue(form.station_type)
          };

          addBatteryStations(formPayload, handleCbFormStation);
        } else if (actionModalForm === 'edit') {
          // edit action
          handleCbFormStation('Battery station berhasil diupdate');
        }
      }
    }
  };

  const handleCbFormStation = async(message: string) => {
    await onCloseModalForm();

    await toastify.success(message);

    setRefreshing(true);
  };

  const onVisibleSelectChange = (visible: boolean, keyForm: string) => {
    setVisibleSelect(prevState => ({
      ...prevState,
      [keyForm]: visible
    }));
  };

  const onClickSelectItem = ({ key }: { key: string; }, keyForm: string) => {
    setVisibleSelect(prevState => ({
      ...prevState,
      [keyForm]: false
    }));

    setTimeout(() => {
      setForm(prevForm => ({
        ...prevForm,
        [keyForm]: key
      }));

      // if (key === 'Home Station') {
      //   setErrorForm(prevErrorForm => ({
      //     ...prevErrorForm,
      //     [keyForm]: '',
      //     user_id: ''
      //   }));
      // } else {

      // }

      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        [keyForm]: ''
      }));

    }, 300);
  };

  const onSetFormValue = (key: string, value: any) => {
    setForm(prevForm => ({
      ...prevForm,
      [key]: value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      [key]: ''
    }));
  };

  const onChangeFormText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onSetFormValue(e.target.name, e.target.value);
  }, []);

  const renderSelect = (
    label: string,
    placeholder: string,
    key: string
  ) => {
    const selectOptions = ['Charging Station', 'Home Station'];

    return (
      <Select
        type='form'
        visible={ visibleSelect[key] }
        onVisibleChange={ (visible: boolean) => onVisibleSelectChange(visible, key) }
        selectTitle={ form[key] }
        label={ label }
        placeholder={ placeholder }
        onClickSelectItem={ (params: MenuEntity) => onClickSelectItem(params, key) }
        selectOptions={ selectOptions }
        backgroundColor={ Colors.white.default }
        isDisabled={ actionModalForm === 'detail' }
        errorMessage={ errorForm[key] }
      />
    );
  };

  const fetchSearchProvinces = async(search: string): Promise<SelectOptionValue[]> => {

    const params: CitiesApiParams = {
      search: search,
      sort: 'asc',
      limit: 50,
      offset: '',
      order: '',
      page: ''
    };

    return getCities(params);

  };

  const changeCities = (val: React.SetStateAction<SelectOptionValue[]>) => {
    setDataSelectedProvinces(val);

    setForm(prevForm => ({
      ...prevForm,
      city: Object.values(val)[1],
    }));
  };

  const renderSelectSearch = (
    label: string,
    placeholder: string,
    key: string
  ) => {
    const disabledInput = actionModalForm === 'detail';
    return (
      <>
        <Text
          lineHeight={ 17 }
          weight={ 500 }
          text={ label }
          color={ Colors.grey.isGrey }
          mb={ 10 }
        />
        <SelectSearchBar
          value={ dataSelectedProvinces }
          defaultOptions={ defaultOptionsProvinces }
          fetchOptions={ fetchSearchProvinces }
          onChange={ newValue => {
            changeCities(newValue);
          } }
          backgroundColor={ Colors.white.default }
          width={ '100%' }
          open={ visibleFilter[key] }
          onDropdownVisibleChange={ (visible: boolean) => onVisibleChange(visible, key) }
          totalItems={ 2 }
          wrapperStyle={ {
            marginBottom: 0,
            width: '100% !important',
          } }
          disabled={ disabledInput }
        />
      </>
    );
  };

  const handleSearchUser = useCallback((value: string) => {
    setSearchUser(value);
  }, []);

  const handleInitUser = value => {
    const selectedUser = dataSearchUser.find(user => user.value === value);
    const textOnBoxInput = selectedUser ? selectedUser.text : '';

    setSearchUser(textOnBoxInput);
  };

  const handleChangeSearchUser = value => {
    handleInitUser(value);

    setForm(prevForm => ({
      ...prevForm,
      // user_id: + value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      // user_id: ''
    }));
  };

  const onDropdownUserVisibleChange = (open: boolean) => {
    // handleInitUser(form.user_id);
    setOpenDropdownUser(open);
  };

  const renderSearchUser = (
    label: string,
    placeholder: string,
    key: string
  ) => {
    const loadingGetUserBySearch = misc.isLazyLoading(lazyLoad, 'allUser');

    return (
      <SearchInput
        label={ label }
        labelColor={ Colors.grey.isGrey }
        // value={ form.user_id && form.user_id > 0 ? form.user_id : '' }
        value={ '' }
        placeholder={ placeholder }
        data={ dataSearchUser }
        loading={ loadingGetUserBySearch }
        searchValue={ searchUser }
        onSearch={ handleSearchUser }
        onChange={ handleChangeSearchUser }
        backgroundColor={ Colors.white.default }
        disabled={ actionModalForm === 'detail' }
        errorMessage={ errorForm[key] }
        onDropdownVisibleChange={ onDropdownUserVisibleChange }
      />
    );
  };

  const renderInput = (inputProps: any) => {
    const {
      label,
      placeholder,
      key,
      type,
      iconName,
      labelClassName,
      inputClassName,
      disabled
    } = inputProps;

    if (type === 'select') {
      return renderSelect(
        label,
        placeholder,
        key
      );
    } else if (type === 'search') {
      return renderSearchUser(
        label,
        placeholder,
        key
      );
    } else if (type === 'selectSearch') {
      return renderSelectSearch(
        label,
        placeholder,
        key
      );
    }

    const disabledInput = actionModalForm === 'detail' || disabled;
    const isAddressInputEnabled = key === 'address' && !disabledInput;

    return (
      <Input
        type={ type }
        label={ label }
        placeholder={ placeholder }
        labelColor={ Colors.grey.isGrey }
        value={ form[key] }
        errorMessage={ errorForm[key] }
        name={ key }
        onChange={ onChangeFormText }
        mb={ 20 }
        backgroundColor={ Colors.white.default }
        iconName={ iconName }
        disabled={ disabledInput }
        labelClassName={ labelClassName }
        renderSuffixInput={ isAddressInputEnabled ? () => (
          <Text
            weight={ 700 }
            color={ Colors.blue.isBlue }
            onClick={ () => setIsContentPinpoint(true) }
          >Ubah</Text>
        ) : undefined }
        onClick={ isAddressInputEnabled && !form.address
          ? () => setIsContentPinpoint(true)
          : undefined }
        inputClassName={ inputClassName }
      />
    );
  };

  const renderItemSearchAddress = (title: string, address?: string) => {
    return (
      <ItemDropdown>
        <div className='container-icon'>
          <Icon iconName='pinLocation' fill={ Colors.grey.isGrey } />
        </div>

        <div className='item-text-wrapper'>
          <Text size='m' weight={ 700 }>{ title }</Text>

          <div>
            <Text color={ Colors.grey.isGreyGreen } className='p1 description-text'>{ address || '' }</Text>
          </div>
        </div>
      </ItemDropdown>
    );
  };

  const renderContentModalForm = () => {
    if (isContentPinpoint) {
      return (
        <MapsLeaflet
          mapKey='Search-Maps-Modal'
          width='100%'
          height='220px'
          containerStyle={ {
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10
          } }
          onChangeLocation={ (result: ValueType) => setValueGeosearch(result) }
          renderItemSearchBox={ renderItemSearchAddress }
          center={ {
            lat: + form.latitude || MapConfig.defaultCenter.lat,
            lng: + form.longitude || MapConfig.defaultCenter.lng
          } }
          initValueGeoSearch={ {
            label: '',
            shortLabel: '',
            value: '',
            postcode: '',
            valueDetail: {
              lat: + form.latitude || MapConfig.defaultCenter.lat,
              lng: + form.longitude || MapConfig.defaultCenter.lng
            }
          } }
          searchBox
          draggable
        />
      );
    }

    // const filteredInputPropsData = form.station_type === 'Home Station'
    //   ? inputPropsData
    //   : inputPropsData.filter(input => input.key !== 'user_id');

    // Jika Linke User Id di implement, maka nyalakan komentar diatas dan ganti variable inputPropsData menjadi filteredInputPropsData

    return inputPropsData.map((input, index) => {

      return (
        <RenderInputs key={ index } data={ input }>
          { renderInput(input) }
        </RenderInputs>
      );
    });
  };

  const getCurrentPagination = (currentPagination: CurrentPagination<BatteryStation[]>) => {
    setFormPagination(prevPagination => ({
      ...prevPagination,
      page: currentPagination.currentPage,
      limit: currentPagination.elementsPerPage
    }));
  };

  const onClickRow = (record: BatteryStation) => {
    setGoToStationDetail({
      progress: 0,
      id: record?.id?.toString()
    });

    setTimeout(() => {
      setGoToStationDetail(prevState => ({
        ...prevState,
        progress: 100
      }));
    }, 1000);
  };

  const renderModalLoader = () => {
    return (
      <ModalLoader
        visible={ modalLoaderVisible }
        onCloseModal={ () => setModalLoaderVisible(false) }
        afterClose={ () => {
          if (actionModalForm) {
            setModalFormVisible(true);
          }
        } }
      />
    );
  };

  const renderTopLoadingBar = () => {
    return (
      <TopLoaderBar
        progress={ goToStationDetail.progress }
        onLoaderFinished={ () => navigation.push(`/dashboard/battery-stations/${ goToStationDetail.id }`) }
      />
    );
  };

  const onChangeTable = (sorterInfo: SorterInfo) => {
    setSortOrder(sorterInfo);
    setInitialPage();
  };

  const getDataCity = async(search: string): Promise<SelectOptionValue[]> => {

    const params: CitiesApiParams = {
      search: search,
      sort: 'asc',
      limit: 50,
      offset: '',
      order: '',
      page: ''
    };

    return getCities(params)?.then(cities => {
      const static_stat = {
        key: '',
        label: 'All City',
        value: ''
      };
      cities.unshift(static_stat);
      return cities;
    });

  };

  const dropdownsFilterFetch = roleHelper.isPermissible(adminPermissions, Permission.battery_station_list)
    ? [
      {
        key: 'city',
        value: activeFilter?.city || 'All City',
        defaultOptions: [
          {
            key: '',
            label: 'All City',
            value: 'All City'
          }
        ],
        fetchOptions: getDataCity,
        onChangeSearch: newValue => {
          setActiveFilter(prevActive => ({
            ...prevActive,
            city: `${ newValue?.value }`
          }));
          setInitialPage();
        }
      }
    ]
    : undefined;

  return (
    <Dashboard
      container={ {
        selectedMenu: 7,
        headerContent: {
          textHeader: 'Battery Stations',
          textBtn: 'Add Battery Stations',
          iconNameBtn: 'add',
          onClickBtn: () => onClickOpenModal('add'),
          permissionBtn: Permission.battery_station_create
        }
      } }
      filterMenu={ {
        dropdownsFilter,
        dropdownsFilterFetch,
        activeFilter,
        onClickMenuItem,
        search,
        placeholderSearch: 'Search station',
        onChangeSearch
      } }
      data={ {
        currentData: station?.data || [],
        loading: loadingBatteryStations,
        fieldName: 'station',
        getCurrentPagination
      } }
      table={ {
        columns,
        buttons: buttonsDataTable,
        permissionClickRow: Permission.battery_station_detail,
        onClickRow,
        onChange: onChangeTable
      } }
      modalForm={ {
        title: 'battery station',
        ...isContentPinpoint ? {
          customText: {
            title: 'Pinpoint Address',
            button: 'Save this Location'
          }
        } : {},
        visible: modalFormVisible,
        actionModal: actionModalForm,
        onCloseModal: onCloseModalForm,
        footer: {
          fieldName: 'BatteryStation',
          onClickEdit: () => onClickOpenModal('edit'),
          onClickButtonSubmit: onClickButtonModalForm,
        },
        contentModal: renderContentModalForm(),
        permissions: { edit: Permission.battery_station_update },
        backButton: isContentPinpoint,
        onClickBackButton: () => setIsContentPinpoint(false)
      } }
      topLoadingBar={ renderTopLoadingBar() }
      modalLoader={ renderModalLoader() }
    />
  );
};

export default BatteryStations;
