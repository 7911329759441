import Styled from 'styled-components';

export const BoxLocationStyle: any = Styled.div `
  .box-location {
    min-height: 350px;
  }

  .text-address-container {
    width: 87%;
  }

  .empty-wrap {
    height: 275px;
    margin-top: 20px;
  }
  .maps-style{
    width: 487px;
		height: 286px;
		border-radius: 50px;
		cursor: pointer;
		z-index: 10;
  }
`;