import Styled from 'styled-components';

import { screen } from 'helpers';

export const BoxScooterInfoStyle: any = Styled.div`
  .img-scooter-skeleton > .ant-skeleton-avatar {
    border-radius: 5px !important;
    height: 160px !important;
    width: 160px !important;
  }

  @media ${ screen.sizesMax.xs } {
    .img-scooter {
      width: 106px;
      height: 100px;
    }

    .img-scooter-skeleton > .ant-skeleton-avatar {
      height: 106px !important;
      width: 106px !important;
    }
  }
`;

type DropdownStatusStyleProps = {
  visible: boolean;
};

export const DropdownStatusStyle: any = Styled.div<DropdownStatusStyleProps>`
  .dropdown-cell {
    display: flex;
    column-gap: 11px;
    align-items: center;
    cursor: pointer;
  }

  .arrow-dropdown-cell {
    ${ props => props.visible ? 'transform: rotateX(180deg);' : '' }
  }
`;