import Styled from 'styled-components';

import { screen } from 'helpers';

type IconStyleProps = {
  size?: number;
  fill?: string;
  hoverFill?: string;
  hoverOpacity?: number | false;
  container?: 'circle' | 'square' | false;
  containerSize?: number;
  containerColor?: string;
  containerHoverColor?: string;
  containerRadius?: number;
  cursor?: 'pointer' | 'default';
};

const handleContainer = props => {
  if (props.container === 'circle' || props.container === 'square') {
    return `
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${props.containerSize || props.size * 2}px;
      height: ${props.containerSize || props.size * 2}px;
      background-color: ${props.containerColor};
      border-radius: ${props.container === 'circle' ? '100%' : `${props.containerRadius}px`};
      border: 1px solid ${props.containerColor};

      &:hover {
        background-color: ${props.containerHoverColor};
        opacity: ${props.hoverOpacity};

        .icon-style {
          ${handleHoverIcon(
            props.hoverFill,
            props.fill,
            props.hoverOpacity
          )};
        }
      }
    `;
  }

  return `
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
  `;
};

const handleHoverIcon = (
  hoverFill,
  fill,
  hoverOpacity
) => {
  return `
    fill: ${hoverFill ? hoverFill : fill};
    opacity: ${hoverOpacity ? hoverOpacity : 0.8};
  `;
};

export const IconStyle: any = Styled.div < IconStyleProps > `
  cursor: ${props => (props.cursor || 'pointer')};
  z-index: 100;

  .icon-container {
    ${props => handleContainer(props)};
  }

  .icon-style {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    fill: ${props => props.fill};

    &:hover {
      ${props => handleHoverIcon(
        props.hoverFill,
        props.fill,
        props.hoverOpacity
      )};
    }
  }

  @media ${screen.sizesMax.xs} {
    .icon-style {
      ${props => props.size ? `width: ${props.size - 2}px;` : ''}
      ${props => props.size ? `height: ${props.size - 2}px;` : ''}
    }

    .icon-container {
      ${props => props.container && props.containerSize ? `width: ${props.containerSize - 4}px;` : ''}
      ${props => props.container && props.containerSize ? `height: ${props.containerSize - 4}px;` : ''}
    }
  }
`;

export const SwalWarningStyle: any = Styled.div`
  .sa {
    width: 140px;
    height: 140px;
    padding: 26px;
    background-color: #fff;
    &-warning {
      border-radius: 50%;
      border: 4px solid #F8BB86;
      box-sizing: content-box;
      height: 80px;
      padding: 0;
      position: relative;
      background-color: #fff;
      width: 80px;
      animation: scaleWarning 0.75s infinite alternate;
      &:after, &:before {
        background: #fff;
        content: '';
        border-radius: 50%;
        height: 100%;
        position: absolute;
        width: 100%;
      }
      &:before {
        display: inline-block;
        opacity: 0;
        animation: pulseWarning 2s linear infinite;
      }
      &:after {
        display: block;
        z-index: 1;
      }
      &-body {
        background-color: #F8BB86;
        border-radius: 2px;
        height: 47px;
        left: 50%;
        margin-left: -2px;
        position: absolute;
        top: 10px;
        width: 5px;
        z-index: 2;
        animation: pulseWarningIns 0.75s infinite alternate;
      }
      &-dot {
        background-color: #F8BB86;
        border-radius: 50%;
        bottom: 10px;
        height: 7px;
        left: 50%;
        margin-left: -3px;
        position: absolute;
        width: 7px;
        z-index: 2;
        animation: pulseWarningIns 0.75s infinite alternate;
      }
    }
  }
  @keyframes scaleWarning {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes pulseWarning {
    0% {
      background-color: #fff;
      transform: scale(1);
      opacity: 0.5;
    }
    30% {
      background-color: #fff;
      transform: scale(1);
      opacity: 0.5;
    }
    100% {
      background-color: #F8BB86;
      transform: scale(2);
      opacity: 0;
    }
  }
  @keyframes pulseWarningIns {
    0% {
      background-color: #F8D486;
    }
    100% {
      background-color: #F8BB86;
    }
  }
`;

export const SwalErrorStyle: any = Styled.div`
  .sa {
    width: 140px;
    height: 140px;
    padding: 26px;
    background-color: #fff;
    &-error {
      border-radius: 50%;
      border: 4px solid #F27474;
      box-sizing: content-box;
      height: 80px;
      padding: 0;
      position: relative;
      background-color: #fff;
      width: 80px;
      animation: animateErrorIcon .5s;
      &:after, &:before {
        background: #fff;
        content: '';
        height: 120px;
        position: absolute;
        transform: rotate(45deg);
        width: 60px;
      }
      &:before {
        border-radius: 40px 0 0 40px;
        width: 26px;
        height: 80px;
        top: -17px;
        left: 5px;
        transform-origin: 60px 60px;
        transform: rotate(-45deg);
      }
      &:after {
        border-radius: 0 120px 120px 0;
        left: 30px;
        top: -11px;
        transform-origin: 0 60px;
        transform: rotate(-45deg);
        animation: rotatePlaceholder 4.25s ease-in;
      }
      &-x {
        display: block;
        position: relative;
        z-index: 2;
      }
      &-placeholder {
        border-radius: 50%;
        border: 4px solid rgba(200, 0, 0, .2);
        box-sizing: content-box;
        height: 80px;
        left: -4px;
        position: absolute;
        top: -4px;
        width: 80px;
        z-index: 2;
      }
      &-fix {
        background-color: #fff;
        height: 90px;
        left: 28px;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 5px;
        z-index: 1;
      }
      &-left, &-right {
        border-radius: 2px;
        display: block;
        height: 5px;
        position: absolute;
        z-index: 2;
        background-color: #F27474;
        top: 37px;
        width: 47px;
      }
      &-left {
        left: 17px;
        transform: rotate(45deg);
        animation: animateXLeft .75s;
      }
      &-right {
        right: 16px;
        transform: rotate(-45deg);
        animation: animateXRight .75s;
      }
    }
  }
  @keyframes rotatePlaceholder {
    0%, 5% {
      transform: rotate(-45deg);
    }
    100%,12% {
      transform: rotate(-405deg);
    }
  }
  @keyframes animateErrorIcon {
    0% {
      transform: rotateX(100deg);
      opacity: 0;
    }
    100% {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }
  @keyframes animateXLeft {
    0%, 65% {
      left: 82px;
      top: 95px;
      width: 0;
    }
    84% {
      left: 14px;
      top: 33px;
      width: 47px;
    }
    100% {
      left: 17px;
      top: 37px;
      width: 47px;
    }
  }
  @keyframes animateXRight {
    0%, 65% {
      right: 82px;
      top: 95px;
      width: 0;
    }
    84% {
      right: 14px;
      top: 33px;
      width: 47px;
    }
    100% {
      right: 16px;
      top: 37px;
      width: 47px;
    }
  }
`;

export const SwalSuccessStyle: any = Styled.div`
  .sa {
    width: 140px;
    height: 140px;
    padding: 26px;
    background-color: #fff;
    &-success {
      border-radius: 50%;
      border: 4px solid #A5DC86;
      box-sizing: content-box;
      height: 80px;
      padding: 0;
      position: relative;
      background-color: #fff;
      width: 80px;
      &:after, &:before {
        background: #fff;
        content: '';
        height: 120px;
        position: absolute;
        transform: rotate(45deg);
        width: 60px;
      }
      &:before {
        border-radius: 40px 0 0 40px;
        width: 26px;
        height: 80px;
        top: -17px;
        left: 5px;
        transform-origin: 60px 60px;
        transform: rotate(-45deg);
      }
      &:after {
        border-radius: 0 120px 120px 0;
        left: 30px;
        top: -11px;
        transform-origin: 0 60px;
        transform: rotate(-45deg);
        animation: rotatePlaceholder 4.25s ease-in;
      }
      &-placeholder {
        border-radius: 50%;
        border: 4px solid rgba(165, 220, 134, 0.25);
        box-sizing: content-box;
        height: 80px;
        left: -4px;
        position: absolute;
        top: -4px;
        width: 80px;
        z-index: 2;
      }
      &-fix {
        background-color: #fff;
        height: 90px;
        left: 28px;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 5px;
        z-index: 1;
      }
      &-tip, &-long {
        background-color: #A5DC86;
        border-radius: 2px;
        height: 5px;
        position: absolute;
        z-index: 2;
      }
      &-tip {
        left: 14px;
        top: 46px;
        transform: rotate(45deg);
        width: 25px;
        animation: animateSuccessTip .75s;
      }
      &-long {
        right: 8px;
        top: 38px;
        transform: rotate(-45deg);
        width: 47px;
        animation: animateSuccessLong .75s;
      }
    }
  }
  @keyframes animateSuccessTip {
    0%, 54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }
  @keyframes animateSuccessLong {
    0%, 65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
  @keyframes rotatePlaceholder {
    0%, 5% {
      transform: rotate(-45deg);
    }
    100%, 12% {
      transform: rotate(-405deg);
    }
  }
`;