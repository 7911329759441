import React, {
  useState,
  useCallback,
  useEffect
} from 'react';

import {
  Box,
  Text,
  Icon,
  Modal,
  EmptyState,
  Skeleton,
  Loader,
  Pagination
} from 'components';

import { Colors, Images } from 'consts';
import {
  hooks,
  misc,
  moment
} from 'helpers';
import { DataStationsVisited } from 'interfaces/scooter';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';
import { SortOrder } from 'typings';
import { CurrentPagination } from 'interfaces/common';

import { renderTitleSubContent } from '../../index';

type BoxStationsProps = {
  widthBox: number | string;
  routeId?: string;
};

const BoxStations: React.FC<BoxStationsProps> = ({ widthBox, routeId }) => {
  const dispatch = hooks.useAppDispatch();

  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const getStationVisited = dispatch(actions.getStationVisited);
  const stationVisited = hooks.useAppSelector(selectors.scooter.stationVisited);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const loadingScooter = misc.isLazyLoading(lazyLoad, 'StationVisited');

  useEffect(() => {
    const params = {
      limit: 10,
      offset: 0,
      page: currentPage,
      search: '',
      sort: 'desc' as SortOrder,
      order: 'swap_batteries.created_date',
    };

    getStationVisited(String(routeId), params);
  }, [currentPage]);

  const getCurrentPaginationStation = (currentPagination: CurrentPagination<DataStationsVisited[]>) => {
    if (currentPagination.currentPage <= currentPagination.pagesCount) {
      setCurrentPage(currentPagination?.currentPage);
    }
  };

  const renderPaginationStation = () => {
    if (loadingScooter) {
      return <Loader className='col center-content' style={ { marginTop: 25 } } />;
    }

    if (currentPage < stationVisited?.pagination?.page_total) {
      return (
        <div className='mt5'>
          <Pagination
            type='lazyLoad'
            fieldName='batteryVinLog'
            data={ stationVisited?.data }
            getCurrentPagination={ getCurrentPaginationStation }
          />
        </div>
      );
    }
  };

  const renderEmptyData = () => {
    return (
      <div className='mt4'>
        <EmptyState
          background={ { height: 285 } }
          image={ {
            src: Images.emptyState.batteryStations,
            width: 56,
            height: 56
          } }
          text='No Stations Visited'
        />
      </div>
    );
  };

  const renderStationsVisited = (useAllData: boolean) => {
    const sliceData: DataStationsVisited[] = stationVisited?.data?.length <= 5 || useAllData
      ? stationVisited?.data
      : stationVisited?.data?.slice(0, 5);

    if (loadingScooter && !useAllData) {
      return <Loader className='col center-content' style={ { marginTop: 25 } } />;
    }

    if (!sliceData?.length && !loadingScooter) {
      return renderEmptyData();
    }

    return sliceData?.map((data, index) => {
      return (
        <Skeleton
          key={ index }
          loading={ loadingScooter }
          paragraph={ { rows: 2 } }
          avatar={ {
            size: 36,
            shape: 'circle'
          } }
          title={ false }
          mt={ index === 0 ? 40 : 20 }
        >
          <div key={ index } className={ `flex row align-center ${ index === 0 ? useAllData ? 'mt2' : 'mt6' : 'mt4' }` }>
            <Icon
              size={ 17 }
              iconName='batteryStations'
              fill={ Colors.black.isBlack }
              container='circle'
              containerColor={ Colors.grey.isLighterGrey }
              containerSize={ 36 }
            />
            <div className='ml3'>
              <Text mb={ 5 }>{ data?.station_name }</Text>
              <Text size='xs' color={ Colors.grey.isGrey }>
                { moment.utcToLocal(data?.created_date, 'llll') }
              </Text>
            </div>
          </div>
        </Skeleton>
      );
    });

  };

  const onCloseModal = useCallback(() => setModalVisible(false), []);

  const renderModalSeeAll = () => {
    return (
      <Modal
        visible={ modalVisible }
        onCloseModal={ onCloseModal }
        modalType='fixed-scroll'
        title={ () => renderTitleSubContent('Stations Visited') }
      >
        { renderStationsVisited(true) }
        { renderPaginationStation() }
      </Modal>
    );
  };

  const renderContent = () => {
    // TODO : ubah kondisi empty state nya
    const subtitle = stationVisited?.data?.length ? 'See All' : '';

    return (
      <>
        { renderTitleSubContent(
          'Stations Visited',
          subtitle,
          () => setModalVisible(true)
        ) }

        { renderStationsVisited(false) }
      </>
    );
  };

  return (
    <>
      <Box
        mt={ 20 }
        padding={ 20 }
        width={ widthBox }
      >
        { renderContent() }
      </Box>
      { renderModalSeeAll() }
    </>
  );
};

export default BoxStations;
