import { Dispatch } from 'redux';

import { toastify } from 'helpers';
import {
  ApiData,
  CommonResponseData,
  Pagination
} from 'interfaces/common';
import {
  FormShowroom,
  Showroom,
  ShowroomApiParams
} from 'interfaces/showroom';
import * as showroomServices from 'services/showroom';
import { AppActions, RootState } from 'store';

import { GET_SHOWROOMS, GET_SHOWROOM_DETAIL } from './actionTypes';
import { GET_USER_SHOWROOMS } from '../Users/actionTypes';
import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';
import { setPaginationData } from '../Pagination/action';

export const getAllShowroom = (dispatch: Dispatch<AppActions>, getState: () => RootState) => (params: ShowroomApiParams, callback?: (showrooms: {
  data: Showroom[],
  pagination: Pagination
}) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('allShowroom'));

      const showroomId = await getState().auth.user.showroom_id;
      const apiParams: ShowroomApiParams = await {
        ...params,
        id: showroomId > 0 ? `${ showroomId }` : ''
      };

      const {
        code,
        data,
        pagination
      }: ApiData<Showroom[]> = await showroomServices.getAllShowroom(apiParams);

      if (code === 200) {
        await dispatch({
          type: GET_SHOWROOMS,
          data: {
            data,
            pagination
          }
        });

        await dispatch(setPaginationData('showrooms', pagination));

        callback
          ? callback({
            data,
            pagination
          }) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('allShowroom'));
  });
};

export const addShowroom = (dispatch: Dispatch<AppActions>) => (formAddShowroom: FormShowroom, callback?: (data: any) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      dispatch(lazyLoadStart('addShowroom'));

      const { code, data }: ApiData<CommonResponseData> = await showroomServices.addShowroom(formAddShowroom);

      if (code === 200) {
        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('addShowroom'));
  });
};

export const editShowroom = (dispatch: Dispatch<AppActions>) => (
  idShowroom: string,
  formEditShowroom: FormShowroom,
  callback?: (data: any) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('editShowroom'));

      const { code, data }: ApiData<CommonResponseData> = await showroomServices.editShowroom(idShowroom, formEditShowroom);

      if (code === 200) {
        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('editShowroom'));
  });
};

export const deleteShowroom = (dispatch: Dispatch<AppActions>) => (idShowroom: string, callback?: (message: string) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('deleteShowroom'));

      const { code, data }: ApiData<CommonResponseData> = await showroomServices.deleteShowroom(idShowroom);

      if (code === 200) {
        callback ? callback(data.message) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('deleteShowroom'));
  });
};

export const getShowroomDetail = (dispatch: Dispatch<AppActions>) => (
  idShowroom: string,
  type?: 'showroomDetail' | 'userShowroom',
  callback?: (showroom: Showroom) => void,
  callbackErr?: (errMessage: string) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    const getShowroomType = type || 'showroomDetail';

    try {
      await dispatch(lazyLoadStart(getShowroomType));

      const {
        code,
        data,
        stat_msg
      }: ApiData<Showroom> = await showroomServices.getShowroomDetail(idShowroom);

      if (data && code === 200) {
        await dispatch({
          type: getShowroomType === 'showroomDetail' ? GET_SHOWROOM_DETAIL : GET_USER_SHOWROOMS,
          data
        });

        if (callback) callback(data);

        resolve();
      } else {
        if (callbackErr) callbackErr(stat_msg);
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    } finally {
      dispatch(lazyLoadEnd(getShowroomType));
    }
  });
};
