import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { convertToFormData } from 'helpers/api';
import {
	ApiData,
	FormUploadImage,
	UploadImageResponse
} from 'interfaces/common';

export const uploadImage = async(formUpload: FormUploadImage) => {
	try {
		const body = await convertToFormData(formUpload);

		const response: ApiData<UploadImageResponse> = await customFetch(Endpoints.uploadImage(), body);

		return response;
	} catch (error) {
		throw error;
	}
};

export const uploadMultipleImages = async(fileList: File[]) => {
	return Promise.all(fileList.map(async(file: File) => {
		const { data }: ApiData<UploadImageResponse> = await uploadImage({ uploadfile: file });

		if (typeof data === 'object' && data?.file_url && data?.file_path) {
			return data;
		}

		return file;
	}));
};