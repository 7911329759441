import axios, { AxiosResponse } from 'axios';
import { JsonProvider } from 'leaflet-geosearch';

import { Endpoints } from 'consts';
import { toastify } from 'helpers';
import { MapsLocation } from 'typings';

export interface ResultReverse {
	x: number;
	y: number;
	label: string;
	shortLabel: string;
	city?: string;
	village?: string;
	postcode: string;
}

const setCity = (address?: any) => {
	if (address?.city?.toLowerCase()?.includes('jakarta')) {
		// khusus untuk jakarta: prioritas: city_district
		return address?.city_district || address?.city || '';
	}

	// lainnya prioritas city -> county
	return address?.city || address?.county || address?.city_district || '';
};

export const getReverseGeocoding = async(location: MapsLocation, callback?: (result: ResultReverse) => void) => {
	const lat = location.lat;
	const lng = location.lng;

	try {
		const response: AxiosResponse<any> = await axios.get(Endpoints.getOSMReverseGeocode().path, {
			params: {
				lat,
				lon: lng,
				format: 'json',
				addressdetails: '1',
				'accept-language': 'id'
			}
		});

		const result = response.data;
		const resultParse: ResultReverse = {
			x: + result?.lon || lng,
			y: + result?.lat || lat,
			label: result?.display_name || '',
			city: setCity(result?.address),
			village: result?.address?.village || result?.address?.road || result?.address?.city_block || '',
			shortLabel: result?.address?.village || result?.address?.city || result?.address?.state || result?.display_name,
			postcode: result?.address?.postcode,
		};

		if (callback) callback(resultParse);

		return resultParse;
	} catch (error) {
		toastify.error('Error request find an address');

		if (callback) {
			callback({
				x: lng,
				y: lat,
				label: '',
				shortLabel: '',
				postcode: ''
			});
		}
	}
};

class OSMCustomProvider extends JsonProvider {
	endpoint({ query }) {
		return this.getUrl(Endpoints.getOSMGeocode().path, {
			q: query,
			format: 'json',
			addressdetails: '1',
			'accept-language': 'id'
		});
	}

	parse({ data }) {
		// Note that `data` is the raw result returned by the server.
		if (data) {
			return data?.map(result => ({
				x: + result.lon,
				y: + result.lat,
				label: result.display_name,
				shortLabel: result?.address?.village || result?.address?.city || result?.address?.state || result?.display_name
			}));
		}

		return [];
	}
}

export default OSMCustomProvider;
