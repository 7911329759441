import React from 'react';

import { ScrollContainerStyleProps, ScrollContainerStyle } from './style';

type ScrollContainerProps = ScrollContainerStyleProps & {
	children: React.ReactNode;
};

const ScrollContainer: React.FC<ScrollContainerProps> = ({
	children,
	...props
}) => {
	return (
		<ScrollContainerStyle { ...props }>
			{ children }
		</ScrollContainerStyle>
	);
};

export default ScrollContainer;
