import Styled from 'styled-components';

import { Colors } from 'consts';

import { handleBackgroundColor, handleBorder } from '../style';

type SearchInputStyleProps = {
  focus: boolean;
  backgroundColor?: string;
  disabled?: boolean;
};

export const SearchInputStyle: any = Styled.div < SearchInputStyleProps > `
  margin-bottom: 20px;

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select:hover .ant-select-selector,
  .ant-select-open .ant-select-selector {
    border-color: ${props => handleBorder(props.focus)} !important;
    box-shadow: none !important;
    background-color: ${props => handleBackgroundColor(
      props.focus,
      props.backgroundColor,
      props.disabled
    )} !important;
  }

  .search-input > .ant-select-selector {
    border-radius: 10px;
    height: 44px;
    background-color: ${props => handleBackgroundColor(
      props.focus,
      props.backgroundColor,
      props.disabled
    )} !important;
    justify-content: center;
    border-color: ${props => handleBorder(props.focus)} !important;
    cursor: ${props => props.disabled ? 'default' : 'text'} !important;
  }

  .ant-select-selection-item {
    font-family: InterMedium;
    font-size: 14px;
    color: ${Colors.black.isBlack};
    line-height: 17px;
    margin-top: 7px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    font-family: InterMedium;
    font-size: 14px;
    color: ${Colors.black.isBlack};
    height: 44px !important;
    cursor: ${props => props.disabled ? 'default' : 'text'} !important;
  }
`;

export const LoaderStyle: any = Styled.div `

`;
