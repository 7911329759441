import { SubscriptionType } from 'interfaces/subscription';
import { language } from 'language';

const subsLang = language.subscriptions;

export const convertTypeFilterValue = (option: string) => {
	if (option === subsLang.type.timeBase) return SubscriptionType.time_base as string;
	else if (option === subsLang.type.swapBase) return SubscriptionType.swap_base as string;

	return '';
};

export const convertSubscriptionAvailability = (is_active: string | boolean) => {
	switch (is_active) {
		case subsLang.status.active:
			return true;
		case subsLang.status.inactive:
			return false;
		case subsLang.status.allStatus:
			return '';
		case true:
			return subsLang.status.active;
		case false:
			return subsLang.status.inactive;
		default:
			return is_active;
	}
};

export const convertSubscriptionType = (subsType: string) => {
	const timeBasedEnum = SubscriptionType.time_base as string;
	const swapBasedEnum = SubscriptionType.swap_base as string;

	switch (subsType) {
		case timeBasedEnum:
			return subsLang.type.timeBase;
		case swapBasedEnum:
			return subsLang.type.swapBase;
		case subsLang.type.timeBase:
			return timeBasedEnum;
		case subsLang.type.swapBase:
			return swapBasedEnum;
		case subsLang.type.allType:
			return '';
		default:
			return subsType;
	}
};