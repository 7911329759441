import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { ColumnsType } from 'antd/es/table';

import {
  Input,
  Dashboard,
  TopLoaderBar,
	Text,
	SelectSearchBar,
	MapsLeaflet,
	Icon
} from 'components';
import {
  renderText,
  renderTag,
  renderButtonAction
} from 'components/DataTable';
import { whatsappAction } from 'components/Button/ButtonContact';
import {
 Colors,
 Endpoints,
 MapConfig
} from 'consts';
import {
  hooks,
  misc,
  navigation,
  toastify,
  validation,
  roleHelper
} from 'helpers';
import {
  FormPagination,
  CurrentPagination,
  SelectOptionValue,
  ReducerList,
  SorterInfo
} from 'interfaces/common';
import {
  User,
  FormUser,
  UserApiParams
} from 'interfaces/user';
import { Cities, CitiesApiParams } from 'interfaces/location';
import { Showroom, ShowroomApiParams } from 'interfaces/showroom';
import { Permission } from 'interfaces/role';
import {
 ActionModalForm,
 DetailNavProps,
 ValueType
} from 'typings';
import * as actions from 'store/actions';
import { selectors } from 'store/selectors';
import { language } from 'language';

import { inputPropsData, dropdownsFilter } from './data';
import { ItemDropdown } from './style';
import { changeSelectFilterValue } from './helpers';

type Filter = 'isActive' | 'showroom_id';

type ActiveFilter = {
  [key in Filter]: string;
};

type FilterCity = 'city';

type VisibleFilter = {
  [key in FilterCity]: boolean;
};

type IconPasswordProps = {
  iconName: string;
  iconPosition: 'left' | 'right';
} | Record<string, never>;

const { errorFormMessage } = language;
const { filterOptions, header } = language.users;

const Users: React.FC = () => {

	const [visibleFilter, setVisibleFilter] = useState<VisibleFilter>({ city: false });

  const dispatch = hooks.useAppDispatch();
  const getAllUser = dispatch(actions.getAllUser);
  const getUserDetail = dispatch(actions.getUserDetail);
  const addUser = dispatch(actions.addUser);
  const editUser = dispatch(actions.editUser);
  const getAllShowroom = dispatch(actions.getAllShowroom);
	const getAllCities = dispatch(actions.getAllCities);

  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const users = hooks.useAppSelector(selectors.user.users);
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);
  const adminShowroomId = hooks.useAppSelector(selectors.auth.adminShowroomId);

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<ActiveFilter>({
    isActive: filterOptions.isActive[0],
    showroom_id: '',
  });
  const [search, setSearch] = useState<string>('');
  const [formPagination, setFormPagination] = useState<FormPagination>({
    limit: 10,
    page: 1
  });
  const [actionModalForm, setActionModalForm] = useState<ActionModalForm>('add');
  const [modalFormVisible, setModalFormVisible] = useState<boolean>(false);
	const [dataSelectedProvinces, setDataSelectedProvinces] = useState<SelectOptionValue[]>([]);
	const [dataSearchCities, setDataSearchCities] = useState<SelectOptionValue[]>([]);
  const [isContentPinpoint, setIsContentPinpoint] = useState<boolean>(false);
  const [valueGeosearch, setValueGeosearch] = useState<ValueType>({
    shortLabel: '',
    label: '',
    value: '',
    postcode: '',
    valueDetail: MapConfig.defaultCenter
  });
	const [editedId, setEditedId] = useState<number>(-1);
  const [form, setForm] = useState<FormUser>({
    name: '',
    username: '',
    email: '',
    phone_number: '',
    password: '',
    re_password: '',
    dob: '',
    address: '',
    x_player: 'xplayer1',
    provider: 'local',
		zip_code: '',
		city: '',
  });
  const [errorForm, setErrorForm] = useState<FormUser>({
    name: '',
    username: '',
    email: '',
    phone_number: '',
    password: '',
    re_password: '',
    dob: '',
    address: '',
		zip_code: '',
		city: '',
  });
	const defaultOptionsProvinces = [
		{
			label: 'Choose City',
			value: ''
		},
	];
  const [sortOrder, setSortOrder] = useState<SorterInfo>({
    sort: null,
    order: ''
  });
  const [goToUserDetail, setGoToUserDetail] = useState<DetailNavProps>({
    progress: -1,
    id: ''
  });

  const debouncedSearch = hooks.useDebounce(search, 1200);
  const loadingUsers = misc.isLazyLoading(lazyLoad, 'allUser');

  const buttonsDataTable = [
    {
      iconName: 'phone',
      color: 'blue',
      permission: true,
      onClick: (record: User) => whatsappAction(record?.phone_number)
    },
    {
      iconName: 'email',
      color: 'blue',
      permission: true,
      onClick: (record: User) => emailAction(record?.email)
    },
    {
      iconName: 'search',
      color: 'blue',
      onClick: (record: User) => onClickRow(record),
      permission: Permission.user_profile,
      // onClick: (record: User) => onClickOpenModal('detail', record) // note: jika fitur modal detail + edit diadakan seperti di halaman showroom, uncomment line ini, dan comment line onClick diatas
    }
  ];

  const columns: ColumnsType<User> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: text => renderText(text)
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: text => renderText(text),
    },
    {
      title: 'Emergency Contact',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: text => renderText(text),
    },
    {
      title: 'Verification',
      dataIndex: 'is_completed',
      key: 'is_completed',
      width: 160,
      render: text => renderTag(text.toString())
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 180,
      render: (_: any, record: User) => renderButtonAction(buttonsDataTable, record)
    },
  ];

	const renderItemSearchAddress = (title: string, address?: string) => {
		return (
			<ItemDropdown>
				<div className='container-icon'>
					<Icon iconName='pinLocation' fill={ Colors.grey.isGrey } />
				</div>
	
				<div className='item-text-wrapper'>
					<Text size='m' weight={ 700 }>{ title }</Text>
	
					<div>
						<Text color={ Colors.grey.isGreyGreen } className='p1 description-text'>{ address || '' }</Text>
					</div>
				</div>
			</ItemDropdown>
		);
	};

  const getDataUsers = () => {
    const params: UserApiParams = {
      ...formPagination,
      search: debouncedSearch,
      is_active: changeSelectFilterValue(activeFilter.isActive),
      sort: (sortOrder.sort || 'asc'),
      order: (sortOrder.order || 'name')
    };
    const showroomId: string = activeFilter.showroom_id && activeFilter.showroom_id !== 'All Showroom'
      ? activeFilter.showroom_id
      : '';

    getAllUser(params, showroomId);
  };

  const getDataShowroom = async(search: string): Promise<SelectOptionValue[]> => {
    const formPaginationShowroom: FormPagination = {
      limit: 7,
      page: 1
    };
    const params: ShowroomApiParams = {
      ...formPaginationShowroom,
      search,
      sort: 'asc'
    };

    let dataShowroom: SelectOptionValue[] = [];

    await getAllShowroom(params, (updatedShowrooms: ReducerList<Showroom[]>) => {
      if (updatedShowrooms?.data) {
        dataShowroom = [
          {
            key: '',
            label: 'All Showroom',
            value: 'All Showroom'
          }, ...updatedShowrooms?.data?.map(showroom => ({
            key: `${ showroom?.id }`,
            value: `${ showroom?.id }`,
            label: showroom?.name
          }))
        ];
      }
    });

    return dataShowroom;
  };

  const dropdownsFilterFetch = !adminShowroomId && roleHelper.isPermissible(adminPermissions, Permission.showroom_list)
    ? [
      {
        key: 'showroom_id',
        value: activeFilter?.showroom_id || 'All Showroom',
        defaultOptions: [
          {
            key: '',
            label: 'All Showroom',
            value: 'All Showroom'
          }
        ],
        fetchOptions: getDataShowroom,
        onChangeSearch: newValue => {
          setActiveFilter(prevActive => ({
            ...prevActive,
            showroom_id: `${ newValue.value }`
          }));
          setInitialPage();
        }
      }
    ]
    : undefined;

  useEffect(() => {
    getDataUsers();
  }, [
    formPagination.page,
    formPagination.limit,
    debouncedSearch,
    activeFilter.isActive,
    activeFilter.showroom_id,
    sortOrder.sort,
    sortOrder.order
  ]);

  // Handle refreshing, ex: after add/edit
  useEffect(() => {
    if (refreshing) {
      getDataUsers();

      setRefreshing(false);
    }
  }, [refreshing]);

	useEffect(() => {
		const params: CitiesApiParams = {
			search: search,
			sort: 'asc',
			limit: 50,
			offset: '',
			order: '',
			page: ''
		};

		getCities(params)?.then(cities => {
			setDataSearchCities(cities);
		});

	}, []);

	const getCities = async params => {
		let dataCities;

		await getAllCities(params,
			(cities: ReducerList<Cities[]>) => {
				if (cities?.data) {

					dataCities = cities?.data?.map(list => ({
              key: list?.city,
              value: list?.city,
              label: list?.province_name + '-' + list?.city
          }));
				} else {
					dataCities = [];
				}
		});
			
		return dataCities;
	};

  const setInitialPage = () => {
    setFormPagination(prevFormPagination => ({
      ...prevFormPagination,
      page: 1
    }));
  };

  const emailAction = (email: string) => {
    window.location.href = Endpoints.mailTo(email);
  };

  const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    setInitialPage();
  }, []);

  const onClickMenuItem = ({ key: keyItem }, keyFilter) => {
    setActiveFilter(prevState => ({
      ...prevState,
      [keyFilter]: keyItem
    }));

    setInitialPage();
  };

  const onClickRow = (record: User) => {
    setGoToUserDetail({
      progress: 0,
      id: record?.id.toString()
    });

    getUserDetail(record?.id.toString(), () => {
      setGoToUserDetail(prevState => ({
        ...prevState,
        progress: 100
      }));
    });
  };

  const onChangeTable = (sorterInfo: SorterInfo) => {
    setSortOrder(sorterInfo);

    setInitialPage();
  };

  const getCurrentPagination = (currentPagination: CurrentPagination<User[]>) => {
    setFormPagination(prevPagination => ({
      ...prevPagination,
      page: currentPagination.currentPage,
      limit: currentPagination.elementsPerPage
    }));
  };

  const onChangeFormText = useCallback((e: any) => {
    setForm(prevForm => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      [e.target.name]: ''
    }));
  }, []);

  const renderInput = (inputProps: any) => {
		const {
      label,
      placeholder,
      key,
      type,
      iconName,
      labelClassName,
      inputClassName
    } = inputProps;

		const disabledInput = actionModalForm === 'detail';
    const isAddressInputEnabled = key === 'address' && !disabledInput;

    const iconPasswordProps: IconPasswordProps = type === 'password'
      ? {
        iconName: 'password',
        iconPosition: 'right'
      }
      : type === 'textArea' && iconName === 'pinLocation' ? {
			iconName: 'pinLocation',
			iconPosition: 'left'
			} : {};

			if (type === 'selectSearch') {
				return renderSelectSearch(
					label,
					placeholder,
					key
				);
			}

    return (
      <Input
        type={ type }
        label={ label }
        placeholder={ placeholder }
        labelColor={ Colors.grey.isGrey }
        value={ form[key] }
        errorMessage={ errorForm[key] }
        name={ key }
        onChange={ onChangeFormText }
        onChangeDate={ onChangeDate }
        mb={ 20 }
        backgroundColor={ Colors.white.default }
        labelClassName={ labelClassName }
        renderSuffixInput={ isAddressInputEnabled ? () => (
          <Text
            weight={ 700 }
            color={ Colors.blue.isBlue }
            onClick={ () => setIsContentPinpoint(true) }
          >Ubah</Text>
        ) : undefined }
        onClick={ isAddressInputEnabled && !form.address
          ? () => setIsContentPinpoint(true)
          : undefined }
        inputClassName={ inputClassName }
        disabled={ actionModalForm === 'detail' }
        { ...iconPasswordProps }
      />
    );
  };

  const onChangeDate = (dateString: string) => {
    setForm(prevForm => ({
      ...prevForm,
      dob: dateString
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      dob: ''
    }));
  };

  const onCloseModalForm = () => {
    setModalFormVisible(false);

    setEditedId(-1);

    setForm(prevForm => ({
      ...prevForm,
      name: '',
      username: '',
      email: '',
      phone_number: '',
      password: '',
      re_password: '',
      dob: '',
      address: '',
			zip_code: '',
			city: '',
    }));

    setErrorForm({
      name: '',
      username: '',
      email: '',
      phone_number: '',
      password: '',
      re_password: '',
      dob: '',
      address: '',
			zip_code: '',
			city: '',
    });
  };

  const handleFormValidation = () => {
    const validationUsername = validation.username(form.username);
    const validationEmail = validation.email(form.email);
    const validationPhone = validation.phoneNumber(misc.phoneNumberFormat(form.phone_number));
    const validationPassMatch = actionModalForm === 'add'
      ? validation.passwordMatch((form.password || ''), (form.re_password || ''))
      : true;
    const validationPassLength = actionModalForm === 'add'
      ? validation.passwordLength((form.password || ''))
      : true;
    const emptyForm = misc.getMultipleKeyByValue(form, '').filter(keyForm => {
      if (actionModalForm === 'edit') {
        return keyForm !== 'password' && keyForm !== 're_password';
      } else {
        return keyForm;
      }
    });

    if (emptyForm.length ||
      !validationEmail ||
      !validationPhone ||
      !validationPassMatch ||
      !validationPassLength ||
      !validationUsername
    ) {
      const errMsgPassword = !validationPassLength
        ? errorFormMessage.password
        : !validationPassMatch
          ? errorFormMessage.passwordMatch
          : '';

      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        username: !validationUsername ? errorFormMessage.username : '',
        email: !validationEmail ? errorFormMessage.email : '',
        phone_number: !validationPhone ? errorFormMessage.phone : '',
        password: errMsgPassword,
        re_password: errMsgPassword,
      }));

      emptyForm.forEach(key => {
        inputPropsData.forEach(input => {
          if (key === input.key) {
            setErrorForm(prevErrorForm => ({
              ...prevErrorForm,
              [key]: errorFormMessage.form(input.label.toLowerCase())
            }));
          }
        });
      });

      return false;
    }

    return true;
  };

  const onClickButtonModalForm = () => {
		if (isContentPinpoint) {
			
      setForm(prevForm => ({
        ...prevForm,
        address: valueGeosearch.value,
				zip_code: valueGeosearch.postcode,
      }));

      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        address: '',
				zip_code: '',
				city: '',
      }));

      setIsContentPinpoint(false);
    } else {
			if (handleFormValidation()) {
				const formPayload = {
					...form,
					phone_number: misc.phoneNumberFormat(form.phone_number),
					
				};

				if (actionModalForm === 'add') {
					addUser(formPayload, handleCbFormUser);
				} else if (actionModalForm === 'edit') {
					editUser(
						editedId.toString(),
						formPayload,
						handleCbFormUser
					);
				}
			}
		}
  };

  const handleCbFormUser = async(message: string) => {
    await onCloseModalForm();

    await toastify.success(message);

    setRefreshing(true);
  };

  const renderContentModalForm = () => {

		if (isContentPinpoint) {
      return (
        <MapsLeaflet
          mapKey='Search-Maps-Modal'
          width='100%'
          height='220px'
          containerStyle={ {
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10
          } }
          onChangeLocation={ (result: ValueType) => setValueGeosearch(result) }
          renderItemSearchBox={ renderItemSearchAddress }
          center={ {
            lat: + MapConfig.defaultCenter.lat,
            lng: + MapConfig.defaultCenter.lng
          } }
          initValueGeoSearch={ {
            label: '',
            shortLabel: '',
            value: '',
            postcode: '',
            valueDetail: {
              lat: + MapConfig.defaultCenter.lat,
              lng: + MapConfig.defaultCenter.lng
            }
          } }
          searchBox
          draggable
        />
      );
    }

    const filteredInputProps = editedId > -1
      ? inputPropsData?.filter(input => input.type !== 'password')
      : inputPropsData;

    return filteredInputProps?.map((input, index) => {
      return (
        <div key={ index }>
          { renderInput(input) }
        </div>
      );
    });
  };

	const findProvinceByCity = async(city: string) => {

		const params: CitiesApiParams = {
			search: city ? city : '',
			sort: 'asc',
			limit: 50,
			offset: '',
			order: '',
			page: ''
		};

		const newOptions = await getCities(params);
		const index = newOptions?.findIndex(item => item?.value === city);
		return newOptions[index]?.label;
	};

  const onClickOpenModal = (actionModal: ActionModalForm, record?: User) => {
    setActionModalForm(actionModal);

    if (record) {
      setEditedId(+ record?.id);

      setForm({
        name: record?.name,
        username: record?.username,
        email: record?.email,
        phone_number: record?.phone_number,
        dob: record?.dob,
        address: record?.address,
				zip_code: record?.zip_code,
				city: record?.city,
      });

			findProvinceByCity(record?.city).then(newOptions => {
				setDataSelectedProvinces([
					{
						value: record?.city,
						key: record?.city,
						label: newOptions
					}
				]);
			});

			onVisibleChange(false, 'city');
    }

    setModalFormVisible(true);
  };

	const onVisibleChange = (visible: boolean, key: string) => {
    setVisibleFilter(prevFilter => ({
      ...prevFilter,
      [key]: visible
    }));
  };

	const fetchSearchProvinces = async(search: string): Promise<SelectOptionValue[]> => {
		
		const params: CitiesApiParams = {
			search: search,
			sort: 'asc',
			limit: 50,
			offset: '',
			order: '',
			page: ''
		};

		return getCities(params);
		
  };

	const changeCities = (val: React.SetStateAction<SelectOptionValue[]>) => {
		setDataSelectedProvinces(val);

		setForm(prevForm => ({
			...prevForm,
			city: Object.values(val)[1],
		}));
	};

	const renderSelectSearch = (
		label: string,
		placeholder:string,
		key: string
	) => {
		const disabledInput = actionModalForm === 'detail';
    return (
			<>
				<Text
          lineHeight={ 17 }
          weight={ 500 }
          text={ label }
          color={ Colors.grey.isGrey }
          mb={ 10 }
        />
				<SelectSearchBar
					value={ dataSelectedProvinces }
					defaultOptions={ defaultOptionsProvinces }
					fetchOptions={ fetchSearchProvinces }
					placeholder={ placeholder }
					onChange={ newValue => {
						changeCities(newValue);
					} }
					backgroundColor={ Colors.white.default }
					width={ '100%' }
					open={ visibleFilter[key] }
					onDropdownVisibleChange={ (visible: boolean) => onVisibleChange(visible, key) }
					totalItems={ 2 }
					wrapperStyle={ {
						marginBottom: 0,
						width: '100% !important',
					} }
					disabled={ disabledInput }
				/>
			</>
    );
  };

  const renderTopLoadingBar = () => {
    return (
      <TopLoaderBar
        progress={ goToUserDetail.progress }
        onLoaderFinished={ () => navigation.push(`/dashboard/users/${ goToUserDetail.id }`) }
      />
    );
  };

  return (
    <Dashboard
      container={ {
        selectedMenu: 4,
        headerContent: {
          textHeader: header.title,
          textBtn: header.textBtn,
          iconNameBtn: 'add',
          onClickBtn: () => onClickOpenModal('add'),
          permissionBtn: Permission.user_create
        },
        openSubmenu: true
      } }
      filterMenu={ {
        activeFilter,
        dropdownsFilter,
        dropdownsFilterFetch,
        onClickMenuItem,
        search,
        placeholderSearch: 'Search by name',
        onChangeSearch
      } }
      data={ {
        currentData: users.data,
        loading: loadingUsers,
        fieldName: 'users',
        getCurrentPagination
      } }
      table={ {
        columns,
        buttons: buttonsDataTable,
        permissionClickRow: Permission.user_profile,
        onClickRow,
        onChange: onChangeTable
      } }
      modalForm={ {
        title: 'user',
				...isContentPinpoint ? {
          customText: {
            title: 'Pinpoint Address',
            button: 'Save this Location'
          }
        } : {},
        visible: modalFormVisible,
        actionModal: actionModalForm,
        onCloseModal: onCloseModalForm,
        footer: {
          fieldName: 'user',
          onClickEdit: () => onClickOpenModal('edit'),
          onClickButtonSubmit: onClickButtonModalForm,
        },
        contentModal: renderContentModalForm(),
        permissions: { edit: Permission.user_update },
				backButton: isContentPinpoint,
				onClickBackButton: () => setIsContentPinpoint(false)
      } }
      topLoadingBar={ renderTopLoadingBar() }
    />
  );
};

export default Users;
