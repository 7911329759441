import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

type TopicMenuStyleProps = {
  isSelected?: boolean;
  key?: number;
  title?: string;
  openSubmenu?: boolean;
};

export const TopicMenuStyle: any = Styled.div`
  min-height: ${screen.height}px;
  background: ${Colors.white.default};
`;

const handleColor = (
  isSelected,
  defaultColor,
  changeColor
) => {
  if (isSelected) {
    return changeColor;
  }

  return defaultColor;
};

export const MenuListStyle: any = Styled.div < TopicMenuStyleProps > `
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .menu-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 20px 20px 20px 0;
    background-color: ${props => handleColor(
      props.isSelected,
      Colors.white.default,
      props.title === 'SAVART' ? Colors.white.default : Colors.blue.isBlueOpacity('0.1')
    ) }
  }

  .ic-container {
    padding: 20px 12px 20px 15px;
    height: 60px;
    background-color: ${props => handleColor(
      props.isSelected,
      Colors.white.default,
      props.title === 'SAVART' ? Colors.white.default : Colors.blue.isBlueOpacity('0.1')
    ) }
  }

  .ic-menu-list {
    width: 20px;
    height: 20px;
    fill: ${props => handleColor(
      props.isSelected,
      Colors.grey.isGrey,
      props.title === 'SAVART' ? Colors.black.isBlack : Colors.blue.isBlue
    ) };
  }

  .ic-arrow {
    ${props => props.openSubmenu ? 'transform: rotateX(180deg);' : ''}
  }

  &:hover {
    .text-menu {
      color: ${props => props.title === 'SAVART' ? Colors.black.isBlack : Colors.blue.isBlue};
    }

    .ic-menu-list {
      fill: ${props => handleColor(
        true,
        Colors.grey.isGrey,
        props.title === 'SAVART' ? Colors.black.isBlack : Colors.blue.isBlue
      ) };
    }
  }
`;