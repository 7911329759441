import Styled from 'styled-components';

type ArrowContainerStyleProps = {
	isOpen?: boolean;
};

export const ModalStepStyles: any = Styled.div`
	.modal-title {
		display: flex;
		justify-content: space-between;
		align-items: start;
	}
	
	.step {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.step .name {
		margin-left: 12px;
	}

	.desc {
		margin-top: 20px;
	}
`;

export const ArrowContainerStyle: any = Styled.div<ArrowContainerStyleProps>`
	.ic-arrow {
		${ props => props.isOpen ? 'transform: rotateX(180deg) !important;' : '' }
	}
`;