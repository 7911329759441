import React, { useState } from 'react';

import { Container, Text } from 'components';
import { Colors } from 'consts';
import {
  hooks,
  misc,
  screen
} from 'helpers';

import {
  BoxAge,
  BoxBatteryCondition,
  BoxRetiredBattery,
  BoxSwapLoc
} from './components';
import { StatsStyle } from './style';

export const renderTitleBox = (text: string, mb = 0) => (
  <Text
    size='m'
    weight={ 700 }
    mb={ mb }
  >{ text }</Text>
);

export const renderSubtitleBox = (text: string) => (
  <Text
    color={ Colors.grey.isGrey }
    spacing={ -0.5 }
    lineHeight={ 19 }
  >{ text }</Text>
);

type Filter =
  'batteryAvg_location' |
  'batteryAvg_time' |
  'swapLoc' |
  'batteryCondition';

type VisibleFilter = {
  [key in Filter]: boolean;
};

type ActiveFilter = {
  [key in Filter]: string;
}

const Stats: React.FC = () => {
  const [visibleFilter, setVisibleFilter] = useState<VisibleFilter>({
    batteryAvg_location: false,
    batteryAvg_time: false,
    swapLoc: false,
    batteryCondition: false
  });
  const [activeFilter, setActiveFilter] = useState<ActiveFilter>({
    batteryAvg_location: 'DKI JAKARTA',
    batteryAvg_time: 'Daily',
    swapLoc: 'Daily',
    batteryCondition: 'All Location'
  });

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const onClickFilterMenu = ({ key }: { key: string }) => {
    const filterOpen = misc.getKeyByValue(visibleFilter, true);
    
    setVisibleFilter(prevFilter => ({
      ...prevFilter,
      [`${filterOpen}`]: false
    }));
    setActiveFilter(prevActive => ({
      ...prevActive,
      [`${filterOpen}`]: key
    }));
  };

  const onVisibleChange = (visible: boolean, key: string) => {
    setVisibleFilter(prevFilter => ({
      ...prevFilter,
      [key]: visible
    }));
  };

  const renderContent = () => {
    const { width: screenWidth } = windowDimensions;
    const filterMenuProps = {
      visibleFilter,
      activeFilter,
      onVisibleChange,
      onClickFilterMenu
    };

    let widthBoxLeft: number = ((screenWidth - 240) * 0.6) - 40;
    let widthBoxRight: number = (screenWidth - 240) * 0.4;

    if (screenWidth <= 1120 && screenWidth > screen.sizes.sx) {
      widthBoxLeft = screenWidth - 260;
      widthBoxRight = screenWidth - 260;
    } else if (screenWidth <= screen.sizes.sx) {
      widthBoxLeft = screenWidth - 60;
      widthBoxRight = screenWidth - 60;
    }

    return (
      <div className='container-content'>
        <div>
          { /* <BoxBatteryUsage
            widthBox={ widthBoxLeft }
            filterMenu={ filterMenuProps }
            screenWidth={ screenWidth }
          /> */ }
          <BoxSwapLoc widthBox={ widthBoxLeft } filterMenu={ filterMenuProps } />
          <BoxRetiredBattery widthBox={ widthBoxLeft } />
        </div>

        <div>
          <BoxAge widthBox={ widthBoxRight } />
          <BoxBatteryCondition widthBox={ widthBoxRight } filterMenu={ filterMenuProps } />
          { /* <BoxGender widthBox={ widthBoxRight } screenWidth={ screenWidth } /> */ }
        </div>
      </div>
    );
  };

  return (
    <Container
      selectedMenu={ 1 }
      headerContent={ { textHeader: 'Stats' } }
      withDrawer
    >
      <StatsStyle>
        { renderContent() }
      </StatsStyle>
    </Container>
  );
};

export default Stats;
