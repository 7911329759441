import Styled from 'styled-components';

import { Colors } from 'consts';

type AutocompleteStyleProps = {
	withPrefix?: boolean;
};

export const AutocompleteStyle: any = Styled.div<AutocompleteStyleProps>`
	.ant-select-selector,
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		width: 100%;
		${ props => props.withPrefix ? 'padding-left: 45px !important;' : '' }

		height: 45px !important;
		border: 1px solid ${ Colors.blue.isBlue } !important;
		border-radius: 8px !important;
    box-shadow: none !important;
		background-color: ${ Colors.white.default } !important;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
		height: 43px !important;
		${ props => props.withPrefix ? 'padding-left: 34px !important;' : '' }
	}
`;
