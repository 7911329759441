import Styled from 'styled-components';

export const BatteryStationStyle: any = Styled.div `
  .box-summary {
    min-height: 91px;
  }
  
  .box-summary-inner {
    min-height: 91px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .box-section-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media screen and (max-width: 1120px) {
    .box-section-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;