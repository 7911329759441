
export const setFilterApiParams = (activeFilter: string) => {
	switch (activeFilter) {
		case 'Monthly':

			const monthlyReport = new Date();
			const getMonth = new Date(monthlyReport.setMonth(monthlyReport.getMonth() - 5));
			const getMonthlyReport = new Date().getFullYear() + '-' + String(getMonth.getMonth()).padStart(2, '0') + '-' + '01';

			return getMonthlyReport;

		case 'Weekly':
			const weeklyReport = new Date();
			const day = weeklyReport.getDate() - 35;
			weeklyReport.setDate(day);
			
			const getWeeklyReport = weeklyReport.getFullYear() + '-' + String(weeklyReport.getMonth() + 1).padStart(2, '0') + '-' + String(weeklyReport.getDate()).padStart(2, '0');

			return getWeeklyReport;
			
		case 'Daily':
			const dailyReport = new Date();
			dailyReport.setDate(dailyReport.getDate() - 7);
			const getDailyReport = dailyReport.getFullYear() + '-' + String(dailyReport.getMonth()).padStart(2, '0') + '-' + String(dailyReport.getDate()).padStart(2, '0');
			
			return getDailyReport;
		default:
			activeFilter.toLowerCase();
	}
};

export const dateChartFormatter = (activeFilter: string) => {
	switch (activeFilter) {
		case 'Monthly':
			return {
				short: 'M/YYYY',
				long: 'MMM YYYY'
			};
		case 'Weekly':
			return {
				short: 'YYYY-MM-DD - YYYY-MM-DD',
				long: 'MMM YYYY'
			};
		case 'Daily':
			return {
				short: 'D/M/YYYY',
				long: 'YYYY/MM/DD'
			};
		default:
			return {
				short: 'D/M/YYYY',
				long: 'YYYY-MM-DD'
			};
	}
};

const monthName = (monthNumber: string | number) => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];
	
	return months[Number(monthNumber) - 1];
};
  
const getSumValueByRangeDate = (
	range: any[],
	arr: any[],
	val: string
) => {
	let sum = 0;
	
	range.forEach((element: any) => {
		const obj = arr?.find((o: { created_date: any; }) => o.created_date === element);
		if (obj) {
			sum += obj[val];
		}
	});
	return sum;
};

export const group_by_month = (array: any[]) => {
	
	const arr = getDaily(array).reverse();

	return Object.values(arr.reduce((a, { created_date: date_string, value: value }) => {
		const month = new Date(date_string).getMonth() + 1;
		const year = new Date(date_string).getFullYear();

		const label = monthName(month) + '/' + year;
		const key = `${year}/${month}`;
		if (a[key] === undefined) {
		  	a[key] = {
				date_x: label,
				value: 0,
				date: key
			};
		}
  
		a[key].value += value;
  
		return a;
	  }, {}),);
};

export const getWeeks = (array: any[]) => {
	
	const arr = getDaily(array).reverse();
	
	const startDate = arr[arr?.length - 1]?.created_date;
	const endDate = arr[0]?.created_date;
	
	const sd = new Date(startDate);
  	const weeks = [] as any[];
	let week = [] as any[];
	let allDate = 0;
	let noDate = 0;

	for (let d = sd; d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
		
		week.push(new Date(d).getFullYear() + '/' + String((new Date(d).getMonth() + 1)).padStart(2, '0') + '/' + String(new Date(d).getDate()).padStart(2, '0'));
		
		const existData = arr?.find(item => item.created_date === new Date(d).getFullYear() + '/' + String((new Date(d).getMonth() + 1)).padStart(2, '0') + '/' + String(new Date(d).getDate()).padStart(2, '0'));
		if (!existData) {
			noDate++;
		}
		if (new Date(d).getDay() === 0) {
			allDate += (week.length - noDate);
			weeks.push({
				date_x: week[0] + '-<br>' + week[week.length - 1],
				value: getSumValueByRangeDate(
					week,
					arr,
					'value'
				),
				created_date: week[0] + '-' + week[week.length - 1]
			});
			week = [];
		}

	}

	// Create point untuk sisa hari yang belum genap 1 minggu
	if (allDate < arr?.length) {
		week = [];

		// Looping untuk sisa tanggal
		for (let index = (arr?.length - allDate) - 1; index >= 0; index--) {
			const d = arr[index].created_date;
			
			week.push(new Date(d).getFullYear() + '/' + String((new Date(d).getMonth() + 1)).padStart(2, '0') + '/' + String(new Date(d).getDate()).padStart(2, '0'));
		}

		weeks.push({
			date_x: week[0] + '-<br>' + week[week.length - 1],
			value: getSumValueByRangeDate(
				week.reverse(),
				arr,
				'value'
				),
			created_date: week[0] + '-' + week[week.length - 1]
		});

		week = [];
	}

	return weeks;
};

export const getDaily = (array: any[]) => {
	const progress = array;
    
	const hash: Record<string, { value: number, created_date: string }> = Object.create(null);
	const grouped: { value: number; created_date: string; }[] = [];

	Object.values(progress).forEach(item => {
	const key: string = item?.created_date?.slice(0, 10);
	if (!hash[key]) {
		hash[key] = {
			value: 0,
			created_date: item?.created_date?.slice(0, 10).replace('-', '/')
       .replace('-', '/'),
		};
		grouped.push(hash[key]);
	}
	hash[key].value += 1;
	});

	return grouped;
};