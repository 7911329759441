import Styled from 'styled-components';

import { Colors } from 'consts';

type EmptyStateStyleProps = {
  backgroundColor?: string;
  width?: string | number;
  height?: string | number;
};

export const EmptyStateStyle: any = Styled.div < EmptyStateStyleProps > `
  display: flex;
	align-items: center;
	justify-content: center;
  flex-direction: column;
  flex: 1;

  border-radius: 10px;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : Colors.grey.isBgLightGrey };
  ${props => typeof props.width === 'number' ? `width: ${props.width}px;` : `width: ${props.width};` }
  ${props => typeof props.height === 'number' ? `height: ${props.height}px;` : `height: ${props.height};` }
`;