import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { ApiData, CommonResponseData } from 'interfaces/common';
import {
  FormShowroom,
  Showroom,
  ShowroomApiParams
} from 'interfaces/showroom';

export const getAllShowroom = async(params: ShowroomApiParams) => {
  try {
    const queryParams: ShowroomApiParams = {
      ...params,
      sort: (params.sort || 'asc'),
      order: (params.order || 'name')
    };

    const response: ApiData<Showroom[]> = await customFetch(Endpoints.getAllShowroom(), queryParams);

    return response;
  } catch (error) {
    throw error;
  }
};

export const addShowroom = async(formAddShowroom: FormShowroom) => {
  try {
    const response: ApiData<CommonResponseData> = await customFetch(Endpoints.addShowroom(), formAddShowroom);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editShowroom = async(idShowroom: string, formEditShowroom: FormShowroom) => {
  try {
    const response: ApiData<CommonResponseData> = await customFetch(Endpoints.editShowroom(idShowroom), formEditShowroom);

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteShowroom = async(idShowroom: string) => {
  try {
    const response: ApiData<CommonResponseData> = await customFetch(Endpoints.deleteShowroom(idShowroom));

    return response;
  } catch (error) {
    throw error;
  }
};

export const getShowroomDetail = async(idShowroom: string) => {
  try {
    const response: ApiData<Showroom> = await customFetch(Endpoints.getShowroomDetail(idShowroom));

    return response;
  } catch (error) {
    throw error;
  }
};
