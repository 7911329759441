import Styled from 'styled-components';

import { Themes } from 'consts';
import { screen, hooks } from 'helpers';

type LoginStyleProps = {
  windowDimensions: hooks.Dimensions;
};

export const LoginStyle: any = Styled.div < LoginStyleProps > `
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .half-container {
    width: 50vw;
    min-height: ${props => props.windowDimensions.height}px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .text-welcome {
    margin-top: 40px;
  }

  .half-background-image {
    width: 50vw;
    height: ${props => props.windowDimensions.height}px;
  }

  .text-underline {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media ${screen.sizesMax.xs} {
    .content {
      flex-direction: column;
      justify-content: center;
      align-items: space-between;
    }

    .half-container {
      width: 100%;
      min-height: 50%;
    }

    .inner-container {
      padding: 40px 35px 60px 35px;
      width: ${props => props.windowDimensions.width}px;
    }

    .half-background-image {
      width: 100%;
      max-height: ${props => props.windowDimensions.height / 4}px;
    }

    .text-welcome {
      font-size: ${Themes.fontSizes.xl}px;
      margin-top: 34px;
    }

    .form-container {
      margin-top: 34px;
    }
  }
`;
