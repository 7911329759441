import Styled from 'styled-components';

import { screen } from 'helpers';

type LogoStyleProps = {
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
};

export const LogoContainer: any = Styled.div < LogoStyleProps > `
  display: flex;
  flex-direction: row;
  margin-top: ${props => (props.mt || 0) + 'px'};
  margin-bottom: ${props => (props.mb || 0) + 'px'};
  margin-left: ${props => (props.ml || 0) + 'px'};
  margin-right: ${props => (props.mr || 0) + 'px'};
  cursor: default;

  @media ${screen.sizesMax.xxxs} {
    .img-logo {
      width: 100px;
      height: 36px;
    }
  }
`;
