import React, { useState, useEffect } from 'react';

import {
  Box,
  Text,
  Icon,
  Spacer,
  Image,
  Divider,
  LinkedUsers,
  Modal,
  ButtonContact,
  EmptyState,
  ModalReport,
  Skeleton,
  ModalLoader
} from 'components';
import { convertColorStatus } from 'components/DataTable/helpers';
import { Colors, Images } from 'consts';
import {
  hooks,
  misc,
  roleHelper,
  toastify
} from 'helpers';
import { language } from 'language';
import { Permission } from 'interfaces/role';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { BoxScooterInfoStyle } from './style';

import { renderTitleSubContent } from '../../index';

type BoxScooterInfoProps = {
  widthBox: number | string;
  routeId?: string;
};

type ScooterInfo = {
  name: string;
  value: string;
};

const BoxScooterInfo: React.FC<BoxScooterInfoProps> = ({ widthBox, routeId }) => {
  const dispatch = hooks.useAppDispatch();

  const getScooterDetail = dispatch(actions.getScooterDetail);
  const editScooter = dispatch(actions.editScooter);

  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const scooterDetail = hooks.useAppSelector(selectors.scooter.scooterDetail);
  const usersScooter = hooks.useAppSelector(selectors.scooter.usersScooter);
  const variantScooters = hooks.useAppSelector(selectors.scooter.variantScooters);
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [modalSeeUsersVisible, setModalSeeUsersVisible] = useState<boolean>(false);
  const [modalReportVisible, setModalReportVisible] = useState<boolean>(false);
  // const [dropdownStatusVisible, setDropdownStatusVisible] = useState<boolean>(false);
  const [modalLoaderVisible, setModalLoaderVisible] = useState<boolean>(false);

  const loadingScooter = misc.isLazyLoading(lazyLoad, 'scooterDetail');

  useEffect(() => {
    if (routeId) {
      getScooterDetail(routeId);

      setRefreshing(false);
    }
  }, [refreshing, routeId]);

  const onCloseModalReport = () => setModalReportVisible(false);

  const onCloseModalSeeUsers = () => setModalSeeUsersVisible(false);

  const onEditScooterStatus = (
    status: string,
    cbSuccess?: (message: string) => void,
    postCb?: () => void
  ) => {
    const selectedVariant = variantScooters?.data.find(variant => `${ variant?.type } - ${ variant?.color }` === `${ scooterDetail?.type } - ${ scooterDetail?.color }`);
    const payloadEdit = {
      vin: scooterDetail?.vin,
      plat_number: scooterDetail?.plat_number,
      status,
      showroom_id: scooterDetail?.showroom_id,
      variant_id: selectedVariant ? selectedVariant.id : 1,
      location: scooterDetail?.location
    };

    editScooter(
      scooterDetail?.vin?.toString(),
      payloadEdit,
      cbSuccess,
      postCb
    );
  };

  const onToastMessageSuccess = (message: string) => {
    toastify.success(message);
  };

  const handleCbReport = (message: string) => {
    onCloseModalReport();

    onToastMessageSuccess(message);

    setRefreshing(true);
  };

  const onClickReport = () => {
    let nextStatus = '';

    if (scooterDetail?.status !== language.scooters.missing) {
      nextStatus = language.scooters.missing;
    } else {
      if (scooterDetail?.parent) {
        nextStatus = 'Commission';
      } else if (scooterDetail?.showroom_id > 0) {
        nextStatus = 'Showroom';
      } else {
        nextStatus = 'Finished Production';
      }
    }

    onEditScooterStatus(nextStatus, handleCbReport);
  };

  // const onClickChangeScooterStatus = (params: MenuEntity) => {
  //   setDropdownStatusVisible(false);

  //   setTimeout(() => {
  //     setModalLoaderVisible(true);

  //     onEditScooterStatus(
  //       params.key,
  //       onToastMessageSuccess,
  //       () => setModalLoaderVisible(false)
  //     );
  //   }, 300);
  // };

  const renderTextValueInfoScooter = (info: ScooterInfo) => {
    const { name, value } = info;

    return (
      <Text
        color={ name === 'Status' ? convertColorStatus(value) : Colors.black.isBlack }
        lineHeight={ 15 }
        align='right'
      >{ value }</Text>
    );
  };

  // const renderDropdownChangeStatus = (info: ScooterInfo) => {
  //   return (
  //     <DropdownStatusStyle visible={ dropdownStatusVisible }>
  //       <Dropdown
  //         overlayData={ overlayDataScooterStatus(scooterDetail?.status) }
  //         onClickMenu={ onClickChangeScooterStatus }
  //         visible={ dropdownStatusVisible }
  //         onVisibleChange={ setDropdownStatusVisible }
  //         placement='bottomRight'
  //       >
  //         <div className='dropdown-cell'>
  //           { renderTextValueInfoScooter(info) }

  //           <Icon
  //             iconName='arrowDown'
  //             size={ 13 }
  //             fill={ Colors.grey.isGrey }
  //             className='arrow-dropdown-cell'
  //           />
  //         </div>
  //       </Dropdown>
  //     </DropdownStatusStyle>
  //   );
  // };

  const renderTextInfoScooter = (info: ScooterInfo) => {
    const name = info.name;

    return (
      <>
        <div className='justify-align-center'>
          <Text color={ Colors.grey.isGrey } lineHeight={ 15 }>{ name }</Text>

          { renderTextValueInfoScooter(info) }
          { /* { name === 'Status' && roleHelper.isPermissible(adminPermissions, Permission.inventory_change_status)
            ? renderDropdownChangeStatus(info)
            : renderTextValueInfoScooter(info) } */ }
        </div>
        <Divider marginVertical={ 10 } />
      </>
    );
  };

  const renderScooterInfo = () => {
    const scooterInfo: ScooterInfo[] = [
      {
        name: 'VIN',
        value: scooterDetail?.vin?.toString()
      },
      {
        name: 'Type',
        value: scooterDetail?.type ?? '-'
      },
      {
        name: 'Color',
        value: scooterDetail?.color ?? '-'
      },
      {
        name: 'Plat Number',
        value: scooterDetail?.plat_number ?? '-'
      },
      {
        name: 'Status',
        value: scooterDetail?.status ?? '-'
      }
    ];

    return scooterInfo?.map((info: ScooterInfo, index: number) => (
      <div key={ index }>
        { renderTextInfoScooter(info) }
      </div>
    ));
  };

  const renderButtonContact = (phone: string) => {
    return <ButtonContact phone={ phone } />;
  };

  const renderModalSeeAllUsers = () => {
    return (
      <Modal
        visible={ modalSeeUsersVisible }
        onCloseModal={ onCloseModalSeeUsers }
        width={ 500 }
        modalType='long-scroll'
        title={ () => renderTitleSubContent('Users') }
      >
        <BoxScooterInfoStyle>
          { renderDataUsers(true) }
        </BoxScooterInfoStyle>
      </Modal>
    );
  };

  const renderDataUsers = (useAllData: boolean) => {
    const dummyData = {
      status: '',
      user_id: '',
      name: '',
      email: '',
      phone_number: '',
      profile_img: ''
    };
    const dataUsersScooter = loadingScooter
      ? misc.createDummyData(2, dummyData) :
      !usersScooter
        ? [] :
        useAllData || usersScooter?.length <= 2
          ? usersScooter
          : usersScooter?.slice(0, 2);

    if (dataUsersScooter?.length) {
      return dataUsersScooter?.map((data: any, index: number) => {
        return (
          <Skeleton
            key={ index }
            loading={ loadingScooter }
            paragraph={ { rows: 2 } }
            avatar={ {
              size: 40,
              shape: 'circle'
            } }
            title={ false }
            mt={ 20 }
          >
            <div key={ index } className='justify-align-center'>
              <div className='mr1'>
                <LinkedUsers
                  name={ data?.name }
                  status={ (data?.status || '') }
                  mt={ 5 }
                  image={ data?.profile_img }
                />
              </div>
              { renderButtonContact(data?.phone_number) }
            </div>
          </Skeleton>
        );
      });
    }

    return (
      <div className='mt4'>
        <EmptyState
          background={ { height: 160 } }
          image={ {
            src: Images.emptyState.users,
            width: 45,
            height: 45
          } }
          text='No Users Exist'
        />
      </div>
    );
  };

  const renderUsers = () => {
    const subtitle = !usersScooter ? '' : 'See All';

    return (
      <Spacer mt={ 30 }>
        { renderTitleSubContent(
          'Users',
          subtitle,
          () => setModalSeeUsersVisible(true)
        ) }
        { renderDataUsers(false) }
      </Spacer>
    );
  };

  const renderModalReportMissing = () => {
    const loadingReport = misc.isLazyLoading(lazyLoad, 'editScooter');
    const reportType = scooterDetail?.status === language.scooters.missing
      ? 'found'
      : 'missing';

    return (
      <ModalReport
        visible={ modalReportVisible }
        onCloseModal={ onCloseModalReport }
        onClickConfirm={ onClickReport }
        type={ reportType }
        loadingConfirm={ loadingReport }
      />
    );
  };

  const renderReportMissing = () => {
    const isPermissibleReportMissing = roleHelper.isPermissible(adminPermissions, Permission.inventory_report_missing);

    if (isPermissibleReportMissing) {
      const color = scooterDetail?.status === language.scooters.missing
        ? Colors.red.isRed
        : Colors.grey.isGrey;

      return (
        <div className='flex row align-center pointer' onClick={ () => setModalReportVisible(true) }>
          <Icon
            size={ 12 }
            iconName='alert'
            fill={ color }
          />
          <Text
            weight={ 700 }
            color={ color }
            align='right'
            ml={ 5 }
          >Missing</Text>
        </div>
      );
    }
  };

  const renderModalLoader = () => {
    return (
      <ModalLoader
        visible={ modalLoaderVisible }
        onCloseModal={ () => setModalLoaderVisible(false) }
        afterClose={ () => setRefreshing(true) }
      />
    );
  };

  return (
    <BoxScooterInfoStyle>
      <Box
        mt={ 20 }
        padding={ 20 }
        width={ widthBox }
      >
        <div className='justify-align-center'>
          <Text
            size='m'
            weight={ 700 }
            lineHeight={ 19 }
          >SAVART</Text>
          { renderReportMissing() }
        </div>

        <div className='col full-height'>
          <div className='center-content' style={ { height: 192 } }>
            <Skeleton
              loading={ loadingScooter }
              avatar={ { shape: 'square' } }
              className='img-scooter-skeleton'
              multiple={ false }
            >
              <Image
                src={ scooterDetail?.images
                  ? scooterDetail?.images
                  : Images.scooter1 }
                width={ 127 }
                height={ 120 }
                className='img-scooter'
                external={ !!scooterDetail?.images }
                preview
              />
            </Skeleton>
          </div>
          <div className='mb4'>
            { renderTitleSubContent('Information') }
          </div>
          <Skeleton
            loading={ loadingScooter }
            paragraph={ { rows: 4 } }
            title={ false }
            mt={ 20 }
          >
            { renderScooterInfo() }
          </Skeleton>
          { renderUsers() }
        </div>
      </Box>
      { renderModalSeeAllUsers() }
      { renderModalReportMissing() }
      { renderModalLoader() }
    </BoxScooterInfoStyle>
  );
};

export default BoxScooterInfo;
