import {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo
} from 'react';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector
} from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useForm = (initialValues: any) => {
  const [values, setValues] = useState(initialValues);

  return [
    values, (e, key) => setValues({
      ...values,
      [key]: e,
    })
  ];
};

export const useStateCallback = (initialState: any) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef<any>(null);

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  }, []);

  useEffect(() => {
    // cb.current is `null` on initial render, so only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export type Dimensions = {
  width: number;
  height: number;
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState<Dimensions>(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<any>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    if (delay !== null && delay > 0) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState<number>(Date.now());

  useInterval(() => {
    setCurrentTime(Date.now());
  }, 60000);

  return [currentTime];
};

export const useDebounceFetcher = <T>(
  fetchOptions: (search: string) => Promise<T[]>,
  onSelectVisibleChange: (open: boolean) => void,
  defaultOptions?: T[],
  debounceTimeout = 800
) => {
  const fetchRef = useRef(0);

  const [fetching, setFetching] = useState<boolean>(false);
  const [options, setOptions] = useState<T[]>(defaultOptions || []);

  useEffect(() => {
    const closeDropdown = (event: Event) => {
      const eventTarget: EventTarget | null = event.target;
      if ((eventTarget || '').toString().includes('Body')) {
        onSelectVisibleChange(false);
      }
    };

    window.addEventListener(
      'scroll',
      closeDropdown,
      true
    );

    return () => window.removeEventListener(
      'scroll',
      closeDropdown,
      true
    );
  }, []);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return {
    fetching,
    options,
    debounceFetcher
  };
};