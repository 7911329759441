import { Dispatch } from 'redux';

import { toastify } from 'helpers';
import { AppActions } from 'store';
import { ApiData, CommonResponseData } from 'interfaces/common';
import {
  BatteryApiParams,
  BatteryPack,
  BatteryPackStatistic,
  FormAddBattery,
  BatteryPackBatteryUsage,
  DischargeTempStatistic,
  DischargeTempApiParams,
  VinLog
} from 'interfaces/battery';
import { ScooterApiParams, SwapLogApiParams } from 'interfaces/scooter';
import * as batteryServices from 'services/battery';

import {
  GET_ALL_BATTERY_PACK,
  GET_BATTERY_PACK_DETAIL,
  GET_BATTERY_PACK_SOC,
  GET_BATTERY_PACK_SOH,
  GET_BATTERY_PACK_DISCHARGE,
  GET_BATTERY_PACK_TEMPERATURE,
  GET_BATTERY_VIN_LOG,
  GET_BATTERY_PACK_BATTERY_USAGES
} from './actionTypes';

import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';
import { setPaginationData } from '../Pagination/action';

export const getAllBatteryPack = (dispatch: Dispatch<AppActions>) => (params: BatteryApiParams) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('allBatteryPack'));

      const {
        code,
        data,
        pagination
      }: ApiData<BatteryPack[]> = await batteryServices.getAllBatteryPack(params);

      if (code === 200) {
        await dispatch({
          type: GET_ALL_BATTERY_PACK,
          data: {
            data,
            pagination
          }
        });

        await dispatch(setPaginationData('batteryPacks', pagination));

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('allBatteryPack'));
  });
};

export const addBatteryPack = (dispatch: Dispatch<AppActions>) => (formAddBattery: FormAddBattery, callback?: (data: any) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('addBatteryPack'));

      const { code, data }: ApiData<any> = await batteryServices.addBatteryPack(formAddBattery);

      if (code === 200) {
        if (callback) callback(data);

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('addBatteryPack'));
  });
};

export const editBatteryPack = (dispatch: Dispatch<AppActions>) => (
  id: string,
  formBattery: any,
  callback?: (message: string) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('editBatteryPack'));

      const { code, data }: ApiData<CommonResponseData> = await batteryServices.editBatteryPack(id, formBattery);

      if (code === 200) {
        callback ? callback(data.message) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('editBatteryPack'));
  });
};

export const getBatteryPackDetail = (dispatch: Dispatch<AppActions>) => (batteryId: string, callback?: (battery: BatteryPack) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('batteryPackDetail'));

      const { code, data }: ApiData<BatteryPack> = await batteryServices.getBatteryPackDetail(batteryId);

      if (code === 200) {
        await dispatch({
          type: GET_BATTERY_PACK_DETAIL,
          data
        });

        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('batteryPackDetail'));
  });
};

export const getSocBatteryPack = (dispatch: Dispatch<AppActions>) => (
  batteryId: string,
  params: BatteryApiParams,
  callback?: (listSoc: BatteryPackStatistic[]) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('socBatteryPack'));

      const { code, data }: ApiData<BatteryPackStatistic[]> = await batteryServices.getSocBatteryPack(batteryId, params);

      if (code === 200) {
        await dispatch({
          type: GET_BATTERY_PACK_SOC,
          data: {
            data,
            filter_time: params.filter || ''
          }
        });

        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('socBatteryPack'));
  });
};

export const getSohBatteryPack = (dispatch: Dispatch<AppActions>) => (
  batteryId: string,
  params: BatteryApiParams,
  callback?: (listSoc: BatteryPackStatistic[]) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('sohBatteryPack'));

      const { code, data }: ApiData<BatteryPackStatistic[]> = await batteryServices.getSohBatteryPack(batteryId, params);

      if (code === 200) {
        await dispatch({
          type: GET_BATTERY_PACK_SOH,
          data: {
            data,
            filter_time: params.filter || ''
          }
        });

        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('sohBatteryPack'));
  });
};

export const getDischargeBatteryPack = (dispatch: Dispatch<AppActions>) => (
  batteryId: string,
  params: DischargeTempApiParams,
  callback?: (listDischarge: DischargeTempStatistic[]) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('dischargeBatteryPack'));

      const { code, data }: ApiData<DischargeTempStatistic[]> = await batteryServices.getDischargeBatteryPack(batteryId, params);

      if (code === 200) {
        await dispatch({
          type: GET_BATTERY_PACK_DISCHARGE,
          data: { data }
        });

        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('dischargeBatteryPack'));
  });
};

export const getTempBatteryPack = (dispatch: Dispatch<AppActions>) => (
  batteryId: string,
  params: DischargeTempApiParams,
  callback?: (listTemp: DischargeTempStatistic[]) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('tempBatteryPack'));

      const { code, data }: ApiData<DischargeTempStatistic[]> = await batteryServices.getTempBatteryPack(batteryId, params);

      if (code === 200) {
        await dispatch({
          type: GET_BATTERY_PACK_TEMPERATURE,
          data: { data }
        });

        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('tempBatteryPack'));
  });
};

export const getBatteryVinLog = (dispatch: Dispatch<AppActions>) => (params: SwapLogApiParams) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('vinLogBatteryPack'));

      const { data, pagination }: ApiData<VinLog[]> = await batteryServices.getVinLog(params);

      dispatch({
        type: GET_BATTERY_VIN_LOG,
        data: {
          data,
          pagination
        }
      });

      await dispatch(setPaginationData('batteryVinLog', pagination));

      resolve();
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('vinLogBatteryPack'));
  });
};

export const getBatteryPackBatteryUsages = (dispatch: Dispatch<AppActions>) => (
  id: string,
  params: ScooterApiParams,
  callback?: () => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('BatteryPackBatteryUsage'));

      const { code, data }: ApiData<BatteryPackBatteryUsage[]> = await batteryServices.getBatteryPackBatteryUsages(id, params);

      if (code === 200) {
        dispatch({
          type: GET_BATTERY_PACK_BATTERY_USAGES,
          data
        });

        callback ? callback() : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('BatteryPackBatteryUsage'));
  });
};