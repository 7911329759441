import {
    BatteryCondition,
	StatsAge,
    SwapLocation,
} from 'interfaces/stats';

import {
    GET_STATS_AGE,
    GET_BATTERY_CONDITION,
    GET_BATTERY_RETIRED,
    GET_SWAP_LOCATION,
    LOGOUT,
    StatsActionTypes,
} from './actionTypes';

interface StatsAgeState {
    statsAge: StatsAge[];
    batteryCondition: BatteryCondition[];
    swapLocation: SwapLocation[];
    batteryRetired: SwapLocation[];
}

const initState: StatsAgeState = {
    statsAge: [],
    batteryCondition: [],
    swapLocation: [],
    batteryRetired: [],
};

const stationReducer = (state = initState, action: StatsActionTypes): StatsAgeState => {
    switch (action.type) {
    case GET_STATS_AGE:
        return {
            ...state,
            statsAge: action.data
        };
    case GET_BATTERY_CONDITION:
        return {
            ...state,
            batteryCondition: action.data
        };
    case GET_SWAP_LOCATION:
        return {
            ...state,
            swapLocation: action.data
        };
    case GET_BATTERY_RETIRED:
        return {
            ...state,
            batteryRetired: action.data
        };
    case LOGOUT:
        return initState;

    default:
        return state;
    }
};

export default stationReducer;