import { language } from 'language';

const { label, placeholder } = language;

export const inputPropsData = [
  {
    label: label.name,
    placeholder: placeholder.name,
    key: 'name',
    type: 'text'
  },
  {
    label: label.username,
    placeholder: placeholder.username,
    key: 'username',
    type: 'text'
  },
  {
    label: label.email,
    placeholder: placeholder.email,
    key: 'email',
    type: 'text'
  },
  {
    label: label.phone,
    placeholder: placeholder.phone,
    key: 'phone_number',
    type: 'text'
  },
  {
    label: label.password,
    placeholder: placeholder.password,
    key: 'password',
    type: 'password'
  },
  {
    label: label.confirmPassword,
    placeholder: placeholder.confirmPassword,
    key: 'retype_password',
    type: 'password'
  },
  {
    label: label.role,
    placeholder: placeholder.role,
    key: 'role_id',
    type: 'select'
  },
  {
    label: label.showroom,
    placeholder: placeholder.searchShowroom,
    key: 'showroom_id',
    type: 'search'
  }
];