import React from 'react';

import {
  Label,
  Input,
  Indicator
} from './style';

import Text from '../Text';

type CheckboxProps = {
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
  label?: string;
  disabled?: boolean;
  labelSize?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  name,
  id,
  label,
  labelSize,
  disabled,
}) => {
  return (
    <Label
      htmlFor={ id }
      disabled={ disabled }
      checked={ checked }
    >
      <Text
        size={ labelSize }
        weight={ 400 }
        text={ label }
        className='p1 text-checkbox'
      />
      <Input
        id={ id }
        type='checkbox'
        name={ name || `checkbox${Math.floor(Math.random() * 100)}` }
        disabled={ disabled }
        checked={ checked }
        onChange={ onChange }
      />
      <Indicator checked={ checked } />
    </Label>
  );
};

Checkbox.defaultProps = {
  disabled: false,
  labelSize: 's'
};

export default Checkbox;
