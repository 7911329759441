import React, {
  useCallback,
  useState,
  useEffect
} from 'react';

import { Colors, Images } from 'consts';
import { screen } from 'helpers';
import {
  Container,
  Box,
  Text,
  Input,
  Button,
  Image,
  Spacer,
  Icon
} from 'components';
import {
  toastify,
  hooks,
  misc,
  navigation
} from 'helpers';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { ForgotPasswordStyle } from './style';

const ForgotPassword: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const forgotPassword = dispatch(actions.forgotPassword);

  const loading = hooks.useAppSelector(selectors.misc.loading);

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [formEmail, setFormEmail] = useState<string>('');
  const [successPage, setSuccessPage] = useState<boolean>(false);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  useEffect(() => {
    if (windowDimensions.width <= screen.isMobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowDimensions.width]);

  const onClickGoBack = useCallback(() => navigation.goBack(), []);

  const onChangeForm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormEmail(e.target.value);
  }, []);

  const renderButton = (text: string, onClick: () => void) => {
    const isLoading = misc.isLoading(loading, 'forgotPassword');

    return (
      <Button
        height={ 43 }
        text={ text }
        fontWeight={ 700 }
        size='m'
        onClick={ onClick }
        isLoading={ isLoading }
      />
    );
  };

  const onClickSendLink = () => {
    if (formEmail) {
      forgotPassword({ email: formEmail }, onCbResetPassword);
    } else {
      toastify.error('Email cannot be empty');
    }
  };

  const onCbResetPassword = () => {
    setSuccessPage(true);
  };

  const renderForm = () => {
    return (
      <div className='form-container'>
        <Input
          placeholder='Enter your email'
          label='Email'
          value={ formEmail }
          name='email'
          onChange={ onChangeForm }
        />
        { renderButton('Send Link', onClickSendLink) }
      </div>
    );
  };

  const renderTitle = (title: string, subtitle: string) => {
    return (
      <>
        <Text
          size={ isMobile || successPage ? 'xl' : 'xl3' }
          lineHeight={ 39 }
          weight={ 700 }
          align={ successPage ? 'center' : 'left' }
          mb={ 10 }
        >{ title }</Text>
        <Text
          weight={ isMobile ? 400 : 500 }
          lineHeight={ 17 }
          align={ successPage ? 'center' : 'left' }
          color={ Colors.grey.isGrey }
        >{ subtitle }</Text>
      </>
    );
  };

  const renderSuccessPage = () => {
    return (
      <div className='col center-content'>
        <Spacer mb={ 5 }>
          <Icon type='swal-icon' iconName='success' />
        </Spacer>
        { renderTitle('Link reset password telah terkirim', 'Silakan cek inbox email kamu untuk memperoleh link reset password') }
      </div>
    );
  };

  return (
    <ForgotPasswordStyle>
      <Container
        backgroundColor={ isMobile ? Colors.white.default : Colors.grey.isBgGrey }
        navbar={ {
          logo: !isMobile,
          backButton: isMobile ? 'square' : 'circle',
          leftClassName: 'left-navbar',
          onClickBack: onClickGoBack
        } }
      >
        <div className={ `content ${isMobile ? 'center-content' : 'justify-center'}` }>
          <div className='flex-col inner-content'>
            <Spacer mb={ 30 } mt={ isMobile ? 0 : 80 }>
              { !successPage && renderTitle('Forgot Password', 'Dont worry, we will send a link to your email to reset your password.') }
            </Spacer>
            {
              isMobile
              ? successPage ? renderSuccessPage() : renderForm()
              : <Box width={ 418 } height={ successPage ? undefined : 235 }>
                  { successPage ? renderSuccessPage() : renderForm() }
                </Box>
            }
          </div>
        </div>
        {
          isMobile && (
            <div className='half-container'>
              <Image src={ Images.bgLoginMobile } className='half-background-image' />
            </div>
          )
        }
      </Container>
    </ForgotPasswordStyle>
  );
};

export default ForgotPassword;
