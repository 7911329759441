import { Dispatch } from 'redux';

import { toastify } from 'helpers';
import { AppActions } from 'store';
import { ApiData, CommonResponseData } from 'interfaces/common';
import {
  Admin,
  AdminDetail,
  FormAdmin,
  FormChangeStatus,
  AdminApiParams
} from 'interfaces/admin';
import * as adminServices from 'services/admin';

import { GET_ALL_ADMIN, GET_ADMIN_DETAIL } from './actionTypes';
import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';
import { setPaginationData } from '../Pagination/action';

export const getAllAdmin = (dispatch: Dispatch<AppActions>) => (params: AdminApiParams, callback?: (admins: Admin[]) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('allAdmin'));

      const {
        code,
        data,
        pagination
      }: ApiData<Admin[]> = await adminServices.getAllAdmin(params);

      if (code === 200) {
        await dispatch({
          type: GET_ALL_ADMIN,
          data: {
            data,
            pagination
          }
        });

        await dispatch(setPaginationData('admins', pagination));

        if (callback) callback(data);

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    } finally {
      dispatch(lazyLoadEnd('allAdmin'));
    }
  });
};

export const getAdminDetail = (dispatch: Dispatch<AppActions>) => (userId: string, callback: (admin: AdminDetail) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('adminDetail'));

      const { code, data }: ApiData<AdminDetail> = await adminServices.getAdminDetail(userId);

      if (code === 200) {
        dispatch({
          type: GET_ADMIN_DETAIL,
          data
        });

        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('adminDetail'));
  });
};

export const addAdmin = (dispatch: Dispatch<AppActions>) => (formAdmin: FormAdmin, callback?: (message: string) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('addAdmin'));

      const { code, data }: ApiData<string> = await adminServices.addAdmin(formAdmin);

      if (code === 200) {
        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('addAdmin'));
  });
};

export const editAdmin = (dispatch: Dispatch<AppActions>) => (
  userId: string,
  formAdmin: FormAdmin,
  callback?: (message: string) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('editAdmin'));

      const { code, data }: ApiData<string> = await adminServices.editAdmin(userId, formAdmin);

      if (code === 200) {
        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('editAdmin'));
  });
};

export const changeStatusAdmin = (dispatch: Dispatch<AppActions>) => (
  userId: string,
  formChangeStatus: FormChangeStatus,
  callback?: (message: string) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('changeStatusAdmin'));

      const { code, data }: ApiData<CommonResponseData> = await adminServices.changeStatusAdmin(userId, formChangeStatus);

      if (code === 200) {
        callback ? callback(data.message ?? data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('changeStatusAdmin'));
  });
};
