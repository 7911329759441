import Styled from 'styled-components';

import { Colors } from 'consts';

export const ModalFormShowroomStyle: any = Styled.div`
	.input-address {
		padding-right: 56px !important;
	}

	.wrapper-card-maps {
		border: 1px solid ${ Colors.grey.isLightGrey };
		border-radius: 10px;
	}

	.address-selected-container {
		display: grid;
		flex-direction: column;
		row-gap: 10px;
		padding: 20px;
	}
`;

export const ItemDropdown: any = Styled.div`
	display: flex;
	padding: 10px 10px 10px 0;

	.ant-select-item-option-content {
		white-space: normal !important;
	}

	.container-icon {
		flex-shrink: 0;
		margin-right: 11px;
	}

	.item-text-wrapper {
		display: flex;
		width: 100%;
		flex-direction: column;
		row-gap: 10px;
	}

	.description-text {
		overflow-wrap: break-word;
		white-space: normal;
	}
`;