import { moment } from 'helpers';

const getDifferenceDate = (diff: number) => {
  const todays = new Date();
  const day = todays.getDate() - Number(diff);
  todays.setDate(day);
  const getData = todays.getFullYear() + '-' + String(todays.getMonth() + 1).padStart(2, '0') + '-' + String(todays.getDate()).padStart(2, '0');
  return getData;
};

export const setFilterTimeApiParams = (filterName: string) => {
  switch (filterName.toLowerCase()) {
    case 'last 24 hours':
      return getDifferenceDate(1);
    case 'last 7 days':
      return getDifferenceDate(7);
    default:
      return '';
  }
};

export const setFilterLevelApiParams = (filterName: string) => {
  // Ketentuan dari Backend:
  switch (filterName.toLowerCase()) {
    case 'average':
      return 'avg';
    case 'max':
    case 'min':
    default:
      return filterName.toLowerCase();
  }
};

export const convertLabelTooltip = (activeFilter, payload) => {
  
  switch (activeFilter.toLowerCase()) {
    case 'last 24 hours':
      return moment.dateConverted(
        payload.hours,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD HH:mm'
      );
    case 'last 7 days':
      return moment.dateConverted(
        payload.hours,
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD'
      );
    default:
      return payload.date;
  }
};

export const setDataKeyXAxis = (activeFilter: string) => {
  switch (activeFilter.toLowerCase()) {
    case 'last 24 hours':
      return 'hours';
    case 'last 7 days':
      return 'hours';
    default:
      return 'day';
  }
};

export const setTickInterval = (lengthData: number) => {
  if (lengthData > 16) {
    return 2;
  } else if (lengthData > 7 && lengthData <= 16) {
    return 1;
  }
  
  return 0;
};
