import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

type PaginationStyleProps = {
  visibleFilter?: boolean;
  screenWidth: number;
};

export const PaginationStyle: any = Styled.div < PaginationStyleProps > `
  thead > tr > th {
    color: ${Colors.grey.isGrey};
    font-family: InterMedium;
    font-size: 14px;
    line-height: 19px;
    background-color: ${Colors.grey.isGreyOpacity('0.05')};
  }

  .skeleton-text-show > .ant-skeleton-avatar {
    border-radius: 8px !important;
    height: 24px !important;
    width: 163px !important;
  }

  .container-text-show,
  .container-navigation-page,
  .container-EPP {
    width: ${props => (props.screenWidth - 40) / 3}px;
  }

  .container-navigation-page {
    justify-content: center;
  }

  .container-EPP {
    justify-content: flex-end;
  }

  .ic-double-arrow-left {
    margin-top: 4.8px;
  }

  .ic-double-arrow-right {
    margin-top: 4.8px;
    transform: rotateY(-180deg);
  }

  .ic-single-arrow-left {
    margin-top: 4.8px;
    margin-right: 24px;
    margin-left: 17px;
  }

  .ic-single-arrow-right {
    transform: rotateY(-180deg);
    margin-top: 4.8px;
    margin-left: 24px;
    margin-right: 17px;
  }

  .arrow-EPP {
    ${props => props.visibleFilter ? 'transform: rotateX(180deg);' : ''}
  }
  
  .dropdown-EPP {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: ${Colors.white.default};
    max-width: 86px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid rgba(242, 242, 242, 1);
    padding: 13px 15px;

    &:hover {
      opacity: 0.8;
    }
  }

  @media ${screen.sizesMax.st} {
    thead > tr > th {
      font-size: 12px;
    }
  }

  @media ${screen.sizesMax.xs} {
    .skeleton-text-show > .ant-skeleton-avatar {
      width: 100px !important;
    }

    .ic-single-arrow-left, .ic-single-arrow-right {
      margin-right: 10px;
      margin-left: 7px;
    }

    .ic-double-arrow-right, .ic-double-arrow-left {
      margin-top: 6.8px;
    }

    .dropdown-EPP {
      padding: 10px;
    }
  }

  @media ${screen.sizesMax.xxs} {
    .skeleton-text-show > .ant-skeleton-avatar {
      width: 80px !important;
    }
  }
`;