import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Text,
  Icon,
  Upload,
  Skeleton,
  Image
} from 'components';
import { Colors } from 'consts';
import {
  hooks,
  misc,
  toastify,
  screen,
  validation,
  roleHelper
} from 'helpers';
import { UploadFileInfo } from 'interfaces/common';
import { DataLegalInfo, FormUploadLegal } from 'interfaces/user';
import { Permission } from 'interfaces/role';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { BoxLegalInfoStyle } from './style';

import { renderTitleSubContent } from '../../index';

type BoxLegalInforops = {
  widthBox: number | string;
  refreshing?: boolean;
  setRefreshing?: React.Dispatch<React.SetStateAction<boolean>>;
};

type LegalInfoType = 'KTP' | 'SIM' | 'Selfie';

type UploadTempState = {
  [key in LegalInfoType]: boolean;
};

const BoxLegalInfo: React.FC<BoxLegalInforops> = ({
  widthBox,
  setRefreshing
}) => {
  const dispatch = hooks.useAppDispatch();
  const uploadLegalInfo = dispatch(actions.uploadLegalInfo);
  const verifyLegalInfo = dispatch(actions.verifyLegalInfo);

  const userDetail = hooks.useAppSelector(selectors.user.userDetail);
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const [uploadTempState, setUploadTempState] = useState<UploadTempState>({
    KTP: false,
    SIM: false,
    Selfie: false
  });
  const [requestUploadLegal, setRequestUploadLegal] = useState<FormUploadLegal>({
    id_image: null,
    selfie_image: null,
    driver_license_image: null
  });

  useEffect(() => {
    const emptyRequestUploadLegal = misc.getMultipleKeyByValue(requestUploadLegal, null);

    if (!emptyRequestUploadLegal.length) {
      uploadLegalInfo(
        userDetail?.id.toString(),
        requestUploadLegal,
        handleCbUploadLegalInfo
      );
    }
  }, [requestUploadLegal]);

  const handleCbUploadLegalInfo = (message: string) => {
    toastify.success(message);

    setUploadTempState(prevState => ({
      ...prevState,
      KTP: false,
      Selfie: false,
      SIM: false
    }));

    if (setRefreshing) setRefreshing(true);
  };

  const renderUploadPhoto = (
    nameUpload: string,
    widthImage: number,
    dataUpload: any
  ) => {
    return (
      <Upload
        uploadLoading={ uploadTempState[nameUpload] }
        uploadPhotoUrl={ dataUpload }
        onChange={ (info: UploadFileInfo) => onChangeUpload(info, nameUpload) }
        width={ widthImage }
        height={ 120 }
        borderRadius={ 10 }
        marginVertical={ 0 }
        className='img-legal-upload'
      />
    );
  };

  const onChangeUpload = (info: UploadFileInfo, nameUpload: string) => {
    let key = '';
    switch (nameUpload) {
      case 'KTP':
        key = 'id_image';
        break;
      case 'SIM':
        key = 'driver_license_image';
        break;
      case 'Selfie':
        key = 'selfie_image';
        break;
      default:
        key = '';
    }

    const isFileValid = validation.uploadImage(info.file);

    if (isFileValid) {
      setUploadTempState(prevState => ({
        ...prevState,
        [nameUpload]: true
      }));

      setRequestUploadLegal({ [key]: info.file });
    }
  };

  const onClickVerifyAll = () => {
    verifyLegalInfo(userDetail?.id.toString(), (message: string) => {
      toastify.success(message);
      if (setRefreshing) setRefreshing(true);
    });
  };

  const renderDataLegalInfo = () => {
    const loading = misc.isLazyLoading(lazyLoad, 'userDetail');
    const loadingVerify = misc.isLazyLoading(lazyLoad, 'verifyLegal');
    const isMobile = windowDimensions.width < screen.isMobile;
    const widthImage = typeof widthBox === 'number'
      ? (widthBox / 3) - 30
      : 180;
    const dataLegal = [
      {
        name: 'KTP',
        image: userDetail?.id_img
      },
      {
        name: 'Selfie',
        image: userDetail?.selfie_img
      },
      {
        name: 'SIM',
        image: userDetail?.driver_lic_img
      }
    ];
    const imageNotCompleted = dataLegal.find(legal => !legal.image);
    const isPermissibleVerify = !userDetail?.is_completed && roleHelper.isPermissible(adminPermissions, [Permission.user_detail_legal_info, Permission.user_verify]);

    return (
      <div>
        <div className='justify-align-center mb4'>
          { renderTitleSubContent('Legal Information') }
          {
            isPermissibleVerify && (
              <div className='flex row'>
                <Button
                  height={ 36 }
                  text='Verify All'
                  fontWeight={ 700 }
                  onClick={ onClickVerifyAll }
                  borderRadius={ 5 }
                  isLoading={ loadingVerify }
                  disabled={ !!imageNotCompleted }
                  className='btn-verify'
                />
              </div>
            )
          }
        </div>
        <div className={ `${ isMobile ? 'col' : 'justify-align-center mt4' }` }>
          {
            dataLegal.map((data: DataLegalInfo, index: number) => (
              <div key={ index } className='inner-container-data-legal'>
                <div className='image-text-data-legal'>
                  <Skeleton
                    loading={ loading }
                    avatar={ { shape: 'square' } }
                    style={ { width: widthImage } }
                    className='img-legal-skeleton'
                    multiple={ false }
                  >
                    {
                      isPermissibleVerify ? renderUploadPhoto(
                        data.name,
                        widthImage,
                        data.image ? misc.getImageUrl(data.image, true) : data.image
                      ) : (
                        <Image
                          src={ data.image }
                          width={ widthImage }
                          height={ 120 }
                          className='img-legal'
                          preview
                          external
                        />
                      )
                    }
                  </Skeleton>
                  <Text
                    mt={ 20 }
                    ml={ isMobile ? 20 : 0 }
                    align='center'
                  >Foto { data.name }</Text>
                </div>
                <div className='container-verify'>
                  {
                    userDetail?.is_completed ? (
                      <div className='flex row align-center verified-checklist'>
                        <Icon
                          size={ 12 }
                          iconName='check'
                          fill={ Colors.white.default }
                          container='circle'
                          containerSize={ 18 }
                          containerColor={ Colors.blue.isBlue }
                        />
                        <Text
                          size='m'
                          weight={ 700 }
                          color={ Colors.blue.isBlue }
                          lineHeight={ 17 }
                          ml={ 7 }
                          align='right'
                        >Verified</Text>
                      </div>
                    ) : null
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  };

  const renderBoxLegalInfo = () => {
    const isShowLegalInfo = roleHelper.isPermissible(adminPermissions, Permission.user_detail_legal_info);

    if (isShowLegalInfo) {
      return (
        <BoxLegalInfoStyle>
          <Box
            mt={ 20 }
            width={ widthBox }
            padding={ 20 }
          >
            { renderDataLegalInfo() }
          </Box>
        </BoxLegalInfoStyle>
      );
    }

    return null;
  };

  return renderBoxLegalInfo();
};

export default BoxLegalInfo;
