
export const convertFilterToReportDate = (activeFilter: string) => {
	switch (activeFilter) {
		case 'Monthly':

			const monthlyReport = new Date();
			const getMonth = new Date(monthlyReport.setMonth(monthlyReport.getMonth() - 5));
			const getMonthlyReport = new Date().getFullYear() + '-' + String(getMonth.getMonth() + 1).padStart(2, '0') + '-' + '01';

			return getMonthlyReport;

		case 'Weekly':
			const weeklyReport = new Date();
			const day = weeklyReport.getDate() - 35;
			weeklyReport.setDate(day);
			
			const getWeeklyReport = weeklyReport.getFullYear() + '-' + String(weeklyReport.getMonth() + 1).padStart(2, '0') + '-' + String(weeklyReport.getDate()).padStart(2, '0');

			return getWeeklyReport;
			
		case 'Daily':
			const dailyReport = new Date();
			dailyReport.setDate(dailyReport.getDate() - 7);
			const getDailyReport = dailyReport.getFullYear() + '-' + String(dailyReport.getMonth() + 1).padStart(2, '0') + '-' + String(dailyReport.getDate()).padStart(2, '0');
			
			return getDailyReport;
		default:
			activeFilter.toLowerCase();
	}
};

export const dateChartFormatter = (activeFilter: string) => {
	switch (activeFilter) {
		case 'Yearly':
			return {
				short: 'YYYY',
				long: 'MMM YYYY'
			};
		case 'Monthly':
			return {
				short: 'M/YYYY',
				long: 'MMM YYYY'
			};
		case 'Weekly':
			return {
				short: 'YYYY-MM-DD',
				long: 'DD MMM YYYY'
			};
		case 'Daily':
			return {
				short: 'YYYY-MM-DD',
				long: 'DD MMM YYYY'
			};
		default:
			return {
				short: 'D/M/YYYY',
				long: 'DD MMM YYYY'
			};
	}
};

const get_date_parts = (iso_string: { split: (arg0: RegExp) => [any, any, any]; }) => {
	const [
		year,
		month,
		day,
	] = iso_string.split(/\D/g);
  
	return {
		year,
		month,
		day,
	};
};

const monthName = (monthNumber: string | number) => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];
	
	return months[Number(monthNumber) - 1];
};
  
export const group_by_month = (arr: any[]) => {

	return Object.values(arr?.reduce((a, { date: date_string, usage: usage }) => {
		const { year, month } = get_date_parts(date_string);
		const label = monthName(month) + '/' + year;
		const key = `${year}/${month}`;
		if (a[key] === undefined) {
		  	a[key] = {
				date_x: label,
				usage: 0,
				date: key
			};
		}
  
		a[key].usage += usage;
  
		return a;
	  }, {}),);
};

const getSumValueByRangeDate = (
	range: any[],
	arr: any[],
	val: string
) => {
	let sum = 0;
	
	range?.forEach((element: any) => {
		const obj = arr?.find((o: { date: any; }) => o.date === element.replace('/', '-').replace('/', '-'));
		if (obj) {
			sum += Number(obj[val]);
		}
	});
	return sum;
};

export const getWeeks = (array: any[],
	fieldSum: string) => {
	let arr = [] as any[];
	// Grouping date same in array data
	arr = Object.values(array.reduce((a, { usage, date }) => {
		a[date.slice(0, 10)] = (a[date.slice(0, 10)] || {
			date: date.slice(0, 10),
			usage: 0
		});
		a[date.slice(0, 10)].usage = String(Number(a[date.slice(0, 10)].usage) + Number(usage));
		return a;
		}, {}));

	arr?.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());

	const startDate = arr[arr?.length - 1]?.date;
	const endDate = arr[0]?.date;
	
	const sd = new Date(startDate);
  	const weeks = [] as any[];
	let week = [] as any[];
	let weekDiff = [] as any[];
	let allDate = 0;
	let noDate = 0;

	for (let d = sd; d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
		
		week.push(new Date(d).getFullYear() + '/' + String((new Date(d).getMonth() + 1)).padStart(2, '0') + '/' + String(new Date(d).getDate()).padStart(2, '0'));
		
		const existData = arr?.find(item => item.date === new Date(d).getFullYear() + '-' + String((new Date(d).getMonth() + 1)).padStart(2, '0') + '-' + String(new Date(d).getDate()).padStart(2, '0'));
		
		if (!existData) {
			noDate++;
		}
		if (new Date(d).getDay() === 0) {
			allDate += (week?.length - noDate);
			weeks.push({
				date_x: week[0] + '-<br>' + week[week.length - 1],
				usage: getSumValueByRangeDate(
					week, arr, fieldSum
				),
				date: week[0] + '-' + week[week.length - 1]
			});
			week = [];
		}

	}
	
	// Create point untuk sisa hari yang belum genap 1 minggu
	if (allDate < arr?.length) {
		weekDiff = [];

		// Looping untuk sisa tanggal
		for (let index = (arr?.length - allDate) - 1; index >= 0; index--) {
			const d = arr[index]?.date;
			if (d) {
				const lastWeek = week.find(item => item === d.replace('-', '/').replace('-', '/'));
				if (lastWeek) {
					weekDiff.push(new Date(d).getFullYear() + '/' + String((new Date(d).getMonth() + 1)).padStart(2, '0') + '/' + String(new Date(d).getDate()).padStart(2, '0'));
				}
			}
		}
		
		weeks.push({
			date_x: weekDiff[0] + '-<br>' + weekDiff[weekDiff.length - 1],
			usage: getSumValueByRangeDate(
				weekDiff.reverse(), arr, fieldSum
				),
			date: weekDiff[0] + '-' + weekDiff[weekDiff.length - 1]
		});

		week = [];
	}

	return weeks;
};