import React from 'react';
import {
  toast,
  Theme,
  ToastPosition,
  DraggableDirection
} from 'react-toastify';

import { language } from 'language';
import { renderToastIcon } from 'components/ToastContainer';

import { capitalizeFirstLetter } from './misc';

type ConfigProps = {
  theme: Theme;
  position: ToastPosition;
  hideProgressBar: boolean;
  draggable: boolean;
  draggablePercent: number;
  draggableDirection: DraggableDirection;
  autoClose: number | false;
  icon?: React.ReactNode | false;
};

const defaultConfig: ConfigProps = {
  theme: 'colored',
  position: toast.POSITION.TOP_CENTER,
  hideProgressBar: true,
  draggable: true,
  draggablePercent: 60,
  draggableDirection: 'y',
  autoClose: 4000
};

export const success = (message: string) => {
  toast.success(capitalizeFirstLetter(message), {
    ...defaultConfig,
    icon: renderToastIcon('success')
  });
};

export const error = (message: string) => {
  toast.error(capitalizeFirstLetter(message), {
    ...defaultConfig,
    icon: renderToastIcon('error')
  });
};

export const warning = (message: string) => {
  toast.warning(capitalizeFirstLetter(message), defaultConfig);
};

export const info = (message: string) => {
  toast.info(capitalizeFirstLetter(message), defaultConfig);
};

export const errorDefaultApi = () => {
  error(language.errorDefault.api);
};
