import React from 'react';

import { Icon } from 'components';
import { Colors } from 'consts';

import { StyledContainer } from './style';

export const renderToastIcon = (type: string) => {
	const propsIcon = {
		size: 15,
		fill: Colors.white.default,
		containerSize: 30
	};

	switch (type) {
		case 'success':
			return (
				<Icon
					iconName='check'
					containerColor={ Colors.green.isPrimaryGreen }
					container='circle'
					{ ...propsIcon }
				/>
			);
		case 'error':
			return (
				<Icon
					iconName='close'
					container='circle'
					containerColor={ Colors.red.isRed }
					{ ...propsIcon }
				/>
			);
	}
};

const CustomToastContainer: React.FC = () => {
	return (
		<StyledContainer
			autoClose={ 4000 }
			closeButton={ false }
			newestOnTop
		/>
	);
};

export default CustomToastContainer;