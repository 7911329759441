import React from 'react';

import { SpacerStyle } from './style';

type SpacerProps = {
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  m?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  p?: number;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
};

const Spacer: React.FC<SpacerProps> = ({
  children,
  style,
  className,
  mt,
  mb,
  ml,
  mr,
  m,
  pt,
  pb,
  pl,
  pr,
  p,
  onClick
}) => {
  return (
    <SpacerStyle
      mt={ mt || 0 }
      mb={ mb || 0 }
      ml={ ml || 0 }
      mr={ mr || 0 }
      m={ m }
      pt={ pt || 0 }
      pb={ pb || 0 }
      pl={ pl || 0 }
      pr={ pr || 0 }
      p={ p }
      style={ style }
      className={ className }
      onClick={ onClick }
    >
      { children }
    </SpacerStyle>
  );
};

export default Spacer;
