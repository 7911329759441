import { AdminLogin } from 'interfaces/auth';

import {
  LOGIN,
  LOGOUT,
  AuthActionTypes
} from './actionTypes';

interface AuthState {
  user: AdminLogin,
  saveToken: boolean;
}

const initState: AuthState = {
  user: {
    username: '',
    role_name: '',
    showroom_id: 0,
    access_token: '',
    refresh_token: '',
    user_id: 0,
    status: '',
    is_active: true,
    permissions: [],
    image: null,
  },
  saveToken: false
};

const userReducer = (state = initState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.data.user,
        saveToken: action.data.saveToken
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

export default userReducer;
