import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import { useParams } from 'react-router-dom';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { onValue, ref } from 'firebase/database';
import moment from 'moment';

import {
  Container,
  Text,
  Image,
  Box,
  Spacer,
  Icon,
  MapsLeaflet,
  Input,
  ButtonContact,
  Button,
  Line,
  ModalConfirm,
  EmptyState,
  Loader,
} from 'components';
import { convertColorStatus } from 'components/DataTable/helpers';
import { getReverseGeocoding } from 'components/MapsLeaflet/OSMProvider';
import {
  Colors,
  Images,
  MapConfig
} from 'consts';
import {
  navigation,
  hooks,
  screen,
  toastify,
  misc
} from 'helpers';
import {
  MapsLocation,
  MarkerProps,
  RangeDateTime,
  ReducerList
} from 'interfaces/common';
import { BatteryStation, StationType } from 'interfaces/station';
import { language } from 'language';
import {
  DataDetailTripHistory,
  DataTripHistory,
  ScooterInfo,
  UserScooter
} from 'interfaces/scooter';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';
import ScooterPointSvg from 'assets/images/icon/scooter-point.svg';
import { db } from 'utils/firebase';

import {
  LiveTrackingStyle,
  BoxUserInfoStyle,
  BottomSheetStyle,
  BoxMissingStateStyle
} from './style';

import { BoxTripHistory } from '../ScooterDetail/components';

type RiderType = 'Main Rider' | 'Ride By';

interface RouteParams {
  scooterId: string;
}

const inputPropsData = [
  {
    placeholder: 'From',
    key: 'date_from'
  }, {
    placeholder: 'To',
    key: 'date_to'
  }
];

const LiveTracking: React.FC = () => {
  const params = useParams<RouteParams>();
  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const dispatch = hooks.useAppDispatch();
  const getScooterDetail = dispatch(actions.getScooterDetail);
  const getAllBatteryStations = dispatch(actions.getAllBatteryStations);
  const getTripHistory = dispatch(actions.getTripHistory);

  const scooterDetail = hooks.useAppSelector(selectors.scooter.scooterDetail);
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const divRightRef = useRef<HTMLDivElement>(null);
  const sheetRef = useRef<BottomSheetRef>(null);

  const [divRightHeight, setDivRightHeight] = useState<number>(820);
  const [modalInfoVisible, setModalInfoVisible] = useState<boolean>(false);
  const [dataMainRider, setDataMainRider] = useState<UserScooter>({
    user_id: 0,
    name: '',
    email: '',
    phone_number: '',
    profile_img: ''
  });
  const [dataCurrentRider, setDataCurrentRider] = useState<UserScooter>({
    user_id: 0,
    name: '',
    email: '',
    phone_number: '',
    profile_img: ''
  });
  const [formDate, setFormDate] = useState<RangeDateTime>({
    date_from: '',
    date_to: ''
  });
  const [sendLocToUser, setSendLocToUser] = useState<boolean>(false);
  const [realtimeLocation, setRealtimeLocation] = useState<MapsLocation[]>([]);
  const [tripHistory, setTripHistory] = useState<DataTripHistory[]>([]);
  const [isSubscribeLocation, setIsSubscribeLocation] = useState<boolean>(true);
  const [callReverseGeocode, setCallReverseGeocode] = useState<boolean>(false);
  const [batteryStationList, setBatteryStationList] = useState<MarkerProps[]>([]);
  const [polylineColorOptions, setPolylineColorOptions] = useState<string[]>([]);

  useEffect(() => {
    if (params?.scooterId) {
      getScooterDetail(params?.scooterId, (scooter: ScooterInfo) => {
        if (scooter?.parent) {
          setDataMainRider(scooter?.parent);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (tripHistory?.length) {
      setPolylineColorOptions(tripHistory?.map(() => misc.getRandColor()));
    }
  }, [tripHistory?.length]);

  useEffect(() => {
    if (!isSubscribeLocation) {
      setRealtimeLocation([]);
      return;
    }

    const query = ref(db, `VIN-${ params?.scooterId }/location`);
    return onValue(
      query, snapshot => {
        const data = snapshot.val();

        if (snapshot.exists()) {
          const lat = data?.latitude;
          const lng = data?.longitude;
          if (lat === 0 && lng === 0) {
            setModalInfoVisible(true);
          } else {
            setRealtimeLocation(prevData => ([
              ...prevData, {
                lat: data?.latitude,
                lng: data?.longitude,
              }
            ]));

            if (!callReverseGeocode) setCallReverseGeocode(true);
          }
        }
      }, error => {
        // eslint-disable-next-line no-undef, no-console
        console.log('[Error subscribe realtime database]', JSON.stringify(error));
      }
    );
  }, [isSubscribeLocation]);

  useEffect(() => {
    if (!scooterDetail?.support_fingerprint) {
      return;
    }

    const query = ref(db, `VIN-${ params?.scooterId }/driver`);
    return onValue(
      query, snapshot => {
        const data = snapshot.val();

        if (snapshot.exists()) {
          setDataCurrentRider({
            user_id: data?.user_id,
            name: data?.name,
            profile_img: data?.profile_img,
            status: data?.status,
            email: data?.email ?? '',
            phone_number: data?.phone_number ?? ''
          });
        }
      }, error => {
        // eslint-disable-next-line no-undef, no-console
        console.log('[Error subscribe realtime database]', JSON.stringify(error));
      }
    );
  }, [scooterDetail?.support_fingerprint]);

  const getReverseGeocodingFn = () => {
    const position: MapsLocation | null = realtimeLocation.length
      ? realtimeLocation[realtimeLocation.length - 1]
      : null;

    if (position) {
      getReverseGeocoding(position, res => {
        if (res.city) {
          getAllBatteryStations({
            page: 1,
            limit: 100,
            city: res.city?.toUpperCase(),
            type: StationType.battery_station
          }, (data: BatteryStation[]) => {
            if (data && data?.length) {
              const mapsStationList = data?.map((station: BatteryStation) => ({
                id: station.id,
                label: station.name,
                detail: station.address,
                lat: + station.latitude,
                lng: + station.longitude
              }));

              setBatteryStationList(mapsStationList);
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    if (callReverseGeocode) {
      getReverseGeocodingFn();
    }
  }, [callReverseGeocode]);

  const onClickSubmitFilterDate = () => {
    if (!formDate.date_from || !formDate.date_to) {
      toastify.error('Start date and end date is required');
    } else {
      getTripHistory(
        {
          vin: + params?.scooterId,
          page: 1,
          limit: 100,
          sort: 'desc',
          order: 'created_date',
          start_date: formDate.date_from,
          end_date: formDate.date_to
        },
        (updatedTripHistory: ReducerList<DataTripHistory[]>) => {
          setIsSubscribeLocation(false);
          setTripHistory(updatedTripHistory?.data ?? []);

          if (!updatedTripHistory?.data?.length) {
            toastify.error('Data tidak ditemukan. Silakan untuk mengganti range filter atau cek lokasi saat ini.');
          }
        },
        'mapsTripHistory'
      );
    }
  };

  const handleButtonSheet = () => {
    if (sheetRef.current) {
      if (sheetRef.current.height > 120) {
        sheetRef.current.snapTo(({ snapPoints }) => snapPoints[0]);
      } else {
        sheetRef.current.snapTo(({ snapPoints }) => snapPoints[1]);
      }
    }
  };

  const resizeHandler = () => {
    if (divRightRef.current) {
      setDivRightHeight(divRightRef.current.clientHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const renderBtnContact = (phone: string) => {
    if (phone) {
      if (windowDimensions.width > screen.isMobile) {
        return <ButtonContact phone={ phone } />;
      } else {
        return (
          <Icon
            size={ 16 }
            iconName='phone'
            fill={ Colors.white.default }
            hoverFill={ Colors.blue.isBlue }
            container='square'
            containerColor={ Colors.blue.isBlue }
            containerHoverColor={ Colors.white.default }
            containerRadius={ 8 }
          />
        );
      }
    }

    return null;
  };

  const renderTitleSubContent = (text: string, size: string) => {
    return <Text size={ size } weight={ 700 }>{ text }</Text>;
  };

  const renderContentBoxUserInfo = (widthBox: number, dataType: RiderType) => {
    const data = dataType === 'Main Rider' ? dataMainRider : dataCurrentRider;
    const containerWidth = widthBox * 0.475;
    const loadingScooter = misc.isLazyLoading(lazyLoad, 'scooterDetail');

    if (loadingScooter) {
      return (
        <BoxUserInfoStyle containerTextWidth={ containerWidth }>
          <Loader className='col center-content' style={ { height: 80 } } />
        </BoxUserInfoStyle>
      );
    }

    if (!data?.user_id || data?.user_id <= 0) {
      return (
        <BoxUserInfoStyle containerTextWidth={ containerWidth }>
          <EmptyState
            background={ { height: 80 } }
            image={ {
              src: Images.emptyState.users,
              width: 25,
              height: 25
            } }
            text='No rider'
          />
        </BoxUserInfoStyle>
      );
    }

    return (
      <BoxUserInfoStyle containerTextWidth={ containerWidth }>
        <div className='justify-align-center'>
          <div className='flex row align-center'>
            <Image
              src={ data?.profile_img }
              width={ 60 }
              height={ 60 }
              className='img-user-info'
              type='circle'
              external={ !!data?.profile_img }
            />
            <Spacer ml={ 15 } className='user-data-container wrap'>
              <Text
                weight={ 700 }
                lineHeight={ 17 }
                mb={ 5 }
                className='p1 wrap'
              >{ data.name }</Text>
              <Text
                color={ Colors.grey.isGrey }
                mb={ 5 }
                className='p1 wrap'
              >{ data.email }</Text>
              <Text color={ Colors.blue.isBlue } className='p1 wrap'>{ data.phone_number }</Text>
            </Spacer>
          </div>
          <div className='col col-end'>
            { renderBtnContact(data.phone_number) }
          </div>
        </div>
      </BoxUserInfoStyle>
    );
  };

  const renderBoxUserInfo = (widthBox: number, dataType: RiderType) => {
    return (
      <Box padding={ 20 } width={ widthBox }>
        <div className='mb3'>
          { renderTitleSubContent(dataType, 'm') }
        </div>
        { renderContentBoxUserInfo(widthBox, dataType) }
      </Box>
    );
  };

  const renderDataRider = (dataType: RiderType, widthBox: number) => {
    return (
      <div className={ dataType === 'Main Rider' ? '' : 'mt4' }>
        { renderBoxUserInfo(widthBox, dataType) }
      </div>
    );
  };

  const renderTextInfoScooter = (
    title: string,
    value: string,
    rightColor: string = Colors.black.isBlack
  ) => {
    return (
      <div className='justify-align-center mt3'>
        <Text color={ Colors.grey.isGrey } mr={ 20 }>{ title }</Text>
        <Text
          weight={ title === 'Status' ? 700 : 500 }
          color={ rightColor }
          align='right'
        >{ value }</Text>
      </div>
    );
  };

  const renderStateMissing = () => {
    if (scooterDetail.status === language.scooters.missing) {
      return (
        <div className='flex row align-center ml3'>
          <Icon
            size={ 12 }
            iconName='alert'
            fill={ Colors.red.isRed }
            className='default'
          />
          <Text
            weight={ 700 }
            color={ Colors.red.isRed }
            align='right'
            ml={ 5 }
          >Missing</Text>
        </div>
      );
    }

    return null;
  };

  const renderContentBoxScooterDetail = () => {
    return (
      <>
        <div className='justify-align-center'>
          <div className='flex row align-center'>
            <Text
              size='m'
              weight={ 700 }
              lineHeight={ 19 }
            >SAVART</Text>
            { renderStateMissing() }
          </div>
        </div>
        <div className='justify-align-center mt4'>
          <div className='center-content container-img-scooter'>
            <Image
              src={ scooterDetail?.images
                ? scooterDetail?.images
                : Images.scooter1 }
              width={ 118 }
              height={ 111 }
              className='img-scooter'
              external={ !!scooterDetail?.images }
            />
          </div>
          <div className='container-info-scooter'>
            { renderTextInfoScooter('VIN', scooterDetail?.vin?.toString()) }
            { renderTextInfoScooter('Type', scooterDetail?.type ?? '-') }
            { renderTextInfoScooter('Color', scooterDetail?.color ?? '-') }
            { renderTextInfoScooter('Plat Number', scooterDetail?.plat_number ?? '-') }
            { renderTextInfoScooter(
              'Status',
              scooterDetail?.status,
              convertColorStatus(scooterDetail?.status)
            ) }
          </div>
        </div>
      </>
    );
  };

  const renderBoxscooterDetail = widthBox => {
    return (
      <Box
        padding={ 20 }
        width={ widthBox }
        mt={ 20 }
      >
        { renderContentBoxScooterDetail() }
      </Box>
    );
  };

  const renderBoxCheckPoint = widthBox => {
    return (
      <>
        <BoxTripHistory widthBox={ widthBox } routeId={ params?.scooterId } />
      </>
    );
  };

  const renderExpandedBottomSheet = (show: boolean) => {
    return (
      <div className='container-content'>
        {
          show
            ? renderContentBoxScooterDetail()
            : <div />
        }
      </div>
    );
  };

  const renderHeaderBottomSheet = () => {
    if (scooterDetail.parent) {
      return (
        <BottomSheetStyle>
          <div className='container-header'>
            { renderContentBoxUserInfo(windowDimensions.width, 'Ride By') }
          </div>
        </BottomSheetStyle>
      );
    }

    return renderExpandedBottomSheet(true);
  };

  const renderBtnCheckCurrentLoc = () => {
    return (
      <div className='row-end mt2 mb2'>
        <Button
          className='btn-submit'
          onClick={ () => {
            setIsSubscribeLocation(true);
            setFormDate({
              date_from: '',
              date_to: ''
            });
          } }
          text='Check Current Location'
        />
      </div>
    );
  };

  const renderTrackingMaps = (
    width: string,
    height: string,
    containerStyle?: React.CSSProperties
  ) => {
    const multiplePolylinePath = !isSubscribeLocation
      ? tripHistory?.map((tripHistory: DataTripHistory, tripIdx: number) => ({
        position: tripHistory?.location?.reduce((res: MapsLocation[], loc: DataDetailTripHistory) => {
          if (loc.latitude && loc.longitude) {
            res.push({
              lat: loc.latitude,
              lng: loc.longitude
            });
          }

          return res;
        }, []),
        color: polylineColorOptions[tripIdx]
      }))?.filter(polylinePath => polylinePath.position?.length)
      : [];
    const polylinePath = isSubscribeLocation
      ? realtimeLocation
      : [];
    const center: MapsLocation = polylinePath.length
      ? polylinePath[polylinePath.length - 1]
      : (multiplePolylinePath[0]?.position[0] ?? MapConfig.defaultCenter);

    const markers = !isSubscribeLocation
      ? tripHistory?.map((tripHistory: DataTripHistory, tripIdx: number) => {
        const originMarker = tripHistory?.location?.[0]?.latitude && tripHistory?.location?.[0]?.longitude
          ? [
            {
              lat: tripHistory?.location?.[0]?.latitude,
              lng: tripHistory?.location?.[0]?.longitude,
              label: `Trip ${ tripHistory?.id } origin`,
              detail: `${ tripHistory?.start_time }`,
              iconHtml: `
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <circle opacity="0.1" cx="12" cy="12" r="12" fill="${ polylineColorOptions[tripIdx] }"/>
              <circle cx="12" cy="12" r="6" fill="${ polylineColorOptions[tripIdx] }"/>
            </svg>
          `,
            }
          ]
          : [];
        const destinationMarker = tripHistory?.location?.[tripHistory?.location?.length - 1]?.latitude && tripHistory?.location?.[tripHistory?.location?.length - 1]?.longitude
          ? [
            {
              lat: tripHistory?.location?.[tripHistory?.location?.length - 1]?.latitude,
              lng: tripHistory?.location?.[tripHistory?.location?.length - 1]?.longitude,
              label: `Trip ${ tripHistory?.id } destination`,
              detail: `${ tripHistory?.end_time }`,
              iconHtml: `
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M11.5 22C10.1467 20.8032 8.89232 19.4911 7.74998 18.0775C6.03571 15.9546 4 12.793 4 9.7803C3.99848 6.63378 5.82597 3.79641 8.62969 2.59222C11.4334 1.38803 14.6607 2.05438 16.8057 4.28032C18.2161 5.73625 19.0063 7.71671 19 9.7803C19 12.793 16.9642 15.9546 15.2499 18.0775C14.1076 19.4911 12.8532 20.8032 11.5 22ZM11.5 6.44765C10.3516 6.44765 9.2905 7.08285 8.71633 8.11398C8.14215 9.1451 8.14215 10.4155 8.71633 11.4466C9.2905 12.4777 10.3516 13.1129 11.5 13.1129C13.2752 13.1129 14.7142 11.6209 14.7142 9.7803C14.7142 7.93973 13.2752 6.44765 11.5 6.44765Z" fill="${ polylineColorOptions[tripIdx] }"/>
            </svg>
          `
            }
          ]
          : [];

        return [...originMarker, ...destinationMarker];
      })
      : [];

    return (
      <MapsLeaflet
        center={ center }
        multipleMarkerPaths={ [...batteryStationList, ...misc.flatten(markers ?? [])] }
        width={ width }
        height={ height }
        zoom={ 18 }
        containerStyle={ containerStyle }
        iconRetinaUrl={ ScooterPointSvg }
        iconUrl={ ScooterPointSvg }
        iconPolylineUrl={ Images.maps.marker_scooter }
        polylinePath={ polylinePath }
        withGeocodingReverse={ false }
        withMarkerCenter={ isSubscribeLocation }
        multiplePolylinePath={ multiplePolylinePath }
      />
    );
  };

  const onChangeDate = (value: string, key: string) => {
    const rangeDateNotValid = key === 'date_to' && moment(value) < moment(formDate.date_from)
      || key === 'date_from' && moment(value) > moment(formDate.date_to);

    if (rangeDateNotValid) {
      toastify.error('End date must be greater than start date');
    }

    setFormDate(prevForm => {
      if (rangeDateNotValid) {
        return {
          ...prevForm,
          [key]: ''
        };
      }

      return {
        ...prevForm,
        [key]: value
      };
    });
  };

  const renderInput = (placeholder: string, key: string) => {
    const dateNow = moment(Date.now());

    return (
      <Input
        type='date'
        placeholder={ placeholder }
        labelColor={ Colors.grey.isGrey }
        value={ formDate[key] }
        name={ key }
        onChangeDate={ (value: string) => onChangeDate(value, key) }
        defaultPickerValue={ dateNow }
        backgroundColor={ Colors.white.default }
      />
    );
  };

  const renderDotLine = (index: number) => {
    if (index % 2 === 1) {
      return (
        <Line
          lineWidth={ 3 }
          lineStyle='dotted'
          lineColor={ Colors.grey.isGreyGreen }
          width={ 20 }
          height={ 5 }
          margin='0 5px'
          className='dot'
        />
      );
    }

    return null;
  };

  const renderFormDate = () => {
    const loadingFilterTrip = misc.isLazyLoading(lazyLoad, 'mapsTripHistory');

    return (
      <div className='container-form-date'>
        <div className='flex row align-center'>
          {
            inputPropsData.map((input, index) => {
              return (
                <div key={ index } className='flex row align-center'>
                  { renderDotLine(index) }
                  { renderInput(input.placeholder, input.key) }
                </div>
              );
            })
          }

          <div className='ml2'>
            <Button
              onClick={ onClickSubmitFilterDate }
              className='btn-submit'
              text='Submit'
              isLoading={ loadingFilterTrip }
              disabled={ loadingFilterTrip || !formDate?.date_from || !formDate?.date_to }
            />
          </div>
        </div>
      </div>
    );
  };

  const renderLocationSent = () => {
    return (
      <div className='flex row align-center'>
        <Icon
          size={ 12 }
          iconName='check'
          fill={ Colors.white.default }
          container='circle'
          containerSize={ 18 }
          containerColor={ Colors.green.isPrimaryGreen }
        />
        <Text
          size='m'
          weight={ 700 }
          ml={ 10 }
          color={ Colors.green.isPrimaryGreen }
        >Location Successfully Sent</Text>
      </div>
    );
  };

  const renderButtonSendLoc = () => {
    return (
      <Button
        borderRadius={ 10 }
        onClick={ () => setSendLocToUser(true) }
        className='btn-send-loc'
        text='Send Current Location to User'
      />
    );
  };

  const renderBoxMissingState = (widthBox: number) => {
    const boxPadding = windowDimensions.width <= screen.isMobile ? 20 : 25;

    return (
      <Box
        width={ widthBox }
        padding={ boxPadding }
        shadow={ `0 5px 20px 0 ${ Colors.black.isShadow2 }` }
      >
        <div className='justify-align-center'>
          <div className='flex row align-center'>
            <Image
              src={ Images.icons.thief }
              width={ 60 }
              height={ 60 }
              className='image-thief'
            />
            <Spacer ml={ 15 }>
              <Text
                size='l'
                color={ Colors.red.isRed }
                weight={ 700 }
                className='p1 title-missing'
                mb={ 10 }
              >This Scooter is Currently Missing!</Text>
              <Text
                size='m'
                color={ Colors.grey.isGreyGreen }
                className='p1 note-missing'
              >Notify the user if the scooter has completely stopped.</Text>
            </Spacer>
          </div>
          {
            sendLocToUser
              ? renderLocationSent()
              : renderButtonSendLoc()
          }
        </div>
      </Box>
    );
  };

  const renderMissingState = (widthSection: number, heightMap: number) => {
    if (scooterDetail.status === language.scooters.missing) {
      return (
        <BoxMissingStateStyle top={ heightMap - 50 }>
          <div className='box-container'>
            { renderBoxMissingState(widthSection - 20) }
          </div>
        </BoxMissingStateStyle>
      );
    }

    return null;
  };

  const renderContent = () => {
    const { width: screenWidth, height: screenHeight } = windowDimensions;
    let widthSection = {
      '1': screenWidth - 95,
      '2': screenWidth - 85
    };
    let heightMap = divRightHeight - 40;

    if (screenWidth <= screen.isMobile) {
      heightMap = screenHeight - 70;

      return (
        <div onClick={ handleButtonSheet } style={ { position: 'relative' } }>
          { renderTrackingMaps(
            `${ screenWidth }px`,
            `${ heightMap }px`,
            { zIndex: 0 }
          ) }
          <div className='floatting-form-date'>
            { renderBtnCheckCurrentLoc() }
            { renderFormDate() }
          </div>
          <BottomSheet
            open
            blocking={ false }
            ref={ sheetRef }
            scrollLocking={ false }
            snapPoints={ ({ headerHeight, maxHeight }: { headerHeight: number, maxHeight: number; }) => [
              headerHeight,
              (maxHeight - 56) * 0.65,
              maxHeight * 0.6
            ] }
            header={ renderHeaderBottomSheet() }
          >
            <BottomSheetStyle>
              { renderExpandedBottomSheet(!!scooterDetail.parent) }
              {
                scooterDetail.status === language.scooters.missing ? (
                  <div className='center-content mb3 mt3'>
                    { renderBoxMissingState(screenWidth) }
                  </div>
                ) : null
              }
            </BottomSheetStyle>
          </BottomSheet>
        </div>
      );
    } else {
      if (screenWidth >= screen.sizes.md2) {
        widthSection = {
          '1': ((screenWidth * 2) / 3) - 80,
          '2': (screenWidth / 3) - 20
        };
      } else if (screenWidth > screen.isMobile && screenWidth < screen.sizes.md2) {
        heightMap = screenHeight - 200;
      }

      return (
        <div className='content'>
          <div className='spacer-section'>
            <div className='justify-align-center mb4'>
              { renderTitleSubContent('Track Scooter', 'xl') }
              { renderFormDate() }
            </div>
            { renderTrackingMaps(
              `${ widthSection['1'] }px`,
              `${ heightMap }px`,
              { borderRadius: 10 }
            ) }
            { renderMissingState(widthSection['1'], heightMap) }
            { renderBtnCheckCurrentLoc() }
          </div>
          <div ref={ divRightRef } className='outer-container-right'>
            {
              scooterDetail?.parent ? (
                <>
                  { renderDataRider('Main Rider', widthSection['2']) }
                  { scooterDetail?.support_fingerprint && renderDataRider('Ride By', widthSection['2']) }
                </>
              ) : null
            }
            { renderBoxscooterDetail(widthSection['2']) }
            { renderBoxCheckPoint(widthSection['2']) }
          </div>
        </div>
      );
    }
  };

  const renderModalInfo = () => {
    return (
      <ModalConfirm
        visible={ modalInfoVisible }
        onCloseModal={ () => setModalInfoVisible(false) }
        onClickConfirm={ () => setModalInfoVisible(false) }
        confirmText='OK'
        title='Live Tracking Tidak Tersedia'
        content='Live tracking tidak tersedia karena Motor dalam keadaan mati. Silahkan gunakan filter untuk mencari data riwayat lokasi'
        withButtonCancel={ false }
        confirmButtonColor='blue'
      />
    );
  };

  return (
    <LiveTrackingStyle>
      <Container
        backgroundColor={ Colors.green.isMintGreen }
        navbar={ {
          logo: true,
          backButton: 'circle',
          backIconName: 'close',
          leftText: 'Close',
          leftClassName: 'left-navbar',
          onClickBack: () => navigation.goBack()
        } }
      >
        { renderContent() }
      </Container>

      { renderModalInfo() }
    </LiveTrackingStyle>
  );
};

export default LiveTracking;
