import { DischargeTempStatistic } from 'interfaces/battery';

export const getDataConverted = (arr: DischargeTempStatistic, filter: string) => {
    const progress = arr;
    
    const hash: Record<string, { arrCurrent: any[]; temperature: number; hours: string }> = Object.create(null);
    const grouped: { arrCurrent: any[]; temperature: number; hours: string }[] = [];

    Object.values(progress)?.map(items => {
        const key: string = filter === 'Last 24 Hours' ? items?.created_date?.slice(0, 13) : items?.created_date?.slice(0, 10);
        if (!hash[key]) {
            hash[key] = {
                arrCurrent: [0],
                hours: filter === 'Last 24 Hours' ? key + ':00' : key,
                temperature: 0,
            };
            grouped.push(hash[key]);
        }

        hash[key].arrCurrent.push(Number(items?.temperature?.toFixed(2)));
    });
      
    grouped.sort((a, b) => {
        return b.hours.localeCompare(a.hours);
    });

    const getMaxTemp = grouped.map(obj => {
        const arr = Object.values(obj.arrCurrent);
        return {
            ...obj,
            temperature: Number(Math.max(...arr).toFixed(2))
        };
        
    });

    return filter === 'Last 24 Hours' ? getMaxTemp.slice(0, 24).reverse() : getMaxTemp.slice(0, 7).reverse();
};