import React, { useState, useCallback } from 'react';

import { Colors } from 'consts';
import { FilterMenuSize } from 'typings';
import { CurrentPagination, SorterInfo } from 'interfaces/common';

import { DashboardStyle } from './style';

import Container, { ContainerProps } from '../Container';
import FilterMenu, { FilterMenuProps } from '../FilterMenu';
import DataTable, { ButtonActionProps } from '../DataTable';
import Pagination from '../Pagination';
import Box from '../Box';
import ModalForm, { ModalFormProps } from '../Modal/ModalForm';

type DashboardProps = {
  container?: ContainerProps;
  filterMenu?: FilterMenuProps;
  data: {
    currentData: any;
    loading: boolean;
    fieldName: string;
    getCurrentPagination?: (currentPagination: CurrentPagination<any>) => void;
  };
  table?: {
    columns: any;
    rowKey?: string;
    buttons?: ButtonActionProps[];
    permissionClickRow?: string | string[] | boolean;
    buttonAction?: (record: any) => JSX.Element; // eslint-disable-line no-undef
    onClickRow?: (record: any, rowIndex?: number) => void;
    onChange?: (sorterInfo: SorterInfo) => void;
  };
  modalForm?: ModalFormProps;
  modalLoader?: React.ReactNode;
  topLoadingBar?: React.ReactNode;
  content?: React.ReactNode;
  children?: React.ReactNode;
};

type VisibleFilter = {
  [key: string]: boolean;
};

const Dashboard: React.FC<DashboardProps> = ({
  container,
  filterMenu,
  data,
  table,
  content,
  modalForm,
  modalLoader,
  topLoadingBar,
  children
}) => {
  const visibledropdownsFilterFetchObj = filterMenu && filterMenu.dropdownsFilterFetch
    ? filterMenu?.dropdownsFilterFetch?.reduce((obj, item) => (obj[item.key] = false, obj), {})
    : null;
  const visibledropdownsFilterObj = filterMenu && filterMenu.dropdownsFilter
    ? filterMenu?.dropdownsFilter?.reduce((obj, item) => (obj[item.key] = false, obj), {})
    : null;

  const [visibleFilter, setVisibleFilter] = useState<VisibleFilter | null>({
    ...visibledropdownsFilterObj,
    ...visibledropdownsFilterFetchObj
  });

  const handleOnVisibleChange = useCallback((visible: boolean, keyFilter: string) => {
    setVisibleFilter(prevState => ({
      ...prevState,
      [keyFilter]: visible
    }));
  }, []);

  const renderFilterMenu = (size: FilterMenuSize) => {
    if (filterMenu) {
      const {
        dropdownsFilter,
        onClickMenuItem,
        ...restProps
      } = filterMenu;

      const onClickFilterMenuItem = (params: any, keyFilter: string) => {
        setVisibleFilter(prevState => ({
          ...prevState,
          [keyFilter]: false
        }));

        onClickMenuItem ? onClickMenuItem(params, keyFilter) : null;
      };

      const filterDropdownProps = filterMenu.dropdownsFilter
        ? {
          visibleFilter,
          dropdownsFilter,
          onVisibleChange: handleOnVisibleChange,
          onClickMenuItem: onClickFilterMenuItem
        }
        : { dropdownsFilter: [] };

      return (
        <FilterMenu
          dataTableSize={ size }
          { ...filterDropdownProps }
          { ...restProps }
        />
      );
    }

    return null;
  };

  const renderDataTable = (type: string) => {
    if (table) {
      const {
        currentData,
        loading,
        fieldName
      } = data;

      return (
        <DataTable
          type={ type }
          { ...table }
          currentData={ currentData }
          loading={ loading }
          name={ fieldName }
        />
      );

    }
  };

  const renderPagination = () => {
    if (data.getCurrentPagination) {
      const {
        currentData,
        loading,
        fieldName,
        getCurrentPagination
      } = data;

      return (
        <Pagination
          data={ currentData }
          fieldName={ fieldName }
          loading={ loading }
          getCurrentPagination={ getCurrentPagination }
        />
      );
    }

    return null;
  };

  const renderModalForm = () => {
    if (modalForm) {
      return <ModalForm { ...modalForm } />;
    }
  };

  const renderModalLoader = () => {
    if (modalLoader) {
      return modalLoader;
    }
  };

  const renderTopLoadingBar = () => {
    if (topLoadingBar) {
      return topLoadingBar;
    }
  };

  const renderDataTableWithFilter = () => {
    if (table) {
      return (
        <>
          { renderFilterMenu('small') }
          { renderDataTable('box') }
          <Box
            mt={ 20 }
            padding={ 0 }
            shadow={ `0px 2px 10px ${ Colors.black.isShadow }` }
          >
            { renderFilterMenu('large') }
            { renderDataTable('table') }
          </Box>
        </>
      );
    }

    return content;
  };

  const renderDashboardContent = () => {
    return (
      <DashboardStyle>
        { children }
        { renderDataTableWithFilter() }
        { renderPagination() }
        { renderModalForm() }
        { renderModalLoader() }
        { renderTopLoadingBar() }
      </DashboardStyle>
    );
  };

  if (!container) return renderDashboardContent();

  return (
    <Container { ...container } withDrawer>
      { renderDashboardContent() }
    </Container>
  );
};

export default Dashboard;
