import { Cities, } from 'interfaces/location';
import { Pagination } from 'interfaces/common';

import {
  GET_CITIES,
  LOGOUT,
	LocationActionTypes
} from './actionTypes';

interface LocationState {
  cities: {
    data: Cities[];
    pagination: Pagination;
  };
}

const initPagination: Pagination = {
  count: 0,
  limit: 0,
  offset: 0,
  order: '',
  page: 0,
  page_total: 0,
  search: '',
  sort: 'asc'
};

const initState: LocationState = {
  cities: {
    data: [],
    pagination: initPagination
  },
};

const locationReducer = (state = initState, action: LocationActionTypes): LocationState => {
  switch (action.type) {
    case GET_CITIES:
      return {
        ...state,
        cities: action.data
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

export default locationReducer;