import Styled from 'styled-components';

import { screen } from 'helpers';

export const ResetPasswordStyle: any = Styled.div`
  .left-navbar {
    padding-left: 40px;
  }

  .content {
    min-height: ${screen.height - 70}px;
  }

  .inner-content {
    width: 418px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .half-background-image {
    width: 100%;
    max-height: ${screen.height / 2}px;
  }

  @media ${screen.sizesMax.xs} {
    .left-navbar {
      padding-left: 20px;
    }

    .content {
      min-height: ${screen.height - (screen.height / 4)}px;
    }

    .inner-content {
      padding: 30px 20px 0px 20px;
      width: ${screen.width}px;
      height: ${screen.height - (screen.height / 2)}px;
    }
  }
`;
