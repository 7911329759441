import Styled from 'styled-components';

import { screen, hooks } from 'helpers';

type NotFoundStyleProps = {
  windowDimensions: hooks.Dimensions;
};

export const NotFoundStyle: any = Styled.div < NotFoundStyleProps > `
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .half-container {
    width: 50vw;
    min-height: ${props => props.windowDimensions.height}px;
  }

  .half-background-image {
    width: 50vw;
    height: ${props => props.windowDimensions.height}px;
  }

  .inner-container {
    padding: 40px;
  }

  @media ${screen.sizesMax.xs} {
    .content {
      flex-direction: column;
      justify-content: center;
      align-items: space-between;
    }

    .half-container {
      width: 100%;
      min-height: 50%;
    }

    .inner-container {
      height: ${props => props.windowDimensions.height / 1.34}px;
      width: ${props => props.windowDimensions.width}px;
    }

    .half-background-image {
      width: 100%;
      max-height: ${props => props.windowDimensions.height / 4}px;
    }
  }
`;
