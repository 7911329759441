import { Pagination } from 'interfaces/common';
import {
	DataTicketing,
	PastSimiliarCase,
	TicketingDetail
} from 'interfaces/ticketing';

export const GET_ALL_CASE = 'GET_ALL_CASE';
export const GET_DETAIL_CASE = 'GET_DETAIL_CASE';
export const GET_PAST_SIMILIAR_CASE = 'GET_PAST_SIMILIAR_CASE';
export const LOGOUT = 'LOGOUT';

export interface SetAllCase {
	type: typeof GET_ALL_CASE;
	data: {
		status: string;
		data: DataTicketing[];
		pagination: Pagination;
	};
}

export interface SetCase {
	type: typeof GET_DETAIL_CASE;
	data: TicketingDetail | null;
}

export interface SetPastSimiliarCase {
	type: typeof GET_PAST_SIMILIAR_CASE;
	data: PastSimiliarCase[];
}

export interface Logout {
	type: typeof LOGOUT;
}

export type CaseActionTypes =
	| SetAllCase
	| SetCase
	| SetPastSimiliarCase
	| Logout;
