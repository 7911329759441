import React from 'react';

type VerticalGridProps = {
  props: any;
  offsetPoint: number;
  lengthData?: number;
};

/* eslint-disable @typescript-eslint/no-unused-vars */

const CustomVerticalGrid: React.FC<VerticalGridProps> = ({
  props,
  offsetPoint,
  lengthData
}) => {
  const renderLineItem = (itemProps: any) => {
    const {
      x1,
      y1,
      x2,
      y2,
      key,
      horizontalPoints,
      verticalPoints,
      verticalFill,
      horizontalFill,
      chartWidth,
      xAxis,
      yAxis,
      chartHeight,
      horizontalCoordinatesGenerator,
      verticalCoordinatesGenerator,
      vertical,
      horizontal,
      ...others
    } = itemProps;

    const lineItem = (
      <line
        x1={ x1 || 1 }
        y1={ y1 }
        x2={ x2 || 1 }
        y2={ y2 }
        fill='none'
        key={ key }
        { ...others }
      />
    );

    return lineItem;
  };

  const renderVerticalGrid = () => {
    const {
      y,
      height,
      xAxis,
      chartWidth,
      chartHeight,
      offset,
      verticalCoordinatesGenerator
    } = props;

    const xAxisInterval = xAxis.interval;
    const verticalPoints1 = verticalCoordinatesGenerator({
      xAxis,
      width: chartWidth,
      height: chartHeight,
      offset
    })?.map(value => {
      if (lengthData && lengthData === 1) {
        return value;
      }

      return value - offsetPoint;
    })
      .slice(0, -2) ?? [];

    const verticalPoints2 = xAxisInterval > 0 ? verticalCoordinatesGenerator({
      xAxis,
      width: chartWidth,
      height: chartHeight,
      offset
    }).map(value => value + offsetPoint)
      .slice(0, -2)
      : [];

    const verticalPoints3 = xAxisInterval > 1 ? verticalCoordinatesGenerator({
      xAxis,
      width: chartWidth,
      height: chartHeight,
      offset
    }).map(value => value + (offsetPoint * xAxisInterval * 1.75))
      .slice(0, -2)
      : [];

    const verticalPoints = [
      ...verticalPoints1,
      ...verticalPoints2,
      ...verticalPoints3
    ];

    const items = verticalPoints.map((entry, i) => {
      const itemProps = {
        ...props,
        x1: entry,
        y1: y,
        x2: entry,
        y2: y + height,
        key: `line-${ i }`,
        index: i,
      };

      return renderLineItem(itemProps);
    });

    return <g className='recharts-cartesian-grid-vertical'>{ items }</g>;
  };

  return renderVerticalGrid();
};

export default CustomVerticalGrid;
