import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

type UploadStyleProps = {
  width: number;
  height: number | string;
  borderRadius: number | string;
  marginVertical: number;
  bgColor?: string;
};

export const UploadStyle: any = Styled.div < UploadStyleProps > `
  display: flex;
  align-items: center;
  justify-content: center;

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-uploader > .ant-upload {
    background-color: ${ props => props.bgColor || Colors.grey.isBgGrey };
    border-radius: ${ props => typeof (props.borderRadius) === 'number' ? `${ props.borderRadius }px` : props.borderRadius };
    width: ${ props => props.width }px;
    height: ${ props => typeof (props.height) === 'number' ? `${ props.height }px` : props.height };
    margin: ${ props => props.marginVertical }px 0;

    &:hover {
      border-color: ${ Colors.blue.isBlue };
    }
  }

  .uploaded-image-wrap {
    display: inline-block;
    position: relative;
    border-radius: ${ props => typeof (props.borderRadius) === 'number' ? `${ props.borderRadius }px` : props.borderRadius };
    width: ${ props => props.width }px;
    height: ${ props => typeof (props.height) === 'number' ? `${ props.height }px` : props.height };
  }

  .uploaded-image-wrap .overlay {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    color: white;
    opacity: 0;
    transition: opacity .5s ease;
    background-color: ${ Colors.black.default };
    border-radius: ${ props => typeof (props.borderRadius) === 'number' ? `${ props.borderRadius }px` : props.borderRadius };
  }
  
  .uploaded-image-wrap:hover .overlay {
    opacity: 0.75;
  }

  .ic-overlay-antd {
    font-size: 18px;
    color: ${ Colors.white.default };
  }

  .uploaded-image-multiple {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 13px;
    row-gap: 10px;
    width: 100%;
    height: 100%;
  }

  @media ${ screen.sizesMax.xs } {
    .avatar-uploader > .ant-upload {
      margin: ${ props => props.marginVertical >= 10 ? props.marginVertical - 10 : 0 }px 0;
    }
  }
`;