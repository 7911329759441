import React from 'react';

import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';

import { Colors } from 'consts';

import CustomTooltip from '../Tooltip';
import CustomVerticalGrid from '../VerticalGrid';
import CustomizedAxisTick from '../AxisTick';
import { propsFont, propsDefaultAxis } from '../index';

type CustomScatterChartProps = {
  width?: number;
  height?: number;
  data: any;
  valueDomain?: number[];
  unit?: string;
  convertLabel?: (label: string, payload: any) => string;
  convertLabelValue?: (value: string) => string;
  dataKey: {
    xAxis: string;
    yAxis: string;
  };
  margin?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  maxCharsLabel?: number | false;
  heightAxis?: number;
  tickInterval?: number;
};

const CustomScatterChart: React.FC<CustomScatterChartProps> = ({
  width,
  height,
  data,
  valueDomain,
  unit,
  convertLabel,
  convertLabelValue,
  dataKey,
  margin,
  maxCharsLabel,
  heightAxis,
  tickInterval
}) => {
  const renderCustomTooltip = (props: any) => {
    const {
      active,
      payload,
      label
    } = props;
    
    if (active && payload) {
      return (
        <CustomTooltip
          label={ convertLabel
            ? convertLabel(label, payload[0]?.payload)
            : label
          }
          value={
            convertLabelValue
            ? convertLabelValue(`${payload[1]?.value}${unit}`)
            : `${payload[1]?.value}${unit}`
          }
        />
      );
    }
  
    return null;
  };

  const renderVerticalGrid = (props: any) => {
    const { chartWidth } = props;

    const dotSpacing = chartWidth / (data.length - 1);
    const offsetPoint = dotSpacing / 2.75;

    return (
      <CustomVerticalGrid
        key={ props.key }
        props={ props }
        offsetPoint={ offsetPoint }
      />
    );
  };

  return (
    <ScatterChart
      width={ width }
      height={ height }
      margin={ margin }
    >
      <CartesianGrid
        horizontal={ false }
        stroke={ Colors.grey.isLightGrey }
        strokeWidth={ 0.5 }
        vertical={ renderVerticalGrid }
      />

      <XAxis
        dataKey={ dataKey.xAxis }
        type='category'
        interval={ tickInterval }
        height={ heightAxis }
        tick={ props => <CustomizedAxisTick props={ props } maxChars={ maxCharsLabel } /> }
        { ...propsFont }
        { ...propsDefaultAxis }
      />
      <YAxis
        dataKey={ dataKey.yAxis }
        domain={ valueDomain }
        unit={ unit }
        padding={ { top: 10 } }
        { ...propsFont }
        { ...propsDefaultAxis }
      />

      <Tooltip
        wrapperStyle={ { outline: 'none' } }
        content={ renderCustomTooltip }
        cursor={ {
          fill: Colors.grey.isLightGrey,
          strokeDasharray: '5 5'
        } }
      />

      <Scatter data={ data } fill={ Colors.blue.isBlue } />
    </ScatterChart>
  );
};

CustomScatterChart.defaultProps = {
  valueDomain: [0, 100],
  unit: '',
  width: 200,
  height: 300,
  heightAxis: 40,
  tickInterval: 0
};

export default CustomScatterChart;
