import Styled from 'styled-components';

export const UsersStyle: any = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemDropdown: any = Styled.div`
	display: flex;
	padding: 10px 10px 10px 0;

	.ant-select-item-option-content {
		white-space: normal !important;
	}

	.container-icon {
		flex-shrink: 0;
		margin-right: 11px;
	}

	.item-text-wrapper {
		display: flex;
		width: 100%;
		flex-direction: column;
		row-gap: 10px;
	}

	.description-text {
		overflow-wrap: break-word;
		white-space: normal;
	}
`;