import React from 'react';

import {
  Label,
  Input,
  Slider
} from './style';

type SwitchProps = {
  checked?: boolean;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
  title?: string;
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'lg';
};

const ToggleSwitch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  name,
  id,
  disabled,
  title,
  size
}) => {
  return (
    <Label
      htmlFor={ id }
      disabled={ disabled }
      title={ title }
      size={ size }
    >
      <Input
        id={ id }
        type='checkbox'
        name={ name || `switch${Math.floor(Math.random() * 100)}` }
        disabled={ disabled }
        checked={ checked }
        onChange={ onChange }
      />
      <Slider />
    </Label>
  );
};

export default ToggleSwitch;
