import Styled from 'styled-components';

import { Colors } from 'consts';

type DividerStyleProps = {
  marginVertical?: number;
  marginHorizontal?: number;
  color?: string;
};

export const DividerStyle: any = Styled.div < DividerStyleProps > `
  .margin-divider {
    margin: ${ props => `${ props.marginVertical || 0 }px ${ props.marginHorizontal || 0 }px` };
  }
  
  .ant-divider {
    color: ${ props => props.color || Colors.grey.isLighterGrey } !important;
    border-top: 1px solid ${ props => props.color || Colors.grey.isLighterGrey } !important;
  }
`;