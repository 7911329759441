import * as toastify from './toastify';

export default {
  username: (username: string) => {
    const re = /[^0-9a-zA-Z]/;

    return username.length > 5 && !(re.test(username));
  },

  email: (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  },

  phoneNumber: (phone: string) => {
    // format phone : 0... => min. 10 digit
    const re = /^\d+$/;

		return !!(phone.length > 9 && phone.length < 20 && re.test(phone));
  },

  passwordLength: (password: string) => {
		return !(password.length < 4);
	},

  passwordMatch: (password: string, confirmPassword: string) => {
    return password === confirmPassword;
  },

  uploadImage: (file: File, withToastError = true) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 1;
  
    if (!isJpgOrPng) {
      if (withToastError) toastify.error('You can only upload JPG/PNG file!');
  
      return false;
    }
  
    if (!isLt2M) {
      if (withToastError) toastify.error('Image must smaller than 1MB!');
  
      return false;
    }
  
    return true;
  }
};
