import React from 'react';
import { Skeleton } from 'antd';
import { SkeletonTitleProps } from 'antd/lib/skeleton/Title';

type Size = 'large' | 'small' | 'default';
type SkeletonProps = {
  children: React.ReactNode;
  loading: boolean;
  avatar?: {
    shape?: 'circle' | 'square';
    size?: number | Size;
  };
  title?: boolean | SkeletonTitleProps;
  paragraph?: {
    rows?: number;
    width?: number | string | number[] | string[];
  };
  button?: {
    shape?: 'circle' | 'round' | 'square';
    size?: Size;
  };
  multiple?: boolean;
  style?: React.CSSProperties;
  className?: string;
  mt?: number;
  mb?: number;
};

const CustomSkeleton: React.FC<SkeletonProps> = ({
  children,
  loading,
  avatar,
  title = true,
  paragraph,
  button,
  multiple = true,
  style,
  className,
  mt,
  mb
}) => {

  if (!loading) {
    return (
      <>
        { children }
      </>
    );
  } else if (loading) {
    if (multiple) {
      return (
        <div style={ {
          marginBottom: `${mb || 0}px`,
          marginTop: `${mt || 0}px`
        } }>
          <Skeleton
            avatar={ avatar ? { ...avatar } : false }
            paragraph={ paragraph ? { ...paragraph } : false }
            title={ title }
            active
          />
        </div>
      );
    } else {
      if (button) {
        return (
          <div style={ {
            marginBottom: `${mb || 0}px`,
            marginTop: `${mt || 0}px`
          } }>
            <Skeleton.Button { ...button } active />
          </div>
        );
      }

      if (avatar) {
        return (
          <Skeleton.Avatar
            { ...avatar }
            style={ style }
            className={ className }
            active
          />
        );
      }
    }
  }

  return null;
};

export default CustomSkeleton;
