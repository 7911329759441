import Styled from 'styled-components';

export const SuffixLabelStyle: any = Styled.div`
  margin-left: 7px;

  .icon-label-input {
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
`;

export const ContentPopoverTypeStyle: any = Styled.div`
  padding: 8px 4px 8px 20px;
  max-width: 350px;

  .wrapper-list {
    list-style-type: disc;
    list-style-position: outside;
    row-gap: 20px;
    display: flex;
    flex-direction: column;
  }
`;