import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import {
  Login,
  ForgotPassword,
  ResetPassword,
  SuccessPayment,
  FailurePayment
} from 'screens';
import {
  auth,
  hooks,
  navigation
} from 'helpers';
import { selectors } from 'store/selectors';

import App from './App';
import PrivateRoute from './private';

const publicRoutes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    component: ResetPassword
  }
];

const PublicRoute = ({ component: Component, ...rest }) => {
  const adminRole = hooks.useAppSelector(selectors.auth.adminRole);
  const redirectUrl = navigation.redirectUrl(adminRole);
  const token = auth.getAuthCredentials('access_token');

  return (
    <Route
      { ...rest }
      render={ props => {
        return !token
          ? <Component { ...props } />
          : <Redirect to={ { pathname: redirectUrl } } />;
      } }
    />
  );
};

interface Props {
  // eslint-disable-next-line no-undef
  history: any;
}

const Router: React.FC<Props> = ({ history }) => {
  return (
    <ConnectedRouter history={ history }>
      <Switch>
        <Route
          key='/success-payment'
          path='/success-payment'
          component={ SuccessPayment }
          exact
        />
        <Route
          key='/failure-payment'
          path='/failure-payment'
          component={ FailurePayment }
          exact
        />

        {
          publicRoutes.map(route => (
            <PublicRoute
              key={ route.path }
              path={ route.path }
              component={ route.component }
              exact
            />
          ))
        }
        <PrivateRoute path='/' component={ App } />
      </Switch>
    </ConnectedRouter>
  );
};

export default Router;
