import React from 'react';

import { Themes } from 'consts';

import { TextStyle } from './style';

type TextProps = {
  text?: any;
  color?: string;
  spacing?: number;
  className?: string;
  style?: React.CSSProperties;
  lineHeight?: number;
  align?: string;
  size?: string;
  weight?: number;
  themes?: string;
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  decoration?: string;
  disabled?: boolean;
  onClick?: () => void;
  ellipsis?: boolean;
};

const Text: React.FC<TextProps> = ({
  children,
  text,
  align,
  color,
  size,
  weight,
  themes,
  lineHeight,
  mb,
  mt,
  mr,
  ml,
  spacing,
  decoration,
  className,
  style,
  disabled,
  onClick,
  ellipsis
}) => {
  const textClassName = `${className || ''} ${ellipsis ? 'text-ellipsis' : ''}`;
  
  const h1 = (
    <h1
      style={ style }
      className={ textClassName }
      onClick={ onClick }
    >{ children || text }</h1>
  );
  const h2 = (
    <h2
      style={ style }
      className={ textClassName }
      onClick={ onClick }
    >{ children || text }</h2>
  );
  const h3 = (
    <h3
      style={ style }
      className={ textClassName }
      onClick={ onClick }
    >{ children || text }</h3>
  );
  const p = (
    <p
      style={ style }
      className={ textClassName }
      onClick={ onClick }
    >{ children || text }</p>
  );

  const render = () => {
    switch (className?.split(' ')[0]) {
      case 'h1': return h1;
      case 'h2': return h2;
      case 'h3': return h3;
      default: return p;
    }
  };

  return (
    <TextStyle
      fontSize={ Themes.fontSizes[size || 's'] }
      lineHeight={ lineHeight }
      fontFamily={ Themes.fontThemes(weight, themes) }
      color={ color }
      spacing={ spacing }
      align={ align }
      mt={ mt }
      mb={ mb }
      ml={ ml }
      mr={ mr }
      decoration={ decoration }
      disabled={ disabled }
      click={ !!onClick }
    >
      { render() }
    </TextStyle>
  );
};

Text.defaultProps = {
  className: 'p1',
  size: 's',
  weight: 500,
  themes: 'primary',
};

export default Text;