import React from 'react';

import {
  Box,
  Divider,
  Image,
  Spacer,
  Text
} from 'components';
import { convertColorStatus } from 'components/DataTable/helpers';
import { Colors, Images } from 'consts';
import { hooks } from 'helpers';
import { selectors } from 'store/selectors';

type BoxGeneralInfoProps = {
  widthBox: number;
};

const BoxGeneralInfo: React.FC<BoxGeneralInfoProps> = ({ widthBox }) => {
  const batteryPackDetail = hooks.useAppSelector(selectors.battery.batteryPackDetail);

  const renderTextGeneralInfo = (title: string, value: string) => {
    const textColor = title === 'Status'
      ? convertColorStatus(value)
      : Colors.black.isBlack;

    return (
      <>
        <div className='justify-align-center'>
          <Text size='xs' color={ Colors.grey.isGrey }>{ title }</Text>
          <Text
            size='xs'
            align='right'
            color={ textColor }
          >{ value }</Text>
        </div>
        { title !== 'Location' && <Divider marginVertical={ 12 } /> }
      </>
    );
  };

  const renderDataGeneralInfo = () => {
    const generalInfo = [
      {
        title: 'ID',
        value: batteryPackDetail?.id
      },
      {
        title: 'Current SoC',
        value: batteryPackDetail?.soc
      },
      {
        title: 'Current SoH',
        value: batteryPackDetail?.soh
      },
      {
        title: 'Status',
        value: batteryPackDetail?.status || '-'
      },
      {
        title: 'Location',
        value: batteryPackDetail?.location || '-'
      },
    ];

    return generalInfo.map((info, index) => (
      <div key={ index }>
        { renderTextGeneralInfo(info.title, info.value.toString()) }
      </div>
    ));
  };

  return (
    <Box
      mt={ 20 }
      mr={ 20 }
      padding={ 20 }
      width={ widthBox }
    >
      <Text size='m' weight={ 700 }>General Information</Text>
      <div className='col justify-center mt6'>
        <div className='col center-content'>
          <Image
            src={ Images.battery }
            width={ 85 }
            height={ 80 }
            preview
          />
        </div>
        <Spacer mt={ 20 }>
          { renderDataGeneralInfo() }
        </Spacer>
      </div>
    </Box>
  );
};

export default BoxGeneralInfo;
