import React, { useState } from 'react';

import {
  Box,
  BarChart,
  Select,
  SelectSearchBar,
  Spacer,
  Text,
  EmptyState
} from 'components';
import { Colors, Images } from 'consts';
import { SelectOptionValue } from 'interfaces/common';

import { renderTitleBox, renderSubtitleBox } from '../../index';
import { dataAvgBatteryUsage } from '../../dataDummy';
import { misc, screen } from 'helpers';

type BoxBatteryUsageProps = {
  widthBox: number;
  filterMenu: {
    activeFilter: any;
    visibleFilter: any;
    onVisibleChange: (visible: boolean, key: string) => void;
    onClickFilterMenu: (params?: any) => void;
  };
  screenWidth: number;
};

const defaultOptionsProvinces = [
  {
    label: 'KEPULAUAN RIAU',
    value: 'KEPULAUAN RIAU'
  },
  {
    label: 'DKI JAKARTA',
    value: 'DKI JAKARTA'
  },
  {
    label: 'JAWA BARAT',
    value: 'JAWA BARAT'
  }
];

const BoxBatteryUsage: React.FC<BoxBatteryUsageProps> = ({
  widthBox,
  filterMenu,
  screenWidth
}) => {
  const [dataSelectedProvinces, setDataSelectedProvinces] = useState<SelectOptionValue[]>([
    {
      key: filterMenu.activeFilter['batteryAvg_location'],
      label: <Text lineHeight={ 24 } ellipsis>{ filterMenu.activeFilter['batteryAvg_location'] }</Text>,
      value: filterMenu.activeFilter['batteryAvg_location']
    }
  ]);

  const isMobile = screenWidth <= screen.isMobile;

  const fetchProvinces = async() => {
    // TODO : fetch from server i-scoot and create dispatch action for fetch location.
    const response = await fetch('http://www.emsifa.com/api-wilayah-indonesia/api/provinces.json');
    const provinces = await response.json();
    const dataProvinces = provinces?.map(province => ({
      key: province?.name,
      label: province?.name,
      value: province?.name
    }));

    return dataProvinces;
  };

  const renderEmptyData = () => {
    return (
      <div className='mt4' style={ { 'width': widthBox } }>
        <EmptyState
          background={ { height: 285 } }
          image={ {
            src: Images.emptyState.batteryStations,
            width: 56,
            height: 56
          } }
          text='No Data'
        />
      </div>
    );
  };

  const renderFilterBatteryAvg = (key: string) => {
    return (
      <Select
        keySelect={ key }
        visible={ filterMenu.visibleFilter[key] }
        onVisibleChange={ filterMenu.onVisibleChange }
        selectTitle={ filterMenu.activeFilter[key] }
        onClickSelectItem={ filterMenu.onClickFilterMenu }
        selectOptions={ ['Daily', 'Weekly'] }
        totalItems={ 2 }
        wrapperStyle={ { marginBottom: 0 } }
      />
    );
  };

  const fetchSearchProvinces = async(search: string): Promise<SelectOptionValue[]> => {
    filterMenu.onVisibleChange(true, 'batteryAvg_location');

    const dataProvinces = await fetchProvinces();

    if (dataProvinces && dataProvinces?.length) {
      if (search) {
        return misc.filterData(search, dataProvinces);
      }

      return dataProvinces;
    }
    
    return [];
  };

  const renderFilterLocation = (key: string) => {
    return (
      <SelectSearchBar
        value={ dataSelectedProvinces }
        defaultOptions={ defaultOptionsProvinces }
        fetchOptions={ fetchSearchProvinces }
        onChange={ newValue => {
          setDataSelectedProvinces(newValue);
        } }
        backgroundColor={ Colors.white.default }
        open={ filterMenu.visibleFilter[key] }
        onDropdownVisibleChange={ (visible: boolean) => filterMenu.onVisibleChange(visible, key) }
        totalItems={ 2 }
        wrapperStyle={ { marginBottom: 0 } }
      />
    );
  };

  return (
    <Box
      mt={ 20 }
      padding={ 20 }
      width={ widthBox }
    >
      <div className='justify-align-center mb4'>
        <div>
          { renderTitleBox('Average Battery Usage', 8) }
          { renderSubtitleBox('10 - 16 Jul 2021') }
        </div>
        <div className={ isMobile ? 'col col-end' : 'flex row' }>
          { renderFilterLocation('batteryAvg_location') }
          <Spacer ml={ isMobile ? 0 : 20 } mt={ isMobile ? 15 : 0 }>
            { renderFilterBatteryAvg('batteryAvg_time') }
          </Spacer>
        </div>
      </div>
      <div className='col center-content'>
      { Object.values(dataAvgBatteryUsage)?.length ?
        <BarChart
          data={ dataAvgBatteryUsage }
          dataKey={ {
            xAxis: 'unit',
            bar: 'batteryAvg'
          } }
          unit=' KWh'
          valueDomain={ [0, 1000] }
          width={ widthBox - 40 }
          margin={ { left: -20 } }
        /> : renderEmptyData() }
      </div>
    </Box>
  );
};

export default BoxBatteryUsage;
