import { ApiParams } from './common';

export interface DataBatteryStation {
  key?: string;
  batteryStations: string;
  avgUtil: string;
  avgBatteryUsage: string;
  swapHours: string;
  avgVisitor: string;
}

export interface FormBatteryStation {
  id: string | number;
  station_type: string;
  pic_name: string;
  pic_number: string;
  address: string;
	city: string;
  zip_code: string;
	latitude: number | string;
  longitude: number | string;
  name: string;
}

export interface BatteryStation extends FormBatteryStation {
  avg_utilization: number;
  average_swap: number;
  avg_power_consumption: number;
  swap_hours: number;
  available_battery: number;
  charging_battery: number;
  total_slot: number;
}

export interface BatteryStationApiParams extends ApiParams {
	type?: string;
	is_active?: string | boolean;
	city?: string;
}

export interface HoleStation {
  index_hole: string;
  battery_id: string;
  voltage: string | number;
  soc: string | number;
  status: string;
}

export interface BusyTime {
  station_id: number;
  city: string;
  created_date: string;
}

export interface StationVisitor {
  total: number;
  city: string;
}

export interface HoleStationApiParams extends ApiParams {
  search: string;
  status: string;
}

export interface BusyTimeApiParams {
  report_date: string;
}

export enum StationType {
	home_station = 'home_station',
	battery_station = 'charging_station'
}

export interface StationDetail {
  id: number;
  station_type: string;
  name: string;
  pic_name: string;
  pic_number: string;
  address: string;
  city: string;
  zip_code: string;
  longitude: number;
  latitude: number;
  avg_utilization: number;
  avg_power_consumption: number;
  swap_hours: string;
  available_battery: number;
  total_slot: number;
  charging_battery: number;
  is_active: boolean;
  created_date: string;
  total_battery?: number;
}

export interface BatteryUsage {
  usage: number;
  date: string;
}