import { language } from 'language';

const { label, placeholder } = language;
const { filterOptions } = language.users;

export const inputPropsData = [
  {
    label: label.name,
    placeholder: placeholder.name,
    key: 'name',
    type: 'text'
  },
  {
    label: label.username,
    placeholder: placeholder.username,
    key: 'username',
    type: 'text'
  },
  {
    label: label.email,
    placeholder: placeholder.email,
    key: 'email',
    type: 'text'
  },
  {
    label: label.phone,
    placeholder: placeholder.phone,
    key: 'phone_number',
    type: 'text'
  },
  {
    label: label.password,
    placeholder: placeholder.password,
    key: 'password',
    type: 'password'
  },
  {
    label: label.confirmPassword,
    placeholder: placeholder.confirmPassword,
    key: 're_password',
    type: 'password'
  },
  {
    label: label.dob,
    placeholder: placeholder.dob,
    key: 'dob',
    type: 'date'
  },
  {
    label: 'City',
    placeholder: 'Choose City',
    key: 'city',
    type: 'selectSearch'
  },
  {
    label: label.address,
    placeholder: placeholder.address,
    key: 'address',
    type: 'textArea',
    iconName: 'pinLocation',
    inputClassName: 'input-address no-scrollbar'
  },
	{
    label: 'Zip Code',
    placeholder: 'Enter zip code',
    key: 'zip_code',
    type: 'text'
  },
];

export const dropdownsFilter = [
  {
    key: 'isActive',
    selectOptions: filterOptions.isActive
  }
];
