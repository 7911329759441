import Styled from 'styled-components';

import { screen } from 'helpers';

export const BoxLegalInfoStyle: any = Styled.div`
  .btn-inner-container {
    padding: 10px;
  }

  .btn-contact {
    height: 36px;
  }

  .btn-verify {
    padding: 7px 10px;
  }

  .inner-container-data-legal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container-verify {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .verified-checklist {
    margin-top: 29.5px;
  }

  .image-text-data-legal {
    display: flex;
    flex-direction: column;
  }

  .img-legal {
    border-radius: 10px;
    object-fit: cover;
  }

  .img-legal-skeleton > .ant-skeleton-avatar {
    border-radius: 5px !important;
    height: 100px !important;
  }

  @media ${ screen.sizesMax.st } {
    .img-legal,
    .img-legal-skeleton > .ant-skeleton-avatar,
    .img-legal-upload > .ant-upload {
      width: 150px !important;
    }
  }

  @media ${ screen.sizesMax.xs } {
    .verified-checklist {
      margin: 0;
    }

    .btn-inner-container {
      padding: 5px;
    }

    .btn-contact {
      height: 31px;
      margin-top: 10px;
    }

    .img-user-detail {
      width: 60px;
      height: 60px;
    }

    .user-data {
      margin-bottom: 3px;
    }

    .inner-container-data-legal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-top: 20px;
    }

    .image-text-data-legal {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  @media ${ screen.sizesMax.xxs } {
    .btn-inner-container {
      padding: 3px;
    }

    .btn-contact {
      height: 25px;
    }

    .img-user-detail {
      width: 40px;
      height: 40px;
    }

    .user-data {
      margin-bottom: 0px;
    }

    .user-data-container {
      margin-left: 5px;
    }

    .img-legal,
    .img-legal-skeleton > .ant-skeleton-avatar,
    .img-legal-upload > .ant-upload {
      width: 120px !important;
    }
  }
`;
