import React from 'react';

import { hooks, screen } from 'helpers';

import Modal from '../../Modal';
import Loader from '../index';

type ModalLoaderProps = {
  visible: boolean;
  onCloseModal: () => void;
  afterClose?: () => void;
}

const ModalLoader: React.FC<ModalLoaderProps> = ({
  visible,
  onCloseModal,
  afterClose
}) => {
  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();
  const isMobile = windowDimensions.width <= screen.isMobile;

  const modalSize = isMobile ? 100 : 125;
  const loaderSize = isMobile ? 70 : 90;
  
  return (
    <Modal
      visible={ visible }
      onCloseModal={ onCloseModal }
      afterClose={ afterClose }
      modalType='loader'
      padding={ 0 }
      bodyStyle={ { height: modalSize } }
      width={ modalSize }
    >
      <div className='col center-content' style={ { height: modalSize } }>
        <Loader size={ loaderSize } />
      </div>
    </Modal>
  );
};

export default ModalLoader;
