import { Colors } from 'consts';
import { SorterInfo } from 'interfaces/common';

export const getColorFill = (color: string) => {
  switch (color) {
    case 'red':
      return [Colors.red.isRed, Colors.red.isRedOpacity('0.1')];
    case 'green':
      return [Colors.green.isGreen, Colors.green.isGreenOpacity('0.1')];
    case 'yellow':
      return [Colors.yellow.isYellow, Colors.yellow.isYellowOpacity('0.1')];
    case 'blue':
      return [Colors.blue.isBlue, Colors.blue.isBlueOpacity('0.1')];
    case 'black':
      return [Colors.black.isBlack, Colors.black.isBlackOpacity('0.1')];
    case 'pink':
      return [Colors.pink.isFuschia, Colors.pink.isFuschiaOpacity('0.1')];
    case 'grey':
    default:
      return [Colors.grey.isGrey, Colors.grey.isLighterGrey];
  }
};

export const convertSortOrderColumn = (columnKey: string, sorterInfo: SorterInfo) => {
  return sorterInfo.order === columnKey && sorterInfo.sort ? `${sorterInfo.sort}end` : undefined;
};

export const convertColorStatus = (text: string) => {
  const status = (text || '')?.toLowerCase();

  switch (status) {
    case 'commission':
    case 'active':
      return Colors.blue.isBlueAlert;
    case 'uncommission':
    case 'available':
      return Colors.blue.isBlue;
    case 'lost':
    case 'missing':
    case 'retired':
      return Colors.red.isTextRed;
    case 'service required':
    case 'recharging':
    case 'charging':
      return Colors.yellow.isYellow;
    case 'undetected':
      return Colors.grey.isGreyGreen;
		case 'finished production':
      return Colors.blue.isBlue;
    default:
      return Colors.blue.isBlueIris;
  }
};
