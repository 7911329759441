import React, { memo } from 'react';
import lodash from 'lodash';

import Text from '../Text';
import Loader from '../Loader';
import Icon from '../Icon';
import { IconProps } from '../Icon';

import { ButtonStyle, getTextColor } from './style';

export type ButtonBackgroundColor = 'black' | 'green' | 'blue' | 'red' | 'pink' | 'yellow' | 'grey';

export type ButtonProps = {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  text?: string;
  className?: string;
  styleType?: string;
  width?: number;
  height?: number;
  borderRadius?: number;
  borderColor?: string;
  bgColor?: ButtonBackgroundColor;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  size?: string;
  fontWeight?: number;
  isLoading?: boolean;
  loaderColor?: 'black' | 'blue' | 'lightGrey';
  icon?: IconProps;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hover?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled,
  style,
  text,
  className,
  styleType,
  width,
  height,
  borderRadius,
  borderColor,
  bgColor,
  mt,
  mb,
  ml,
  mr,
  size,
  fontWeight,
  isLoading,
  loaderColor,
  icon,
  onMouseEnter,
  onMouseLeave,
  hover
}) => {
  const debouncedOnClick = lodash.debounce(
    () => onClick ? onClick() : null,
    1000,
    {
      leading: true,
      trailing: false,
    }
  );

  const renderText = () => {
    return (
      <Text
        align={ icon ? 'left' : 'center' }
        color={ getTextColor(styleType, bgColor) }
        size={ size }
        weight={ fontWeight }
        ml={ icon ? 10 : 0 }
        className='p1 text-button'
      >{ text }</Text>
    );
  };

  const renderTextWithIcon = () => {
    return (
      <div className='flex row align-center btn-icon-inner'>
        <Icon
          size={ 16 }
          hoverOpacity={ 1 }
          className='icon-button'
          { ...icon }
        />

        { text && renderText() }
      </div>
    );
  };

  const renderChildren = () => {
    if (isLoading) {
      return <Loader color={ loaderColor } />;
    }

    if (children) {
      return children;
    }

    if (icon) {
      return renderTextWithIcon();
    }

    return renderText();
  };

  return (
    <ButtonStyle
      styleType={ styleType }
      width={ width }
      height={ height }
      borderRadius={ borderRadius }
      borderColor={ borderColor }
      mt={ mt }
      mb={ mb }
      ml={ ml }
      mr={ mr }
      disabled={ disabled || isLoading }
      bgColor={ bgColor }
      hover={ hover }
    >
      <button
        onClick={ debouncedOnClick }
        disabled={ disabled || isLoading }
        style={ style }
        className={ className }
        onMouseEnter={ onMouseEnter }
        onMouseLeave={ onMouseLeave }
      >
        { renderChildren() }
      </button>
    </ButtonStyle>
  );
};

Button.defaultProps = {
  disabled: false,
  borderRadius: 4,
  size: 's',
  fontWeight: 500,
  isLoading: false,
  styleType: 'primary',
  bgColor: 'blue',
  height: 43,
  loaderColor: 'blue',
  hover: true
};

export default memo(Button);
