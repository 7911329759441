import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { ApiData } from 'interfaces/common';

import {
	BatteryCondition,
	BatteryConditionParams,
	StatsAge,
	SwapLocation,
	SwapLocationParams,
} from 'interfaces/stats';

export const getStatsAge = async() => {
	try {
		const response: ApiData<StatsAge[]> = await customFetch(Endpoints.getStatsAge());

		return response;
	} catch (error) {
		throw error;
	}
};

export const getBatteryCondition = async(params: BatteryConditionParams) => {
	try {
		const response: ApiData<BatteryCondition[]> = await customFetch(Endpoints.getBatteryCondition(), params);

		return response;
	} catch (error) {
		throw error;
	}
};

export const getSwapLocation = async(params: SwapLocationParams) => {
	try {
		const response: ApiData<SwapLocation[]> = await customFetch(Endpoints.getSwapLocation(), params);

		return response;
	} catch (error) {
		throw error;
	}
};