import Styled from 'styled-components';

import { screen } from 'helpers';

type BoxGenderStyleProps = {
  widthBox: number;
};

export const BoxGenderStyle: any = Styled.div < BoxGenderStyleProps > `
  .container-gender {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .inner {
    width: ${props => (props.widthBox - 40) / 2}px;
  }

  @media ${screen.sizesMax.xxs} {
    .container-gender {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;