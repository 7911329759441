import { Pagination } from 'interfaces/common';
import { Role } from 'interfaces/role';

import {
  GET_ALL_ROLE,
  LOGOUT,
  RoleActionTypes,
} from './actionTypes';

interface RoleState {
  roles: {
    data: Role[];
    pagination: Pagination;
  };
}

const initState: RoleState = {
  roles: {
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
      order: '',
      page: 0,
      page_total: 0,
      search: '',
      sort: 'asc'
    }
  }
};

const roleReducer = (state = initState, action: RoleActionTypes): RoleState => {
  switch (action.type) {
    case GET_ALL_ROLE:
			return {
        ...state,
        roles: action.data
      };

    case LOGOUT:
			return initState;

		default:
			return state;
  }
};

export default roleReducer;
