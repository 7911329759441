import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

type ImageUsersStyleProps = {
  indexUser: number;
};

type IndicatorStyleProps = {
  isActiveCarousel?: boolean;
};

type LinkedUsersStyleProps = {
  isMobile: boolean;
  width: number;
};

export const BoxScooterStyle: any = Styled.div`
  .ic-arrow-left {
    transform: rotate(90deg);
  }

  .ic-arrow-right {
    transform: rotate(-90deg);
  }

  .wrapper-indicator {
    flex-wrap: wrap;
    row-gap: 5px;
  }

  .carousel-outer {
    width: 108px;
    height: 100px;
  }

  .carousel-outer-modal {
    width: 258px;
    height: 250px;
  }

  .indicator-outer {
    margin-top: 40px;
  }

  .box-blue {
    margin-left: 7px;
  }

  .text-blue-container {
    padding: 7px 10px;
  }

  .img-scooter-container {
    margin: 0 2px;
  }

  .img-scooter {
    width: 106px;
    height: 100px;
  }

  .img-scooter-skeleton > .ant-skeleton-avatar {
    border-radius: 5px !important;
    height: 100px !important;
    width: 200px !important;
  }

  .btn-pair-modal {
    width: 100%;
  }

  @media ${ screen.sizesMax.md2 } ${ screen.sizesMin.xs } {
    .carousel-outer {
      width: 128px;
      height: 120px;
    }

    .img-scooter {
      width: 126px;
      height: 120px;
    }
  }

  @media ${ screen.sizesMax.sm } {
    .carousel-outer-modal {
      width: 208px;
      height: 200px;
    }

    .indicator-outer {
      margin-top: 80px;
    }
  }
`;

export const ImageUsersStyle: any = Styled.div < ImageUsersStyleProps > `
  .image-users {
    ${ props => props.indexUser > 0 ? 'margin-left: -15px;' : '' };
    border: 2px solid ${ Colors.white.default };
    border-radius: 100%;
  }
`;

export const IndicatorStyle: any = Styled.div < IndicatorStyleProps > `
  .indicator-scooter {
    background-color: ${ props => props.isActiveCarousel ? Colors.blue.isBlue : Colors.blue.isBlueOpacity('0.1') };
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const LinkedUsersStyle: any = Styled.div < LinkedUsersStyleProps > `
  width: ${ props => props.width }px;
  overflow-y: auto;
  max-height: ${ props => props.isMobile ? '40vh' : '60vh' };
`;