import Styled from 'styled-components';

import { Colors } from 'consts';

export const PopupStyle: any = Styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 7px;
`;

export const SearchBoxStyle: any = Styled.div`
	.wrapper-card-maps {
		border: 1px solid ${ Colors.grey.isLightGrey };
		border-radius: 10px;
	}

	.address-selected-container {
		display: grid;
		flex-direction: column;
		row-gap: 10px;
		padding: 20px;
	}
`;

export const WrapperAutocompleteAddress: any = Styled.div`
	padding-bottom: 20px;
	margin-bottom: 20px;

	.outer {
		display: flex;
		align-items: center;
		width: 100%;
		position: relative;
		padding-top: 30px;
	}

	.icon-search-outer {
		padding-left: 16px;
		position: absolute;
		left: 0;
		z-index: 10;
		pointer-events: none;
	}

	.wrapper-autocomplete {
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
	}
`;