import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { convertToFormData } from 'helpers/api';
import { ApiData, CommonResponseData } from 'interfaces/common';
import {
  Admin,
  AdminDetail,
  FormAdmin,
  FormChangeStatus,
  AdminApiParams
} from 'interfaces/admin';

export const getAllAdmin = async(params: AdminApiParams) => {
  try {
    const queryParams: AdminApiParams = {
      ...params,
      sort: 'desc',
      order: 'id'
    };

    const response: ApiData<Admin[]> = await customFetch(Endpoints.getAllAdmin(), queryParams);

    return response;
  } catch (error) {
    throw error;
  }
};

export const addAdmin = async(formAdmin: FormAdmin) => {
  try {
    const body = await convertToFormData(formAdmin);

    const response: ApiData<string> = await customFetch(Endpoints.addAdmin(), body);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editAdmin = async(userId: string, formAdmin: FormAdmin) => {
  try {
    const body = await convertToFormData(formAdmin);

    const response: ApiData<string> = await customFetch(Endpoints.editAdmin(userId), body);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getAdminDetail = async(userId: string) => {
  try {
    const response: ApiData<AdminDetail> = await customFetch(Endpoints.getAdminDetail(userId));

    return response;
  } catch (error) {
    throw error;
  }
};

export const changeStatusAdmin = async(userId: string, formChangeStatus: FormChangeStatus) => {
  try {
    const response: ApiData<CommonResponseData> = await customFetch(Endpoints.changeStatusAdmin(userId), formChangeStatus);

    return response;
  } catch (error) {
    throw error;
  }
};
