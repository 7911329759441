import { Dispatch } from 'redux';

import { toastify } from 'helpers';

import { AppActions } from 'store';
import { ApiData, Pagination } from 'interfaces/common';
import { Cities, CitiesApiParams } from 'interfaces/location';

import * as locationServices from 'services/location';

import { GET_CITIES } from './actionTypes';
import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';
import { setPaginationData } from '../Pagination/action';

export const getAllCities = (dispatch: Dispatch<AppActions>) => (params: CitiesApiParams,
  callback?: (cities: { data: Cities[], pagination: Pagination; }) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('allCities'));

      const apiParams: CitiesApiParams = await { ...params };

      const {
				code,
        data,
        pagination
      }: ApiData<Cities[]> = await locationServices.getAllCities(apiParams);
			
      if (code === 200) {
        await dispatch({
					type: GET_CITIES,
					data: {
						data,
						pagination
					}
				});

        await dispatch(setPaginationData('cities', pagination));

        callback ? callback({
					data,
					pagination
				}) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();
      reject(error);
    }

    dispatch(lazyLoadEnd('allCities'));
  });
};