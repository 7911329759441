import React, {
	useCallback,
	useEffect,
	useState
} from 'react';
import { ColumnsType } from 'antd/es/table';

import {
	Dashboard,
	ModalLoader,
	TopLoaderBar
} from 'components';
import { renderButtonAction, renderText } from 'components/DataTable';
import {
	hooks,
	misc,
	navigation,
	roleHelper,
	toastify
} from 'helpers';
import { DataTicketing, TicketingApiParams } from 'interfaces/ticketing';
import { CurrentPagination, FormPagination } from 'interfaces/common';
import { Permission } from 'interfaces/role';
import { selectors } from 'store/selectors';
import { Colors } from 'consts';
import { language } from 'language';
import { DetailNavProps } from 'typings';
import * as actions from 'store/actions';

import { BtnActionCases, TabViewStyle } from './style';
import {
	RenderModalSolveCase,
	RenderModalDetailCase,
	RenderModalStepCase,
	RenderModalReassignCS,
	MenuAssignCS
} from './components';
import {
	convertStatusColor,
	convertTabNumberToStatus,
	convertTabTitleToStatus,
	convertDateToLocal
} from './helpers';

export interface RouteParams {
	id?: string;
	action?: string;
}

const ticketingLang = language.ticketing;

const CustomerServices: React.FC = () => {
	const query = hooks.useQuery();

	const dispatch = hooks.useAppDispatch();

	const getAllCase = dispatch(actions.getAllCase);
	const getDetailCase = dispatch(actions.getDetailCase);
	const getPastSimiliarCase = dispatch(actions.getPastSimiliarCase);
	const takeCase = dispatch(actions.takeCase);

	const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
	const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);
	const cases = hooks.useAppSelector(selectors.case.cases);

	const [isMounted, setIsMounted] = useState<boolean>(false);
	const [modalLoaderVisible, setModalLoaderVisible] = useState<boolean>(false);
	const [actionButton, setActionButton] = useState<string>('');
	const [formPagination, setFormPagination] = useState<FormPagination>({
		limit: 10,
		page: 1,
	});
	const [search, setSearch] = useState<string>('');
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const [tabs] = useState<string[]>([
		'Open',
		'Pending',
		'Close'
	]);
	const [columns, setColumns] = useState<ColumnsType<DataTicketing>>([]);
	const [modalSolveCase, setModalSolveCase] = useState<boolean>(false);
	const [modalDetailCase, setModalDetailCase] = useState<boolean>(false);
	const [modalStepCase, setModalStepCase] = useState<boolean>(false);
	const [modalReassignCS, setModalReassignCS] = useState<boolean>(false);
	const [selectedCase, setSelectedCase] = useState<DataTicketing>({
		id: 0,
		case_code: '',
		cust_id: 0,
		cust_name: '',
		cust_image: '',
		cust_email: '',
		cust_phone: '',
		scooter_type: '',
		scooter_status: '',
		plat_number: '',
		vin_id: 0,
		category_id: 0,
		category_name: '',
		admin_id: 0,
		admin_phone: '',
		admin_email: '',
		admin_name: '',
		open_duration: '',
		status: '',
		description: '',
		is_notified: false,
		created_date: '',
		updated_date: ''
	});
	const [refreshing, setRefreshing] = useState<boolean>(false);
	const [menuAssignCSVisible, setMenuAssignCSVisible] = useState<boolean>(false);
	const [goTo, setGoTo] = useState<DetailNavProps>({
		progress: -1,
		id: '',
		route: ''
	});

	const debouncedSearch = hooks.useDebounce(search, 1200);
	const isPermissibleAssignCS = roleHelper.isPermissible(adminPermissions, Permission.call_me_assign_cs);
	const isPermissibleTakeIssue = roleHelper.isPermissible(adminPermissions, Permission.call_me_take_issue);
	const isPermissibleCaseSolve = roleHelper.isPermissible(adminPermissions, Permission.case_solve);
	const isPermissibleReassignCS = roleHelper.isPermissible(adminPermissions, Permission.call_me_reassign_cs);

	const queryId = query?.get('id');
	const queryAction = query?.get('action');

	const buttonsDataTable = [
		{
			button: {
				borderRadius: 5,
				size: 's',
				fontWeight: 700,
				width: 113,
				height: 32,
				text: ticketingLang.takeIssue,
				isLoading: misc.isLazyLoading(lazyLoad, 'takeCase')
			},
			permission: isPermissibleTakeIssue && selectedTab === 0,
			onClick: (record: DataTicketing) => {
				takeCase(record.id?.toString(), () => {
					toastify.success(`Successfully taking issue ${ record.case_code }. Check “Pending” tab & solve the issue`);

					getInitialDataCases([0, 1]); // refreshing open & pending cases
				});
			}
		},
		{
			button: {
				borderRadius: 5,
				size: 's',
				fontWeight: 700,
				width: 78,
				height: 30,
				text: ticketingLang.solve
			},
			onClick: (record: DataTicketing) => {
				setSelectedCase(record);
				setModalSolveCase(true);
			},
			permission: isPermissibleCaseSolve && selectedTab === 1
		},
		{
			button: {
				borderRadius: 5,
				size: 's',
				fontWeight: 700,
				width: 96,
				height: 30,
				text: ticketingLang.reassignCS
			},
			onClick: (record: DataTicketing) => {
				setSelectedCase(record);
				setModalReassignCS(true);
			},
			permission: isPermissibleReassignCS && selectedTab === 1
		},
		{
			iconName: 'question',
			color: 'black',
			onClick: (record: DataTicketing) => {
				setSelectedCase(record);
				setActionButton('getPastSimiliarCase');
				setModalLoaderVisible(true);
				getPastSimiliarCase(String(record.id), () => setModalLoaderVisible(false));
			},
			permission: (isPermissibleCaseSolve || isPermissibleTakeIssue) && selectedTab === 1
		},
		{
			iconName: 'search',
			color: 'blue',
			onClick: (record: DataTicketing) => {
				setSelectedCase(record);

				// notes: confirmed from BE, API get detail case hanya untuk case yg statusnya 'solved', selain itu data case diambil dari get all case saja (record)
				if (record.status === 'solved') {
					setModalLoaderVisible(true);
					setActionButton('getDetailCase');

					if (record && record?.id) {
						if (record?.id > 0) {
							getDetailCase(String(record.id), () => setModalLoaderVisible(false));
						}
					}
				} else {
					setModalDetailCase(true);
				}
			},
			permission: selectedTab === 1 || selectedTab === 2
		}
	];

	/* todo: ubah dataIndex & key u/ duration dengan response API (jika sudah ada response u/ duration) */
	const columnOpeningCases: ColumnsType<DataTicketing> = [
		{
			title: 'Ticketing ID',
			dataIndex: 'case_code',
			key: 'case_code',
			render: text => renderText(text)
		},
		{
			title: 'Customer',
			dataIndex: 'cust_name',
			key: 'cust_name',
			render: text => renderText(text || '-')
		},
		{
			title: 'VIN',
			dataIndex: 'vin_id',
			key: 'vin_id',
			render: text => renderText(text)
		},
		{
			title: 'Created',
			dataIndex: 'created_date',
			key: 'created_date',
			render: text => renderText(convertDateToLocal(text))
		},
		{
			title: 'Problem',
			dataIndex: 'category_name',
			key: 'category_name',
			render: text => renderText(text)
		},
		{
			title: 'Open Duration',
			dataIndex: 'open_duration',
			key: 'open_duration',
			render: text => renderText(text || '-')
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			render: (_: any, record: DataTicketing) => {
				if (isPermissibleAssignCS) {
					return renderText(record.admin_name || ticketingLang.assignCS, Colors.grey.isGrey);
				}

				if (isPermissibleTakeIssue) {
					return renderButtonAction(buttonsDataTable, record);
				}
			},
			onCell: (record: DataTicketing) => ({
				title: 'Action',
				dataIndex: 'action',
				record,
				dropdownVisible: menuAssignCSVisible,
				onVisibleChange: (visible: boolean) => setMenuAssignCSVisible(visible),
				isDropdownCell: isPermissibleAssignCS,
				overlay: () => (
					<MenuAssignCS
						record={ record }
						onSuccess={ () => getInitialDataCases([0, 1]) } // refreshing open & pending cases
						dropdownCellVisible={ menuAssignCSVisible }
						setDropdownCellVisible={ setMenuAssignCSVisible }
					/>
				),
				placement: 'bottomRight',
			}),
		},
	];
	const columnPendingCases: ColumnsType<DataTicketing> = [
		{
			title: 'Ticketing ID',
			dataIndex: 'case_code',
			key: 'case_code',
			render: text => renderText(text)
		},
		{
			title: 'Customer',
			dataIndex: 'cust_name',
			key: 'cust_name',
			render: text => renderText(text || '-')
		},
		{
			title: 'VIN',
			dataIndex: 'vin_id',
			key: 'vin_id',
			render: text => renderText(text)
		},
		{
			title: 'Created',
			dataIndex: 'created_date',
			key: 'created_date',
			render: text => renderText(convertDateToLocal(text))
		},
		{
			title: 'Problem',
			dataIndex: 'category_name',
			key: 'category_name',
			render: text => renderText(text)
		},
		{
			title: 'Duration',
			dataIndex: 'open_duration',
			key: 'open_duration',
			render: text => renderText(text || '-')
		},
		{
			title: isPermissibleAssignCS ? 'In-Charge' : 'Action',
			dataIndex: 'action',
			key: 'action',
			render: (_: any, record: DataTicketing) => {
				return (
					<BtnActionCases>
						{ isPermissibleAssignCS && (
							<div className='container-cs'>
								{ renderText(record.admin_name || '-') }
							</div>
						) }
						{ renderButtonAction(buttonsDataTable, record) }
					</BtnActionCases>
				);
			}
		},
	];
	const columnCloseCases: ColumnsType<DataTicketing> = [
		{
			title: 'Ticketing ID',
			dataIndex: 'case_code',
			key: 'case_code',
			render: text => renderText(text)
		},
		{
			title: 'Customer',
			dataIndex: 'cust_name',
			key: 'cust_name',
			render: text => renderText(text || '-')
		},
		{
			title: 'VIN',
			dataIndex: 'vin_id',
			key: 'vin_id',
			render: text => renderText(text)
		},
		{
			title: 'Created',
			dataIndex: 'created_date',
			key: 'created_date',
			render: text => renderText(convertDateToLocal(text))
		},
		{
			title: 'Solved Date',
			dataIndex: 'updated_date',
			key: 'updated_date',
			render: text => renderText(convertDateToLocal(text))
		},
		{
			title: 'Problem',
			dataIndex: 'category_name',
			key: 'category_name',
			render: text => renderText(text)
		},
		{
			title: 'Duration',
			dataIndex: 'open_duration',
			key: 'open_duration',
			render: text => renderText(text || '-')
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: 140,
			render: (_: any, record: DataTicketing) => {
				return (
					<div className='flex justify-align-center'>
						{ renderText(misc.titleCase(`${ record.status }`), convertStatusColor(record.status)) }
						{ renderButtonAction(buttonsDataTable, record) }
					</div>
				);
			}
		},
	];

	// initial get data cases (to get total data in the tab section)
	const getInitialDataCases = (arrTabIdx: number[]) => {
		Promise.all(arrTabIdx.map(async tabIdx => {
			await misc.sleep(tabIdx * 500);
			getDataCases(tabIdx);
		})).then(() => {
			setIsMounted(true);
		});
	};

	useEffect(() => {
		getInitialDataCases([
			0,
			1,
			2
		]);
	}, []);

	useEffect(() => {
		if (isMounted) {
			getDataCases(selectedTab);
		}
	}, [
		formPagination.page,
		formPagination.limit,
		debouncedSearch,
		selectedTab
	]);

	// Handle refreshing
	useEffect(() => {
		if (refreshing) {
			getDataCases(selectedTab, () => setRefreshing(false));
		}
	}, [refreshing]);

	useEffect(() => {
		if (selectedTab === 0) {
			setColumns(columnOpeningCases);
		}
		if (selectedTab === 1) {
			setColumns(columnPendingCases);
		}
		if (selectedTab === 2) {
			setColumns(columnCloseCases);
		}
	}, [selectedTab]);

	useEffect(() => {
		// todo: jika sudah tersedia api untuk get detail case ketika status pending, maka uncomment line berikut & adjust action getDetailCase nya jika berbeda action:
		// if (queryId) {
		// 	setModalLoaderVisible(true);
		// 	setActionButton('getDetailCase');

		// 	getDetailCase(String(queryId), () => setModalLoaderVisible(false));
		// }

		if (queryAction && queryAction === 'pending') {
			setSelectedTab(1);
		}
	}, [queryId, queryAction]);

	const getDataCases = (selectedTab: number, callback?: () => void) => {
		const params: TicketingApiParams = {
			...formPagination,
			search: debouncedSearch,
			status: convertTabNumberToStatus(selectedTab),
			...selectedTab === 2
				? {
					order: 'updated_date',
					sort: 'desc'
				}
				: {}
		};

		getAllCase(params, callback);
	};

	const getCurrentPagination = (currentPagination: CurrentPagination<DataTicketing[]>) => {
		setFormPagination(prevPagination => ({
			...prevPagination,
			page: currentPagination.currentPage,
			limit: currentPagination.elementsPerPage
		}));
	};

	const setInitialPage = () => {
		setFormPagination(prevForm => ({
			...prevForm,
			page: 1
		}));
	};

	const setInitialFormPagination = () => {
		setFormPagination(prevForm => ({
			...prevForm,
			page: 1,
			limit: 10
		}));
	};

	const onSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);

		setInitialPage();
	}, []);

	const renderTabView = () => {
		return (
			<TabViewStyle>
				<ul>
					{ tabs?.map((item, i) => (
						<li
							key={ i }
							className={ selectedTab === i ? 'active' : '' }
							onClick={ () => {
								setSelectedTab(i);
								setInitialFormPagination();
							} } >{ `${ item } (${ cases[`${ convertTabTitleToStatus(item) }`]?.pagination?.count || 0 })` }</li>
					)) }
				</ul>
			</TabViewStyle>
		);
	};

	const renderModalSolveCase = () => {
		const closeModal = () => {
			setModalSolveCase(false);
		};

		return (
			<RenderModalSolveCase
				caseDetail={ selectedCase }
				visible={ modalSolveCase }
				closeModal={ closeModal }
				onSuccess={ () => getInitialDataCases([1, 2]) } // refreshing pending & closed cases
			/>
		);
	};

	const renderModalReassignCS = () => {
		return (
			<RenderModalReassignCS
				caseDetail={ selectedCase }
				visible={ modalReassignCS }
				closeModal={ () => setModalReassignCS(false) }
				setRefreshing={ setRefreshing }
			/>
		);
	};

	const renderModalDetailCase = () => {
		const closeModal = () => {
			setModalDetailCase(false);
		};

		return (
			<RenderModalDetailCase
				record={ selectedCase }
				visible={ modalDetailCase }
				closeModal={ closeModal }
				setGoTo={ setGoTo }
			/>
		);
	};

	const renderModalStepCase = () => {
		const closeModal = () => {
			setModalStepCase(false);
		};

		return (
			<RenderModalStepCase
				caseDetail={ selectedCase }
				visible={ modalStepCase }
				closeModal={ closeModal }
			/>
		);
	};

	const renderTopLoadingBar = () => {
		return (
			<TopLoaderBar
				progress={ goTo.progress }
				onLoaderFinished={ () => navigation.push(`${ goTo.route }`) }
			/>
		);
	};

	const renderModalLoader = () => {
		return (
			<ModalLoader
				visible={ modalLoaderVisible }
				onCloseModal={ () => setModalLoaderVisible(false) }
				afterClose={ () => {
					if (actionButton === 'getPastSimiliarCase') {
						setModalStepCase(true);
					} else if (actionButton === 'getDetailCase') {
						setModalDetailCase(true);
					}
				} }
			/>
		);
	};

	const renderDashboardCustomerServices = () => {
		const status = convertTabNumberToStatus(selectedTab);
		const currentDataCases = (cases[status]?.data || []);

		return (
			<Dashboard
				container={ {
					headerContent: {
						textHeader: ticketingLang.header.title,
						content: renderTabView(),
					},
					selectedMenu: 11
				} }
				data={ {
					currentData: currentDataCases,
					loading: misc.isLazyLoading(lazyLoad, `allCase.${ status }`),
					fieldName: `cases.${ status }`,
					getCurrentPagination
				} }
				filterMenu={ {
					search,
					placeholderSearch: 'Search',
					onChangeSearch: onSearch
				} }
				table={ {
					columns,
					buttonAction: (record: DataTicketing) => {
						if (roleHelper.isPermissible(adminPermissions, Permission.call_me_assign_cs) && selectedTab === 0) {
							return (
								<MenuAssignCS
									record={ record }
									onSuccess={ () => getInitialDataCases([0, 1]) } // refreshing open & pending cases
									mobile
								/>
							);
						}

						return <></>;
					},
					buttons: buttonsDataTable
				} }
				modalLoader={ renderModalLoader() }
				topLoadingBar={ renderTopLoadingBar() }
			>
				{ renderModalSolveCase() }
				{ renderModalDetailCase() }
				{ renderModalStepCase() }
				{ renderModalReassignCS() }
			</Dashboard>
		);
	};

	return renderDashboardCustomerServices();
};

export default CustomerServices;
