import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { ApiData, CommonResponseData } from 'interfaces/common';
import {
	HoleStation,
	HoleStationApiParams,
	StationDetail,
	BusyTime,
	BatteryStation,
	BatteryStationApiParams,
	FormBatteryStation,
	BusyTimeApiParams,
	StationVisitor
} from 'interfaces/station';

export const getAllBatteryStations = async(queryParams: BatteryStationApiParams) => {
	try {
		const response: ApiData<BatteryStation[]> = await customFetch(Endpoints.getAllBatteryStations(), queryParams);

		return response;
	} catch (error) {
		throw error;
	}
};

export const addBatteryStations = async(formAddBatteryStations: FormBatteryStation) => {
	try {
		const response: ApiData<CommonResponseData> = await customFetch(Endpoints.addBatteryStations(), formAddBatteryStations);

		return response;
	} catch (error) {
		throw error;
	}
};

export const getStationDetail = async(stationId: string) => {
	try {
		const response: ApiData<StationDetail> = await customFetch(Endpoints.getStationDetail(stationId));

		return response;
	} catch (error) {
		throw error;
	}
};

export const getHoleStation = async(stationId: string, params: HoleStationApiParams) => {
	try {
		const response: ApiData<HoleStation[]> = await customFetch(Endpoints.getHoleStation(stationId), params);

		return response;
	} catch (error) {
		throw error;
	}
};

export const getBusyTime = async(stationId: string, params: BusyTimeApiParams) => {
	try {
		const response: ApiData<BusyTime[]> = await customFetch(Endpoints.getBusyTime(stationId), params);

		return response;
	} catch (error) {
		throw error;
	}
};

export const getStationVisitor = async(stationId: string) => {
	try {
		const response: ApiData<StationVisitor[]> = await customFetch(Endpoints.getStationVisitor(stationId));

		return response;
	} catch (error) {
		throw error;
	}
};