import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { Dashboard } from 'components';
import { renderText } from 'components/DataTable';
import { convertColorStatus } from 'components/DataTable/helpers';
import { selectors } from 'store/selectors';
import { hooks } from 'helpers';
import * as actions from 'store/actions';
import { HoleStation } from 'interfaces/station';
import { FormPagination } from 'interfaces/common';
import { SortOrder } from 'typings';

import { dropdownsFilter } from './data';

type BoxHoleStationProps = {
  totalSlot: number;
};

interface RouteParams {
  stationId: string;
}

type Filter = 'status';
type ActiveFilter = {
  [key in Filter]: string;
};

const BoxHoleStation: React.FC<BoxHoleStationProps> = ({ totalSlot }) => {
  const params = useParams<RouteParams>();
  const dispatch = hooks.useAppDispatch();
  const getHoleStation = dispatch(actions.getHoleStation);

  const holeStation = hooks.useAppSelector(selectors.station.holeStation);
  const [activeFilter, setActiveFilter] = useState<ActiveFilter>({ status: 'All Status' });
  const [search, setSearch] = useState<string>('');

  const columns: ColumnsType<HoleStation> = [
    {
      title: 'Index Hole',
      dataIndex: 'index_hole',
      key: 'index_hole',
      render: text => renderText(String(text)),
    },
    {
      title: 'Battery ID',
      dataIndex: 'battery_id',
      key: 'battery_id',
      render: text => renderText(text),
    },
    {
      title: 'Voltage',
      dataIndex: 'voltage',
      key: 'voltage',
      render: text => renderText(Number(text).toFixed(2)),
    },
    {
      title: 'Charging Duration',
      dataIndex: 'charging_duration',
      key: 'charging_duration',
      render: text => renderText(text ? text : '-'),
    },
    {
      title: 'SoC',
      key: 'soc',
      dataIndex: 'soc',
      render: text => renderText(text)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => renderText(text, convertColorStatus(text)),
    },
  ];

  const [formPagination, setFormPagination] = useState<FormPagination>({
    limit: 10,
    page: 1
  });

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const onClickMenuItem = ({ key: keyItem }, keyFilter) => {
    setActiveFilter(prevState => ({
      ...prevState,
      [keyFilter]: keyItem
    }));
  };

  useEffect(() => {
    const status = activeFilter?.status === 'All Status' ? '' : activeFilter?.status === 'Available' ? 'bms is available' : activeFilter?.status?.toLowerCase();
    const paramx = {
      ...formPagination,
      page: refreshing ? 1 : formPagination?.page,
      sort: 'desc' as SortOrder,
      status,
      search,
    };
    if (params?.stationId) {
      getHoleStation(params.stationId, paramx);

    }
  }, [search, activeFilter.status]);

  const findIndexAvailable = (object: HoleStation[], value: number) => {
      return object.find(obj => { return Number(obj.index_hole) === value; });
  };

  const dataConverted = () => {

    const holeStationEmpty = [] as HoleStation[];
    for (let index = 0; index < totalSlot; index++) {
      
      const findHole = findIndexAvailable(holeStation, index);

      if (findHole) {
        holeStationEmpty.push({
          index_hole: findHole?.index_hole,
          battery_id: findHole?.battery_id,
          voltage: findHole?.voltage,
          soc: findHole?.soc,
          status: findHole?.status,
        });
      } else {
        holeStationEmpty.push({
          index_hole: String(index),
          battery_id: '',
          voltage: '',
          soc: '',
          status: '',
        });
      }
    }
    return holeStationEmpty;
  };

  const dataConvert = (activeFilter?.status === 'All Status' && search.length === 0) ? dataConverted() : holeStation;

  return (
    <Dashboard
      filterMenu={ {
        dropdownsFilter,
        activeFilter,
        onClickMenuItem,
        search,
        placeholderSearch: 'Search',
        onChangeSearch
      } }
      data={ {
        currentData: dataConvert,
        loading: false,
        fieldName: 'holeStations'
      } }
      table={ {
        columns,
        rowKey: 'batteryId'
      } }
    />
  );
};

export default BoxHoleStation;
