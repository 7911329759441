import React, {
	useCallback,
	useEffect,
	useState
} from 'react';

import {
	Text,
	Button,
	Input,
	Modal as CustomModal
} from 'components';
import {
	hooks,
	misc,
	toastify
} from 'helpers';
import { Colors } from 'consts';
import { DataTicketing } from 'interfaces/ticketing';
import { language } from 'language';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';
import SearchInput, { DataOption } from 'components/Input/SearchInput';
import { Admin } from 'interfaces/admin';

type ModalSolveCaseProps = {
	caseDetail: DataTicketing;
	visible: boolean;
	closeModal: () => void;
	setRefreshing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const RenderModalReassignCS: React.FC<ModalSolveCaseProps> = ({
	caseDetail,
	visible,
	closeModal,
	setRefreshing
}) => {
	const dispatch = hooks.useAppDispatch();
	const getAllAdmin = dispatch(actions.getAllAdmin);
	const reassignCS = dispatch(actions.reassignCS);

	const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

	const [selectedUserId, setSelectedUserId] = useState<number>(0);
	const [reason, setReason] = useState<string>('');
	const [searchUser, setSearchUser] = useState<string>('');
	const [dataSearchUser, setDataSearchUser] = useState<DataOption[]>([]);
	const [openDropdownUser, setOpenDropdownUser] = useState<boolean>(false);

	const debouncedSearchUser = hooks.useDebounce(searchUser, 1200);

	// Handle search & select admin
	useEffect(() => {
		if (openDropdownUser) {
			const params = {
				page: 1,
				limit: 20,
				search: debouncedSearchUser,
				role_name: 'Customer Service',
				except_me: true
			};

			getAllAdmin(params, (admins: Admin[]) => {
				if (admins) {
					const dataCustomerServices = admins?.reduce((filtered: DataOption[], option: Admin) => {
						if (option?.id !== caseDetail?.admin_id) {
							const adminOption = {
								value: option?.id,
								text: option?.name
							};

							filtered.push(adminOption);
						}

						return filtered;
					}, []);

					setDataSearchUser(dataCustomerServices);
				} else {
					setDataSearchUser([]);
				}
			});
		}
	}, [debouncedSearchUser, openDropdownUser]);

	const onCloseModal = () => {
		setReason('');
		setSearchUser('');
		setDataSearchUser([]);
		setSelectedUserId(0);
		closeModal();
	};

	const onChangeFormText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setReason(e.target.value);
	}, []);

	const onClickReassignCS = async() => {
		reassignCS(
			caseDetail?.id?.toString(),
			{
				cs_id: + selectedUserId,
				notes: reason
			},
			() => {
				toastify.success(`Successfully reassign cs to ${ caseDetail?.case_code || '' }`);

				closeModal();

				if (setRefreshing) setRefreshing(true);
			}
		);
	};

	const renderButtonReassign = () => {
		const isLoading = misc.isLazyLoading(lazyLoad, 'reassignCS');

		return (
			<Button
				size='m'
				disabled={ !reason || !selectedUserId }
				text={ language.ticketing.reassignCS }
				mt={ 30 }
				isLoading={ isLoading }
				onClick={ onClickReassignCS }
			/>
		);
	};

	const handleSearchUser = useCallback((value: string) => {
		setSearchUser(value);
	}, []);

	const handleInitUser = value => {
		const selectedUser = dataSearchUser?.find(user => user?.value === value);
		const textOnBoxInput = selectedUser ? selectedUser?.text : '';

		setSearchUser(textOnBoxInput);
	};

	const handleChangeSearchUser = value => {
		handleInitUser(value);

		setSelectedUserId(+ value);
	};

	const onDropdownUserVisibleChange = (open: boolean) => {
		handleInitUser(selectedUserId);
		setOpenDropdownUser(open);
	};

	const renderSearchUser = () => {
		const loadingGetUserBySearch = misc.isLazyLoading(lazyLoad, 'allUser');

		return (
			<SearchInput
				label='Assign CS'
				labelColor={ Colors.grey.isGrey }
				value={ selectedUserId && selectedUserId > 0 ? selectedUserId : '' }
				placeholder='Select CS'
				data={ dataSearchUser }
				loading={ loadingGetUserBySearch }
				searchValue={ searchUser }
				onSearch={ handleSearchUser }
				onChange={ handleChangeSearchUser }
				backgroundColor={ Colors.white.default }
				onDropdownVisibleChange={ onDropdownUserVisibleChange }
			/>
		);
	};

	return (
		<CustomModal
			visible={ visible }
			onCloseModal={ onCloseModal }
			modalType='long-scroll'
			padding={ 30 }
			bodyStyle={ {
				marginTop: 50,
				marginBottom: 30
			} }
			width={ 500 }
			title={ () => (
				<Text
					text={ language.ticketing.reassignCS }
					size='xl'
					weight={ 700 }
				/>
			) }
		>
			<div>
				{ renderSearchUser() }

				<div className='mt4'>
					<Input
						label='Reason'
						key='reason'
						type='textArea'
						labelColor={ Colors.grey.isGrey }
						backgroundColor={ Colors.white.default }
						value={ reason }
						onChange={ onChangeFormText }
					/>
				</div>

				{ renderButtonReassign() }
			</div>
		</CustomModal>
	);
};

export default RenderModalReassignCS;
