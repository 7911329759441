import React, { useState } from 'react';
import {
  Drawer,
  Empty,
  Popover
} from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';

import { Colors, Images } from 'consts';
import {
  hooks,
  navigation,
  screen
} from 'helpers';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import Dropdown from '../Dropdown';
import Box from '../Box';
import Logo from '../Logo';
import Icon from '../Icon';
import Text from '../Text';
import Image from '../Image';
import Badge from '../Badge';
import Divider from '../Divider';
import Spacer from '../Spacer';

import {
  NavbarStyle,
  NavbarDrawerStyle,
  PopoverStyle
} from './style';

type backButton = 'circle' | 'square' | 'none';
type NavbarProps = {
  backButton?: backButton;
  backIconName?: string;
  leftText?: string;
  logo?: boolean;
  leftClassName?: string;
  midClassName?: string;
  rightClassName?: string;
  onClickBack?: () => void;
  navbarDrawer?: boolean;
  menu?: React.ReactNode;
};

const Navbar: React.FC<NavbarProps> = ({
  backButton = '',
  backIconName = 'arrowDown',
  leftText = 'Back',
  logo = false,
  leftClassName,
  midClassName,
  rightClassName,
  onClickBack,
  navbarDrawer = false,
  menu
}) => {
  const dispatch = hooks.useAppDispatch();
  const logout = dispatch(actions.logout);
  const readAllNotification = dispatch(actions.readAllNotification);
  const getAllNotification = dispatch(actions.getAllNotification);

  const username = hooks.useAppSelector(selectors.auth.username);
  const avatar = hooks.useAppSelector(selectors.auth.adminImage);
  const adminRole = hooks.useAppSelector(selectors.auth.adminRole);
  const notifList = hooks.useAppSelector(selectors.notif.notifList);
  const newUnreadMessage = hooks.useAppSelector(selectors.notif.newUnreadMessage);

  const [visible, setVisible] = useState<boolean>(false);
  const [openSubmenuLogout, setOpenSubmenuLogout] = useState<boolean>(false);
  const [hoverBackContainer, setHoverBackContainer] = useState<boolean>(false);
  const [hoverText, setHoverText] = useState<boolean>(false);
  const [hoverIconLeave, setHoverIconLeave] = useState<boolean>(false);
  const [seeAllNotification, setSeeAllNotification] = useState<boolean>(false);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();
  const overlayDataMenuLogout = [
    {
      key: '1',
      item: () => renderMenuLogout(false)
    }
  ];

  const onMouseEnter = (hovered: string) => {
    if (hovered === 'text') {
      setHoverText(true);
    } else if (hovered === 'ic-leave') {
      setHoverIconLeave(true);
    } else {
      setHoverBackContainer(true);
    }
  };

  const onMouseLeave = (hovered: string) => {
    if (hovered === 'text') {
      setHoverText(false);
    } else if (hovered === 'ic-leave') {
      setHoverIconLeave(false);
    } else {
      setHoverBackContainer(false);
    }
  };

  const renderBackButton = () => {
    if (backButton === 'circle') {
      return (
        <>
          <Icon
            size={ 13 }
            iconName={ backIconName }
            className='back-icon'
            fill={ Colors.grey.isGrey }
            hoverFill={ Colors.blue.isBlue }
            container='circle'
            containerColor={ Colors.grey.isLighterGrey }
            containerHoverColor={ Colors.blue.isBlueOpacity('0.1') }
            containerSize={ 32 }
            containerClassName='back-container'
            onClick={ onClickBack }
            onMouseEnter={ () => onMouseEnter('backContainer') }
            onMouseLeave={ () => onMouseLeave('backContainer') }
          />
          <div onMouseEnter={ () => onMouseEnter('text') } onMouseLeave={ () => onMouseLeave('text') }>
            <Text
              size='m'
              lineHeight={ 19 }
              color={ hoverBackContainer ? Colors.blue.isBlueOpacity('0.8') : Colors.grey.isGrey }
              className='p1 back-text'
              onClick={ onClickBack }
              text={ leftText }
            />
          </div>
        </>
      );
    } else if (backButton === 'square') {
      return (
        <>
          <Icon
            size={ 13 }
            iconName='leftBack'
            fill={ Colors.grey.isGrey }
            hoverFill={ Colors.blue.isBlue }
            container='square'
            containerColor={ Colors.grey.isLighterGrey }
            containerHoverColor={ Colors.blue.isBlueOpacity('0.1') }
            containerSize={ 32 }
            containerClassName='back-container'
            onClick={ onClickBack }
          />
        </>
      );
    }
  };

  const renderLogo = () => (
    <Logo width={ 27.98 } height={ 30 } />
  );

  const onClickLogout = () => logout();

  const renderMenuLogout = (changeColor?: boolean) => {
    const color = (hoverIconLeave || hoverText) && changeColor
      ? Colors.blue.isBlue
      : Colors.grey.isGrey;

    return (
      <div className='flex row' onClick={ onClickLogout }>
        <Icon
          iconName='logout'
          size={ 20 }
          fill={ color }
          onMouseEnter={ () => onMouseEnter('ic-leave') }
          onMouseLeave={ () => onMouseLeave('ic-leave') }
        />
        <div onMouseEnter={ () => onMouseEnter('text') } onMouseLeave={ () => onMouseLeave('text') }>
          <Text
            lineHeight={ 19 }
            color={ color }
            ml={ 12 }
            mt={ 2 }
            className='p1 pointer'
          >Logout</Text>
        </div>
      </div>
    );
  };

  const handleOnVisibleChangeDropdownLogout = (visible: boolean) => {
    setOpenSubmenuLogout(visible);
  };

  const onClickOpenSubmenuLogout = () => {
    setOpenSubmenuLogout(!openSubmenuLogout);
  };

  const renderFooterContentNotif = () => {
    return (
      <>
        <Divider marginVertical={ 10 } />

        <div className='pointer' onClick={ () => setSeeAllNotification(!seeAllNotification) }>
          <Text
            weight={ 700 }
            align='center'
            color={ Colors.black.isTextBlack3 }
          >
            { seeAllNotification ? 'Hide notifications' : 'See all notifications' }
          </Text>
        </div>
      </>
    );
  };

  const onClickNotifDetail = (url?: string) => {
    navigation.push(url ?? '/dashboard/customer-services');
  };

  const contentNotification = () => {
    const isNotifExist = notifList && notifList?.length;
    const widthBoxNotif = windowDimensions.width <= screen.sizes.sx
      ? windowDimensions.width / 1.8
      : 275;
    const sliceDataNotif = isNotifExist && notifList?.length >= 3 && !seeAllNotification
      ? notifList.slice(0, 3)
      : notifList;

    return (
      <PopoverStyle>
        <div className='popover-inner'>
          { isNotifExist
            ? (
              sliceDataNotif.map((notif, index) => {
                return (
                  <div
                    key={ index }
                    className='default'
                    style={ { width: widthBoxNotif } }
                  >
                    { index > 0 && <Divider marginVertical={ 0 } /> }
                    <div className='content' onClick={ () => onClickNotifDetail(notif?.detail?.cta?.url) }>
                      <FileSearchOutlined style={ { fontSize: '20px' } } />
                      <Spacer ml={ 15 }>
                        <Text
                          size='xs'
                          color={ Colors.red.isRed }
                          weight={ 700 }
                          className='p1 title-missing'
                          mb={ 5 }
                        >{ notif.msg_title }</Text>
                        <Text
                          size='xxs'
                          color={ Colors.grey.isGreyGreen }
                          className='p1 note-missing'
                        >{ notif.msg }</Text>
                      </Spacer>
                    </div>
                  </div>
                );
              })
            )
            : (
              <div style={ { width: widthBoxNotif } }>
                <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
              </div>
            ) }
        </div>
        { isNotifExist
          ? renderFooterContentNotif()
          : null }
      </PopoverStyle>
    );
  };

  const iconNotifMobile = () => {
    return (
      <div className='menu-mobile'>
        <Icon
          iconName='notif'
          size={ 25 }
          fill={ Colors.grey.isGrey }
          hoverFill={ Colors.blue.isBlue }
        />
      </div>
    );
  };

  const iconNotifWeb = () => {
    return (
      <div className='mr6'>
        <Badge
          offset={ [-7, 6] }
          size={ 10 }
          border='1px solid white'
          color={ newUnreadMessage ? Colors.blue.isBlue : Colors.grey.isGrey }
        >
          <Icon
            iconName='notif'
            size={ 18 }
            fill={ Colors.grey.isGrey }
            hoverFill={ Colors.blue.isBlue }
            container='circle'
            containerHoverColor={ Colors.blue.isBlueOpacity('0.1') }
            containerColor={ Colors.grey.isLighterGrey }
            containerSize={ 36 }
          />
        </Badge>
      </div>
    );
  };

  const renderNotifIcon = () => {
    const isNavbarMobile = windowDimensions.width <= screen.sizes.sx;

    return (
      <Popover
        trigger='click'
        title={ () => <Text weight={ 700 } mt={ 5 }>Notifications</Text> }
        content={ contentNotification }
        placement={ isNavbarMobile ? 'bottomRight' : 'bottom' }
        onOpenChange={ (visible: boolean) => {
          if (!visible) {
            setSeeAllNotification(false);
          } else {
            readAllNotification(() => getAllNotification());
          }
        } }
      >
        {
          isNavbarMobile
            ? iconNotifMobile()
            : iconNotifWeb()
        }
      </Popover>
    );
  };

  if (navbarDrawer) {
    return (
      <NavbarDrawerStyle
        openSubmenu={ openSubmenuLogout }
        hoverText={ hoverText }
        windowWidth={ windowDimensions.width }
      >
        <nav className='navbar'>
          <div className='menu-content-web'>
            <div className={ `left-container ${ leftClassName }` }>
              { backButton && renderBackButton() }
            </div>
            <div className='flex row align-center'>
              <div className='mr4'>
                <Icon
                  iconName='settings'
                  size={ 18 }
                  fill={ Colors.grey.isGrey }
                  hoverFill={ Colors.blue.isBlue }
                  container='circle'
                  containerColor={ Colors.grey.isLighterGrey }
                  containerHoverColor={ Colors.blue.isBlueOpacity('0.1') }
                  containerSize={ 36 }
                />
              </div>
              { renderNotifIcon() }

              <Dropdown
                visible={ openSubmenuLogout }
                onVisibleChange={ handleOnVisibleChangeDropdownLogout }
                overlayData={ overlayDataMenuLogout }
                onClickMenu={ onClickLogout }
                trigger={ ['click'] }
              >
                <div className='profile-container' onClick={ onClickOpenSubmenuLogout }>
                  <Image
                    src={ avatar || Images.avatar }
                    width={ 42 }
                    height={ 42 }
                    external={ !!avatar }
                    type='circle'
                    style={ { objectFit: 'cover' } }
                  />
                  <div className='profile'>
                    <Text weight={ 600 } mb={ 5 }>{ username }</Text>
                    <Text
                      size='xs'
                      weight={ 400 }
                      color={ Colors.grey.isGrey }
                      lineHeight={ 15 }
                    >{ adminRole }</Text>
                  </div>
                  <Icon
                    iconName='arrowDown'
                    size={ 13 }
                    fill={ Colors.blue.isBlue }
                    className='ic-arrow'
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          <div className='menu-content-mobile'>
            <div className='menu-mobile' onClick={ () => setVisible(true) }>
              <Icon
                iconName='menu'
                size={ 30 }
                fill={ Colors.grey.isGrey }
                hoverFill={ Colors.blue.isBlue }
              />
            </div>
            <div className='menu-mobile'>
              { renderLogo() }
            </div>

            { renderNotifIcon() }

          </div>
          <Drawer
            placement='left'
            onClose={ () => setVisible(false) }
            visible={ visible }
            closeIcon={ false }
            width={ windowDimensions.width <= screen.isMobile ? '65%' : '378px' }
          >
            <Box
              shadow={ false }
              border={ `0.5px solid ${ Colors.grey.isLightGrey }` }
              mb={ 30 }
              padding={ 15 }
            >
              <div className='flex row align-center'>
                <Image
                  src={ Images.avatar }
                  width={ 42 }
                  height={ 42 }
                />
                <div className='flex col ml3'>
                  <Text
                    size='xs'
                    weight={ 700 }
                    lineHeight={ 17 }
                    mb={ 5 }
                    color={ Colors.black.isTextBlack3 }
                  >{ username }</Text>
                  <Text
                    size='xxs'
                    lineHeight={ 17 }
                    color={ Colors.black.isTextBlack3 }
                  >{ adminRole }</Text>
                </div>
              </div>
            </Box>
            { menu }
            { renderMenuLogout(true) }
          </Drawer>
        </nav>
      </NavbarDrawerStyle>
    );
  }

  return (
    <NavbarStyle
      hoverText={ hoverText }
      backIconName={ backIconName }
      screenWidth={ windowDimensions.width }
    >
      <div className={ `left-container ${ leftClassName }` }>
        { backButton && renderBackButton() }
      </div>
      <div className={ `mid-container ${ midClassName }` }>
        { logo && renderLogo() }
      </div>
      <div className={ `right-container ${ rightClassName }` } />
    </NavbarStyle>
  );
};

export default Navbar;
