import { createSelector } from 'reselect';

import { moment } from 'helpers';

import { RootState } from '../index';
import { BatteryPackStatistic } from 'interfaces/battery';

const batterySelector = (state: RootState) => state.battery;

export const batteryPacks = createSelector(batterySelector, battery => battery.batteryPacks);

export const batteryPackDetail = createSelector(batterySelector, battery => battery.batteryPackDetail);

const setFormatDate = (filter: string) => {
  switch (filter) {
    case 'current':
      return 'HH:mm';
    default:
      return 'YYYY-MM-DD';
  }
};

const setFormattedListData = (list: BatteryPackStatistic[], filter: string) => {
  const formatDate = setFormatDate(filter);
  
  const updattedList = list.map(data => ({
    ...data,
    date: moment.dateConverted(
      data.date,
      filter === 'current' ? 'YYYY-MM-DD hh:mm:ss' : formatDate,
      formatDate
    ),
    day: moment.dateConverted(
      data.day,
      'dddd',
      'ddd'
    )
  }));

  if (filter === '' && list.length) { // filter === '' adalah filter 'Daily'
    const start = moment.dateStrToMoment(list[list.length - 1]?.date, formatDate);
    const end = 7 - list.length;

    for (let i = 0; i < end; i++) {
      const date = start.subtract(1, 'day').format(formatDate);
      const day = moment.dateConverted(
        date,
        formatDate,
        'ddd'
      );
  
      updattedList.unshift({
        date,
        day,
        value: '0'
      });
    }
  }

  return updattedList;
};

export const listSoc = createSelector(batterySelector, battery => {
  const soc = battery.listSoc?.data;
  const filterTime = battery.listSoc?.filter_time;

  return setFormattedListData(soc, filterTime);
});

export const listSoh = createSelector(batterySelector, battery => {
  const soc = battery.listSoh?.data;
  const filter = battery.listSoh?.filter_time;

  return setFormattedListData(soc, filter);
});

export const listDischarge = createSelector(batterySelector, battery => battery.listDischarge.data);

export const listTemp = createSelector(batterySelector, battery => battery.listTemp.data);

export const vinLog = createSelector(batterySelector, battery => battery.vinLog);

export const batteryPackBatteryUsage = createSelector(batterySelector, battery => battery.batteryPackBatteryUsage);