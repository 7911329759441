import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

type IndicatorStyleProps = {
  isActiveCarousel?: boolean;
};

export const ModalReportStyle: any = Styled.div `
  @media ${screen.sizesMax.xs} {
    .image-warning {
      width: 95px;
      height: 95px; 
    }
  }
`;

export const IndicatorStyle: any = Styled.div < IndicatorStyleProps > `
  .indicator-scooter {
    background-color: ${props => props.isActiveCarousel ? Colors.blue.isBlue : Colors.grey.isGrey};
    width: ${props => props.isActiveCarousel ? '36px' : '8px'};
    height: 8px;
    border-radius: ${props => props.isActiveCarousel ? '100px' : '100%'};
    margin: 0 4.5px;
  }
`;