import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import {
  ApiData,
  ApiParams,
  CommonResponseData
} from 'interfaces/common';
import {
  FormScooter,
  Location,
  Scooter,
  ScooterApiParams,
  BatteryUsage,
  ScooterInfo,
  VariantScooter,
  DataTripHistory,
  TripHistoryParams,
  DataStationsVisited
} from 'interfaces/scooter';

export const getAllScooter = async(params: ScooterApiParams) => {
  try {
    const queryParams: ScooterApiParams = {
      ...params,
      sort: (params.sort || 'asc'),
      order: (params.order || 'vin'),
    };

    const response: ApiData<Scooter[]> = await customFetch(Endpoints.getAllScooter(), queryParams);

    return response;
  } catch (error) {
    throw error;
  }
};

export const addScooter = async(formAddScooter: FormScooter) => {
  try {
    const response: ApiData<any> = await customFetch(Endpoints.addScooter(), formAddScooter);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editScooter = async(vin: string, formEditScooter: FormScooter) => {
  try {
    const response: ApiData<CommonResponseData> = await customFetch(Endpoints.editScooter(vin), formEditScooter);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getScooterDetail = async(vin: string) => {
  try {
    const response: ApiData<ScooterInfo> = await customFetch(Endpoints.getScooterDetail(vin));

    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllScooterVariant = async() => {
  try {
    const queryParams: ScooterApiParams = {
      page: 1,
      limit: 100,
      sort: 'asc',
      search: '',
      order: 'id'
    };

    const response: ApiData<VariantScooter[]> = await customFetch(Endpoints.getAllScooterVariant(), queryParams);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getScooterLocation = async(vin: string, params: ScooterApiParams) => {
  try {
    const response: ApiData<Location[]> = await customFetch(Endpoints.getScooterLocation(vin), params);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getScooterBatteryUsages = async(vin: string, params: ScooterApiParams) => {
  try {

    const response: ApiData<BatteryUsage> = await customFetch(Endpoints.getScooterBatteryUsages(vin), params);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTripHistory = async(params: TripHistoryParams) => {
  try {
    const response: ApiData<DataTripHistory[]> = await customFetch(Endpoints.getTripHistoryScooter(), params);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getStationVisited = async(vin: string, params: ApiParams) => {
  try {
    const response: ApiData<DataStationsVisited[]> = await customFetch(Endpoints.getStationVisited(vin), params);
    return response;
  } catch (error) {
    throw error;
  }
};
