import Styled from 'styled-components';

export const StatsStyle: any = Styled.div`
  .container-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 1120px) {
    .container-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;
