import React from 'react';

import { Colors, Endpoints } from 'consts';
import { misc, toastify } from 'helpers';
import { language } from 'language';

import { ButtonContactStyle } from './style';

import Button from '../index';

type ButtonContactProps = {
  phone: string;
  withText?: boolean;
  mt?: number;
};

const { contact, errorDefault } = language;

export const whatsappAction = async(phone: string) => {
  try {
    const phoneFormatted = misc.phoneNumberFormat(phone, '62');
    const url = Endpoints.whatsapp(phoneFormatted);

    const win = window.open(url, '_blank');

    if (win) {
      win.focus();
    }
  } catch (error) {
    toastify.error(errorDefault.notSupport);
  }
};

const ButtonContact: React.FC<ButtonContactProps> = ({
  phone,
  withText = true,
  mt = 15
}) => {
  return (
    <ButtonContactStyle withText={ withText }>
      <Button
        styleType='inverse'
        borderRadius={ 5 }
        mt={ mt }
        fontWeight={ 700 }
        icon={ {
          iconName: 'phone',
          fill: Colors.blue.isBlue
        } }
        className='btn-contact'
        text={ withText ? contact : '' }
        onClick={ () => whatsappAction(phone) }
      />
    </ButtonContactStyle>
  );
};

export default ButtonContact;
