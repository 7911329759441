import { Dispatch } from 'react';

import { toastify } from 'helpers';
import { ApiData } from 'interfaces/common';
import {
	DataTicketing,
	FormAssignCase,
	FormReassignCS,
	FormSolveCase,
	PastSimiliarCase,
	TicketingApiParams,
	TicketingDetail
} from 'interfaces/ticketing';
import { AppActions } from 'store';
import * as caseService from 'services/case';

import {
	GET_ALL_CASE,
	GET_DETAIL_CASE,
	GET_PAST_SIMILIAR_CASE
} from './actionTypes';

import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';
import { setPaginationData } from '../Pagination/action';

export const getAllCase = (dispatch: Dispatch<AppActions>) => (params: TicketingApiParams, callback?: () => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart(`allCase.${ params.status }`));

			const {
				code,
				data,
				pagination
			}: ApiData<DataTicketing[]> = await caseService.getAllCase(params);

			if (code === 200) {
				await dispatch({
					type: GET_ALL_CASE,
					data: {
						status: params.status || params.action || '',
						data,
						pagination
					}
				});

				await dispatch(setPaginationData(`cases.${ params.status }`, pagination));

				callback ? callback() : null;

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd(`allCase.${ params.status }`));
		}
	});
};

export const assignCS = (dispatch: Dispatch<AppActions>) => (
	caseId: string,
	formAssign: FormAssignCase,
	cbSuccess?: () => void,
	cbError?: () => void
) => {

	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('assignCS'));

			const { code }: ApiData<any> = await caseService.assignCS(caseId, formAssign);

			if (code === 200) {
				if (cbSuccess) cbSuccess();

				resolve();
			} else {
				if (cbError) cbError();
			}
		} catch (error) {
			if (cbError) cbError();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('assignCS'));
		}
	});
};

export const takeCase = (dispatch: Dispatch<AppActions>) => (
	caseId: string,
	cbSuccess?: () => void,
	cbError?: () => void
) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('takeCase'));

			const { code }: ApiData<any> = await caseService.takeCase(caseId);

			if (code === 200) {
				if (cbSuccess) cbSuccess();

				resolve();
			} else {
				if (cbError) cbError();
			}
		} catch (error) {
			if (cbError) cbError();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('takeCase'));
		}
	});
};

export const reassignCS = (dispatch: Dispatch<AppActions>) => (
	caseId: string,
	formReassign: FormReassignCS,
	cbSuccess?: () => void,
	cbError?: () => void
) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('reassignCS'));

			const { code }: ApiData<any> = await caseService.reassignCS(caseId, formReassign);

			if (code === 200) {
				if (cbSuccess) cbSuccess();

				resolve();
			} else {
				if (cbError) cbError();
			}
		} catch (error) {
			if (cbError) cbError();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('reassignCS'));
		}
	});
};

export const solveCase = (dispatch: Dispatch<AppActions>) => (
	caseId: string,
	formSolveCase: FormSolveCase,
	cbSuccess?: () => void
) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('solveCase'));

			const { code }: ApiData<any> = await caseService.solveCase(caseId, formSolveCase);

			if (code === 200) {
				if (cbSuccess) cbSuccess();

				resolve();
			}
		} catch (error) {
			reject(error);
		} finally {
			dispatch(lazyLoadEnd('solveCase'));
		}
	});
};

export const getDetailCase = (dispatch: Dispatch<AppActions>) => (caseId: string, callback?: () => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			const { code, data }: ApiData<TicketingDetail> = await caseService.getDetailCase(caseId);

			if (code === 200) {
				await dispatch({
					type: GET_DETAIL_CASE,
					data
				});

				resolve();
			} else {
				await dispatch({
					type: GET_DETAIL_CASE,
					data: null
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();

			dispatch({
				type: GET_DETAIL_CASE,
				data: null
			});

			reject(error);
		} finally {
			if (callback) callback();
		}
	});
};

export const getPastSimiliarCase = (dispatch: Dispatch<AppActions>) => (caseId: string, callback?: () => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			const { code, data }: ApiData<PastSimiliarCase[]> = await caseService.getPastSimiliarCase(caseId);

			if (code === 200) {
				await dispatch({
					type: GET_PAST_SIMILIAR_CASE,
					data
				});

				resolve();
			} else {
				await dispatch({
					type: GET_PAST_SIMILIAR_CASE,
					data: []
				});
			}
		} catch (error) {
			toastify.errorDefaultApi();

			dispatch({
				type: GET_PAST_SIMILIAR_CASE,
				data: []
			});

			reject(error);
		} finally {
			if (callback) callback();
		}
	});
};
