export interface Role {
  id: number;
  name: string;
}

export enum RoleType {
  super_admin = 'Super Admin',
  showroom_admin = 'Showroom Admin',
  customer_service = 'Customer Service'
}

export enum Permission {
  // Stats
  stats_all = 'stats.all',

  // Iscoot - Inventory
  // todo: minta tambahkan ke BE untuk permission inventory.update, inventory.report_missing
  inventory_list = 'inventory.list',
  inventory_create = 'inventory.create',
  inventory_detail = 'inventory.detail',
  inventory_change_status = 'inventory.change_status',
  inventory_update = 'inventory.update',
  inventory_report_missing = 'inventory.report_missing',

  // Iscoot - User Profile (Customer)
  // todo: minta tambahkan ke BE untuk permission user.update, user.pairing_scooter
  users_list = 'users.list',
  user_verify = 'user.verify',
  user_create = 'user.create',
  user_update = 'user.update',
  user_profile = 'user.profile',
  user_detail_legal_info = 'user.detail_legal_info',
  user_assign_showroom = 'user.assign_showroom',
  user_pairing_scooter = 'user.pairing_scooter',

  // Iscoot - Showroom
  // todo: minta tambahkan ke BE untuk permission showroom.delete, atau fitur delete showroom dihapus?
  showroom_list = 'showroom.list',
  showroom_detail = 'showroom.detail',
  showroom_create = 'showroom.create',
  showroom_update = 'showroom.update',
  showroom_delete = 'showroom.delete',

  // Battery Pack
  battery_list = 'battery.list',
  battery_change_status = 'battery.change_status',
  battery_detail = 'battery.detail',
  battery_create = 'battery.create',

  // Battery Station
  battery_station_list = 'battery_station.list',
  battery_station_create = 'battery_station.create',
  battery_station_detail = 'battery_station.detail',
  battery_station_update = 'battery_station.update',
  battery_station_change_status = 'battery_station.change_status',

  // Admin (User Management)
  admin_list = 'admin.list',
  admin_create = 'admin.create',
  admin_update = 'admin.update',
  admin_change_password = 'admin.change_password',
  admin_detail = 'admin.detail',

  // Subscription
  // todo: minta tambahkan ke BE untuk permission subscription.delete, atau fitur delete subs dihapus?
  subscription_list = 'subscription.list',
  subscription_create = 'subscription.create',
  subscription_detail = 'subscription.detail',
  subscription_delete = 'subscription.delete',
  subscription_update = 'subscription.update',
  subscription_change_status = 'subscription.change_status',

  // Customer Services
  call_me_list = 'call_me.list',
  call_me_assign_cs = 'call_me.assign_cs',
  call_me_take_issue = 'call_me.take_issue',
  call_me_reassign_cs = 'call_me.reassign_cs',

  case_list = 'case.list',
  case_take = 'case.take',
  case_solve = 'case.solve'
}