import {
	LOADING,
	LAZY_LOAD,
	RESET_ALL_LOADING,
	LOGOUT,
	MiscActionTypes
} from './actionTypes';

interface MiscState {
	loading: {
		isLoading?: boolean;
		loadingMsg?: string;
	},
	lazyLoad: {
		[key: string]: boolean;
	};
	shouldPopup: boolean;
}

const initState: MiscState = {
	loading: {
		isLoading: false,
		loadingMsg: '',
	},
	lazyLoad: {},
	shouldPopup: true,
};

const miscReducer = (state = initState, action: MiscActionTypes): MiscState => {
	switch (action.type) {
		case LOADING:
			return {
				...state,
				loading: {
					isLoading: action.data.isLoading,
					loadingMsg: action.data.loadingMsg
				}
			};

		case LAZY_LOAD:
			return {
				...state,
				lazyLoad: {
					...state.lazyLoad,
					[action.data.fieldName]: action.data.isLoading,
				},
			};

		case RESET_ALL_LOADING:
		case LOGOUT:
			return initState;

		default:
			return state;
	}
};

export default miscReducer;
