import { ApiParams } from './common';

export interface CitiesForm {
  city: string;
}

export interface FormSubscription {
	name: string;
	subscription_type: string;
	description: string;
	duration: string | number;
	swap_amount: string | number;
	price: string | number;
	is_active: string | boolean;
	cities: any[] | null;
}

export interface SubscriptionApiParams extends ApiParams {
	type?: string;
	is_active?: string | boolean;
}

export interface Subscription extends FormSubscription {
	id: number;
	duration: number;
	price: number;
	is_active: boolean;
}

export enum SubscriptionType {
	time_base = 'time_base',
	swap_base = 'swap_base'
}