import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { ColumnsType } from 'antd/es/table';

import {
  Input,
  ModalConfirm,
  Dashboard,
  Text,
  Icon,
  MapsLeaflet,
	SelectSearchBar,
  Switch,
} from 'components';
import {
  renderText,
  renderTooltip,
  renderButtonAction
} from 'components/DataTable';
import { Colors, MapConfig } from 'consts';
import {
  toastify,
  hooks,
  misc,
  validation
} from 'helpers';
import {
  CurrentPagination,
  FormPagination,
  SorterInfo,
	ReducerList,
	SelectOptionValue
} from 'interfaces/common';

import {
  FormShowroom,
  Showroom,
  ShowroomApiParams,
} from 'interfaces/showroom';
import { Cities, CitiesApiParams } from 'interfaces/location';
import { Permission } from 'interfaces/role';
import { ActionModalForm, ValueType } from 'typings';
import { language } from 'language';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { inputPropsData } from './data';
import { ItemDropdown, ModalFormShowroomStyle } from './style';

const { errorFormMessage } = language;

const renderItemSearchAddress = (title: string, address?: string) => {
  return (
    <ItemDropdown>
      <div className='container-icon'>
        <Icon iconName='pinLocation' fill={ Colors.grey.isGrey } />
      </div>

      <div className='item-text-wrapper'>
        <Text size='m' weight={ 700 }>{ title }</Text>

        <div>
          <Text color={ Colors.grey.isGreyGreen } className='p1 description-text'>{ address || '' }</Text>
        </div>
      </div>
    </ItemDropdown>
  );
};

type Filter = 'city';

type VisibleFilter = {
  [key in Filter]: boolean;
};

const ShowroomScreen: React.FC = () => {

	const [visibleFilter, setVisibleFilter] = useState<VisibleFilter>({ city: false });

  const dispatch = hooks.useAppDispatch();
  const getAllShowroom = dispatch(actions.getAllShowroom);
  const addShowroom = dispatch(actions.addShowroom);
  const editShowroom = dispatch(actions.editShowroom);
  const deleteShowroom = dispatch(actions.deleteShowroom);
	const getAllCities = dispatch(actions.getAllCities);
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const showrooms = hooks.useAppSelector(selectors.showroom.showrooms);
	
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SorterInfo>({
    sort: null,
    order: ''
  });
  const [search, setSearch] = useState<string>('');
  const [modalFormVisible, setModalFormVisible] = useState<boolean>(false);
  const [modalConfirmVisible, setModalConfirmVisible] = useState<boolean>(false);
	const [dataSelectedProvinces, setDataSelectedProvinces] = useState<SelectOptionValue[]>([]);
  const [formPagination, setFormPagination] = useState<FormPagination>({
    limit: 10,
    page: 1
  });
  const [form, setForm] = useState<FormShowroom>({
    name: '',
    phone_number: '',
    email: '',
    address: '',
    pic_name: '',
    latitude: 0,
    longitude: 0,
		zip_code: '',
		city: '',
    is_pinned: false,
  });
	const defaultOptionsProvinces = [
		{
			label: 'Choose City',
			value: ''
		},
	];
  const [errorForm, setErrorForm] = useState<FormShowroom>({
    name: '',
    phone_number: '',
    email: '',
    address: '',
    pic_name: '',
    latitude: '',
    longitude: '',
		zip_code: '',
		city: '',
    is_pinned: false,
  });
  const [actionModalForm, setActionModalForm] = useState<ActionModalForm>('add');
  const [editedIndex, setEditedIndex] = useState<number>(-1);
  const [deletedIndex, setDeletedIndex] = useState<number>(-1);
  const [isContentPinpoint, setIsContentPinpoint] = useState<boolean>(false);
  const [valueGeosearch, setValueGeosearch] = useState<ValueType>({
    shortLabel: '',
    label: '',
    value: '',
    postcode: '',
    valueDetail: MapConfig.defaultCenter
  });

  const debouncedSearch = hooks.useDebounce(search, 1200);
  const loadingShowrooms = misc.isLazyLoading(lazyLoad, 'allShowroom');

  const buttonsDataTable = [
    {
      iconName: 'search',
      color: 'blue',
      onClick: (record: Showroom) => onClickOpenModal('detail', record),
      permission: Permission.showroom_detail
    }, {
      iconName: 'delete',
      color: 'red',
      onClick: (record: Showroom) => onClickOpenModal('delete', record),
      permission: Permission.showroom_delete
    }
  ];

  const columns: ColumnsType<Showroom> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (_: any, record: Showroom) => renderName(record)
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: true,
      render: text => renderText(text)
    },
    {
      title: 'Contact',
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: text => renderText(text),
    },
    {
      title: 'PIC',
      key: 'pic_name',
      dataIndex: 'pic_name',
      sorter: true,
      render: text => renderText(text)
    },
    {
      title: 'Available Scooter(s)',
      key: 'available',
      dataIndex: 'available',
      ellipsis: true,
      sorter: true,
      render: text => renderText(misc.setMultiplierWording(text.toString()))
    },
    {
      title: 'Total Unit',
      key: 'total',
      dataIndex: 'total',
      sorter: true,
      render: text => renderText(misc.setMultiplierWording(text.toString()))
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: 200,
      ellipsis: { showTitle: false },
      render: text => renderTooltip(text)
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_: any, record: Showroom) => renderButtonAction(buttonsDataTable, record)
    },
  ];

  const renderName = (data: Showroom) => {
    return (
      <div className='flex'>
        <div> { renderText(data.name) } </div>
        { data.is_pinned ?
          <div style={ {
            backgroundColor: '#60BBC3',
            color: 'white',
            fontSize: '9px',
            borderRadius: '5px',
            padding: '2px 5px',
            fontWeight: 'bold',
            marginLeft: '5px',
            height: '100%',
          } }>HQ</div> : <></>
        }
      </div>
    );
  };

  const getDataShowrooms = () => {
    const params: ShowroomApiParams = {
      ...formPagination,
      search: debouncedSearch,
      sort: (sortOrder.sort || 'asc'),
      order: (sortOrder.order || 'name')
    };

    getAllShowroom(params);
  };

  useEffect(() => {
    getDataShowrooms();
  }, [
    formPagination.page,
    formPagination.limit,
    debouncedSearch,
    sortOrder.sort,
    sortOrder.order
  ]);

  // Handle refreshing, ex: after add/edit/delete
  useEffect(() => {
    if (refreshing) {
      getDataShowrooms();

      setRefreshing(false);
    }
  }, [refreshing]);

	const getCities = async params => {
		let dataCities;

		await getAllCities(params,
			(cities: ReducerList<Cities[]>) => {
				if (cities?.data) {

					dataCities = cities?.data?.map(list => ({
              key: list.city,
              value: list.city,
              label: list.province_name + '-' + list.city
          }));
				} else {
					dataCities = [];
				}
		});
			
		return dataCities;
	};

  const setInitialPage = () => {
    setFormPagination(prevFormPagination => ({
      ...prevFormPagination,
      page: 1
    }));
  };

  const onChangeFormText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevForm => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      [e.target.name]: ''
    }));
  }, []);

  const onChangeIshQ = (form: FormShowroom) => {
    setForm(prevForm => ({
        ...prevForm,
        is_pinned: !form.is_pinned
    }));
  };

  const renderSwtich = (key: string | number, label: any) => {
    const disabledInput = actionModalForm === 'detail';
    return (
    <>
      <Text
          lineHeight={ 17 }
          weight={ 500 }
          text={ label }
          color={ Colors.grey.isGrey }
          mb={ 10 }
        />
        <Switch
          disabled={ disabledInput }
          size='sm'
          checked={ form[key] }
          onChange={ () => onChangeIshQ(form) }
        />
      </>);
  };

  const renderInput = (inputProps: any) => {
    const {
      label,
      placeholder,
      key,
      type,
      iconName,
      labelClassName,
      inputClassName
    } = inputProps;
    const disabledInput = actionModalForm === 'detail';
    const isAddressInputEnabled = key === 'address' && !disabledInput;

		if (type === 'selectSearch') {
      return renderSelectSearch(
        label,
        placeholder,
        key
      );
    }

    if (type === 'switch') {
      return renderSwtich(key, label);
    }

    return (
      <Input
        type={ type }
        label={ label }
        placeholder={ placeholder }
        labelColor={ Colors.grey.isGrey }
        value={ form[key] }
        name={ key }
        errorMessage={ errorForm[key] }
        onChange={ onChangeFormText }
        mb={ 20 }
        backgroundColor={ Colors.white.default }
        iconName={ iconName }
        labelClassName={ labelClassName }
        renderSuffixInput={ isAddressInputEnabled ? () => (
          <Text
            weight={ 700 }
            color={ Colors.blue.isBlue }
            onClick={ () => setIsContentPinpoint(true) }
          >Ubah</Text>
        ) : undefined }
        onClick={ isAddressInputEnabled && !form.address
          ? () => setIsContentPinpoint(true)
          : undefined }
        inputClassName={ inputClassName }
        disabled={ disabledInput }
      />
    );
  };

  const onCloseModalForm = () => {
    setModalFormVisible(false);
    setIsContentPinpoint(false);

    setEditedIndex(-1);

    setForm(prevForm => ({
      ...prevForm,
      name: '',
      phone_number: '',
      email: '',
      address: '',
      pic_name: '',
      latitude: 0,
      longitude: 0,
			zip_code: '',
			city: '',
      is_pinned: false,
    }));

    setErrorForm({
      name: '',
      phone_number: '',
      email: '',
      address: '',
      pic_name: '',
      latitude: '',
      longitude: '',
			zip_code: '',
			city: '',
      is_pinned: false,
    });
  };

  const onCloseModalConfirm = () => setModalConfirmVisible(false);

  const renderModalConfirm = () => {
    const loadingDelete = misc.isLazyLoading(lazyLoad, 'deleteShowroom');

    return (
      <ModalConfirm
        visible={ modalConfirmVisible }
        onCloseModal={ onCloseModalConfirm }
        onClickConfirm={ onClickDeleteData }
        confirmText='Ya, hapus'
        confirmButtonWidth={ 120 }
        loadingConfirm={ loadingDelete }
      />
    );
  };

  const onClickDeleteData = () => {
    deleteShowroom(deletedIndex.toString(), handleCbDeleteShowroom);
  };

  const handleCbDeleteShowroom = async(message: string) => {
    await onCloseModalConfirm();

    await toastify.success(message);

    setRefreshing(true);
  };

	const findProvinceByCity = async(city: string) => {

		const params: CitiesApiParams = {
			search: city ? city : '',
			sort: 'asc',
			limit: 50,
			offset: '',
			order: '',
			page: ''
		};

		const newOptions = await getCities(params);
		const index = newOptions.findIndex(item => item.value === city);
		return newOptions[index].label;
	};

  const onClickOpenModal = (actionModal: ActionModalForm, record?: Showroom) => {
		
    setActionModalForm(actionModal);
		
    if (record) {
      if (actionModal === 'delete') {
        setDeletedIndex(record?.id);
      } else {
        setEditedIndex(+ record?.id);

        setForm(prevForm => ({
          ...prevForm,
          name: record?.name,
          phone_number: record?.phone_number,
          email: record?.email,
          address: record?.address,
          pic_name: record?.pic_name,
          latitude: + record?.latitude,
          longitude: + record?.longitude,
					zip_code: record?.zip_code,
					city: record?.city,
          is_pinned: record?.is_pinned,
        }));

				findProvinceByCity(record?.city).then(newOptions => {
					setDataSelectedProvinces([
						{
							value: record?.city,
							key: record?.city,
							label: newOptions
						}
					]);
				});

				onVisibleChange(false, 'city');
      }
    }

    if (actionModal === 'add' || actionModal === 'detail') {
      setModalFormVisible(true);
    } else if (actionModal === 'delete') {
      setModalConfirmVisible(true);
    }
  };

  const handleCbFormShowroom = async(message: string) => {
    await onCloseModalForm();

    await toastify.success(message);

    setRefreshing(true);
  };

  const handleFormValidation = () => {
    const validationEmail = validation.email(form.email);
    const validationPhone = validation.phoneNumber(misc.phoneNumberFormat(form.phone_number));
    const emptyForm = misc.getMultipleKeyByArrOfValue(form, ['', 0]);

    if (
      emptyForm.length ||
      !validationEmail ||
      !validationPhone
    ) {
      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        email: !validationEmail ? errorFormMessage.email : '',
        phone_number: !validationPhone ? errorFormMessage.phone : ''
      }));

      emptyForm.forEach(key => {
        inputPropsData.forEach(input => {
          if (key === 'latitude' || key === 'longitude' || key === 'address') {
            setErrorForm(prevErrorForm => ({
              ...prevErrorForm,
              address: errorFormMessage.form('address (latitude & longitude)')
            }));
          } else {
            if (key === input.key) {
              setErrorForm(prevErrorForm => ({
                ...prevErrorForm,
                [key]: errorFormMessage.form(input.label.toLowerCase())
              }));
            }
          }
        });
      });

      return false;
    }

    return true;
  };

  const onClickButtonModalForm = () => {
    if (isContentPinpoint) {
			
      setForm(prevForm => ({
        ...prevForm,
        address: valueGeosearch?.value,
				zip_code: valueGeosearch?.postcode,
        longitude: valueGeosearch?.valueDetail.lng,
        latitude: valueGeosearch?.valueDetail.lat,
      }));

      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        address: '',
        latitude: '',
        longitude: '',
				zip_code: '',
				city: '',
      }));

      setIsContentPinpoint(false);
    } else {
      if (handleFormValidation()) {
        const formPayload = {
          ...form,
          phone_number: misc.phoneNumberFormat(form.phone_number),
        };
				
        if (actionModalForm === 'add') {
          addShowroom(formPayload, handleCbFormShowroom);
        } else if (actionModalForm === 'edit') {
          editShowroom(
            editedIndex.toString(),
            formPayload,
            handleCbFormShowroom
          );
        }
      }
    }
  };

	const onVisibleChange = (visible: boolean, key: string) => {
    setVisibleFilter(prevFilter => ({
      ...prevFilter,
      [key]: visible
    }));
  };

	const fetchSearchProvinces = async(search: string): Promise<SelectOptionValue[]> => {
		
		const params: CitiesApiParams = {
			search: search,
			sort: 'asc',
			limit: 50,
			offset: '',
			order: '',
			page: ''
		};

		return getCities(params);
		
  };

	const changeCities = (val: React.SetStateAction<SelectOptionValue[]>) => {
		setDataSelectedProvinces(val);

		setForm(prevForm => ({
			...prevForm,
			city: Object.values(val)[1],
		}));
	};

	const renderSelectSearch = (
		label: string,
		placeholder:string,
		key: string
	) => {
		const disabledInput = actionModalForm === 'detail';
    return (
			<>
				<Text
          lineHeight={ 17 }
          weight={ 500 }
          text={ label }
          color={ Colors.grey.isGrey }
          mb={ 10 }
        />
				<SelectSearchBar
					value={ dataSelectedProvinces }
					defaultOptions={ defaultOptionsProvinces }
					fetchOptions={ fetchSearchProvinces }
					placeholder={ placeholder }
					onChange={ newValue => {
						changeCities(newValue);
					} }
					backgroundColor={ Colors.white.default }
					width={ '100%' }
					open={ visibleFilter[key] }
					onDropdownVisibleChange={ (visible: boolean) => onVisibleChange(visible, key) }
					totalItems={ 2 }
					wrapperStyle={ {
						marginBottom: 0,
						width: '100% !important',
					} }
					disabled={ disabledInput }
				/>
			</>
    );
  };

  const renderContentModalForm = () => {
    if (isContentPinpoint) {
      return (
        <MapsLeaflet
          mapKey='Search-Maps-Modal'
          width='100%'
          height='220px'
          containerStyle={ {
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10
          } }
          onChangeLocation={ (result: ValueType) => setValueGeosearch(result) }
          renderItemSearchBox={ renderItemSearchAddress }
          center={ {
            lat: + form.latitude || MapConfig.defaultCenter.lat,
            lng: + form.longitude || MapConfig.defaultCenter.lng
          } }
          initValueGeoSearch={ {
            label: '',
            shortLabel: '',
            value: '',
            postcode: '',
            valueDetail: {
              lat: + form.latitude || MapConfig.defaultCenter.lat,
              lng: + form.longitude || MapConfig.defaultCenter.lng
            }
          } }
          searchBox
          draggable
        />
      );
    }

    return (
      <ModalFormShowroomStyle>
        { inputPropsData.map((input, index) => {
          return (
            <div key={ `form-add-showroom-${ index }` }>
              { renderInput(input) }
            </div>
          );
        }) }
      </ModalFormShowroomStyle>
    );
  };

  const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    setInitialPage();
  }, []);

  const onChangeTable = (sorterInfo: SorterInfo) => {
    setSortOrder(sorterInfo);

    setInitialPage();
  };

  const getCurrentPagination = (currentPagination: CurrentPagination<Showroom[]>) => {
    setFormPagination(prevPagination => ({
      ...prevPagination,
      page: currentPagination.currentPage,
      limit: currentPagination.elementsPerPage
    }));
  };

  const headerContent = () => {
    return {
      textHeader: 'Showroom',
      textBtn: 'Add Showroom',
      iconNameBtn: 'add',
      onClickBtn: () => onClickOpenModal('add'),
      permissionBtn: Permission.showroom_create
    };
  };

  return (
    <Dashboard
      container={ {
        selectedMenu: 5,
        headerContent: headerContent(),
        openSubmenu: true
      } }
      filterMenu={ {
        search,
        placeholderSearch: 'Search by name',
        onChangeSearch
      } }
      data={ {
        currentData: showrooms.data,
        loading: loadingShowrooms,
        fieldName: 'showrooms',
        getCurrentPagination
      } }
      table={ {
        columns,
        buttons: buttonsDataTable,
        onChange: onChangeTable
      } }
      modalForm={ {
        title: 'showroom',
        ...isContentPinpoint ? {
          customText: {
            title: 'Pinpoint Address',
            button: 'Save this Location'
          }
        } : {},
        visible: modalFormVisible,
        actionModal: actionModalForm,
        onCloseModal: onCloseModalForm,
        footer: {
          fieldName: 'showroom',
          onClickEdit: () => onClickOpenModal('edit'),
          onClickButtonSubmit: onClickButtonModalForm,
        },
        contentModal: renderContentModalForm(),
        permissions: { edit: Permission.showroom_update },
        backButton: isContentPinpoint,
        onClickBackButton: () => setIsContentPinpoint(false)
      } }
    >
      { renderModalConfirm() }
    </Dashboard>
  );
};

export default ShowroomScreen;
