import React, { useState, useEffect } from 'react';

import { Colors } from 'consts';

import { DropdownCellStyle } from './style';

import Dropdown, { MenuEntity, Placement } from '../../../Dropdown';
import Icon from '../../../Icon';

type DropdownCellProps = {
  isDropdownCell: boolean;
  dropdownVisible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  onClickMenu?: (params: MenuEntity) => void;
  onClickOpenDropdown?: (record: any) => void;
  overlayDropdown?: any;
  overlay?: any;
  title?: string;
  children: React.ReactNode;
  dataIndex: string;
  record: any;
  placement?: Placement;
};

const DropdownCell: React.FC<DropdownCellProps> = ({
  isDropdownCell,
  dropdownVisible = false,
  children,
  dataIndex, // eslint-disable-line @typescript-eslint/no-unused-vars
  onVisibleChange,
  onClickMenu,
  onClickOpenDropdown,
  overlayDropdown,
  record,
  overlay,
  placement,
  ...restProps
}) => {
  const [editing, setEditing] = useState<boolean>(dropdownVisible);

  let childNode = children;

  useEffect(() => {
    const closeDropdown = () => setEditing(false);

    window.addEventListener(
      'scroll',
      closeDropdown,
      true
    );

    return () => window.removeEventListener(
      'scroll',
      closeDropdown,
      true
    );
  }, []);

  useEffect(() => {
    setEditing(dropdownVisible);
  }, [dropdownVisible]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const onClickOpenDropdownCell = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleEdit();
    onClickOpenDropdown && onClickOpenDropdown(record);
    onVisibleChange && onVisibleChange(!editing);
  };

  const onClickMenuDropdown = (eMenu: MenuEntity) => {
    eMenu.domEvent.stopPropagation();

    onClickMenu && onClickMenu(eMenu);

    setTimeout(() => {
      toggleEdit();
    }, 100);
  };

  if (isDropdownCell) {
    childNode = editing ? (
      <DropdownCellStyle visible={ editing }>
        <Dropdown
          overlayData={ overlayDropdown }
          overlay={ overlay }
          onClickMenu={ onClickMenuDropdown }
          visible={ editing }
          onVisibleChange={ (visible: boolean) => {
            onVisibleChange && onVisibleChange(visible);
            toggleEdit();
          } }
          placement={ placement }
        >
          <div className='dropdown-cell' onClick={ e => e.stopPropagation() }>
            { children }
            <Icon
              iconName='arrowDown'
              size={ 13 }
              fill={ Colors.blue.isBlue }
              className='arrow-dropdown-cell'
            />
          </div>
        </Dropdown>
      </DropdownCellStyle>
    ) : (
      <DropdownCellStyle visible={ editing }>
        <div className='dropdown-cell' onClick={ onClickOpenDropdownCell }>
          { children }
          <Icon
            iconName='arrowDown'
            size={ 13 }
            fill={ Colors.grey.isGrey }
            className='arrow-dropdown-cell'
          />
        </div>
      </DropdownCellStyle>
    );
  }

  return <td { ...restProps }>{ childNode }</td>;
};

export default DropdownCell;
