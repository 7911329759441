import { Pagination } from 'interfaces/common';

export const SET_PAGINATION = 'SET_PAGINATION';
export const LOGOUT = 'LOGOUT';

export interface SetPagination {
  type: typeof SET_PAGINATION;
  data: {
    fieldName: string;
    pagination: Pagination;
  }
}

export interface Logout {
	type: typeof LOGOUT;
}

export type PaginationActionTypes = SetPagination | Logout;
