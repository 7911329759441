import React, {
  useCallback,
  useState,
  useEffect
} from 'react';

import { Colors, Images } from 'consts';
import {
  Container,
  Box,
  Text,
  Input,
  Button,
  Image
} from 'components';
import {
  toastify,
  hooks,
  misc,
  navigation,
  validation,
  screen
} from 'helpers';
import { FormResetPassword } from 'interfaces/auth';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { ResetPasswordStyle } from './style';

interface Form {
  ref: string;
  password: string;
  confirmPassword: string;
}

const ResetPassword: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const resetPassword = dispatch(actions.resetPassword);

  const loading = hooks.useAppSelector(selectors.misc.loading);

  const query = hooks.useQuery();

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [form, setForm] = useState<Form>({
    ref: query.get('ref') || '',
    password: '',
    confirmPassword: ''
  });
  
  useEffect(() => {
    if (windowDimensions.width <= screen.isMobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowDimensions.width]);

  const onClickGoBack = useCallback(() => navigation.replace('/login'), []);

  const onChangeForm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevForm => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }));
  }, []);

  const renderButton = (text: string, onClick: () => void) => {
    const isLoading = misc.isLoading(loading, 'resetPassword');
    
    return (
      <Button
        text={ text }
        fontWeight={ 700 }
        size='m'
        onClick={ onClick }
        isLoading={ isLoading }
      />
    );
  };

  const onClickChangePassword = () => {
    const validationPassMatch = validation.passwordMatch(form.password, form.confirmPassword);
    const emptyForm = misc.getMultipleKeyByValue(form, '');

    if (emptyForm.length) {
      toastify.error('Password / confirm password cannot be empty');
    } else if (!validationPassMatch) {
      toastify.error('Password and confirm password doesn\'t match');
    } else {
      const payload: FormResetPassword = {
        ref: form.ref,
        password: form.password,
        retype_password: form.confirmPassword
      };
      
      resetPassword(payload);
    }
  };

  const renderForm = () => {
    return (
      <div className='form-container'>
        <Input
          placeholder='New password'
          label='New Password'
          value={ form.password }
          type='password'
          name='password'
          iconName='password'
          iconPosition='right'
          onChange={ onChangeForm }
        />
        <Input
          placeholder='Confirm new password'
          label='Confirm New Password'
          value={ form.confirmPassword }
          type='password'
          name='confirmPassword'
          iconName='password'
          iconPosition='right'
          onChange={ onChangeForm }
        />
        { renderButton('Change Password', onClickChangePassword) }
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <>
        <Text
          size={ isMobile ? 'xl' : 'xl3' }
          lineHeight={ 39 }
          weight={ 700 }
          mt={ isMobile ? 0 : 80 }
          mb={ 10 }
        >Change Password</Text>
        <Text
          weight={ isMobile ? 400 : 500 }
          lineHeight={ 17 }
          color={ Colors.grey.isGrey }
          mb={ 30 }
        >Create your new password to start using this dashboard.</Text>
      </>
    );
  };

  return (
    <ResetPasswordStyle>
      <Container
        backgroundColor={ isMobile ? Colors.white.default : Colors.grey.isBgGrey }
        navbar={ {
          logo: !isMobile,
          backButton: isMobile ? 'square' : '',
          leftClassName: 'left-navbar',
          onClickBack: onClickGoBack
        } }
      >
        <div className={ `content ${isMobile ? 'center-content' : 'justify-center'}` }>
          <div className='flex-col inner-content'>
            { renderTitle() }
            {
              isMobile ? renderForm() : (
                <Box width={ 418 } height={ 327 }>
                  { renderForm() }
                </Box>
              )
            }
          </div>
        </div>
        {
          isMobile && (
            <div className='half-container'>
              <Image src={ Images.bgLoginMobile } className='half-background-image' />
            </div>
          )
        }
      </Container>
    </ResetPasswordStyle>
  );
};

export default ResetPassword;
