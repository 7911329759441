import React from 'react';
import { Upload as UploadAntd } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';

import { Colors } from 'consts';

import { UploadStyle } from './style';

import Icon from '../Icon';
import Image from '../Image';
import Text from '../Text';
import Loader from '../Loader';

const beforeUploadDefault = () => {
  // return false karena upload ke API tidak dibuat dihandle oleh props dari Upload Antd
  // sehingga agar Upload Antd component tidak langsung request POST, maka dibuat return false
  return false;
};

type CustomUploadProps = {
  uploadLoading?: boolean;
  uploadPhotoUrl?: any; // array if multiple = true
  fileList?: any; // array if multiple = true, wajib jika multiple = true
  onChange?: (info: any) => void;
  onRemove?: (file: any) => boolean | void;
  width?: number;
  height?: number | string;
  borderRadius?: number | string;
  marginVertical?: number;
  className?: string;
  wrapperClassName?: string;
  disabled?: boolean;
  bgColor?: string;
  renderPlaceholderUpload?: () => JSX.Element; // eslint-disable-line no-undef
  multiple?: boolean;
  beforeUpload?: (file, fileList) => boolean | Promise<File>;
};

const Upload: React.FC<CustomUploadProps> = ({
  className,
  onChange,
  onRemove,
  disabled,
  multiple,
  children,
  beforeUpload,
  fileList
}) => {
  return (
    <UploadAntd
      name='avatar'
      listType='picture-card'
      className={ `avatar-uploader ${ className }` }
      showUploadList={ false }
      beforeUpload={ beforeUpload || beforeUploadDefault }
      fileList={ fileList }
      onChange={ onChange }
      onRemove={ onRemove }
      disabled={ disabled }
      multiple={ multiple }
    >
      { children }
    </UploadAntd>
  );
};

const CustomUpload: React.FC<CustomUploadProps> = ({
  uploadLoading,
  uploadPhotoUrl,
  onChange,
  width = 100,
  height = 100,
  borderRadius = '100%',
  marginVertical = 30,
  className,
  disabled,
  bgColor,
  renderPlaceholderUpload,
  onRemove,
  multiple,
  wrapperClassName,
  beforeUpload,
  fileList
}) => {
  const renderUploadPhoto = (
    <div className='col center-content'>
      { renderPlaceholderUpload
        ? renderPlaceholderUpload()
        : (
          <>
            {
              uploadLoading
                ? <Loader color='blue' />
                : (
                  <Icon
                    size={ 24 }
                    iconName='image'
                    fill={ Colors.grey.isGrey }
                  />
                )
            }
            <Text
              size='xxs'
              color={ Colors.grey.isGrey }
              lineHeight={ 12 }
              mt={ 10 }
            >{ uploadLoading ? 'Upload...' : '+ Upload Photo' }</Text>
          </>
        ) }
    </div>
  );

  const renderImage = (
    src: any,
    width?: string | number,
    height?: string | number
  ) => {
    return (
      <Image
        src={ src }
        width={ width }
        height={ height }
        type={ borderRadius === '100%' ? 'circle' : 'square' }
        borderRadius={ typeof borderRadius === 'number' ? borderRadius : undefined }
        className='cover'
      />
    );
  };

  const renderSingleUpload = () => {
    return (
      <Upload
        className={ className }
        onChange={ onChange }
        disabled={ disabled }
        beforeUpload={ beforeUpload }
        onRemove={ onRemove }
      >
        {
          uploadPhotoUrl && !uploadLoading
            ? (
              <div className={ `uploaded-image-wrap ${ className }` }>
                { renderImage(
                  uploadPhotoUrl,
                  width,
                  height
                ) }
                <div className='overlay flex col center-content'>
                  <UploadOutlined className='ic-overlay-antd' />
                  <Text
                    weight={ 400 }
                    color={ Colors.white.default }
                    mt={ 10 }
                  >Change Photo</Text>
                </div>
              </div>
            )
            : renderUploadPhoto
        }
      </Upload>
    );
  };

  const renderMultipleUpload = () => {
    if (uploadPhotoUrl && Array.isArray(uploadPhotoUrl) && uploadPhotoUrl?.length) {
      return (
        <div className='uploaded-image-multiple'>
          { uploadPhotoUrl.map((url, urlIndex) => (
            <div key={ urlIndex } className={ `uploaded-image-wrap ${ className }` }>
              { renderImage(
                url,
                100,
                100
              ) }
              <div className='overlay flex col center-content'>
                <DeleteOutlined className='ic-overlay-antd pointer' onClick={ () => onRemove && onRemove(fileList ? fileList[urlIndex] : null) } />
              </div>
          </div>
          )) }

          <div>
            <Upload
              className={ className }
              onChange={ onChange }
              disabled={ disabled }
              beforeUpload={ beforeUpload }
              fileList={ fileList }
              onRemove={ onRemove }
              multiple
            >
              { renderUploadPhoto }
            </Upload>
          </div>
        </div>
      );
    }

    return (
      <Upload
        className={ className }
        onChange={ onChange }
        disabled={ disabled }
        onRemove={ onRemove }
        beforeUpload={ beforeUpload }
        fileList={ fileList }
        multiple
      >
        { renderUploadPhoto }
      </Upload>
    );
  };

  return (
    <UploadStyle
      width={ width }
      height={ height }
      borderRadius={ borderRadius }
      marginVertical={ marginVertical }
      bgColor={ bgColor }
    >
      <div className={ `${ wrapperClassName || '' } avatar-container` }>
        { !multiple
          ? renderSingleUpload()
          : renderMultipleUpload() }
      </div>
    </UploadStyle>
  );
};

export default CustomUpload;
