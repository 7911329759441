import { Dispatch } from 'redux';

import { toastify } from 'helpers';
import { ApiData } from 'interfaces/common';
import { Notification } from 'interfaces/notification';
import { AppActions } from 'store';
import * as notifServices from 'services/notification';

import { ADD_NOTIFICATION, GET_NOTIFICATION_LIST } from './actionTypes';

import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';

export const getAllNotification = (dispatch: Dispatch<AppActions>) => (callback?: (notifList: Notification[]) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('allNotification'));

			const { code, data }: ApiData<Notification[]> = await notifServices.getAllNotification();

			if (code === 200) {
				await dispatch({
					type: GET_NOTIFICATION_LIST,
					data
				});

				if (callback) {
					callback(data);
				}

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		}

		dispatch(lazyLoadEnd('allNotification'));
	});
};

export const readAllNotification = (dispatch: Dispatch<AppActions>) => (callback?: () => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('readAllNotification'));

			const { code }: ApiData<any> = await notifServices.readAllNotification();

			if (code === 200) {
				if (callback) callback();

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		}

		dispatch(lazyLoadEnd('readAllNotification'));
	});
};

export const addNotification = (dispatch: Dispatch<AppActions>) => (data: Notification) => {
	return new Promise<void>(async resolve => {
		dispatch({
			type: ADD_NOTIFICATION,
			data
		});

		resolve();
	});
};
