import React from 'react';

import { Colors } from 'consts';

import Tooltip from '../../../Tooltip';

import { renderText } from '../../index';

type TooltipTableProps = {
  text: string;
  align?: string;
  color?: string;
  weight?: number;
  mb?: number;
};

const TooltipTable: React.FC<TooltipTableProps> = ({
  text,
  align = 'left',
  color = Colors.black.isTextBlack2,
  weight = 500,
  mb = 0,
}) => {
  return (
    <Tooltip placement='topLeft' title={ () => renderText(text, 'white') }>
      <span>
        {
          renderText(
            text,
            color,
            weight,
            mb,
            true,
            align
          )
        }
      </span>
    </Tooltip>
  );
};

export default TooltipTable;
