import { Endpoint } from 'helpers/api';

import Config from './config';

const version = Config.version;

export default {
	// Contact-Maps
	whatsapp: (phone: string) => `https://api.whatsapp.com/send?phone=${ phone }`,
	mailTo: (email: string) => `mailto:${ email }`,
	googleMaps: (address: string) => `https://www.google.com/maps/search/?api=1&query=${ address }`,
	// OSM Geocode
	getOSMGeocode: (): Endpoint => ({
		path: 'https://nominatim.openstreetmap.org/search',
		method: 'GET'
	}),
	getOSMReverseGeocode: (): Endpoint => ({
		path: 'https://nominatim.openstreetmap.org/reverse',
		method: 'GET'
	}),

	// AWS
	uploadImage: (): Endpoint => ({
		path: `${ version }/uploads`,
		method: 'POST'
	}),

	// Auth
	login: (): Endpoint => ({
		path: `${ version }/auth/login-cms`,
		method: 'POST'
	}),
	refreshToken: (): Endpoint => ({
		path: `${ version }/auth/refresh-token`,
		method: 'POST'
	}),
	forgotPassword: (): Endpoint => ({
		path: `${ version }/auth/forgot-password-cms`,
		method: 'POST'
	}),
	resetPassword: (): Endpoint => ({
		path: `${ version }/auth/reset-password`,
		method: 'POST'
	}),

	// Admin (Admin Management)
	getAllAdmin: (): Endpoint => ({
		path: `${ version }/admins`,
		method: 'GET'
	}),
	getAdminDetail: (userId: string): Endpoint => ({
		path: `${ version }/admins/${ userId }`,
		method: 'GET'
	}),
	addAdmin: (): Endpoint => ({
		path: `${ version }/admins`,
		method: 'POST'
	}),
	editAdmin: (userId: string): Endpoint => ({
		path: `${ version }/admins/${ userId }`,
		method: 'PUT'
	}),
	changeStatusAdmin: (userId: string): Endpoint => ({
		path: `${ version }/admins/${ userId }/change-status`,
		method: 'PUT'
	}),

	// Role Admin
	getAllRole: (): Endpoint => ({
		path: `${ version }/roles`,
		method: 'GET'
	}),

	// Showroom
	getAllShowroom: (): Endpoint => ({
		path: `${ version }/showrooms`,
		method: 'GET'
	}),
	getShowroomDetail: (idShowroom: string): Endpoint => ({
		path: `${ version }/showrooms/${ idShowroom }`,
		method: 'GET'
	}),
	addShowroom: (): Endpoint => ({
		path: `${ version }/showrooms`,
		method: 'POST'
	}),
	editShowroom: (idShowroom: string): Endpoint => ({
		path: `${ version }/showrooms/${ idShowroom }`,
		method: 'PUT'
	}),
	deleteShowroom: (idShowroom: string): Endpoint => ({
		path: `${ version }/showrooms/${ idShowroom }`,
		method: 'DELETE'
	}),

	// Users
	getAllUser: (): Endpoint => ({
		path: `${ version }/users`,
		method: 'GET'
	}),
	getUserDetail: (userId: string): Endpoint => ({
		path: `${ version }/users/${ userId }`,
		method: 'GET'
	}),
	addUser: (): Endpoint => ({
		path: `${ version }/auth/register`,
		method: 'POST'
	}),
	editUser: (userId: string): Endpoint => ({
		path: `${ version }/users/${ userId }`,
		method: 'PUT'
	}),
	showroomAssignment: (userId: string): Endpoint => ({
		path: `${ version }/users/${ userId }/showroom-assignment`,
		method: 'POST'
	}),
	editShowroomAssignment: (userId: string): Endpoint => ({
		path: `${ version }/users/${ userId }/showroom-assignment`,
		method: 'PUT'
	}),
	scooterAssignment: (parentId: string): Endpoint => ({
		path: `${ version }/users/${ parentId }/parent-assignment`,
		method: 'POST'
	}),
	uploadLegalInfo: (userId: string): Endpoint => ({
		path: `${ version }/users/${ userId }/complete-legal`,
		method: 'POST'
	}),
	verifyLegalInfo: (userId: string): Endpoint => ({
		path: `${ version }/users/${ userId }/verify`,
		method: 'POST'
	}),

	// Inventory (Scooters)
	getAllScooter: (): Endpoint => ({
		path: `${ version }/scooters`,
		method: 'GET'
	}),
	getScooterDetail: (vin: string): Endpoint => ({
		path: `${ version }/scooters/${ vin }/info`,
		method: 'GET'
	}),
	addScooter: (): Endpoint => ({
		path: `${ version }/scooters`,
		method: 'POST'
	}),
	editScooter: (vin: string): Endpoint => ({
		path: `${ version }/scooters/${ vin }`,
		method: 'PUT'
	}),
	getAllScooterVariant: (): Endpoint => ({
		path: `${ version }/variants`,
		method: 'GET'
	}), // pagination get variant for dev phase 1 is hardcoded, next must be dynamic.
	getScooterLocation: (vin: string): Endpoint => ({
		path: `${ version }/scooters/${ vin }/location`,
		method: 'GET'
	}),
	getScooterBatteryUsages: (vin: string): Endpoint => ({
		path: `${ version }/scooters/${ vin }/consumptions`,
		method: 'GET'
	}),
	getTripHistoryScooter: (): Endpoint => ({
		path: `${ version }/statistics/trip-history`,
		method: 'GET'
	}),
	getStationVisited: (vin: string): Endpoint => ({
		path: `${ version }/scooters/${ vin }/station-visited`,
		method: 'GET'
	}),

	// Battery Packs
	getAllBatteryPack: (): Endpoint => ({
		path: `${ version }/battery-packs`,
		method: 'GET'
	}),
	getBatteryPackDetail: (batteryId: string): Endpoint => ({
		path: `${ version }/battery-packs/${ batteryId }`,
		method: 'GET'
	}),
	addBatteryPack: (): Endpoint => ({
		path: `${ version }/battery-packs`,
		method: 'POST'
	}),
	editBatteryPack: (id: string): Endpoint => ({
		path: `${ version }/battery-packs/${ id }`,
		method: 'PUT'
	}),
	getSocBatteryPack: (id: string): Endpoint => ({
		path: `${ version }/battery-packs/${ id }/soc`,
		method: 'GET'
	}),
	getSohBatteryPack: (id: string): Endpoint => ({
		path: `${ version }/battery-packs/${ id }/soh`,
		method: 'GET'
	}),
	getDischargeBatteryPack: (id: string): Endpoint => ({
		path: `${ version }/battery-packs/${ id }/discharges`,
		method: 'GET'
	}),
	getTempBatteryPack: (id: string): Endpoint => ({
		path: `${ version }/battery-packs/${ id }/temperatures`,
		method: 'GET'
	}),
	getBatteryPackBatteryUsages: (id: string): Endpoint => ({
		path: `${ version }/battery-packs/${ id }/consumptions`,
		method: 'GET'
	}),
	getTransactionVinLog: (): Endpoint => ({
		path: `${ version }/swap-logs`,
		method: 'GET'
	}),

	// Cases
	getAllCase: (): Endpoint => ({
		path: `${ version }/cases`,
		method: 'GET'
	}),
	assignCS: (caseId: string): Endpoint => ({
		path: `${ version }/cases/assign/${ caseId }`,
		method: 'POST'
	}),
	reassignCS: (caseId: string): Endpoint => ({
		path: `${ version }/cases/re-assign/${ caseId }`,
		method: 'POST'
	}),
	takeCase: (caseId: string): Endpoint => ({
		path: `${ version }/cases/${ caseId }/take`,
		method: 'POST'
	}),
	solveCase: (caseId: string): Endpoint => ({
		path: `${ version }/cases/solve/${ caseId }`,
		method: 'POST'
	}),
	getDetailCase: (caseId: string): Endpoint => ({
		path: `${ version }/cases/detail/${ caseId }`,
		method: 'GET'
	}),
	getPastSimiliarCase: (caseId: string): Endpoint => ({
		path: `${ version }/cases/${ caseId }/similar-case`,
		method: 'GET'
	}),

	// Subscription
	getAllSubscription: (): Endpoint => ({
		path: `${ version }/cms/subscriptions`,
		method: 'GET'
	}),
	addSubscription: (): Endpoint => ({
		path: `${ version }/cms/subscriptions`,
		method: 'POST'
	}),
	editSubscription: (id: string): Endpoint => ({
		path: `${ version }/cms/subscriptions/${ id }`,
		method: 'PUT'
	}),
	deleteSubscription: (id: string): Endpoint => ({
		path: `${ version }/cms/subscriptions/${ id }`,
		method: 'DELETE'
	}),

	// Battery Station
	getAllBatteryStations: (): Endpoint => ({
		path: `${ version }/cms/stations`,
		method: 'GET'
	}),
	addBatteryStations: (): Endpoint => ({
		path: `${ version }/cms/stations`,
		method: 'POST'
	}),
	editBatteryStations: (id: string): Endpoint => ({
		path: `${ version }/cms/stations/${ id }`,
		method: 'PUT'
	}),
	deleteBatteryStations: (id: string): Endpoint => ({
		path: `${ version }/cms/stations/${ id }`,
		method: 'DELETE'
	}),
	getStationDetail: (id: string): Endpoint => ({
		path: `${ version }/cms/stations/${ id }`,
		method: 'GET'
	}),

	// Location Cities
	getAllCities: (): Endpoint => ({
		path: `${ version }/cities`,
		method: 'GET'
	}),

	// Notification
	getAllNotification: (): Endpoint => ({
		path: `${ version }/inboxes`,
		method: 'GET'
	}),
	readAllNotification: (): Endpoint => ({
		path: `${ version }/inboxes`,
		method: 'PUT'
	}),

	getHoleStation: (id: string): Endpoint => ({
		path: `${ version }/cms/stations/${ id }/batteries`,
		method: 'GET'
	}),
	
	getBusyTime: (id: string): Endpoint => ({
		path: `${ version }/cms/stations/${ id }/busy-times`,
		method: 'GET'
	}),
	
	getStationVisitor: (id: string): Endpoint => ({
		path: `${ version }/cms/stations/${ id }/visitors`,
		method: 'GET'
	}),
	
	// Stats
	getStatsAge: (): Endpoint => ({
		path: `${ version }/cms/dashboards/age-behavior-stats`,
		method: 'GET'
	}),
	
	getBatteryCondition: (): Endpoint => ({
		path: `${ version }/cms/dashboards/battery-conditions`,
		method: 'GET'
	}),
	
	getSwapLocation: (): Endpoint => ({
		path: `${ version }/cms/dashboards/popular-swap-locations`,
		method: 'GET'
	}),
};
