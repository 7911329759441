export const convertPercentage = (arr: any[]) => {
    
    let sums = 0;
    // calculate sum using forEach() method
    Object.values(arr).forEach(num => {
        sums += num;
    });

    const result = Object.values(arr).map((total, index) => ({
        range: Object.keys(arr)[index],
        value: total ? Math.round(((total * 100) / sums)) : 0,
    }));

    return result;
};