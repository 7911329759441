import React, {
  useState,
  useRef,
  useMemo,
  useEffect
} from 'react';
import { Empty, Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { RefSelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';

import { Colors } from 'consts';

import { SelectMultipleStyle } from './style';

import Loader from '../../Loader';
import Text from '../../Text';

const { Option } = Select;

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  placeholder?: string;
  style?: React.CSSProperties;
  label?: string;
  labelSize?: string;
  labelColor?: string;
  backgroundColor?: string;
  ref?: React.Ref<RefSelectProps>;
  dependenciesFetch?: any[];
}

function DebounceSelect<
  ValueType extends { key?: string; label: string | number; value: string | number; } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  label,
  labelSize,
  labelColor,
  placeholder,
  style,
  backgroundColor,
  disabled,
  ref,
  onChange,
  dependenciesFetch = [],
  ...props
}: DebounceSelectProps) {

  const [focus, setFocus] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    debounceFetcher(searchValue);
  }, [searchValue, ...dependenciesFetch]);

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  const renderLabel = () => {
    if (label) {
      const textColor = focus ? Colors.blue.isBlue : labelColor;

      return (
        <Text
          size={ labelSize }
          mb={ 10 }
          lineHeight={ 17 }
          weight={ 500 }
          color={ textColor }
          text={ label }
        />
      );
    }

    return null;
  };

  const renderTextOption = (text: string) => {
    return <Text lineHeight={ 24 } weight={ 400 }>{ text }</Text>;
  };

  const optionList = options.map(option => (
    <Option
      key={ option.value }
      value={ option.value }
      label={ option.label }
    >{ renderTextOption(option.label.toString()) }</Option>
  ));

  return (
    <SelectMultipleStyle
      focus={ focus }
      backgroundColor={ backgroundColor }
      disabled={ disabled }
      option={ props.value }
    >
      { renderLabel() }

      <Select<ValueType>
        ref={ ref }
        searchValue={ searchValue }
        labelInValue
        filterOption={ false }
        onSearch={ setSearchValue }
        onChange={ (value, option) => {
          onChange && onChange(value, option);
          setSearchValue('');
        } }
        notFoundContent={ fetching
          ? <Loader className='col center-content' style={ { height: 100 } } />
          : <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
        }
        placeholder={ <Text color={ Colors.grey.isGrey }>{ placeholder }</Text> }
        style={ {
          width: '100%',
          ...style
        } }
        listHeight={ 100 }
        dropdownStyle={ { borderRadius: 10 } }
        onFocus={ onFocus }
        onBlur={ onBlur }
        className='search-input'
        { ...props }
      >
        { optionList }
      </Select>

    </SelectMultipleStyle>
  );
}

export default DebounceSelect;
