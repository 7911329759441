import { language } from 'language';

const { label, placeholder } = language;

export const inputPropsData = [
  {
    label: label.showroomName,
    placeholder: placeholder.showroomName,
    key: 'name',
    type: 'text'
  },
  {
    label: label.email,
    placeholder: placeholder.email.replace(' your', ''),
    key: 'email',
    type: 'text'
  },
  {
    label: label.phone,
    placeholder: placeholder.phone.replace(' your', ''),
    key: 'phone_number',
    type: 'text'
  },
  {
    label: label.pic,
    placeholder: placeholder.pic,
    key: 'pic_name',
    type: 'text'
  },
	{
    label: 'City',
    placeholder: 'Choose City',
    key: 'city',
    type: 'selectSearch'
  },
  {
    label: label.address,
    placeholder: placeholder.address,
    key: 'address',
    type: 'textArea',
    iconName: 'pinLocation',
    inputClassName: 'input-address no-scrollbar'
  },
	{
    label: 'Zip Code',
    placeholder: 'Enter zip code',
    key: 'zip_code',
    type: 'text'
  },
	{
    label: 'Is HQ',
    key: 'is_pinned',
    type: 'switch'
  },
];
