import React from 'react';

import { Colors } from 'consts';

import { BoxStyle } from './style';

export type BoxProps = {
  children?: React.ReactNode;
  hover?: boolean;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  padding?: number | string;
  borderRadius?: number;
  width?: number | string;
  height?: number | string;
  adjustWidth?: boolean;
  shadow?: string | false;
  bgColor?: string;
  border?: string | false;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const Box: React.FC<BoxProps> = ({
  children,
  hover,
  mb,
  mt,
  ml,
  mr,
  padding,
  borderRadius,
  width,
  height,
  adjustWidth,
  shadow,
  bgColor,
  border,
  className,
  style,
  onClick
}) => {
  return (
    <BoxStyle
      mb={ mb }
      mt={ mt }
      ml={ ml }
      mr={ mr }
      padding={ padding }
      hover={ hover }
      borderRadius={ borderRadius }
      width={ width }
      height={ height }
      adjustWidth={ adjustWidth }
      shadow={ shadow }
      bgColor={ bgColor }
      border={ border }
      className={ className }
      style={ style }
      onClick={ onClick }
    >
      { children }
    </BoxStyle>
  );
};

Box.defaultProps = {
  padding: 40,
  borderRadius: 10,
  width: '100%',
  adjustWidth: false,
  shadow: `0px 5px 10px 0px ${Colors.black.isShadow}`,
  bgColor: Colors.white.default,
  border: false,
};

export default Box;
