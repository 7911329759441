import Styled from 'styled-components';

import { Colors, Themes } from 'consts';
import { screen } from 'helpers';

type TextStyleProps = {
  fontSize?: number;
  lineHeight?: number;
  fontFamily?: string;
  color?: string;
  align?: string;
  spacing?: number;
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  decoration?: string;
  disabled?: boolean;
  click?: boolean;
};

const properties = ({
  fontSize = 12,
	lineHeight = Themes.lineHeightDefault(fontSize),
	color = Colors.black.isBlack,
  align = 'left',
	spacing = 0,
	mb = 0,
	mr = 0,
	mt = 0,
	ml = 0,
  decoration = 'none',
  fontFamily = 'InterRegular',
  disabled = false,
  click = false
}) => `
  font-family: ${fontFamily};
  font-size: ${fontSize}px;
  line-height: ${lineHeight}px;
  color: ${color};
  letter-spacing: ${spacing}px;
  margin-bottom: ${mb}px;
  margin-top: ${mt}px;
  margin-left: ${ml}px;
  margin-right: ${mr}px;
  text-decoration: ${decoration};
  text-align: ${align};
  ${disabled ? 'cursor: default;' : ''}
  ${click && !disabled ? `
    cursor: pointer;
    ::hover {
      opacity: 0.8;
    }` : ''}
  
  @media ${screen.sizesMax.xs} {
    font-size: ${fontSize > 12 ? fontSize - 2 : fontSize}px;
  }

  @media ${screen.sizesMax.xxs} {
    font-size: ${fontSize > 10 ? fontSize - 4 : fontSize}px;
  }
`;

export const TextStyle: any = Styled.div < TextStyleProps > `
  h1 {
    ${props => properties(props)};
  }

  h2 {
    ${props => properties(props)};
  }

  h3 {
    ${props => properties(props)};
  }

  .p1 {
    ${props => properties(props)};
  }

  .p2 {
    ${props => properties(props)};
  }

  .p3 {
    ${props => properties(props)};
  }
`;
