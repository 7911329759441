export const inputPropsData = [
  {
    label: 'ID',
    placeholder: 'Enter battery ID',
    key: 'id',
    type: 'text'
  },
  // {
  //   label: 'Status',
  //   placeholder: 'Choose battery status',
  //   key: 'status',
  //   type: 'select'
  // },
  // {
  //   label: 'SoC',
  //   placeholder: 'Enter SoC (%)',
  //   key: 'soc',
  //   type: 'number'
  // },
  // {
  //   label: 'Capacity',
  //   placeholder: 'Enter capacity (KWH)',
  //   key: 'capacity',
  //   type: 'number'
  // },
  // {
  //   label: 'Location',
  //   placeholder: 'Choose battery location',
  //   key: 'location',
  //   type: 'select'
  // },
  // {
  //   label: 'SoH',
  //   placeholder: 'Enter SoH (%)',
  //   key: 'soh',
  //   type: 'number'
  // },
];

export const statusOptions = [
  'Uncommission',
  'Active',
  'Retired',
  'Available',
  'Charging',
  'Undetected'
];

export const locationOptions = [
  'SAVART',
  'Station',
  'Factory'
];

export const dropdownsFilter = [
  {
    key: 'status',
    selectOptions: ['All Status', ...statusOptions]
  }, {
    key: 'location',
    selectOptions: ['All Location', ...locationOptions]
  }
];
