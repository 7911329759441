import React from 'react';

import {
  Box,
  Icon,
  Loader,
  MapsLeaflet,
  Skeleton,
  Text
} from 'components';
import {
  Colors,
  Endpoints,
  Images
} from 'consts';
import { hooks, misc } from 'helpers';
import { MarkerProps } from 'interfaces/common';
import { selectors } from 'store/selectors';

type BoxLocationProps = {
  widthBox: number;
  address?: string;
  latitude?: number;
  longitude?: number;
  label?: string;
  id?: number;
};

const BoxLocation: React.FC<BoxLocationProps> = ({
  widthBox,
  label = '',
  address = '',
  latitude = 0,
  longitude = 0,
  id = 0
}) => {
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const isLoading = misc.isLazyLoading(lazyLoad, 'stationDetail');

  const fullAddress: MarkerProps = {
    id,
    label,
    detail: address,
    lat: latitude,
    lng: longitude
  };

  const renderAddress = () => {
    return (
      <div className='mb4'>
        <Skeleton
          paragraph={ {
            rows: 2,
            width: '100%'
          } }
          title={ false }
          loading={ isLoading }
        >
          <Text align='justify'>{ misc.capitalizeFirstLetter(fullAddress?.detail || '') }</Text>
        </Skeleton>
      </div>
    );
  };

  const renderMaps = () => {
    if (isLoading) {
      return (
        <Loader className='col center-content' style={ { height: 212 } } />
      );
    }

    return (
      <MapsLeaflet
        multipleMarkerPaths={ [fullAddress] }
        center={ {
          lat: fullAddress?.lat,
          lng: fullAddress?.lng
        } }
        width='100%'
        height='212px'
        containerStyle={ { borderRadius: 10 } }
        zoom={ 16 }
        iconPolylineUrl={ Images.maps.marker_scooter }
        withMarkerCenter={ false }
        withGeocodingReverse={ false }
      />
    );
  };

  const onClickSeeOnMap = () => {
    window.open(Endpoints.googleMaps(`${ fullAddress.lat },${ fullAddress.lng }`), '_blank');
  };

  const renderTitleSubContent = () => {
    return (
      <div className='justify-align-center mb3'>
        <div className='flex row'>
          <Text
            size='m'
            weight={ 700 }
            lineHeight={ 19 }
            mr={ 13 }
          >Station Location</Text>
          <Icon
            size={ 16 }
            iconName='location'
            fill={ Colors.blue.isBlue }
          />
        </div>
        <Text
          weight={ 700 }
          color={ Colors.blue.isBlue }
          lineHeight={ 19 }
          align='right'
          onClick={ onClickSeeOnMap }
          disabled={ isLoading }
        >See on Google Map</Text>
      </div>
    );
  };

  const renderLocation = () => {
    return (
      <>
        { renderAddress() }
        { renderMaps() }
      </>
    );
  };

  return (
    <>
      <Box
        mt={ 20 }
        padding={ 20 }
        width={ widthBox }
      >
        { renderTitleSubContent() }
        { renderLocation() }
      </Box>
    </>
  );
};

export default BoxLocation;
