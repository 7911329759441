import {
	BatteryStation,
	BusyTime,
	HoleStation,
	StationDetail,
	StationVisitor
} from 'interfaces/station';
import { Pagination } from 'interfaces/common';

import {
	GET_BATTERY_STATIONS,
	GET_STATION_DETAIL,
	GET_HOLE_STATION,
	GET_BUSY_TIME,
	GET_STATION_VISITOR,
	LOGOUT,
	StationActionTypes,
} from './actionTypes';

interface StationState {
	station: {
		data: BatteryStation[];
		pagination: Pagination;
	},
	stationDetail: StationDetail;
	holeStation: HoleStation[];
	listBusyTime: BusyTime[];
	stationVisitor: StationVisitor[];
	
}

const initPagination: Pagination = {
	count: 0,
	limit: 0,
	offset: 0,
	order: '',
	page: 0,
	page_total: 0,
	search: '',
	sort: 'asc'
};

const initState: StationState = {
	station: {
		data: [],
		pagination: initPagination
	},
	stationDetail: {
		id: 0,
		station_type: '',
		name: '',
		pic_name: '',
		pic_number: '',
		address: '',
		city: '',
		zip_code: '',
		longitude: 0,
		latitude: 0,
		avg_utilization: 0,
		avg_power_consumption: 0,
		swap_hours: '',
		available_battery: 0,
		charging_battery: 0,
		total_battery: 0,
		total_slot: 0,
		is_active: true,
		created_date: ''
	},
	holeStation: [],
	listBusyTime: [],
	stationVisitor: [],
};

const stationReducer = (state = initState, action: StationActionTypes): StationState => {
	switch (action.type) {
		case GET_BATTERY_STATIONS:
			return {
				...state,
				station: action.data
			};

			case GET_STATION_DETAIL:
			return {
				...state,
				stationDetail: action.data
			};
			
			case GET_HOLE_STATION:
			return {
				...state,
				holeStation: action.data
			};
			
			case GET_BUSY_TIME:
			return {
				...state,
				listBusyTime: action.data
			};
			
			case GET_STATION_VISITOR:
			return {
				...state,
				stationVisitor: action.data
			};

		case LOGOUT:
			return initState;

		default:
			return state;
	}
};

export default stationReducer;
