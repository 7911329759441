import React, { useEffect, useState } from 'react';
import { Empty, Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { RefSelectProps } from 'antd/es/select';

import { Colors } from 'consts';
import { hooks } from 'helpers';

import { SelectSearchBarStyle, SearchStyle } from './style';

import Loader from '../../Loader';
import Text from '../../Text';
import Input from '../../Input';
import Divider from '../../Divider';
import Icon from '../../Icon';

const { Option } = Select;

export interface SelectSearchBarProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  style?: React.CSSProperties;
  backgroundColor?: string;
  width?: string;
  placeholder?: string;
  ref?: React.Ref<RefSelectProps>;
  onDropdownVisibleChange?: (open: boolean) => void;
  totalItems?: number;
  defaultOptions?: ValueType[];
  wrapperStyle?: React.CSSProperties;
}

function SelectSearchBar<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number; } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  style,
  backgroundColor,
	width,
  disabled,
	placeholder,
  ref,
  onDropdownVisibleChange,
  totalItems,
  defaultOptions,
  wrapperStyle,
  ...props
}: SelectSearchBarProps) {
  const [focus, setFocus] = useState<boolean>(false);
  const [valueInSearch, setValueInSearch] = useState<string>('');

  const onSelectVisibleChange = (open: boolean) => {
    onDropdownVisibleChange ? onDropdownVisibleChange(open) : null;

    if (!open) {
      setFocus(false);
      setValueInSearch('');
    } else {
      setFocus(true);
    }
  };

  const {
    fetching,
    options,
    debounceFetcher
  } = hooks.useDebounceFetcher(
    fetchOptions,
    onSelectVisibleChange,
    defaultOptions,
    debounceTimeout
  );

  useEffect(() => {
    debounceFetcher(valueInSearch);
  }, [valueInSearch]);

  const renderTextOption = (text: string) => {
    return <Text lineHeight={ 24 } ellipsis>{ text }</Text>;
  };

  const optionList = options?.map((option, index) => (
    <Option
      key={ index }
      value={ option.value }
      label={ option.label }
    >{ renderTextOption(option.label.toString()) }</Option>
  ));

  const renderSearch = () => {
    return (
      <SearchStyle>
        <div>
          <Input
            placeholder = { placeholder || 'Search' }
            value={ valueInSearch }
            name='search'
            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
              setValueInSearch(e.target.value);
            } }
            mb={ 20 }
            borderRadius={ 8 }
            weight={ 400 }
            iconName='search'
            iconClassName='ic-search'
          />
        </div>
      </SearchStyle>
    );
  };

  return (
    <SelectSearchBarStyle
      isOpen={ focus }
      backgroundColor={ backgroundColor }
      width={ width }
      disabled={ disabled }
      totalItems={ totalItems }
      style={ wrapperStyle }
    >
      <Select<ValueType>
        ref={ ref }
        labelInValue
        filterOption={ false }
				placeholder = { placeholder || 'Search' }
				disabled={ disabled }
        notFoundContent={ fetching
          ? <Loader className='col center-content' style={ { height: 100 } } />
          : <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
        }
        style={ style }
        listHeight={ 100 }
        dropdownStyle={ { borderRadius: 10 } }
        onDropdownVisibleChange={ onSelectVisibleChange }
        className='search-input text-ellipsis'
        dropdownRender={ menu => (
          <div>
            { renderSearch() }
            <Divider marginVertical={ 2 } />
            { menu }
          </div>
        ) }
        suffixIcon={
          <Icon
            iconName='arrowDown'
            size={ 13 }
            fill={ Colors.blue.isBlue }
            className='ic-arrow'
          />
        }
        { ...props }
      >
        { optionList }
      </Select>
    </SelectSearchBarStyle>
  );
}

export default SelectSearchBar;
