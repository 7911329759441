import { DischargeTempStatistic } from 'interfaces/battery';

export const getDataConverted = (arr: DischargeTempStatistic, filter: string) => {
    const progress = Object.values(arr);
    
    const hash: Record<string, { arrCurrent: any[], current: number; hours: string }> = Object.create(null);
    const grouped: { arrCurrent: any[], current: number; hours: string }[] = [];
    
    progress.forEach(item => {
      const key: string = filter === 'Last 24 Hours' ? item?.created_date?.slice(0, 13) : item?.created_date?.slice(0, 10);
    
      if (!hash[key]) {
          hash[key] = {
            arrCurrent: [0],
            hours: filter === 'Last 24 Hours' ? key + ':00' : key,
            current: 0,
          };
          grouped.push(hash[key]);
      }
      
      hash[key].arrCurrent.push(Number(item?.current?.toFixed(2)));
    });

    grouped.sort((a, b) => {
        return b.hours.localeCompare(a.hours);
    });

    const getMaxDischarge = grouped.map(obj => {
        const arr = Object.values(obj.arrCurrent);
        return {
            ...obj,
            current: Number(Math.max(...arr).toFixed(2))
        };
        
    });

    return filter === 'Last 24 Hours' ? getMaxDischarge.slice(0, 24).reverse() : getMaxDischarge.slice(0, 7).reverse();
};