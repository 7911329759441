import { createSelector } from 'reselect';

import { RootState } from '../index';

const authSelector = (state: RootState) => state.auth.user;

export const username = createSelector(authSelector, user => user.username);
export const adminId = createSelector(authSelector, user => user.user_id);
export const adminRole = createSelector(authSelector, user => user?.role_name);
export const adminImage = createSelector(authSelector, user => user?.image);
export const adminShowroomId = createSelector(authSelector, user => user.showroom_id);
export const adminPermissions = createSelector(authSelector, user => user?.permissions);
