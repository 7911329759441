import React, { useEffect, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';

import { Colors } from 'consts';
import { LoadingBarRef } from 'typings';

export type TopLoaderBar = {
  progress?: number;
  color?: string;
  onLoaderFinished?: () => void;
};

const TopLoaderBar: React.FC<TopLoaderBar> = ({
  progress,
  color,
  onLoaderFinished
}) => {
  const refLoadingBar = useRef<LoadingBarRef>(null);

  useEffect(() => {
    if (progress === 0) {
      if (refLoadingBar.current) {
        refLoadingBar.current.continuousStart(0, 1000);
      }
    } else if (progress === 100) {
      if (refLoadingBar.current) {
        refLoadingBar.current.complete();
      }
    }
  }, [progress]);

  const progressColor = color
  ? color
  : Colors.blue.isBlue;

  return (
    <LoadingBar
      ref={ refLoadingBar }
      color={ progressColor }
      height={ 3 }
      onLoaderFinished={ onLoaderFinished }
    />
  );
};

export default TopLoaderBar;
