import { Colors } from 'consts';
import { moment } from 'helpers';
import { CaseStatus } from 'interfaces/ticketing';

export const convertTabNumberToStatus = (selectedTab: number) => {
	switch (selectedTab) {
		case 0:
			return CaseStatus.open as string;
		case 1:
			return CaseStatus.pending as string;
		case 2:
			return CaseStatus.close as string;
		default:
			return '';
	}
};

export const convertTabTitleToStatus = (tabName: string) => {
	switch (tabName.toLowerCase()) {
		case 'close':
			return CaseStatus.close;
		case 'open':
			return CaseStatus.open;
		case 'pending':
			return CaseStatus.pending;
		default:
			return tabName.toLowerCase();
	}
};

export const convertStatusColor = (status: string) => {
	const statusLowerCase = status?.toLowerCase();

	if (statusLowerCase === 'solved') return Colors.blue.isBlue;
	if (statusLowerCase === 'unsolved') return Colors.grey.isGrey;

	return Colors.black.isBlack;
};

export const convertDateToLocal = (date: string) => {
	return moment.utcToLocal(date, 'DD/MM/YYYY h:mm A');
};