import React, { useState } from 'react';

import {
  Box,
  Text,
  Icon,
  EmptyState,
  MapsLeaflet
} from 'components';
import { Colors, Images } from 'consts';
import { hooks, misc } from 'helpers';
import { selectors } from 'store/selectors';
import { MapsLocation, ValueType } from 'typings';

import { BoxLocationStyle } from './style';

type BoxLocationProps = {
  widthBox: number | string;
  onClickLiveTracking?: () => void;
  scooterLocation: MapsLocation | null;
};

const BoxLocation: React.FC<BoxLocationProps> = ({
  widthBox,
  scooterLocation,
  onClickLiveTracking
}) => {
  // const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const scooterDetail = hooks.useAppSelector(selectors.scooter.scooterDetail);

  const [address, setAddress] = useState('');

  // const [checkpoint, setCheckpoint] = useState<Checkpoint[]>([]);
  // const [loadingGetCheckpoint, setLoadingGetCheckpoint] = useState<boolean>(false);
  // const [currentTime] = hooks.useCurrentTime();

  // const loadingScooter = misc.isLazyLoading(lazyLoad, 'scooterDetail');

  // const onGetCheckpoint = (arrPlaces: PlaceResult[]) => {
  //   const nullExist = arrPlaces.filter(place => place === null);

  //   if (!nullExist.length) {
  //     const placesResult: PlaceResult[] = arrPlaces.filter(place => place !== null);
  //     const checkpoint: Checkpoint[] = [];

  //     for (let i = 0; i < placesResult.length; i++) {
  //       checkpoint.push(_.merge(placesResult[i], scooterLocation[i]));
  //     }

  //     setCheckpoint(checkpoint);

  //     if (placesResult.length < 1 && scooterLocation.length) {
  //       setLoadingGetCheckpoint(true);
  //     } else {
  //       setLoadingGetCheckpoint(false);
  //     }
  //   } else {
  //     setLoadingGetCheckpoint(false);
  //   }
  // };

  const renderMaps = () => {
    const defaultCenter = scooterLocation
      ? scooterLocation
      : undefined;

    return (
      <MapsLeaflet
        center={ defaultCenter }
        width='100%'
        height='218px'
        containerStyle={ { borderRadius: 10 } }
        onChangeLocation={ (result: ValueType) => setAddress(result.value) }
        iconRetinaUrl={ Images.maps.marker_scooter_2x }
        iconUrl={ Images.maps.marker_scooter }
      />
    );
  };

  // const renderTime = (time: string) => {
  //   return (
  //     <Text
  //       size='xs'
  //       color={ Colors.grey.isGrey }
  //       lineHeight={ 15 }
  //       align='right'
  //     >{ time }</Text>
  //   );
  // };

  const renderAddress = () => {
    return (
      <div className='justify-between mt4 mb3'>
        <div className='text-address-container'>
          <Text align='justify'>{ misc.capitalizeFirstLetter(address) }</Text>
        </div>
        { /* { renderTime(formattedTime) } */ }
      </div>
    );
  };

  const renderCurrentLocation = () => {
    if (scooterLocation) {
      return (
        <>
          { renderAddress() }
          { renderMaps() }
        </>
      );
    }

    return (
      <div className='col center-content empty-wrap'>
        <EmptyState
          image={ {
            src: Images.emptyState.location,
            width: 45,
            height: 45
          } }
          text='No Location Detected'
        />
      </div>
    );
  };

  const renderBtnTextToLiveTracking = () => {
    const disabled = !scooterDetail?.parent && !scooterDetail?.childs?.length;

    return (
      <Text
        weight={ 700 }
        color={ Colors.blue.isBlueOpacity(disabled ? '0.5' : '1') }
        lineHeight={ 19 }
        align='right'
        onClick={ !disabled ? onClickLiveTracking : undefined }
        disabled={ disabled }
      >Live Tracking</Text>
    );
  };

  return (
    <BoxLocationStyle>
      <Box
        mt={ 20 }
        padding={ 20 }
        width={ widthBox }
        className='box-location'
      >
        <div className='justify-align-center'>
          <div className='flex row'>
            <Text
              size='m'
              weight={ 700 }
              mr={ 5 }
            >Current Location</Text>
            <Icon
              size={ 16 }
              iconName='location'
              fill={ Colors.blue.isBlue }
            />
          </div>
          { renderBtnTextToLiveTracking() }
        </div>

        { renderCurrentLocation() }
      </Box>
    </BoxLocationStyle>
  );
};

export default BoxLocation;
