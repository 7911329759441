import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { ApiData, CommonResponseData } from 'interfaces/common';
import {
  BatteryApiParams,
  BatteryPack,
  BatteryPackStatistic,
  FormAddBattery,
  FormBatteryPack,
  BatteryPackBatteryUsage,
  DischargeTempStatistic,
  DischargeTempApiParams,
  VinLog
} from 'interfaces/battery';
import { ScooterApiParams, SwapLogApiParams } from 'interfaces/scooter';

export const getAllBatteryPack = async(params: BatteryApiParams) => {
  try {
    const queryParams: BatteryApiParams = {
      ...params,
      sort: params.sort || 'asc',
      order: params.order || 'id',
    };

    const response: ApiData<BatteryPack[]> = await customFetch(Endpoints.getAllBatteryPack(), queryParams);

    return response;
  } catch (error) {
    throw error;
  }
};

export const addBatteryPack = async(formAddBattery: FormAddBattery) => {
  try {
    const response: ApiData<any> = await customFetch(Endpoints.addBatteryPack(), formAddBattery);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editBatteryPack = async(id: string, formBattery: FormBatteryPack) => {
  try {
    const allowedFields = ['status', 'location'];
    const formEditBattery = Object.keys(formBattery)
      .filter(key => allowedFields.includes(key))
      .reduce((obj, key) => {
        obj[key] = formBattery[key];
        return obj;
      }, {});

    const response: ApiData<CommonResponseData> = await customFetch(Endpoints.editBatteryPack(id), formEditBattery);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getBatteryPackDetail = async(batteryId: string) => {
  try {
    const response: ApiData<BatteryPack> = await customFetch(Endpoints.getBatteryPackDetail(batteryId));

    return response;
  } catch (error) {
    throw error;
  }
};

export const getSocBatteryPack = async(batteryId: string, params: BatteryApiParams) => {
  try {
    const response: ApiData<BatteryPackStatistic[]> = await customFetch(Endpoints.getSocBatteryPack(batteryId), params);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getSohBatteryPack = async(batteryId: string, params: BatteryApiParams) => {
  try {
    const response: ApiData<BatteryPackStatistic[]> = await customFetch(Endpoints.getSohBatteryPack(batteryId), params);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getDischargeBatteryPack = async(batteryId: string, params: DischargeTempApiParams) => {
  try {
    const response: ApiData<DischargeTempStatistic[]> = await customFetch(Endpoints.getDischargeBatteryPack(batteryId), params);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getTempBatteryPack = async(batteryId: string, params: DischargeTempApiParams) => {
  try {
    const response: ApiData<DischargeTempStatistic[]> = await customFetch(Endpoints.getTempBatteryPack(batteryId), params);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getVinLog = async(params: SwapLogApiParams) => {
  try {
    const response: ApiData<VinLog[]> = await customFetch(Endpoints.getTransactionVinLog(), params);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBatteryPackBatteryUsages = async(id: string, params: ScooterApiParams) => {
  try {

    const response: ApiData<BatteryPackBatteryUsage[]> = await customFetch(Endpoints.getBatteryPackBatteryUsages(id), params);

    return response;
  } catch (error) {
    throw error;
  }
};