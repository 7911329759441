import { Dispatch } from 'redux';

import { AppActions } from 'store';
import * as miscServices from 'services/misc';

import {
	LOADING,
	LAZY_LOAD,
	SetLoading,
	SetLazyLoad,
} from './actionTypes';
import {
	ApiData,
	FormUploadImage,
	UploadImageResponse
} from 'interfaces/common';
import { toastify } from 'helpers';

export const loadingStart = (loadingMsg: string): SetLoading => {
	return {
		type: LOADING,
		data: {
			isLoading: true,
			loadingMsg
		}
	};
};

export const loadingEnd = (loadingMsg: string): SetLoading => {
	return {
		type: LOADING,
		data: {
			isLoading: false,
			loadingMsg
		}
	};
};

export const lazyLoadStart = (fieldName: string): SetLazyLoad => {
	return {
		type: LAZY_LOAD,
		data: {
			fieldName,
			isLoading: true
		}
	};
};

export const lazyLoadEnd = (fieldName: string): SetLazyLoad => {
	return {
		type: LAZY_LOAD,
		data: {
			fieldName,
			isLoading: false
		}
	};
};

export const startLoading = (loadingMsg: string) => {
	return async(dispatch: Dispatch<AppActions>) => {
		dispatch(loadingStart(loadingMsg));
	};
};

export const stopLoading = (loadingMsg: string) => {
	return async(dispatch: Dispatch<AppActions>) => {
		dispatch(loadingEnd(loadingMsg));
	};
};

export const startLazyLoading = (fieldName: string) => {
	return async(dispatch: Dispatch<AppActions>) => {
		dispatch(lazyLoadStart(fieldName));
	};
};

export const stopLazyLoading = (fieldName: string) => {
	return async(dispatch: Dispatch<AppActions>) => {
		dispatch(lazyLoadEnd(fieldName));
	};
};

export const uploadImage = (dispatch: Dispatch<AppActions>) => (formUpload: FormUploadImage, callback?: (dataUploaded: UploadImageResponse) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('uploadImage'));

			const { code, data }: ApiData<UploadImageResponse> = await miscServices.uploadImage(formUpload);

			if (code === 200) {
				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('uploadImage'));
		}
	});
};

export const uploadMultipleImages = (dispatch: Dispatch<AppActions>) => (fileList: File[], callback?: (fileListUploaded: (UploadImageResponse | File)[]) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('uploadMultipleImages'));

			const fileListUploaded: (UploadImageResponse | File)[] = await miscServices.uploadMultipleImages(fileList);

			if (callback) callback(fileListUploaded);

			resolve();
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('uploadMultipleImages'));
		}
	});
};