import React from 'react';

import { Images } from 'consts';

import Image from '../Image';

type LoaderProps = {
  className?: string;
  size?: number;
  color?: 'black' | 'blue' | 'lightGrey';
  style?: React.CSSProperties;
};

const Loader: React.FC<LoaderProps> = ({
  className,
  size,
  color,
  style
}) => {
  const renderLazyLoader = () => {
    let srcImageLoading = Images.loadingBlue;

    if (color === 'black')
      srcImageLoading = Images.loadingBlack;
    else if (color === 'lightGrey')
      srcImageLoading = Images.loadingLightGrey;
  
    return (
      <div className={ className } style={ style }>
        <Image
          src={ srcImageLoading }
          width={ size }
          height={ size }
        />
      </div>
    );
  };

  return renderLazyLoader();
};

Loader.defaultProps = { size: 40 };

export default Loader;
