import React, { useEffect } from 'react';

import { BoxChart, LineChart } from 'components';
import { FilterMenuChartProps } from 'components/Box/BoxChart';
import { hooks, misc } from 'helpers';
import { DischargeTempApiParams, DischargeTempStatistic } from 'interfaces/battery';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import {
  setFilterTimeApiParams,
  convertLabelTooltip
} from '../../helpers';

import { getDataConverted } from './helpers';

type BoxTempProps = {
  widthBox: number;
  filterMenu: FilterMenuChartProps;
  routeId?: string;
};

const BoxTemp: React.FC<BoxTempProps> = ({
  widthBox,
  filterMenu,
  routeId
}) => {
  const dispatch = hooks.useAppDispatch();
  const getTempBatteryPack = dispatch(actions.getTempBatteryPack);

  const listTemp = hooks.useAppSelector(selectors.battery.listTemp);
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const loadingTemp = misc.isLazyLoading(lazyLoad, 'tempBatteryPack');
  const activeFilterTime = filterMenu.activeFilter.temp_time;

  const getListDataTemperature = (callback?: (listTemp: DischargeTempStatistic[]) => void) => {
    const params: DischargeTempApiParams = { report_date: setFilterTimeApiParams(activeFilterTime) };

    getTempBatteryPack(
      routeId ?? '',
      params,
      callback
    );
  };

  useEffect(() => {
    if (routeId) {
      getListDataTemperature();
    }
  }, [filterMenu.activeFilter.temp_time]);

  const convertDataChart = arr => {
    return getDataConverted(arr, filterMenu.activeFilter.temp_time);
  };

  const renderLineChart = () => {

    const dataChartConvert = convertDataChart(listTemp);
    const maxValue = Math.max(...dataChartConvert?.map(o => o.temperature));

    return (
      <LineChart
        width={ widthBox - 40 }
        data={ dataChartConvert }
        dataKey={ {
          xAxis: 'hours',
          line: 'temperature'
        } }
        unit='°C'
        convertLabel={ (_, payload) => convertLabelTooltip(activeFilterTime, payload) }
        valueDomain={ [0, maxValue ? maxValue : 100] }
        maxCharsLabel={ 5 }
        yAxisWidth={ 50 }
      />
    );
  };

  return (
    <BoxChart
      title='Temperature'
      width={ widthBox }
      filterMenu={ filterMenu }
      chart={ renderLineChart() }
      mt={ 20 }
      mr={ 20 }
      loading={ loadingTemp }
      empty={ !Object.values(listTemp)?.length }
    />
  );
};

export default BoxTemp;
