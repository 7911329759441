import Styled from 'styled-components';

import { LineProps } from './index';

export const LineStyle: any = Styled.div < LineProps > `
  border-bottom: ${props =>
    `${typeof props.lineWidth === 'number' ? `${props.lineWidth}px` : props.lineWidth} ${props.lineStyle} ${props.lineColor}`};
  border-radius: ${props => props.borderRadius}px;
  margin: ${props => props.margin};
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;