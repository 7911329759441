import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

type BoxStatsStyleProps = {
  isFilterActive?: boolean;
};

type LineChartStyleProps = {
  width?: number;
  height?: number;
};

export const BoxStatsStyle: any = Styled.div < BoxStatsStyleProps > `
  display: flex;
  flex-direction: column;

  .dropdown-filter {
    cursor: pointer;
    background-color: ${Colors.grey.isBgLightGrey};
    width: 155px;
    height: 43px;
    padding: 13px 20px;
    border-radius: 8px;

    &:hover {
      opacity: 0.8;
    }
  }

  .ic-arrow {
    ${props => props.isFilterActive ? 'transform: rotateX(180deg);' : ''}
  }

  @media ${screen.sizesMax.st} {
    .dropdown-filter {
      width: 180px;
    }
  }

  @media ${screen.sizesMax.xs} {
    .dropdown-filter {
      width: 109px;
    }
  }

  @media ${screen.sizesMax.xxs} {
    .dropdown-filter {
      width: 70px;
    }
  }
`;

export const LineChartStyle: any = Styled.div < LineChartStyleProps > `
  .chart-skeleton > .ant-skeleton-avatar {
    border-radius: 5px !important;
    height: ${props => props.height}px !important;
    width: ${props => props.width}px !important;
  }
`;
