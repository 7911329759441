import React from 'react';

import { Images } from 'consts';
import { hooks, screen } from 'helpers';

import Box, { BoxProps } from '../index';
import Text from '../../Text';
import Select from '../../Select';
import Spacer from '../../Spacer';
import Skeleton from '../../Skeleton';
import EmptyState from '../../EmptyState';

import { BoxChartStyle } from './style';

export type FilterMenuChartProps = {
  keys: string[];
  activeFilter: any;
  visibleFilter: any;
  onVisibleChange: (visible: boolean, key: string) => void;
  onClickFilterMenu: (params?: any) => void;
  selectOptions: string[][];
};

type BoxChartProps = BoxProps & {
  title: string;
  filterMenu: FilterMenuChartProps;
  chart: React.ReactNode;
  children?: React.ReactNode;
  empty?: boolean;
  loading?: boolean;
};

const BoxChart: React.FC<BoxChartProps> = ({
  title,
  filterMenu,
  chart,
  children,
  empty,
  loading,
  ...boxProps
}) => {
  
  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();
  const isMobile = windowDimensions.width <= screen.isMobile;
  const widthContent = typeof boxProps.width === 'number'
  ? boxProps.width - 40
  : boxProps.width;

  const renderFilterMenu = (index: number) => {
    const {
      keys,
      visibleFilter,
      onVisibleChange,
      activeFilter,
      onClickFilterMenu,
      selectOptions
    } = filterMenu;

    return (
      <Select
        keySelect={ keys[index] }
        visible={ visibleFilter[keys[index]] }
        onVisibleChange={ onVisibleChange }
        selectTitle={ activeFilter[keys[index]] }
        onClickSelectItem={ onClickFilterMenu }
        selectOptions={ selectOptions[index] }
        totalItems={ keys.length }
        defaultWidth={ keys.length === 1 ? '200px' : '180px' }
        wrapperStyle={ { marginBottom: 0 } }
      />
    );
  };

  const renderFilterMenus = () => {
    if (filterMenu.keys.length === 1) {
      return renderFilterMenu(0);
    }

    if (filterMenu.keys.length === 2) {
      const boxWidth = boxProps.width && typeof boxProps.width === 'string'
      ? Number(boxProps.width.slice(0, -2))
      : Number(boxProps.width);
      const isColumnWrapper = isMobile || boxWidth < 570;

      return (
        <div className={ isColumnWrapper ? 'col col-end' : 'flex row' }>
          { renderFilterMenu(0) }
          <Spacer ml={ isColumnWrapper ? 0 : 20 } mt={ isColumnWrapper ? 15 : 0 }>
            { renderFilterMenu(1) }
          </Spacer>
        </div>
      );
    }
  };

  const renderEmptyDataStatistic = (width?: number | string, height?: number | string) => {
    return (
      <EmptyState
        background={ {
          width,
          height
        } }
        image={ {
          src: Images.emptyState.chart,
          width: 56,
          height: 56
        } }
        text='No Stats Available'
      />
    );
  };

  return (
    <BoxChartStyle width={ widthContent } height={ 300 }>
      <Box padding={ 20 } { ...boxProps }>
        <div className='justify-align-center mb4'>
          <Text size='m' weight={ 700 }>{ title }</Text>
          { renderFilterMenus() }
        </div>
        <Skeleton
          loading={ loading || false }
          avatar={ { shape: 'square' } }
          className='chart-skeleton'
          multiple={ false }
        >
          {
            empty
            ? renderEmptyDataStatistic(widthContent, 300)
            : (
              <>
                
                { chart }
                
                { children ? children : null }
              </>
            )
          }
        </Skeleton>
      </Box>
    </BoxChartStyle>
  );
};

export default BoxChart;
