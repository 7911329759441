interface Config {
  baseUrl: string;
  version: string;
  baseImageUrl: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
}

const config: Config = {
  baseUrl: process.env.REACT_APP_API_URL ?? '',
  version: process.env.REACT_APP_API_VERSION ?? '',
  baseImageUrl: process.env.REACT_APP_BASE_IMAGE_URL ?? '',
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? '',
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL ?? '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? '',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID ?? ''
  }
};

export default config;
