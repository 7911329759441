import { store } from 'store';

import * as LocalStorage from './localStorage';
import * as SessionStorage from './sessionStorage';

export const removeAuthCredentials = () => {
	LocalStorage.clearToken();
	SessionStorage.clearToken();

	return;
};

export const setAuthCredentials = (accessToken: string, refreshToken: string) => {
	const isSaveToken = store.getState().auth.saveToken;

	if (isSaveToken) {
		return LocalStorage.setToken(accessToken, refreshToken);
	}

	return SessionStorage.setToken(accessToken, refreshToken);
};

export const getAuthCredentials = (tokenType: 'access_token' | 'refresh_token') => {
	return LocalStorage.getToken(tokenType) || SessionStorage.getToken(tokenType);
};
