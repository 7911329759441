import React from 'react';

import { Colors } from 'consts';

import { propsFont } from '../index';

type CustomizedAxisTickProps = {
  props: any;
  width?: number;
  maxChars?: number | false;
  fontSize?: number;
  lineHeight?: number;
  fill?: string;
  customTransform?: string;
  anchorText?: string;
};

const CustomizedAxisTick: React.FC<CustomizedAxisTickProps> = ({
  props,
  width,
  maxChars,
  fontSize,
  lineHeight,
  fill,
  customTransform,
  anchorText
}) => {
  const {
    x,
    y,
    payload
  } = props;

  let chunks;
  
  const valueSplitted = String(payload?.value)?.replace(/-/g, ' ')
    ?.split(' ');
  const valueLineBreak = String(payload?.value)?.split('<br>');
  
  if (maxChars) {
    const rx = new RegExp(`.{1,${maxChars}}`, 'g');
    chunks = valueSplitted?.map(s => s.match(rx)).flat();
  } else {
    if (valueLineBreak) {
      chunks = valueLineBreak;
    } else {
      chunks = valueSplitted?.flat();
    }
  }
  
  const tspans = chunks.map((s, i) =>
    <tspan
      key={ i }
      x={ 0 }
      y={ (lineHeight || 15) }
      dy={ (i * (lineHeight || 15)) }
    >
      { s }
    </tspan>);

  const customLabelStyle = {
    transform: customTransform,
    propsFont
  };

  return (
    <g transform={ `translate(${x},${y})` }>
      <text
        width={ width }
        height='auto'
        textAnchor={ anchorText || 'middle' }
        fontSize={ fontSize }
        fill={ fill }
        style={ customLabelStyle }
        fontWeight='bold'
      >
        { tspans }
      </text>
    </g>
  );
};

CustomizedAxisTick.defaultProps = {
  width: 50,
  maxChars: 4,
  fontSize: 12,
  lineHeight: 15,
  fill: Colors.grey.isGrey
};

export default CustomizedAxisTick;
