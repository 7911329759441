import Styled from 'styled-components';

type BoxChartStyleProps = {
  width?: number | string;
  height?: number | string;
};

export const BoxChartStyle: any = Styled.div < BoxChartStyleProps > `
  .chart-skeleton > .ant-skeleton-avatar {
    border-radius: 5px !important;
    ${props => typeof props.width === 'number' ? `width: ${props.width}px;` : `width: ${props.width};` }
    ${props => typeof props.height === 'number' ? `height: ${props.height}px;` : `height: ${props.height};` }
  }
`;
