import { Pagination } from 'interfaces/common';
import {
  BatteryPack,
  BatteryPackBatteryUsage,
  BatteryPackStatistic,
  DischargeTempStatistic,
  VinLog
} from 'interfaces/battery';

export const GET_ALL_BATTERY_PACK = 'GET_ALL_BATTERY_PACK';
export const GET_BATTERY_PACK_DETAIL = 'GET_BATTERY_PACK_DETAIL';
export const GET_BATTERY_PACK_SOC = 'GET_BATTERY_PACK_SOC';
export const GET_BATTERY_PACK_SOH = 'GET_BATTERY_PACK_SOH';
export const GET_BATTERY_PACK_DISCHARGE = 'GET_BATTERY_PACK_DISCHARGE';
export const GET_BATTERY_PACK_TEMPERATURE = 'GET_BATTERY_PACK_TEMPERATURE';
export const GET_BATTERY_VIN_LOG = 'GET_BATTERY_VIN_LOG';
export const GET_BATTERY_PACK_BATTERY_USAGES = 'GET_BATTERY_PACK_BATTERY_USAGES';
export const LOGOUT = 'LOGOUT';

export interface SetAllBatteryPack {
  type: typeof GET_ALL_BATTERY_PACK;
  data: {
    data: BatteryPack[];
    pagination: Pagination;
  };
}

export interface SetBatteryPack {
  type: typeof GET_BATTERY_PACK_DETAIL;
  data: BatteryPack;
}

export interface SetBatteryPackSoc {
  type: typeof GET_BATTERY_PACK_SOC;
  data: {
    data: BatteryPackStatistic[];
    filter_time: string;
  };
}

export interface SetBatteryPackSoh {
  type: typeof GET_BATTERY_PACK_SOH;
  data: {
    data: BatteryPackStatistic[];
    filter_time: string;
  };
}

export interface SetBatteryPackDischarge {
  type: typeof GET_BATTERY_PACK_DISCHARGE;
  data: {
    data: DischargeTempStatistic[];
  };
}

export interface SetBatteryPackTemp {
  type: typeof GET_BATTERY_PACK_TEMPERATURE;
  data: {
    data: DischargeTempStatistic[];
  };
}

export interface SetBatteryVinLog {
  type: typeof GET_BATTERY_VIN_LOG;
  data: {
    data: VinLog[],
    pagination: Pagination;
  };
}

export interface SetBatteryPackBatteryUsage {
  type: typeof GET_BATTERY_PACK_BATTERY_USAGES;
  data: BatteryPackBatteryUsage[];
}

export interface Logout {
  type: typeof LOGOUT;
}

export type BatteryActionTypes =
  SetAllBatteryPack |
  SetBatteryPack |
  SetBatteryPackSoc |
  SetBatteryPackSoh |
  SetBatteryPackDischarge |
  SetBatteryPackTemp |
  SetBatteryVinLog |
  SetBatteryPackBatteryUsage |
  Logout;
