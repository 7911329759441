import React from 'react';

import { Colors } from 'consts';
import {
  hooks,
  roleHelper,
  screen
} from 'helpers';
import { language } from 'language';
import { selectors } from 'store/selectors';

import { ButtonActionProps } from '../../index';
import { getColorFill } from '../../helpers';

import Icon from '../../../Icon';
import Button, { ButtonProps } from '../../../Button';

import { ButtonActionStyle } from './style';

type ButtonActionTableProps = {
  buttons: ButtonActionProps[];
  record: any;
};

const ButtonActionTable: React.FC<ButtonActionTableProps> = ({ buttons, record }) => {
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);
  const filteredButtons = buttons.filter((buttonAction: ButtonActionProps) => roleHelper.isPermissible(adminPermissions, buttonAction.permission));

  return (
    <div className='center-content'>
      <ButtonActionStyle>
        {
          filteredButtons.map((dataButton: ButtonActionProps, index: number) => {
            const isScooterMissingActive = dataButton.iconName === 'alert' && record.status === language.scooters.missing;
            const colors = getColorFill(isScooterMissingActive ? 'red' : (dataButton.color || 'grey'));
            const isMobile = screen.width <= screen.isMobile;
            const iconFill = isMobile || isScooterMissingActive
              ? colors[0]
              : Colors.grey.isGrey;
            const iconContainerFill = isMobile || isScooterMissingActive
              ? colors[1]
              : Colors.grey.isLighterGrey;

            if (dataButton.iconName) {
              return (
                <Icon
                  key={ `btn-action-icon-${ index }` }
                  size={ 13 }
                  iconName={ dataButton.iconName }
                  fill={ iconFill }
                  hoverFill={ colors[0] }
                  container='square'
                  containerColor={ iconContainerFill }
                  containerHoverColor={ colors[1] }
                  onClick={ (e: React.MouseEvent) => {
                    e.stopPropagation();
                    dataButton.onClick && dataButton.onClick(record);
                  } }
                />
              );
            }

            if (dataButton.button) {
              const buttonProps: ButtonProps = dataButton.button;

              return (
                <Button
                  key={ `btn-action-icon-${ index }` }
                  onClick={ () => dataButton.onClick && dataButton.onClick(record) }
                  { ...buttonProps }
                />
              );
            }
          })
        }
      </ButtonActionStyle>
    </div>
  );
};

export default ButtonActionTable;
