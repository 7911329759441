import React, {
  useState,
  useRef,
  useEffect
} from 'react';
import { Carousel, Space } from 'antd';
import { CarouselRef } from 'antd/lib/carousel/index';

import { Colors, Images } from 'consts';
import { hooks, screen } from 'helpers';

import { ModalReportStyle, IndicatorStyle } from './style';

import Modal from '../../Modal';
import Text from '../../Text';
import Button from '../../Button';
import Icon from '../../Icon';
import Image from '../../Image';

type ModalReportProps = {
  visible: boolean;
  onCloseModal: () => void;
  loadingConfirm?: boolean;
  onClickConfirm?: () => void;
  type?: 'missing' | 'found';
};

const carouselMissingList = [
  {
    image: Images.state.warning,
    title: 'Are you sure you this scooter is missing?',
    content: 'Did someone took this scooter? Are you sure?',
    confirmText: 'Yes'
  }, {
    image: Images.icons.locked,
    title: 'Are you sure you this scooter is missing?',
    content: 'We will remotely lock this scooter? Are you sure?',
    confirmText: 'YES, Lock it'
  }
];

const ModalReport: React.FC<ModalReportProps> = ({
  visible,
  onCloseModal,
  loadingConfirm,
  onClickConfirm,
  type
}) => {
  const slider = useRef<CarouselRef>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();
  const isMobile = windowDimensions.width <= screen.isMobile;

  const onCloseModalReport = async() => {
    await onCloseModal();
    goToSlide(0);
  };

  useEffect(() => {
    if (!loadingConfirm && !visible) {
      goToSlide(0);
    }
  }, [loadingConfirm, visible]);

  const goToSlide = (index: number) => {
    if (slider.current) {
      slider.current.goTo(index);
    }
  };

  const renderIndicator = () => {
    return Array.from(Array(carouselMissingList.length).keys()).map(i => {
      return (
        <IndicatorStyle key={ i } isActiveCarousel={ activeIndex === i }>
          <div className='indicator-scooter' />
        </IndicatorStyle>
      );
    });
  };

  const onClickBtnConfirm = async() => {
    if (activeIndex === 0 && type === 'missing') {
      goToSlide(activeIndex + 1);
    } else {
      onClickConfirm ? await onClickConfirm() : null;
    }
  };

  const renderImage = (image: any) => {
    return (
      <Image
        src={ image }
        width={ 110 }
        height={ 110 }
        className='image-warning'
      />
    );
  };

  const renderText = (title: string, content: string) => {
    return (
      <>
        <Text
          size='l'
          weight={ 700 }
          align='center'
          mt={ 23 }
          color={ Colors.black.isTextBlack3 }
        >{ title }</Text>
        <Text
          size='m'
          color={ Colors.grey.isDarkGrey }
          align='center'
          mt={ 20 }
        >{ content }</Text>
      </>
    );
  };

  const renderButton = (negativeText: string, positiveText: string) => {
    const buttonWidth = isMobile ? (windowDimensions.width - 120) / 2 : 190;

    return (
      <div className='col center-content mt6'>
        <div className='flex row align-center'>
          <Space size={ isMobile ? 'small' : 'middle' }>
            <Button
              width={ buttonWidth }
              fontWeight={ 700 }
              size='m'
              onClick={ onCloseModalReport }
              text={ negativeText }
            />
            <Button
              width={ buttonWidth }
              styleType='inverse'
              fontWeight={ 700 }
              hover={ false }
              size='m'
              onClick={ onClickBtnConfirm }
              isLoading={ loadingConfirm }
              text={ positiveText }
            />
          </Space>
        </div>
      </div>
    );
  };

  const renderCarouselContent = () => {
    return carouselMissingList.map((dataContent, index) => {
      return (
        <div key={ index }>
          <div className='col center-content mb8'>
            { renderImage(dataContent.image) }
            <div className='center-content mt3 mb3'>
              <div className='flex row align-center'>
                { renderIndicator() }
              </div>
            </div>
            { renderText(dataContent.title, dataContent.content) }
          </div>
          { renderButton('No', dataContent.confirmText) }
        </div>
      );
    });
  };

  const renderModalContent = () => {
    const widthCarousel = isMobile ? windowDimensions.width - 105 : 440;

    if (type === 'missing') {
      return (
        <div style={ { width: widthCarousel } }>
          <Carousel
            dots={ false }
            ref={ slider }
            className='center-content'
            beforeChange={ (from, to) => setActiveIndex(to) }
          >
            { renderCarouselContent() }
          </Carousel>
        </div>
      );
    }

    return (
      <div>
        <div className='col center-content mb8'>
          { renderImage(Images.icons.pin) }
          { renderText('Found Scooter?', 'Are you sure this scooter has been found?') }
        </div>
        { renderButton('Not Yet', 'Yes') }
      </div>
    );
  };

  return (
    <Modal
      visible={ visible }
      onCloseModal={ onCloseModalReport }
      modalType='long-scroll'
      padding={ 30 }
      bodyStyle={ {
        marginTop: 50,
        marginBottom: 30
      } }
      width={ 500 }
    >
      <ModalReportStyle>
        <div className='col col-end mb1'>
          <Icon
            size={ 16 }
            iconName='close'
            fill={ Colors.grey.isGrey }
            onClick={ onCloseModalReport }
          />
        </div>
        { renderModalContent() }
      </ModalReportStyle>
    </Modal>
  );
};

ModalReport.defaultProps = { type: 'missing' };

export default ModalReport;
