import React, { useState } from 'react';

import {
  Icon,
  Text,
  Dropdown
} from 'components';
import { Colors } from 'consts';

import { MenuEntity } from '../Dropdown';

const menuEdit = () => {
  return (
    <div className='flex row'>
      <Icon
        iconName='edit'
        size={ 14 }
        fill={ Colors.grey.isDarkGrey }
      />
      <Text
        lineHeight={ 19 }
        color={ Colors.grey.isDarkGrey }
        ml={ 12 }
        className='p1 pointer'
      >Edit</Text>
    </div>
  );
};

const overlayDropdownDefault = [
  {
    key: 'edit',
    item: menuEdit
  }
];

type OverlayDataProps = {
  key: string;
  item: () => JSX.Element; // eslint-disable-line no-undef
}

type MenuMoreProps = {
  overlayData?: OverlayDataProps[];
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  onClickMenu?: (params: MenuEntity) => void;
};

const MenuMore: React.FC<MenuMoreProps> = ({
  overlayData,
  visible,
  onVisibleChange,
  onClickMenu
}) => {
  const [visibleMore, setVisibleMore] = useState<boolean>(false);

  const onClickMenuDropdown = (params: MenuEntity) => {
    setVisibleMore(false);

    onClickMenu ? onClickMenu(params) : null;
  };

  return (
    <Dropdown
      visible={ visible ? visible : visibleMore }
      onVisibleChange={ visible => {
        onVisibleChange
        ? onVisibleChange(visible)
        : setVisibleMore(visible);
      } }
      overlayData={ overlayData ? overlayData : overlayDropdownDefault }
      onClickMenu={ onClickMenuDropdown }
      placement='bottomLeft'
      trigger={ ['click'] }
    >
      <Icon iconName='more' fill={ Colors.grey.isGrey } />
    </Dropdown>
  );
};

export default MenuMore;
