import { language } from 'language';

const { label, placeholder } = language;

export const inputPropsData = [
  {
    label: 'Station ID',
    placeholder: 'Enter station ID',
    key: 'id',
    type: 'text'
  },
  {
    label: 'Station Name',
    placeholder: 'Enter station name',
    key: 'name',
    type: 'text'
  },
  {
    label: 'Type',
    placeholder: 'Choose station type',
    key: 'station_type',
    type: 'select'
  },
  // {
  //   label: 'Linked User ID',
  //   placeholder: 'Search and select linked user',
  //   key: 'user_id',
  //   type: 'search'
  // },
	{
    label: 'City',
    placeholder: 'Choose City',
    key: 'city',
    type: 'selectSearch'
  },
	{
    label: label.address,
    placeholder: placeholder.address,
    key: 'address',
    type: 'textArea',
    iconName: 'pinLocation',
    inputClassName: 'input-address no-scrollbar'
  },
  {
    label: 'Zip Code',
    placeholder: 'Enter zip code',
    key: 'zip_code',
    type: 'text'
  },
  {
    label: 'Longitude',
    placeholder: '',
    key: 'longitude',
    type: 'text',
		cols: 'first',
		column: '2',
		disabled: true,
  },
  {
    label: 'Latitude',
    placeholder: '',
    key: 'latitude',
    type: 'text',
		cols: 'second',
		column: '2',
		disabled: true,
  },
  {
    label: 'PIC Name',
    placeholder: 'Enter PIC name',
    key: 'pic_name',
    type: 'text'
  },
  {
    label: 'PIC Contact',
    placeholder: 'Enter PIC phone number',
    key: 'pic_number',
    type: 'text'
  },
];

const batteryStationType = ['Home Station', 'Charging Station'];

export const batteryStationFilterType = ['All Type', ...batteryStationType];

export const dropdownsFilter = [
  {
    key: 'station_type',
    selectOptions: batteryStationFilterType
  }
];
