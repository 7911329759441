import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { ApiData, ApiParams } from 'interfaces/common';
import { Role } from 'interfaces/role';

export const getAllRole = async(params: ApiParams) => {
  try {
    const queryParams: ApiParams = {
      ...params,
      sort: 'asc',
      search: '',
      order: 'id'
    };

    const response: ApiData<Role[]> = await customFetch(Endpoints.getAllRole(), queryParams);

    return response;
  } catch (error) {
    throw error;
  }
};