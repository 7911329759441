import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

type ContainerStyleProps = {
  backgroundColor?: string;
};

export const ContainerStyle: any = Styled.div < ContainerStyleProps > `
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ContainerDrawerStyle: any = Styled.div`
  .content {
    padding: 20px;
    min-height: ${screen.height}px;
    background-color: ${Colors.green.isMintGreen};
  }

  .btn-header {
    padding: 12px 20px;
  }

  @media ${screen.sizesMax.xs} {
    .btn-header {
      padding: 9px 18px;
    }
  }
`;
