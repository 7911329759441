import { misc } from 'helpers';

export const langId = {
  logout: 'Logout',
  login: 'Log In',
  editInfo: 'Edit Info',
  save: 'Save',
  delete: 'Delete',
  confirmDelete: 'Are you sure to delete this data?',
  yes: 'Yes',
  no: 'No',
  contact: 'Contact',
  errorFormMessage: {
    username: 'Minimum username is 6 characters and without special characters',
    password: 'Minimum password is 4 characters',
    passwordMatch: 'Password and confirm password doesn\'t match',
    email: 'Invalid email format',
    phone: 'Invalid phone number',
    form: (label: string) => `${ misc.capitalizeFirstLetter(label) } is required`
  },
  errorDefault: {
    api: 'Maaf, terjadi kesalahan. Silakan muat ulang halaman.',
    notSupport: 'Mohon maaf, terjadi kesalahan atau perangkat tidak mendukung'
  },
  label: {
    name: 'Name',
    username: 'Username',
    email: 'Email',
    phone: 'Phone Number',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    dob: 'Date of Birth',
    address: 'Address',
    location: 'Location',
    vin: 'VIN',
    scooterName: 'Scooter Name',
    platNumber: 'Plat Number',
    status: 'Status',
    showroom: 'Showroom',
    scooterType: 'Type',
    role: 'Role',
    pic: 'PIC',
    showroomName: 'Showroom Name',
    type: 'Type',
    subscriptionName: 'Subscription Name',
    description: 'Description',
    duration: 'Duration',
    swap: 'Swap',
    price: 'Price',
    setAvailability: 'Set Availability',
  },
  placeholder: {
    name: 'Enter your name',
    username: 'Enter your username',
    email: 'Enter your email',
    phone: 'Enter your phone number',
    password: 'Enter your password',
    confirmPassword: 'Enter your confirm password',
    dob: 'Choose your date of birth',
    address: 'Enter full address',
    location: 'Choose location',
    vin: 'Enter VIN scooter',
    scooterName: 'Enter scooter name',
    platNumber: 'Enter scooter plat number',
    scooterStatus: 'Choose scooter status',
    searchShowroom: 'Search and select showroom',
    scooterType: 'Choose scooter type',
    role: 'Pilih role',
    pic: 'Enter PIC name',
    showroomName: 'Enter showroom name',
    type: 'Select Type',
    subscriptionName: 'Enter Subscription Name',
    description: 'Enter Description',
    duration: 'Duration',
    swap: 'Swap',
    price: 'Enter Price',
    setAvailability: 'Set Availability',
  },
  statusState: {
    active: 'Active',
    inactive: 'Inactive',
    verified: 'Verified',
    nonVerified: 'Not Yet Verified'
  },
  users: {
    header: {
      title: 'User Profile',
      textBtn: 'Add New User'
    },
    filterOptions: {
      isActive: [
        'All User',
        'User Active',
        'User Non-Active'
      ]
    }
  },
  scooters: {
    missing: 'Missing',
    header: {
      title: 'Inventory',
      textBtn: 'Add New Scooter'
    },
    scooterStatus: [
      'Finished Production',
      'Missing',
      'Commission',
      'Service Required',
      'Showroom',
    ],
    scooterLocation: [
      'Distribution Center',
      'Showroom',
      'Factory'
    ]
  },
  userDetail: {
    header: { title: 'User Detail' },
    address: {
      title: 'Address',
      open: 'Open in Map'
    },
    showroom: {
      title: 'Showroom',
      empty: 'No Showroom Assigned to this User',
      assign: 'Assign Showroom'
    },
    linkedUsers: {
      title: 'Linked Users',
      empty: 'No Linked Users'
    },
    legalInfo: {
      title: 'Legal Information',
      ktp: 'Foto KTP',
      selfie: 'Foto Selfie',
      stnk: 'Foto STNK'
    },
    scooter: {
      title: 'SAVART',
      pairing: 'Pair New Scooter',
      emptyScooter: {
        title: 'User doesn’t have any Scooter',
        instruction: 'Give scooter access for this user by simply the button below.'
      },
      users: 'Users'
    },
    subcontent: {
      address: 'Address',
      showroom: 'Showroom',
      linkedUsers: 'Linked Users',
      legalInfo: 'Legal Information',
      scooter: 'SAVART',
      stationsVisited: 'Stations Visited'
    }
  },
  management: {
    header: {
      title: 'Role Management',
      textBtn: 'Add User'
    },
    editProfile: 'Edit Profile'
  },
  subscriptions: {
    header: {
      title: 'Subscriptions',
      textBtn: 'Create Subscription'
    },
    btnForm: { save: 'Save' },
    duration: {
      days: 'days',
      timesSwap: 'times swap'
    },
    type: {
      timeBase: 'Time Base',
      swapBase: 'Swap Base',
      allType: 'All Type'
    },
    status: {
      allStatus: 'All Status',
      active: 'Active',
      inactive: 'Inactive',
    }
  },
  ticketing: {
    header: { title: 'Ticketing Issues' },
    assignCS: 'Assign CS',
    takeIssue: 'Take Issue',
    solve: 'Solve',
    reassignCS: 'Reassign CS'
  }
};