const sizes = {
	xxxs: 320,
	xxs: 375,
	xs: 600,
	st: 768,
	sm: 860,
	sx: 992,
	tablet: 1024,
	md2: 1190,
	md: 1280,
	smallRes: 1440,
	lg: 1920
};

export default {
	sizes: sizes,
	sizesMax: {
		xxxs: `screen and (max-width: ${sizes.xxxs}px)`,
		xxs: `screen and (max-width: ${sizes.xxs}px)`,
		xs: `screen and (max-width: ${sizes.xs}px)`,
		st: `screen and (max-width: ${sizes.st}px)`,
		sm: `screen and (max-width: ${sizes.sm}px)`,
		sx: `screen and (max-width: ${sizes.sx}px)`,
		tablet: `screen and (max-width: ${sizes.tablet}px)`,
		md2: `screen and (max-width: ${sizes.md2}px)`,
		md: `screen and (max-width: ${sizes.md}px)`,
		smallRes: `screen and (max-width : ${sizes.smallRes}px)`,
		lg: `screen and (max-width: ${sizes.lg}px)`,
	},
	sizesMin: {
		xxs: `and (min-width: ${sizes.xxs}px)`,
		xs: `and (min-width: ${sizes.xs}px)`,
		st: `and (min-width: ${sizes.st}px)`,
		sm: `and (min-width: ${sizes.sm}px)`,
		sx: `and (min-width: ${sizes.sx}px)`,
		tablet: `and (min-width: ${sizes.tablet}px)`,
		md2: `and (min-width: ${sizes.md2}px)`,
		md: `and (min-width: ${sizes.md}px)`,
		smallRes: `and (min-width: ${sizes.smallRes}px)`,
		lg: `and (min-width: ${sizes.lg}px)`,
	},
	width: window.innerWidth,
	height: window.innerHeight,
	maxWidth: '2048px',
	isMobile: sizes.xs,
	showDrawer: sizes.sx
};
