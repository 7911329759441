import { MapsLocation } from 'interfaces/common';

const defaultCenter: MapsLocation = {
  lat: -6.175403066579251,
  lng: 106.82714738255616
};

const controlOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: true,
  zoomControlOptions: { position: 4.0 }
};

export default {
  defaultCenter,
  controlOptions
};
