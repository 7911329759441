import React from 'react';

import { Colors } from 'consts';

import Image, { ImageProps } from '../Image';
import Text from '../Text';

import { EmptyStateStyle } from './style';

type EmptyStateProps = {
  background?: {
    backgroundColor?: string;
    width?: string | number;
    height?: string | number;
  };
  image?: ImageProps;
  text?: string;
};

const EmptyState: React.FC<EmptyStateProps> = ({
  background,
  image,
  text
}) => {
  return (
    <EmptyStateStyle { ...background }>
      { image && <Image { ...image } /> }
      { text && (
        <Text
          size='xs'
          color={ Colors.grey.isGrey }
          mt={ 10 }
          text={ text }
        />
      ) }
    </EmptyStateStyle>
  );
};

EmptyState.defaultProps = {
  background: {
    backgroundColor: Colors.grey.isBgLightGrey,
    width: '100%',
    height: '100%'
  }
};

export default EmptyState;
