import React, { useEffect } from 'react';

import {
  Box,
  Icon,
  Text
} from 'components';
import { Colors } from 'consts';
import { hooks } from 'helpers';

import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

type BoxRetiredBatteryProps = {
  widthBox: number;
};

const BoxRetiredBattery: React.FC<BoxRetiredBatteryProps> = ({ widthBox }) => {
      
  const dispatch = hooks.useAppDispatch();
  
  const getBatteryRetired = dispatch(actions.getBatteryRetired);
  const batteryRetired = hooks.useAppSelector(selectors.stats.batteryRetired);
  
  useEffect(() => {
    getBatteryRetired({ status: 'retired' });
  }, []);
  
  return (
    <Box
      mt={ 20 }
      padding={ 20 }
      width={ widthBox }
    >
      <div className='flex row align-center'>
        <Icon
          iconName='brokenBattery'
          size={ 20 }
          fill={ Colors.red.isRed }
          container='circle'
          containerHoverColor={ Colors.red.isRedOpacity('0.1') }
          containerColor={ Colors.red.isRedOpacity('0.1') }
          containerSize={ 51 }
          cursor='default'
        />
        <div className='ml6'>
          <Text color={ Colors.grey.isGrey } mb={ 10 }>Total Retired Battery</Text>
          <Text size='l' weight={ 700 }> { batteryRetired?.length } Units</Text>
        </div>
      </div>
    </Box>
  );
};

export default BoxRetiredBattery;
