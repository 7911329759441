import { language } from 'language';

const { filterOptions } = language.users;

export const changeSelectFilterValue = (option: string) => {
  const isActiveOptions = filterOptions.isActive;

  switch (option) {
    case isActiveOptions[1]: return 'true';
    case isActiveOptions[2]: return 'false';
    case isActiveOptions[0]:
    default:
      return '';
  }
};