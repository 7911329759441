import Styled from 'styled-components';

import { screen } from 'helpers';

export const BatteryPackDetailStyle: any = Styled.div `
  .box-section-container {
    display: flex;
    flex-direction: row;
  }

  @media ${screen.sizesMax.sm} {
    .box-section-container {
      display: flex;
      flex-direction: column;
    }
  }
`;