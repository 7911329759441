import Styled from 'styled-components';

import { Colors } from 'consts';

type MenuAssignCSStyleProps = { isOpen?: boolean; };

export const SearchInputStyle: any = Styled.div`
	display: flex;
  flex-direction: column;

  .search-container {
    margin-right: -17px;
    margin-left: 20px;
  }

  .ic-search {
    width: 14px;
    height: 14px;
  }

  .filter-container {
    padding: 20px 20px 1px 20px;
  }
`;

export const MenuAssignCSStyle: any = Styled.div<MenuAssignCSStyleProps>`
  .ic-arrow {
    ${ props => props.isOpen ? 'transform: rotateX(180deg) !important;' : '' }
		margin-left: 10px;
  }
`;

export const MenuDropdownStyle: any = Styled.div`
	.menu-assign-cs {
		box-shadow: 0px 10px 20px ${Colors.black.isShadowOpacity('0.2')};
		border-radius: 10px;
		padding: 15px;
		width: 250px;
	}

	.wrapper-cs-list {
		display: flex;
		flex-direction: column;
	}
`;