import React from 'react';

import { Colors } from 'consts';
import {
  hooks,
  misc,
  roleHelper
} from 'helpers';
import { ActionModalForm } from 'typings';
import { selectors } from 'store/selectors';
import { language } from 'language';

import Modal from '../index';

import Text from '../../Text';
import Button from '../../Button';
import Popconfirm from '../../Popconfirm';

import { ButtonsStyle } from './style';

type PermissionType = string | string[] | boolean;
type PermissionActionModalForm = {
  edit?: PermissionType;
  delete?: PermissionType;
};

export type ModalFormProps = {
  title?: string;
  customText?: {
    title?: string;
    button?: string;
  };
  visible: boolean;
  actionModal: ActionModalForm;
  onCloseModal: () => void;
  footer?: {
    fieldName: string;
    onClickButtonSubmit: (action: ActionModalForm) => void;
    onClickEdit?: () => void;
    customButtom?: React.ReactNode;
  };
  contentModal: React.ReactNode;
  permissions?: PermissionActionModalForm;
  backButton?: boolean;
  onClickBackButton?: () => void;
};

const ModalForm: React.FC<ModalFormProps> = ({
  title,
  customText,
  visible,
  onCloseModal,
  actionModal,
  footer,
  contentModal,
  permissions,
  backButton,
  onClickBackButton
}) => {
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const renderTitleModalForm = (formTitle: string) => {
    return (
      <Text
        size='xl'
        weight={ 700 }
        lineHeight={ 29 }
        align={ backButton ? 'center' : 'left' }
      >{ formTitle }</Text>
    );
  };

  const renderButtonForm = (formTitle: string) => {
    if (footer) {
      const {
        fieldName,
        customButtom,
        onClickButtonSubmit,
        onClickEdit
      } = footer;
      const keyword = misc.capitalizeFirstLetter(fieldName);
      const loadingAdd = misc.isLazyLoading(lazyLoad, `add${ keyword }`);
      const loadingEdit = misc.isLazyLoading(lazyLoad, `edit${ keyword }`);
      const loadingDelete = misc.isLazyLoading(lazyLoad, `delete${ keyword }`);
      const loadingBtnFetch = actionModal === 'add'
        ? loadingAdd
        : loadingEdit;
      const defaultButtonProps = {
        height: 43,
        size: 'm',
        fontWeight: 700
      };
      const isPermissibleEdit = roleHelper.isPermissible(adminPermissions, permissions?.edit);
      const isPermissibleDelete = roleHelper.isPermissible(adminPermissions, permissions?.delete);

      if (customButtom) return <>{ customButtom }</>;

      if (actionModal === 'add' || actionModal === 'edit') {
        const buttonText = customText && customText?.button
          ? customText?.button
          : formTitle;

        return (
          <Button
            { ...defaultButtonProps }
            mt={ 30 }
            onClick={ () => onClickButtonSubmit(actionModal) }
            isLoading={ loadingBtnFetch }
            text={ buttonText }
          />
        );
      }

      if (actionModal === 'detail' && isPermissibleEdit) {
        return (
          <Button
            { ...defaultButtonProps }
            mt={ 30 }
            borderColor={ Colors.grey.isLightGrey }
            styleType='inverse'
            onClick={ onClickEdit }
            text={ language.editInfo }
          />
        );
      }

      if (actionModal === 'delete-edit') {
        return (
          <ButtonsStyle isAllPermissible={ isPermissibleDelete && isPermissibleEdit }>
            { isPermissibleDelete && (
              <Popconfirm onConfirm={ () => onClickButtonSubmit('delete') }>
                <Button
                  { ...defaultButtonProps }
                  isLoading={ loadingDelete }
                  bgColor='grey'
                  text={ language.delete }
                />
              </Popconfirm>
            ) }

            { isPermissibleEdit && (
              <Button
                { ...defaultButtonProps }
                onClick={ () => onClickButtonSubmit('edit') }
                isLoading={ loadingBtnFetch }
                text={ language.save }
              />
            ) }
          </ButtonsStyle>
        );
      }
    }

    return <></>;
  };

  const renderModalForm = () => {
    const formTitle = customText && customText?.title
      ? customText?.title
      : misc.setModalFormTitle(actionModal, (title || ''));

    return (
      <Modal
        visible={ visible }
        onCloseModal={ onCloseModal }
        modalType='long-scroll'
        padding={ 30 }
        bodyStyle={ {
          marginTop: 50,
          marginBottom: 30
        } }
        width={ 500 }
        title={ title
          ? () => renderTitleModalForm(formTitle)
          : undefined
        }
        footer={ footer
          ? () => renderButtonForm(formTitle)
          : undefined }
        backButton={ backButton }
        onClickBackButton={ onClickBackButton }
      >
        { contentModal }
      </Modal>
    );
  };

  return renderModalForm();
};

export default ModalForm;
