import React, { useEffect } from 'react';
import {
	Redirect,
	Route,
	Switch
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { onValue, ref } from 'firebase/database';

import {
	hooks,
	navigation,
	roleHelper
} from 'helpers';
import { history } from 'store';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';
import {
	BatteryPackDetail,
	BatteryPacks,
	BatteryStationDetail,
	BatteryStations,
	LiveTracking,
	RoleManagement,
	Scooters,
	ScooterDetail,
	Showroom,
	Stats,
	Users,
	UserDetail,
	NotFound,
	Subscriptions,
	CustomerServices
} from 'screens';
import { Permission } from 'interfaces/role';
import { db } from 'utils/firebase';

const privateRoutes = [
	{
		path: '/dashboard/stats',
		component: Stats,
		permissions: [Permission.stats_all]
	},
	{
		path: '/dashboard/inventory',
		component: Scooters,
		permissions: [Permission.inventory_list]
	},
	{
		path: '/dashboard/inventory/:scooterId',
		component: ScooterDetail,
		permissions: [Permission.inventory_detail]
	},
	{
		path: '/dashboard/inventory/:scooterId/live-tracking',
		component: LiveTracking,
		permissions: [Permission.inventory_detail, Permission.user_profile]
	},
	{
		path: '/dashboard/users',
		component: Users,
		permissions: [Permission.users_list]
	},
	{
		path: '/dashboard/users/:userId',
		component: UserDetail,
		permissions: [Permission.user_profile]
	},
	{
		path: '/dashboard/showroom',
		component: Showroom,
		permissions: [Permission.showroom_list]
	},
	{
		path: '/dashboard/battery-packs',
		component: BatteryPacks,
		permissions: [Permission.battery_list]
	},
	{
		path: '/dashboard/battery-packs/:batteryId',
		component: BatteryPackDetail,
		permissions: [Permission.battery_detail]
	},
	{
		path: '/dashboard/battery-stations',
		component: BatteryStations,
		permissions: [Permission.battery_station_list]
	},
	{
		path: '/dashboard/battery-stations/:stationId',
		component: BatteryStationDetail,
		permissions: [Permission.battery_station_detail]
	},
	{
		path: '/dashboard/user-management',
		component: RoleManagement,
		permissions: [Permission.admin_list]
	},
	{
		path: '/dashboard/subscriptions',
		component: Subscriptions,
		permissions: [Permission.subscription_list]
	},
	{
		path: '/dashboard/customer-services',
		component: CustomerServices,
		permissions: [Permission.call_me_list, Permission.case_list]
	}
];

const AppRouter = () => {
	const dispatch = hooks.useAppDispatch();
	const getAllNotification = dispatch(actions.getAllNotification);
	const addNotification = dispatch(actions.addNotification);

	const adminRole = hooks.useAppSelector(selectors.auth.adminRole);
	const adminId = hooks.useAppSelector(selectors.auth.adminId);
	const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);

	const redirectUrl = navigation.redirectUrl(adminRole);
	const filteredPrivateRoutes = privateRoutes.filter(route => roleHelper.isPermissible(adminPermissions || [], route.permissions));

	useEffect(() => {
		getAllNotification();
	}, []);

	useEffect(() => {
		const query = ref(db, `inboxes/admin_${ adminId }`);
		return onValue(
			query, snapshot => {
				const data = snapshot.val();

				if (snapshot.exists()) {
					addNotification(data);
				}
			}, error => {
				// eslint-disable-next-line no-undef, no-console
				console.log('[Error subscribe realtime database]', JSON.stringify(error));
			}
		);
	}, []);

	return (
		<ConnectedRouter history={ history }>
			<Switch>
				{
					filteredPrivateRoutes.map(route => (
						<Route
							key={ route.path }
							path={ route.path }
							component={ route.component }
							exact
						/>
					))
				}
				<Route
					exact
					path='/'
					render={ () => <Redirect to={ redirectUrl } /> }
				/>
				<Route path='/404' component={ NotFound } />
				<Redirect to='/404' />
			</Switch>
		</ConnectedRouter>
	);
};

export default AppRouter;
