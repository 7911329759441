import { Dispatch } from 'redux';

import { misc, toastify } from 'helpers';
import {
  ApiData,
  ReducerList,
  ApiParams,
  Pagination
} from 'interfaces/common';
import {
  FormScooter,
  Location,
  Scooter,
  ScooterApiParams,
  BatteryUsage,
  ScooterInfo,
  ScooterLocation,
  VariantScooter,
  DataTripHistory,
  TripHistoryParams,
  DataStationsVisited,
} from 'interfaces/scooter';
import { AppActions, RootState } from 'store';
import * as scooterServices from 'services/scooter';

import {
  GET_SCOOTERS,
  GET_SCOOTER_DETAIL,
  GET_VARIANT_SCOOTERS,
  GET_SCOOTER_LOCATION,
  GET_SCOOTER_BATTERY_USAGES,
  GET_TRIP_HISTORY_SCOOTER,
  GET_VISITED_STATION
} from './actionTypes';
import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';
import { setPaginationData } from '../Pagination/action';

export const getAllScooter = (dispatch: Dispatch<AppActions>, getState: () => RootState) => (
  params: ScooterApiParams,
  showroomId?: string,
  callback?: (scooter: Scooter[]) => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('allScooter'));

      const adminShowroomId = await getState().auth.user.showroom_id;
      let payloadShowroomId = '';

      if (showroomId) {
        payloadShowroomId = showroomId;
      } else if (!showroomId && adminShowroomId > 0) {
        payloadShowroomId = `${ adminShowroomId }`;
      }

      const apiParams: ScooterApiParams = await {
        ...params,
        showroom_id: payloadShowroomId
      };

      const {
        code,
        data,
        pagination
      }: ApiData<Scooter[]> = await scooterServices.getAllScooter(apiParams);

      if (code === 200) {
        await dispatch({
          type: GET_SCOOTERS,
          data: {
            data,
            pagination
          }
        });

        await dispatch(setPaginationData('scooters', pagination));

        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();
      reject(error);
    }

    dispatch(lazyLoadEnd('allScooter'));
  });
};

export const addScooter = (dispatch: Dispatch<AppActions>) => (formAddScooter: FormScooter, callback?: (message: string) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('addScooter'));

      const { code, data }: ApiData<any> = await scooterServices.addScooter(formAddScooter);

      if (code === 200) {
        callback ? callback(data) : null;
        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('addScooter'));
  });
};

export const editScooter = (dispatch: Dispatch<AppActions>) => (
  vin: string,
  formEditScooter: FormScooter,
  cbSuccess?: (message: string) => void,
  postCb?: () => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('editScooter'));

      const { code, data }: ApiData<any> = await scooterServices.editScooter(vin, formEditScooter);

      if (code === 200) {
        if (cbSuccess) cbSuccess(data);

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    } finally {
      dispatch(lazyLoadEnd('editScooter'));

      if (postCb) postCb();
    }
  });
};

export const getScooterDetail = (dispatch: Dispatch<AppActions>) => (vin: string, callback?: (scooter: ScooterInfo) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('scooterDetail'));

      const { code, data }: ApiData<ScooterInfo> = await scooterServices.getScooterDetail(vin);

      if (code === 200) {
        dispatch({
          type: GET_SCOOTER_DETAIL,
          data
        });

        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('scooterDetail'));
  });
};

export const getAllScooterVariant = (dispatch: Dispatch<AppActions>) => (callback?: () => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('allVariantScooter'));

      const {
        code,
        data,
        pagination
      }: ApiData<VariantScooter[]> = await scooterServices.getAllScooterVariant();

      if (code === 200) {
        dispatch({
          type: GET_VARIANT_SCOOTERS,
          data: {
            data,
            pagination
          }
        });

        callback ? callback() : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('allVariantScooter'));
  });
};

// const setTimestampFromNow = (substractMin: number) => {
//   return moment(Date.now())
//   .subtract(substractMin, 'minutes')
//   .valueOf();
// };

export const getScooterLocation = (dispatch: Dispatch<AppActions>) => (
  vin: string,
  limit: string,
  callback?: () => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('scooterLocation'));

      const params: ScooterApiParams = { limit };

      const { code, data }: ApiData<Location[]> = await scooterServices.getScooterLocation(vin, params);

      // const code = 200;
      // const sameCheckpoint = misc.createDummyData(30, {
      //   latitude: -7.381240,
      //   longitude: 112.631463,
      //   timestamp: setTimestampFromNow(120)
      // });
      // const sameCheckpoint2 = misc.createDummyData(30, {
      //   latitude: -7.376516,
      //   longitude: 112.633223,
      //   timestamp: setTimestampFromNow(75)
      // });
      // const sameCheckpoint3 = misc.createDummyData(30, {
      //   latitude: -7.374516,
      //   longitude: 112.638630,
      //   timestamp: setTimestampFromNow(30)
      // });

      // const data = [
      //   ...sameCheckpoint,
      //   {
      //     latitude: -7.378389,
      //     longitude: 112.630562,
      //     timestamp: setTimestampFromNow(80)
      //   },
      //   ...sameCheckpoint2,
      //   ...sameCheckpoint3,
      //   {
      //     latitude: -7.372601,
      //     longitude: 112.643522,
      //     timestamp: setTimestampFromNow(10)
      //   }
      // ];

      if (code === 200) {
        const newKeys = {
          latitude: 'lat',
          longitude: 'lng'
        };
        const dataMapsLoc: ScooterLocation[] = data.map(mark => misc.renameObjKeys(mark, newKeys));

        dispatch({
          type: GET_SCOOTER_LOCATION,
          data: dataMapsLoc
        });

        callback ? callback() : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('scooterLocation'));
  });
};

export const getScooterBatteryUsages = (dispatch: Dispatch<AppActions>) => (
  vin: string,
  params: ScooterApiParams,
  callback?: () => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('BatteryUsage'));

      const { code, data }: ApiData<BatteryUsage> = await scooterServices.getScooterBatteryUsages(vin, params);

      if (code === 200) {
        dispatch({
          type: GET_SCOOTER_BATTERY_USAGES,
          data
        });

        callback ? callback() : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('BatteryUsage'));
  });
};

export const getTripHistory = (dispatch: Dispatch<AppActions>) => (
  params: TripHistoryParams,
  callback?: (tripHistoryList: ReducerList<DataTripHistory[]>) => void,
  loadingName?: string
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart(loadingName ?? 'TripHistory'));

      const {
        code,
        data,
        pagination
      }: ApiData<DataTripHistory[]> = await scooterServices.getTripHistory(params);

      if (code === 200) {
        dispatch({
          type: GET_TRIP_HISTORY_SCOOTER,
          data: {
            data,
            pagination
          }
        });

        if (!loadingName) {
          dispatch(setPaginationData('scooterTripHistory', pagination));
        }

        if (callback) {
          callback({
            data,
            pagination
          });
        }

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd(loadingName ?? 'TripHistory'));
  });
};

export const getStationVisited = (dispatch: Dispatch<AppActions>) => (
  vin: string,
  params: ApiParams,
  callback?: () => void
) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('StationVisited'));

      if (!vin) {
        const initPagination: Pagination = {
          count: 0,
          limit: 0,
          offset: 0,
          order: '',
          page: 0,
          page_total: 0,
          search: '',
          sort: 'asc'
        };

        dispatch({
          type: GET_VISITED_STATION,
          data: {
            data: [],
            pagination: initPagination
          }
        });

        dispatch(setPaginationData('StationVisited', initPagination));

        if (callback) callback();

        resolve();
      } else {
        const {
          code,
          data,
          pagination
        }: ApiData<DataStationsVisited[]> = await scooterServices.getStationVisited(vin, params);

        if (code === 200) {
          dispatch({
            type: GET_VISITED_STATION,
            data: {
              data,
              pagination
            }
          });

          dispatch(setPaginationData('StationVisited', pagination));

          if (callback) callback();

          resolve();
        }
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('StationVisited'));
  });
};