import React, { useCallback, useState } from 'react';
import type { RcFile } from 'antd/es/upload';

import {
	Text,
	Button,
	Input,
	Modal as CustomModal,
	Upload,
	Icon
} from 'components';
import {
	hooks,
	screen,
	misc,
	toastify,
	validation
} from 'helpers';
import { Colors } from 'consts';
import { UploadFileInfo, UploadImageResponse } from 'interfaces/common';
import {
	DataTicketing,
	ImageCase,
	FormSolveCase
} from 'interfaces/ticketing';
import { Base64Type } from 'typings';
import { language } from 'language';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { ModalSolveStyles } from './style';

type ModalSolveCaseProps = {
	caseDetail: DataTicketing;
	visible: boolean;
	closeModal: () => void;
	onSuccess?: () => void;
};

type ItemCaseDetail = {
	title: string;
	value: string;
};

const RenderModalSolveCase: React.FC<ModalSolveCaseProps> = ({
	caseDetail,
	visible,
	closeModal,
	onSuccess
}) => {
	const dispatch = hooks.useAppDispatch();

	const uploadMultipleImages = dispatch(actions.uploadMultipleImages);
	const solveCase = dispatch(actions.solveCase);

	const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

	const [solvingStepsText, setSolvingStepsText] = useState<string>('');
	const [fileListBase64, setFileListBase64] = useState<Base64Type[]>([]);
	const [fileList, setFileList] = useState<File[]>([]);

	const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();
	const isMobile = windowDimensions.width <= screen.isMobile;

	const handleDeleteListFiles = () => {
		setFileList([]);
		setFileListBase64([]);
	};

	const onCloseModal = () => {
		handleDeleteListFiles();
		setSolvingStepsText('');

		closeModal();
	};

	const onClickSolveCase = () => {
		uploadMultipleImages(fileList, (fileListUploaded: (UploadImageResponse | File)[]) => {
			// remove images from fileList that failed to upload (either due to size issues, etc)
			fileListUploaded.forEach((fileResponse: (UploadImageResponse | File)) => {
				if (fileResponse instanceof File) {
					onRemoveUpload(fileResponse);
				}
			});

			// get a successfully uploaded image
			const imagesFiltered: UploadImageResponse[] = fileListUploaded.filter((fileResponse: (UploadImageResponse | File)) =>
				!(fileResponse instanceof File) && fileResponse?.file_path && fileResponse?.file_url) as UploadImageResponse[];
			// convert for payload solve case
			const imagesToSolve: ImageCase[] = imagesFiltered?.map((image: UploadImageResponse) => ({ image_url: image.file_url }));

			const formSolveCase: FormSolveCase = {
				status: 'solved',
				notes: solvingStepsText,
				images: imagesToSolve
			};

			if (imagesToSolve.length) {
				solveCase(
					caseDetail?.id?.toString() || '',
					formSolveCase,
					() => {
						onCloseModal();

						toastify.success(`Successfully solving issue ${ caseDetail?.case_code }. Check “Close” tab`);

						if (onSuccess) onSuccess();
					}
				);
			}
		});
	};

	const beforeUpload = (file: File) => {
		const isValidUploadImage = validation.uploadImage(file);

		if (isValidUploadImage) {
			setFileList(prevFiles => ([...prevFiles, file]));
		}

		return false;
	};

	const onRemoveUpload = (file: File) => {
		const indexToRemove = fileList.indexOf(file);
		const newFileList = fileList.filter((_, index: number) => index !== indexToRemove);
		const newFileListBase64 = fileListBase64.filter((_, index: number) => index !== indexToRemove);

		setFileList(newFileList);
		setFileListBase64(newFileListBase64);
	};

	const onChangeUpload = async(info: UploadFileInfo) => {
		if (info && info.fileList) {
			const files: RcFile[] = await info?.fileList;
			const newArrayFiles: File[] = await files?.map((file: any) => file?.originFileObj ? (file?.originFileObj) : file);
			const newArrayFilesFiltered: File[] = await newArrayFiles?.filter((file: File) => validation.uploadImage(file, false));
			const dataBase64: Base64Type[] = await Promise.all(newArrayFilesFiltered?.map((item: any) => misc.getBase64(item)));

			await setFileListBase64(dataBase64);

			setFileList(newArrayFilesFiltered);
		}
	};

	const onChangeFormText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSolvingStepsText(e.target.value);
	}, []);

	const renderListCaseDetail = () => {
		const dataCase: ItemCaseDetail[] = [
			{
				title: 'Ticket ID',
				value: caseDetail?.case_code
			},
			{
				title: 'Problem',
				value: caseDetail?.category_name
			},
			{
				title: 'Customer',
				value: caseDetail?.cust_name || '-'
			}
		];

		return dataCase.map((item: ItemCaseDetail, index: number) => (
			<div key={ index } className='item'>
				<Text
					text={ item.title }
					color={ Colors.grey.isGrey }
					size='xs'
				/>
				<Text
					text={ item.value || '-' }
					color={ Colors.black.isBlack }
					size='xs'
				/>
			</div>
		));
	};

	const renderUploadImage = () => {
		const isFileUploaded = fileListBase64?.length;
		const widthUpload = isFileUploaded
			? 100
			: isMobile
				? windowDimensions.width - 105
				: 440;

		return (
			<div className='upload-image'>
				<Text
					text='Upload Prove'
					size='m'
					weight={ 700 }
				/>

				<Upload
					uploadPhotoUrl={ fileListBase64 }
					fileList={ fileList }
					onChange={ onChangeUpload }
					borderRadius={ 10 }
					width={ widthUpload }
					height={ isFileUploaded ? 100 : 220 }
					bgColor={ Colors.grey.isBgLightGrey }
					marginVertical={ 0 }
					beforeUpload={ beforeUpload }
					onRemove={ onRemoveUpload }
					wrapperClassName='wrapper-upload-image'
					renderPlaceholderUpload={ () => (
						<>
							<Icon
								size={ 25 }
								iconName='uploadPhoto'
								fill={ Colors.grey.isGrey }
							/>
							<Text
								text='+ Upload Photo'
								size='xs'
								color={ Colors.grey.isGrey }
								weight={ 700 }
								mt={ 10 }
							/>
						</>
					) }
					multiple
				/>
			</div>
		);
	};

	const renderButtonSolveCase = () => {
		const isLoading = misc.isLazyLoading(lazyLoad, 'uploadMultipleImages') || misc.isLazyLoading(lazyLoad, 'solveCase');

		return (
			<Button
				size='m'
				disabled={ !fileList?.length }
				text={ language.ticketing.solve }
				mt={ 30 }
				isLoading={ isLoading }
				onClick={ onClickSolveCase }
			/>
		);
	};

	return (
		<CustomModal
			visible={ visible }
			onCloseModal={ onCloseModal }
			modalType='long-scroll'
			padding={ 30 }
			bodyStyle={ {
				marginTop: 50,
				marginBottom: 30
			} }
			width={ 500 }
			title={ () => (
				<Text
					text='Solve Issues'
					size='xl'
					weight={ 700 }
				/>
			) }
		>
			<ModalSolveStyles>
				<div className='list'>
					{ renderListCaseDetail() }
				</div>

				{ renderUploadImage() }

				<div className='mt4'>
					<Input
						label='Case Solving Steps (Optional)'
						key='solvingStepsText'
						type='textArea'
						labelColor={ Colors.grey.isGrey }
						backgroundColor={ Colors.white.default }
						value={ solvingStepsText }
						onChange={ onChangeFormText }
					/>
				</div>

				{ renderButtonSolveCase() }
			</ModalSolveStyles>
		</CustomModal>
	);
};

export default RenderModalSolveCase;
