import React from 'react';

import { Colors } from 'consts';
import { hooks, screen } from 'helpers';
import { FilterMenuSize } from 'typings';

import { FilterMenuStyle } from './style';

import Input from '../Input';
import Select from '../Select';
import SelectSearchBar from '../Select/SelectSearchBar';

export type FilterMenuProps = {
  onChangeSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  search?: string;
  placeholderSearch?: string;
  onVisibleChange?: (visible: boolean, keyFilter: string) => void;
  onClickMenuItem?: (params: any, keyFilter: string) => void;
  visibleFilter?: any;
  activeFilter?: any;
  dropdownsFilter?: any;
  dropdownsFilterFetch?: any;
  dataTableSize?: FilterMenuSize;
};

const FilterMenu: React.FC<FilterMenuProps> = ({
  onChangeSearch,
  search,
  placeholderSearch,
  onVisibleChange,
  onClickMenuItem,
  visibleFilter,
  activeFilter,
  dropdownsFilter,
  dropdownsFilterFetch,
  dataTableSize
}) => {
  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const totalFilter = activeFilter
    ? Object.keys(activeFilter).length
    : 0;

  const renderSearch = (width: number | string) => {
    return (
      <Input
        placeholder={ placeholderSearch ? placeholderSearch : 'Search' }
        value={ search }
        name='search'
        onChange={ onChangeSearch }
        mb={ 20 }
        borderRadius={ 8 }
        width={ width }
        weight={ 400 }
        iconName='search'
        iconClassName='ic-search'
      />
    );
  };

  const renderDropdownsFilter = () => {
    return dropdownsFilter.map((dropdown, index) => (
      <div key={ index } className={ `${ index === dropdownsFilter.length - 1 && !dropdownsFilterFetch ? 'mr0' : 'mr2' }` }>
        <Select
          visible={ visibleFilter[dropdown.key] }
          onVisibleChange={ visible => onVisibleChange && onVisibleChange(visible, dropdown.key) }
          selectTitle={ activeFilter[dropdown.key] }
          onClickSelectItem={ params => {
            onClickMenuItem && onClickMenuItem(params, dropdown.key);
            onVisibleChange && onVisibleChange(false, dropdown.key);
          } }
          selectOptions={ dropdown.selectOptions }
          totalItems={ totalFilter }
        />
      </div>
    ));
  };

  const renderDropdownsFilterWithFetch = () => {
    if (dropdownsFilterFetch) {
      return dropdownsFilterFetch.map((dropdown, index) => (
        <div key={ index } className={ `${ index === dropdownsFilter.length - 1 ? 'mr0' : 'mr2' }` }>
          <SelectSearchBar
            value={ dropdown.value }
            defaultOptions={ dropdown.defaultOptions ? dropdown.defaultOptions : undefined }
            fetchOptions={ dropdown.fetchOptions }
            onChange={ dropdown.onChangeSearch }
            backgroundColor={ Colors.grey.isBgLightGrey }
            open={ visibleFilter[dropdown.key] }
            onDropdownVisibleChange={ (visible: boolean) => onVisibleChange && onVisibleChange(visible, dropdown.key) }
            totalItems={ totalFilter }
          />
        </div>
      ));
    }

    return null;
  };

  const renderFilterDataWithSearch = () => {
    if (windowDimensions.width >= screen.sizes.sm && dataTableSize === 'large') {
      const isWithoutFilter = (!dropdownsFilter || !dropdownsFilter?.length) && !dropdownsFilterFetch;

      return (
        <div className={ `${ isWithoutFilter ? '' : 'justify-between' } filter-container` }>
          { isWithoutFilter
            ? null
            : (
              <div className='flex row'>
                { renderDropdownsFilter() }
                { renderDropdownsFilterWithFetch() }
              </div>
            ) }

          <div>
            { renderSearch(310) }
          </div>
        </div>
      );
    } else if (windowDimensions.width < screen.sizes.sm && dataTableSize === 'small') {
      return (
        <div className='mt4'>
          <div>
            { renderSearch('100%') }
          </div>
          <div className='flex row'>
            { renderDropdownsFilter() }
            { renderDropdownsFilterWithFetch() }
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <FilterMenuStyle activeFilter={ activeFilter } visibleFilter={ visibleFilter }>
      { renderFilterDataWithSearch() }
    </FilterMenuStyle>
  );
};

export default FilterMenu;
