import { ApiParams } from './common';

export interface ImageCase {
	image_url: string;
}

export interface VehicleDetail {
	cust_name: string;
	cust_image: string;
	cust_email: string;
	cust_phone: string;
	image: ImageCase[];
	vin: string | number;
	type: string;
	plat_no: string;
	battery_status: string;
}

export interface DataTicketing {
	key?: string;
	id: number;
	case_code: string;
	cust_id: number;
	cust_name: string;
	cust_image: string;
	cust_email: string;
	cust_phone: string;
	scooter_type: string;
	plat_number: string;
	scooter_status: string;
	scooter_color?: string;
	scooter_images?: string;
	vin_id: number;
	category_id: number;
	category_name: string;
	admin_id: number;
	admin_phone: string;
	admin_email: string;
	admin_name: string;
	open_duration: string;
	status: string;
	description: string;
	is_notified: boolean;
	created_date: string;
	updated_date: string;
}

export interface TicketingApiParams extends ApiParams {
	status?: string;
	action?: string;
	with_details?: boolean;
}

export interface FormAssignCase {
	cs_id: number;
}

export interface FormReassignCS {
	cs_id: number;
	notes: string;
}

export interface FormSolveCase {
	status: string;
	notes: string;
	images: ImageCase[];
}

export interface TicketingDetail {
	id: number;
	case_code: string;
	cust_name: string;
	cs_name: string;
	vin: number | string;
	created_date: string;
	note: string;
	description: string;
	is_notified: boolean;
	vehicle_detail: VehicleDetail;
	images: ImageCase[];
}

export interface PastSimiliarCase {
	id: number;
	case_code: string;
	category_name: string;
	status: string;
	admin_name: string;
	admin_image: string;
	notes: string;
	images: any;
	created_date: string;
}

export enum CaseStatus {
	open = 'open',
	pending = 'pending',
	close = 'closed'
}