import { Dispatch } from 'redux';

import { toastify } from 'helpers';
import { ApiData } from 'interfaces/common';
import {
	BatteryStation,
	FormBatteryStation,
	BatteryStationApiParams,
	HoleStationApiParams,
	BusyTimeApiParams,
	BusyTime,
	StationVisitor,
	StationDetail,
	HoleStation
} from 'interfaces/station';
import { AppActions } from 'store';
import * as stationServices from 'services/station';

import {
	GET_BATTERY_STATIONS,
	GET_STATION_DETAIL,
	GET_HOLE_STATION,
	GET_BUSY_TIME,
	GET_STATION_VISITOR
}
	from './actionTypes';

import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';
import { setPaginationData } from '../Pagination/action';

export const getAllBatteryStations = (dispatch: Dispatch<AppActions>) => (params: BatteryStationApiParams, callback?: (station: BatteryStation[]) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('allBatteryStations'));

			const {
				code,
				data,
				pagination
			}: ApiData<BatteryStation[]> = await stationServices.getAllBatteryStations(params);

			if (code === 200) {
				await dispatch({
					type: GET_BATTERY_STATIONS,
					data: {
						data,
						pagination
					}
				});

				await dispatch(setPaginationData('station', pagination));

				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('allBatteryStations'));
		}
	});
};

export const addBatteryStations = (dispatch: Dispatch<AppActions>) => (formAddBatteryStations: FormBatteryStation, callback?: (message: string) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			dispatch(lazyLoadStart('addBatteryStations'));

			const { code, stat_msg }: ApiData<any> = await stationServices.addBatteryStations(formAddBatteryStations);

			if (code === 200) {
				callback ? callback(stat_msg) : null;

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('addBatteryStations'));
		}
	});
};

export const getStationDetail = (dispatch: Dispatch<AppActions>) => (id: string, callback?: (station: StationDetail) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('stationDetail'));

			const { code, data }: ApiData<StationDetail> = await stationServices.getStationDetail(id);

			if (code === 200) {
				dispatch({
					type: GET_STATION_DETAIL,
					data: {
						...data,
						total_battery: data.charging_battery + data.available_battery
					}
				});

				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		}

		dispatch(lazyLoadEnd('stationDetail'));
	});
};

export const getHoleStation = (dispatch: Dispatch<AppActions>) => (
	id: string,
	params: HoleStationApiParams,
	callback?: (holeStation: HoleStation[]) => void
) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('holeStation'));

			const { code, data }: ApiData<HoleStation[]> = await stationServices.getHoleStation(id, params);

			if (code === 200) {
				dispatch({
					type: GET_HOLE_STATION,
					data
				});

				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		}

		dispatch(lazyLoadEnd('holeStation'));
	});
};

export const getBusyTime = (dispatch: Dispatch<AppActions>) => (
	id: string,
	params: BusyTimeApiParams,
	callback?: (listBusyTime: BusyTime[]) => void
) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('busyTime'));

			const { code, data }: ApiData<BusyTime[]> = await stationServices.getBusyTime(id, params);

			if (code === 200) {
				dispatch({
					type: GET_BUSY_TIME,
					data
				});

				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		}

		dispatch(lazyLoadEnd('busyTime'));
	});
};

export const getStationVisitor = (dispatch: Dispatch<AppActions>) => (id: string, callback?: (stationVisitor: StationVisitor[]) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('stationVisitor'));

			const { code, data }: ApiData<StationVisitor[]> = await stationServices.getStationVisitor(id);

			if (code === 200) {
				dispatch({
					type: GET_STATION_VISITOR,
					data
				});

				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		}

		dispatch(lazyLoadEnd('stationVisitor'));
	});
};