import Styled from 'styled-components';

import { screen } from 'helpers';

type SpacerStyleProps = {
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  m?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  p?: number;
};

export const SpacerStyle: any = Styled.div < SpacerStyleProps > `
  margin-top: ${props => props.mt}px;
  margin-bottom: ${props => props.mb}px;
  margin-left: ${props => props.ml}px;
  margin-right: ${props => props.mr}px;
  ${props => props.m ? `margin: ${props.m}px;` : ''}
  
  padding-top: ${props => props.pt}px;
  padding-bottom: ${props => props.pb}px;
  padding-left: ${props => props.pl}px;
  padding-right: ${props => props.pr}px;
  ${props => props.p ? `padding: ${props.p}px;` : ''}

  @media ${screen.sizesMax.xs} {
    ${props => props.m ? `margin: ${props.m - 5}px;` : ''}
    ${props => props.mt ? `margin-top: ${props.mt - 5}px;` : ''}
    ${props => props.mb ? `margin-bottom: ${props.mb - 5}px;` : ''}
    ${props => props.ml ? `margin-left: ${props.ml - 5}px;` : ''}
    ${props => props.mr ? `margin-right: ${props.mr - 5}px;` : ''}

    ${props => props.p ? `padding: ${props.p - 5}px;` : ''}
    ${props => props.pt ? `padding-top: ${props.pt - 5}px;` : ''}
    ${props => props.pb ? `padding-bottom: ${props.pb - 5}px;` : ''}
    ${props => props.pl ? `padding-left: ${props.pl - 5}px;` : ''}
    ${props => props.pr ? `padding-right: ${props.pr - 5}px;` : ''}
  }
`;
