import { Cities } from 'interfaces/location';
import { Pagination } from 'interfaces/common';

export const GET_CITIES = 'GET_CITIES';
export const LOGOUT = 'LOGOUT';

export interface SetAllCities {
  type: typeof GET_CITIES;
  data: {
    data: Cities[],
    pagination: Pagination;
  };
}

export interface Logout {
  type: typeof LOGOUT;
}

export type LocationActionTypes = SetAllCities | Logout;