import Styled, { keyframes } from 'styled-components';

import { Colors } from 'consts';

type LabelStyleProps = {
  disabled?: boolean;
};

type IndicatorStyleProps = {
  checked?: boolean;
  inverse?: boolean;
};

export const Input: any = Styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
  padding: 0 !important;
  margin: 0 !important;
  display: none;
`;

const popIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5) ;
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1) ;
  }
`;

export const Label: any = Styled.label<LabelStyleProps>`
  position: relative;
  display: inline-block;
  cursor: ${ props => (props.disabled ? 'not-allowed' : 'pointer') };
  padding: 0;
  margin: 0;
`;

const bgIndicator = (props: IndicatorStyleProps) => {
  if (props.inverse) {
    return Colors.white.default;
  } else {
    if (props.checked) return Colors.blue.isBlue;

    return Colors.white.default;
  }
};

const circleCenterColor = (props: IndicatorStyleProps) => {
  if (props.inverse && props.checked) return Colors.blue.isBlue;

  return Colors.white.default;
};

export const Indicator: any = Styled.div<IndicatorStyleProps>`
  border: 1px solid ${ Colors.blue.isBlue };
  border-radius: 1em;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  background-color: ${ props => bgIndicator(props) };

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  &::after {
    display: block;
    border: solid ${ props => circleCenterColor(props) };
    border-radius: 1em;
    background-color: ${ props => circleCenterColor(props) };
    width: ${ props => props.inverse ? '12px' : '10px' };
    height: ${ props => props.inverse ? '12px' : '10px' };
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${ popIn };
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  ${ Input }:disabled + & {
    pointer-events: none;
    opacity: 0.6;
    background: #e6e6e6;
  }
`;
