import React from 'react';

import { Images, Colors } from 'consts';

import {
  IconStyle,
  SwalWarningStyle,
  SwalErrorStyle,
  SwalSuccessStyle
} from './style';

export type IconProps = {
  iconName?: string;
  className?: string;
  containerClassName?: string;
  size?: number;
  fill?: string;
  hoverFill?: string;
  hoverOpacity?: number | false;
  container?: 'circle' | 'square' | false;
  containerSize?: number;
  containerColor?: string;
  containerHoverColor?: string;
  containerRadius?: number;
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  type?: string;
  cursor?: 'pointer' | 'default';
};

const Icon: React.FC<IconProps> = ({
  iconName,
  className,
  containerClassName,
  size,
  fill,
  hoverFill,
  hoverOpacity,
  container,
  containerSize,
  containerColor,
  containerHoverColor,
  containerRadius,
  onClick,
  onMouseEnter,
  onMouseLeave,
  type,
  cursor
}) => {
  const Icon = Images.icons[`${iconName}`];

  if (type === 'swal-icon') {
    switch (iconName) {
      case 'warning':
        return (
          <SwalWarningStyle>
            <div className='sa'>
              <div className='sa-warning'>
                <div className='sa-warning-body'/>
                <div className='sa-warning-dot'/>
              </div>
            </div>
          </SwalWarningStyle>
        );
      case 'error':
        return (
          <SwalErrorStyle>
            <div className='sa'>
              <div className='sa-error'>
                <div className='sa-error-x'>
                  <div className='sa-error-left'/>
                  <div className='sa-error-right'/>
                </div>
                <div className='sa-error-placeholder'/>
                <div className='sa-error-fix'/>
              </div>
            </div>
          </SwalErrorStyle>
        );
      case 'success':
      default:
        return (
          <SwalSuccessStyle>
            <div className='sa'>
              <div className='sa-success'>
                <div className='sa-success-tip'/>
                <div className='sa-success-long'/>
                <div className='sa-success-placeholder'/>
                <div className='sa-success-fix'/>
              </div>
            </div>
          </SwalSuccessStyle>
        );
    }
  }

  return (
    <IconStyle
      size={ size }
      fill={ fill }
      hoverFill={ hoverFill }
      hoverOpacity={ hoverOpacity }
      container={ container }
      containerSize={ containerSize }
      containerColor={ containerColor }
      containerHoverColor={ containerHoverColor }
      containerRadius={ containerRadius }
      cursor={ cursor }
    >
      <div
        className={ `icon-container ${containerClassName}` }
        onClick={ onClick }
        onMouseEnter={ onMouseEnter }
        onMouseLeave={ onMouseLeave }
      >
        <Icon className={ `icon-style ${className}` } />
      </div>
    </IconStyle>
  );
};

Icon.defaultProps = {
  size: 20,
  fill: Colors.grey.isGrey,
  hoverOpacity: 0.8,
  container: false,
  containerColor: Colors.grey.isLighterGrey,
  containerHoverColor: Colors.grey.isLighterGrey,
  containerRadius: 4,
  cursor: 'pointer'
};

export default Icon;
