import {
	BatteryStation,
	BusyTime,
	StationVisitor,
	HoleStation,
	StationDetail
} from 'interfaces/station';
import { Pagination } from 'interfaces/common';

export const GET_BATTERY_STATIONS = 'GET_BATTERY_STATIONS';
export const GET_STATION_DETAIL = 'GET_STATION_DETAIL';
export const GET_HOLE_STATION = 'GET_HOLE_STATION';
export const GET_BUSY_TIME = 'GET_BUSY_TIME';
export const GET_STATION_VISITOR = 'GET_STATION_VISITOR';
export const LOGOUT = 'LOGOUT';

export interface SetStation {
	type: typeof GET_STATION_DETAIL;
	data: StationDetail;
}

export interface SetAllBatteryStations {
	type: typeof GET_BATTERY_STATIONS;
	data: {
		data: BatteryStation[];
		pagination: Pagination;
	}
}

export interface SetHoleStation {
	type: typeof GET_HOLE_STATION;
	data: HoleStation[];
}

export interface SetBusyTime {
	type: typeof GET_BUSY_TIME;
	data: BusyTime[];
}

export interface setStationVisitor {
	type: typeof GET_STATION_VISITOR;
	data: StationVisitor[];
}

export interface Logout {
	type: typeof LOGOUT;
}

export type StationActionTypes =
SetAllBatteryStations |
SetStation |
SetHoleStation |
SetBusyTime |
setStationVisitor |
Logout;