import { createSelector } from 'reselect';

import { misc } from 'helpers';

import { RootState } from '../index';
import { ScooterLocation } from 'interfaces/scooter';

const scooterSelector = (state: RootState) => state.scooter;

export const scooters = createSelector(scooterSelector, scooter => scooter.scooters);
export const scooterDetail = createSelector(scooterSelector, scooter => scooter.scooterDetail);
export const usersScooter = createSelector(scooterSelector, scooter => {
  const parent = scooter?.scooterDetail?.parent;
  const childs = scooter?.scooterDetail?.childs;

  if (parent && parent?.user_id !== 0) {
    const updatedParent = {
      ...scooter.scooterDetail.parent,
      status: 'parent'
    };

    if (childs && childs?.length) {
      const updatedChilds = childs
        .map(child => ({
          ...child,
          status: 'child'
        }))
        .filter(child => child.user_id !== parent.user_id);

      return [updatedParent, ...updatedChilds];
    }

    return [updatedParent];
  }

  return null;
});
export const variantScooters = createSelector(scooterSelector, scooter => scooter.variantScooters);
export const scooterBatteryUsages = createSelector(scooterSelector, scooter => scooter.scooterBatteryUsages);
export const batteryUsage = createSelector(scooterSelector, scooter => scooter.batteryUsage);
export const tripHistory = createSelector(scooterSelector, scooter => scooter.tripHistory);
export const stationVisited = createSelector(scooterSelector, scooter => scooter.stationVisited);

export const scooterLocation = createSelector(scooterSelector, scooter => {
  // remove duplikasi data
  return misc.getUniqueArray<ScooterLocation>(scooter.scooterLocation);
});

export const scooterCheckpoint = createSelector(scooterSelector, scooter => {
  // menghitung jumlah masing-masing object yg terduplikasi
  const duplicateValue = misc.countConsecutiveDuplicateValues<ScooterLocation>(scooter.scooterLocation);

  // checkpoint : titik ketika scooter diam >= 10 menit
  // data dikirim per 20 detik, sehingga akan dianggap suatu checkpoint jika data terduplikasi minimal sebanyak 30 (600 sec / 20 sec)
  const checkpoint = Object.keys(duplicateValue)
    .filter(key => duplicateValue[key] >= 30)
    .map(value => JSON.parse(value));

  return [...checkpoint].reverse();
});
