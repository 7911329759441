import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

import type { ButtonBackgroundColor } from './index';

type ButtonStyleProps = {
  disabled?: boolean;
  styleType?: string;
  width?: number;
  height?: number;
  bgColor?: ButtonBackgroundColor;
  borderColor?: string;
  borderRadius?: number;
  padding?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  hover?: boolean;
};

export const getButtonColorFill = (color, disabled = false) => {
  switch (color) {
    case 'red':
      return Colors.red.isRedOpacity(disabled ? '0.2' : '1');
    case 'yellow':
      return Colors.yellow.isYellowOpacity(disabled ? '0.2' : '1');
    case 'green':
      return Colors.green.isGreenOpacity(disabled ? '0.2' : '1');
    case 'black':
      return Colors.black.isBlackOpacity(disabled ? '0.2' : '1');
    case 'pink':
      return Colors.pink.isFuschiaOpacity(disabled ? '0.2' : '1');
    case 'grey':
      return disabled ? Colors.grey.isBgGrey : Colors.grey.isLighterGrey;
    case 'blue':
    default:
      return disabled ? Colors.grey.isGreyBlue : Colors.blue.isBlue;
  }
};

export const getTextColor = (styleType?: string, bgColor?: ButtonBackgroundColor) => {
  let color = Colors.white.default;

  if (styleType === 'inverse' || styleType === 'tertiary') {
    color = getButtonColorFill(bgColor, false);
  } else if (styleType === 'disabled' || styleType === 'inverseDisabled') {
    color = Colors.grey.isInactiveGrey;
  } else if (bgColor === 'grey') {
    color = Colors.grey.isGreyGreen;
  }

  return color;
};

const primaryBtn = (bgColor, disabled) => {
  return `
    background-color: ${ getButtonColorFill(bgColor, disabled) };
    border: 1px solid ${ getButtonColorFill(bgColor, disabled) };
  `;
};

const inverseBtn = (
  bgColor = 'blue',
  borderColor,
  hover
) => {
  return `
    background-color: transparent;
    border: 1px solid ${ borderColor ? borderColor : getButtonColorFill(bgColor) };

    ${ hover
      ? `
      &:hover {
        background-color: ${ getButtonColorFill(bgColor) };
      }`
      : `
      &:hover {
        opacity: 0.8;
      }`
    }
  `;
};

const disabledBtn = `
  background-color: ${ Colors.grey.isBgGrey };
  border: 1px solid ${ Colors.grey.isLighterGrey };
`;

const inverseDisabledBtn = `
  background-color: ${ Colors.grey.isBgLightGrey };
  border: 1px solid ${ Colors.grey.isInactiveGrey };
  cursor: not-allowed;
`;

const buttonStyle = (
  type,
  disabled,
  bgColor,
  borderColor,
  hover
) => {
  switch (type) {
    case 'inverse':
      return inverseBtn(
        bgColor,
        borderColor,
        hover
      );
    case 'disabled': return disabledBtn;
    case 'inverseDisabled': return inverseDisabledBtn;
    case 'primary':
    default:
      return primaryBtn(bgColor, disabled);
  }
};

export const ButtonStyle: any = Styled.div < ButtonStyleProps > `
  button {
    ${ props => buttonStyle(
  props.styleType,
  props.disabled,
  props.bgColor,
  props.borderColor,
  props.hover
) };

    width: ${ props => props.width ? props.width + 'px' : '100%' };
    height: ${ props => props.height ? props.height + 'px' : '100%' };
    border-radius: ${ props => (props.borderRadius ? props.borderRadius : 10) + 'px' };
    cursor: ${ props => props.disabled ? 'default' : 'pointer' };

    margin-top: ${ props => (props.mt ? props.mt : 0) + 'px' };
    margin-bottom: ${ props => (props.mb ? props.mb : 0) + 'px' };
    margin-left: ${ props => (props.ml ? props.ml : 0) + 'px' };
    margin-right: ${ props => (props.mr ? props.mr : 0) + 'px' };

    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all;
    
    ${ props =>
    props.styleType !== 'disabled' &&
    !props.disabled &&
    props.styleType !== 'inverse' && `
      &:hover {
        opacity: 0.8;
      }
    `}

    ${ props =>
    props.styleType === 'inverse' && props.hover && `
      &:hover {
        .text-button {
          color: white;
        }

        .icon-button {
          fill: white;
        }
      }  
    `}

    &:focus {
      outline: none;
    }
  }

  .btn-icon-inner {
    padding: 10px;
  }

  @media ${ screen.sizesMax.md } {
    .btn-icon-inner {
      padding: 7px;
    }
  }

  @media ${ screen.sizesMax.xs } {
    .btn-icon-inner {
      padding: 5px;
    }
  }

  @media ${ screen.sizesMax.xxs } {
    .btn-icon-inner {
      padding: 3px;
    }
  }
`;
