export default {
	white: {
		default: 'rgb(255, 255, 255)',
		opacity: 'rgba(255, 255, 255, 0.8)',
		opacityHalf: 'rgba(255, 255, 255, 0.5)',
		opacityLow: 'rgba(255, 255, 255, 0.1)',
	},
	black: {
		default: 'rgb(38, 38, 38)',
		isBlack: 'rgba(35, 31, 32, 1)',
		isBlackOpacity: (opacity: string) => `rgba(35, 31, 32, ${ opacity })`,
		isBlackGreen: 'rgba(44, 49, 47, 1)',
		isTextBlack: 'rgba(23, 23, 23, 1)',
		isTextBlack2: 'rgba(0, 0, 0, 1)',
		isTextBlack2Opacity: (opacity: string) => `rgba(0, 0, 0, ${ opacity })`,
		isTextBlack3: 'rgba(65, 65, 65, 1)',
		isShadow: 'rgba(160, 163, 189, 0.1)',
		isShadowOpacity: (opacity: string) => `rgba(160, 163, 189, ${ opacity })`,
		isShadow2: 'rgba(0, 0, 0, 0.08)'
	},
	grey: {
		default: 'rgb(128, 128, 128)',
		lighter: 'rgb(245, 245, 245)',
		dark: 'rgb(67, 67, 67)',
		isInactiveGrey: 'rgba(234, 234, 234, 1)',
		isLightGrey: 'rgba(238, 238, 238, 1)',
		isLighterGrey: 'rgba(244, 244, 244, 1)',
		isDarkGrey: 'rgba(148, 148, 148, 1)',
		isDarkGrey2: 'rgba(82, 92, 86, 1)',
		isBgGrey: 'rgba(248, 248, 248, 1)',
		isBgLightGrey: 'rgba(250, 250, 250, 1)',
		isGrey: 'rgba(176, 176, 176, 1)',
		isGreyOpacity: (opacity: string) => `rgba(176, 176, 176, ${ opacity })`,
		isGreyGreen: 'rgba(130, 141, 138, 1)',
		isGreyBlue: 'rgba(210, 232, 234, 1)'
	},
	red: {
		default: 'rgb(255, 0, 0)',
		light: 'rgb(255, 242, 243)',
		isRed: 'rgba(224, 64, 64, 1)',
		isRedOpacity: (opacity: string) => `rgba(224, 64, 64, ${ opacity })`,
		isTextRed: 'rgba(235, 87, 87, 1)',
		isRedOuterCircle: 'rgba(255, 205, 205, 1)'
	},
	blue: {
		default: 'rgb(26, 66, 120)',
		light: 'rgb(232, 236, 242)',
		dark: 'rgb(58, 91, 135)',
		isBlue: 'rgba(2, 162, 245, 1)',
		isBlueOpacity: (opacity: string) => `rgba(57, 170, 180, ${ opacity })`,
		isBlueIris: 'rgba(93, 95, 239, 1)',
		isBlueIrisOpacity: (opacity: string) => `rgba(93, 95, 239, ${ opacity })`,
		isBlueAlert: 'rgba(47, 128, 237, 1)',
		isBlueAlertOpacity: (opacity: string) => `rgba(47, 128, 237, ${ opacity })`,
	},
	yellow: {
		default: 'rgb(255, 255, 0)',
		isYellow: 'rgba(203, 157, 19, 1)',
		isYellowOpacity: (opacity: string) => `rgba(203, 157, 19, ${ opacity })`
	},
	green: {
		default: 'rgb(0, 128, 0)',
		tosca: 'rgb(6, 214, 159)',
		toscaLight: 'rgb(176, 227, 214)',
		isPrimaryGreen: 'rgba(111, 207, 151, 1)',
		isGreen: 'rgba(58, 146, 121, 1)',
		isGreenOpacity: (opacity: string) => `rgba(58, 146, 121, ${ opacity })`,
		isLightGreen: 'rgba(97, 168, 148, 1)',
		isLighterGreen: 'rgba(187, 215, 207, 1)',
		isMintGreen: 'rgba(251, 255, 254, 1)',
		isBgGreen: 'rgba(58, 146, 121, 0.05)',
		isGreenAlert: 'rgba(33, 191, 49, 1)',
		isGreenAlertOpacity: (opacity: string) => `rgba(33, 191, 49, ${ opacity })`,
	},
	pink: {
		default: 'rgb(255, 192, 203)',
		isFuschia: 'rgba(239, 93, 168, 1)',
		isFuschiaOpacity: (opacity: string) => `rgba(239, 93, 168, ${ opacity })`
	},
	orange: {
		default: 'rgb(255, 166, 0)',
		light: 'rgb(239, 233, 231)',
		isOrange: 'rgba(242, 153, 74, 1)',
		isOrangeOpacity: (opacity: string) => `rgba(242, 153, 74, ${ opacity })`
	},
	toast: {
		success: {
			primary: (opacity: string) => `rgba(111, 207, 151, ${ opacity })`,
			secondary: 'rgba(248, 253, 250, 1)',
		},
		error: {
			primary: 'rgba(252, 236, 236, 1)',
			secondary: (opacity: string) => `rgba(245, 91, 93, ${ opacity })`
		}
	}
};
