import { history } from 'store';

export const goBack = () => history.goBack();

export const push = (path: string, params?: any) => history.push(path, params);

export const replace = (path: string, params?: any) => history.replace(path, params);

export const redirectUrl = (adminRole: string) => {
  const roleName = adminRole?.toLowerCase() || '';

  switch (roleName) {
    case 'super admin':
      return '/dashboard/stats';
    case 'customer service':
      return '/dashboard/customer-services';
    case 'showroom admin':
    default:
      return '/dashboard/inventory';
  }
};
