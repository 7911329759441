import adminReducer from './Admin/reducer';
import authReducer from './Auth/reducer';
import batteryReducer from './Battery/reducer';
import caseReducer from './Case/reducer';
import miscReducer from './Misc/reducer';
import paginationReducer from './Pagination/reducer';
import roleReducer from './Role/reducer';
import scooterReducer from './Scooters/reducer';
import showroomReducer from './Showroom/reducer';
import subscriptionReducer from './Subscriptions/reducer';
import userReducer from './Users/reducer';
import LocationReducer from './Location/reducer';
import stationReducer from './Station/reducer';
import notifReducer from './Notification/reducer';
import statsReducer from './Stats/reducer';

export default {
	case: caseReducer,
	admin: adminReducer,
	auth: authReducer,
	battery: batteryReducer,
	misc: miscReducer,
	pagination: paginationReducer,
	role: roleReducer,
	scooter: scooterReducer,
	showroom: showroomReducer,
	subscription: subscriptionReducer,
	user: userReducer,
	location: LocationReducer,
	station: stationReducer,
	notif: notifReducer,
	stats: statsReducer,
};