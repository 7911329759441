import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { ApiData } from 'interfaces/common';
import { Cities, CitiesApiParams } from 'interfaces/location';

export const getAllCities = async(params: CitiesApiParams) => {
  try {
    const queryParams: CitiesApiParams = {
      ...params,
      sort: (params.sort || 'asc'),
      order: (params.order || 'provinces.name'),
    };

    const response: ApiData<Cities[]> = await customFetch(Endpoints.getAllCities(), queryParams);

    return response;
  } catch (error) {
    throw error;
  }
};