import { Colors } from 'consts';
import { screen } from 'helpers';
import Styled from 'styled-components';

type SelectSearchBarStyleProps = {
  isOpen: boolean;
  backgroundColor?: string;
  disabled?: boolean;
  totalItems?: number;
	width?: string;
};

const setWidthFilter = (
  totalItems: number,
  length2: string,
  length3: string
) => {
  if (totalItems === 1) {
    return '200px';
  } else if (totalItems === 2) {
    return length2;
  } else {
    return length3;
  }
};

export const SearchStyle: any = Styled.div `
  padding: 5px 5px 0 5px;
  .ic-search {
    width: 14px;
    height: 14px;
  }

  .search-container {
    margin-right: -17px;
    margin-left: 20px;
  }

  @media ${screen.sizesMax.xs} {
    .ic-search {
      width: 12px;
      height: 12px;
    }

    .search-container {
      margin-right: -18px;
      margin-left: 22px;
    }
  }
`;

export const IconInputStyle: any = Styled.div `
  z-index: 100;
  margin-right: -37px;

  @media ${screen.sizesMax.xs} {
    margin-right: -27px;
  }
`;

export const SelectSearchBarStyle: any = Styled.div < SelectSearchBarStyleProps > `
  margin-bottom: 20px;

  .ic-arrow {
    ${props => props.isOpen ? 'transform: rotateX(180deg) !important;' : ''}
    margin-left: -20px !important;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select:hover .ant-select-selector,
  .ant-select-open .ant-select-selector {
    cursor: pointer;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : Colors.grey.isBgLightGrey};
    border-color: ${Colors.grey.isBgLightGrey} !important;
    box-shadow: none !important;
    border-radius: 8px;
  }

	.search-input {
		width: 100%;
	}

  .search-input > .ant-select-selector {
    height: 43px;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : Colors.grey.isBgLightGrey};
    border-color: ${Colors.grey.isBgLightGrey} !important;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    padding: 13px 33px 13px 20px !important;
    width: ${props => props.width ? props.width : '200px'};
  }

  .ant-select-selection-item {
    font-family: InterMedium !important;
    font-size: 14px;
    color: ${Colors.black.isBlack};
    line-height: 24px !important;
    padding: 0px !important;
    margin-top: -4.2px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    font-family: InterMedium !important;
    font-size: 14px;
    color: ${Colors.black.isBlack};
    height: 43px !important;
    cursor: pointer !important;
  }

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix .ant-select-selection-search {
    font-family: InterMedium !important;
    font-size: 14px;
    color: ${Colors.black.isBlack};
  }

  @media ${screen.sizesMax.md2} {
    .search-input > .ant-select-selector {
      width: ${props => setWidthFilter(
        (props.totalItems || 0),
        props.width ? props.width : '200px',
        '155px'
        )};
    }
  }

  @media ${screen.sizesMax.xs} {
    .search-input > .ant-select-selector {
      width: ${props => setWidthFilter(
        (props.totalItems || 0),
        props.width ? props.width : '200px',
        '140px'
        )};
    }
  }

  @media screen and (max-width: 500px) {
    .search-input > .ant-select-selector {
      width: ${props => setWidthFilter(
        (props.totalItems || 0),
        '160px',
        '120px'
        )};
    }
  }

  @media screen and (max-width: 450px) {
    .search-input > .ant-select-selector {
      width: ${props => setWidthFilter(
        (props.totalItems || 0),
        '140px',
        '110px'
        )};
    }
  }

  @media screen and (max-width: 415px) {
    .search-input > .ant-select-selector {
      width: ${props => setWidthFilter(
        (props.totalItems || 0),
        '140px',
        '100px'
        )};
    }
  }

  @media ${screen.sizesMax.xxs} {
    .search-input > .ant-select-selector {
      width: ${props => setWidthFilter(
        (props.totalItems || 0),
        '100px',
        '70px'
        )};
    }
  }
`;
