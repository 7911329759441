import {
	BatteryCondition,
	StatsAge,
	SwapLocation,
	BatteryRetired
} from 'interfaces/stats';

export const GET_STATS_AGE = 'GET_STATS_AGE';
export const GET_BATTERY_CONDITION = 'GET_BATTERY_CONDITION';
export const GET_SWAP_LOCATION = 'GET_SWAP_LOCATION';
export const GET_BATTERY_RETIRED = 'GET_BATTERY_RETIRED';
export const LOGOUT = 'LOGOUT';

export interface setStatsAge {
	type: typeof GET_STATS_AGE;
	data: StatsAge[];
}

export interface setBatteryCondition {
	type: typeof GET_BATTERY_CONDITION;
	data: BatteryCondition[];
}

export interface setSwapLocation {
	type: typeof GET_SWAP_LOCATION;
	data: SwapLocation[];
}

export interface setBatteryRetired {
	type: typeof GET_BATTERY_RETIRED;
	data: BatteryRetired[];
}

export interface Logout {
	type: typeof LOGOUT;
}

export type StatsActionTypes =
setStatsAge |
setBatteryCondition |
setSwapLocation |
setBatteryRetired |
Logout;