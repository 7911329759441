import React, {
  useState,
  useCallback,
  useEffect
} from 'react';

import {
  Box,
  Text,
  Icon,
  Modal,
  EmptyState,
  Skeleton,
  Loader,
  Pagination
} from 'components';
import { Colors, Images } from 'consts';
import {
  hooks,
  misc,
  moment
} from 'helpers';
import { SortOrder } from 'typings';
import { DataStationsVisited } from 'interfaces/scooter';
import { PairedScooter } from 'interfaces/user';
import { CurrentPagination } from 'interfaces/common';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { renderTitleSubContent } from '../../index';

type BoxStationsProps = {
  widthBox: number | string;
  activeScooter: PairedScooter;
};

const BoxStations: React.FC<BoxStationsProps> = ({
  widthBox,
  activeScooter,
}) => {
  const dispatch = hooks.useAppDispatch();
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const getStationVisited = dispatch(actions.getStationVisited);
  const stationVisited = hooks.useAppSelector(selectors.scooter.stationVisited);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const loadingScooter = misc.isLazyLoading(lazyLoad, 'StationVisited') || misc.isLazyLoading(lazyLoad, 'userDetail');

  useEffect(() => {
    const params = {
      limit: 10,
      offset: 0,
      page: currentPage,
      search: '',
      sort: 'desc' as SortOrder,
      order: 'swap_batteries.created_date',
    };

    getStationVisited(String(activeScooter?.vin), params);
  }, [activeScooter?.vin, currentPage]);

  const renderEmptyData = () => {
    return (
      <div className='mt4'>
        <EmptyState
          background={ { height: 285 } }
          image={ {
            src: Images.emptyState.batteryStations,
            width: 56,
            height: 56
          } }
          text='No Stations Visited'
        />
      </div>
    );
  };

  const getCurrentPaginationStation = (currentPagination: CurrentPagination<DataStationsVisited[]>) => {
    if (currentPagination.currentPage <= currentPagination.pagesCount) {
      setCurrentPage(currentPagination?.currentPage);
    }
  };

  const renderPaginationStation = () => {
    if (loadingScooter) {
      return <Loader className='col center-content' style={ { marginTop: 25 } } />;
    }

    if (currentPage < stationVisited?.pagination?.page_total) {
      return (
        <div className='mt5'>
          <Pagination
            type='lazyLoad'
            fieldName='batteryVinLog'
            data={ stationVisited?.data }
            getCurrentPagination={ getCurrentPaginationStation }
          />
        </div>
      );
    }
  };

  const renderStationsVisited = (allData: boolean) => {
    const sliceData: DataStationsVisited[] = stationVisited?.data?.length <= 5 || allData
      ? stationVisited?.data
      : stationVisited?.data?.slice(0, 5);

    if (loadingScooter && !allData) {
      return <Loader className='col center-content' style={ { marginTop: 25 } } />;
    }

    if ((!sliceData?.length || !activeScooter?.vin) && !loadingScooter) {
      return renderEmptyData();
    }

    return sliceData?.map((data, index) => {
      return (
        <Skeleton
          key={ index }
          loading={ loadingScooter }
          paragraph={ { rows: 2 } }
          avatar={ {
            size: 36,
            shape: 'circle'
          } }
          title={ false }
          mt={ index === 0 ? 40 : 20 }
        >
          <div key={ index } className={ `flex row align-center ${ index === 0 ? allData ? 'mt2' : 'mt6' : 'mt4' }` }>
            <Icon
              size={ 17 }
              iconName='batteryStations'
              fill={ Colors.black.isBlack }
              container='circle'
              containerColor={ Colors.grey.isLighterGrey }
              containerSize={ 36 }
            />
            <div className='ml3'>
              <Text mb={ 5 }>{ data.station_name }</Text>
              <Text size='xs' color={ Colors.grey.isGrey }>{ moment.utcToLocal(data.created_date, 'llll') }</Text>
            </div>
          </div>
        </Skeleton>
      );
    });
  };

  const onCloseModal = useCallback(() => {
    setModalVisible(false);
    setCurrentPage(1);
  }, []);

  const renderModalSeeAll = () => {
    return (
      <Modal
        visible={ modalVisible }
        onCloseModal={ onCloseModal }
        modalType='fixed-scroll'
        title={ () => renderTitleSubContent('Stations Visited') }
      >
        { renderStationsVisited(true) }
        { renderPaginationStation() }
      </Modal>
    );
  };

  const renderTitle = () => {
    if (stationVisited?.data?.length && activeScooter?.vin) {
      return renderTitleSubContent(
        'Stations Visited',
        stationVisited?.data?.length > 5 ? 'See All' : '',
        () => stationVisited?.data?.length > 5 ? setModalVisible(true) : null
      );
    }

    return renderTitleSubContent('Stations Visited');
  };

  return (
    <>
      <Box
        mt={ 20 }
        padding={ 20 }
        width={ widthBox }
      >
        { renderTitle() }

        { renderStationsVisited(false) }
      </Box>
      { renderModalSeeAll() }
    </>
  );
};

export default BoxStations;
