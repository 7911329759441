import { language } from 'language';

const { label, placeholder } = language;
const { scooterStatus, scooterLocation } = language.scooters;

export const inputPropsData = [
  {
    label: label.vin,
    placeholder: placeholder.vin,
    key: 'vin',
    type: 'text'
  },
  {
    label: label.platNumber,
    placeholder: placeholder.platNumber,
    key: 'plat_number',
    type: 'text'
  },
  {
    label: label.status,
    placeholder: placeholder.scooterStatus,
    key: 'status',
    type: 'select'
  },
  {
    label: label.showroom,
    placeholder: placeholder.searchShowroom,
    key: 'showroom_id',
    type: 'search'
  },
  {
    label: label.location,
    placeholder: placeholder.location,
    key: 'location',
    type: 'select'
  },
  {
    label: label.scooterType,
    placeholder: placeholder.scooterType,
    key: 'variant_id',
    type: 'select'
    /**
     * The variant_id input type for dev phase 1 is "select", because the scooter variant list still has 1.
     * Next, when the add variant scooter feature has been added, it is better to use the component search input.
     */
  },
];

export const scooterFilterStatus = ['All Status', ...scooterStatus];
export const scooterFilterLocation = ['All Location', ...scooterLocation];
export const dropdownsFilter = [
  {
    key: 'status',
    selectOptions: scooterFilterStatus
  }, {
    key: 'location',
    selectOptions: scooterFilterLocation
  }
];
