import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BoxChart, LineChart } from 'components';
import { moment, hooks } from 'helpers';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { FilterMenuChartProps } from 'components/Box/BoxChart';

import {
  dateChartFormatter,
  getDaily,
  getWeeks,
  group_by_month,
  setFilterApiParams
} from './helpers';

import { BoxStatsStyle, LineChartStyle } from './style';

type BoxBusyTimeProps = {
  widthBox: number;
  filterMenu: FilterMenuChartProps;
};

interface RouteParams {
  stationId: string;
}

const defaultDateFormat = 'YYYY-MM-DD';

const BoxBusyTime: React.FC<BoxBusyTimeProps> = ({
  widthBox,
  filterMenu
}) => {
  const params = useParams<RouteParams>();
  const dispatch = hooks.useAppDispatch();

  const getBusyTime = dispatch(actions.getBusyTime);
  const listBusyTime = hooks.useAppSelector(selectors.station.listBusyTime);

  const dateFormattedLong = dateChartFormatter(filterMenu.activeFilter.busyTime).long;

  useEffect(() => {
    if (params?.stationId) {
      const report_date = setFilterApiParams(filterMenu.activeFilter.busyTime);

      getBusyTime(params?.stationId || '', { report_date: String(report_date) });
    }
  }, [filterMenu.activeFilter.busyTime]);

  const convertLabelLineChart = (label: string, payload: any) => {
    if (filterMenu.activeFilter.busyTime === 'Weekly') {
      return label.replace('<br>', '');
    }

    return moment.dateConverted(
      payload?.date || label,
      defaultDateFormat,
      dateFormattedLong
    );
  };

  const convertDataChart = () => {

    const dataChart = listBusyTime || [];

    if (filterMenu.activeFilter.busyTime === 'Monthly') {
      return group_by_month(dataChart)?.slice(0, 5)
        ?.reverse();
    }

    if (filterMenu.activeFilter.busyTime === 'Weekly') {
      return getWeeks(dataChart)?.slice(0, 5);
    }

    if (filterMenu.activeFilter.busyTime === 'Daily') {
      return getDaily(dataChart)?.slice(0, 7);

    }

  };

  const renderLineChart = () => {

    const dataChartConverted = convertDataChart();

    const widthChart = widthBox - 40;

    return (
      <LineChartStyle width={ widthChart } height={ 240 }>
        <LineChart
          data={ dataChartConverted }
          dataKey={ {
            xAxis: filterMenu.activeFilter.busyTime === 'Daily' ? 'created_date' : 'date_x',
            line: 'value'
          } }
          convertLabel={ convertLabelLineChart }
          width={ widthChart }
          height={ 240 }
          margin={ { left: 0 } }
          yAxisWidth={ 70 }
          heightAxis={ 20 }
          sizeFont={ 10 }
          maxCharsLabel={ false }
          transformLabel={ filterMenu.activeFilter.busyTime === 'Daily' ? 'rotate(10deg)' : undefined }
        />
      </LineChartStyle>
    );
  };

  return (
    <BoxStatsStyle isFilterActive={ filterMenu.visibleFilter }>
      <BoxChart
        mt={ 20 }
        title='Busy Time'
        width={ widthBox }
        filterMenu={ filterMenu }
        chart={ renderLineChart() }
        empty={ !listBusyTime?.length }
      />
    </BoxStatsStyle>
  );
};

export default BoxBusyTime;
