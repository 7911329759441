import Styled from 'styled-components';

import { screen } from 'helpers';

export const ScooterDetailStyle: any = Styled.div `
  display: flex;
  flex-direction: column;

  .btn-live-tracking {
    padding: 12px 30px;
  }

  .content-container {
    display: flex;
    flex-direction: row;
  }

  @media ${screen.sizesMax.md2} ${screen.sizesMin.sx} {
    .content-container {
      display: flex;
      flex-direction: column;
    }
  }

  @media ${screen.sizesMax.xs} {
    .btn-live-tracking {
      padding: 9px 17px;
    }
  }
`;
