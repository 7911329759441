import Styled from 'styled-components';

import { Colors } from 'consts';

import { handleBackgroundColor, handleBorder } from '../../Input/style';

type SelectMultipleStyleProps = {
  focus: boolean;
  backgroundColor?: string;
  disabled?: boolean;
  option?: any;
};

export const SelectMultipleStyle: any = Styled.div < SelectMultipleStyleProps > `
  margin-bottom: 20px;

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select:hover .ant-select-selector,
  .ant-select-open .ant-select-selector {
    border-color: ${props => handleBorder(props.focus)} !important;
    box-shadow: none !important;
    background-color: ${props => handleBackgroundColor(
      props.focus,
      props.backgroundColor,
      props.disabled
    )} !important;
  }

  .search-input > .ant-select-selector {
    border-radius: 10px;
    height: ${props => props.option.length > 1 ? 'auto' : '44px'};
    background-color: ${props => handleBackgroundColor(
      props.focus,
      props.backgroundColor,
      props.disabled
    )} !important;
    justify-content: center;
    border-color: ${props => handleBorder(props.focus)} !important;
    cursor: ${props => props.disabled ? 'default' : 'text'} !important;
  }

  .ant-select-selection-item {
    font-family: InterMedium;
    font-size: 14px;
    color: ${Colors.black.isBlack};
    line-height: 17px;
    display: flex;
    align-items: center;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    font-family: InterMedium;
    font-size: 14px;
    color: ${Colors.black.isBlack};
    height: 44px !important;
    cursor: ${props => props.disabled ? 'default' : 'text'} !important;
  }

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix .ant-select-selection-search {
    font-family: InterMedium !important;
    font-size: 14px;
    color: ${Colors.black.isBlack};
  }
`;
