import Styled from 'styled-components';

import { screen } from 'helpers';

type ButtonContactStyleProps = {
  withText?: boolean;
};

export const ButtonContactStyle: any = Styled.div<ButtonContactStyleProps>`
  .btn-contact {
    height: 36px;
    width: ${ props => props.withText ? '100%' : '36px' };

    &:hover {
      opacity: 1;
    }
  }

  @media ${ screen.sizesMax.xs } {
    .btn-contact {
      height: 31px;
      width: ${ props => props.withText ? '100%' : '31px' };
      margin-top: 10px;
    }
  }

  @media ${ screen.sizesMax.xxs } {
    .btn-contact {
      height: 25px;
      width: ${ props => props.withText ? '100%' : '25px' };
    }
  }
`;