import React from 'react';

import { Colors } from 'consts';

import { TooltipStyle } from './style';

import Box from '../../Box';
import Text from '../../Text';

export type CustomTooltipProps = {
  label?: string;
  value: string;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ label, value }) => {
  return (
    <TooltipStyle>
      <Box
        borderRadius={ 10 }
        bgColor={ Colors.black.isBlack }
        padding={ 0 }
        className='box-tooltip-linechart'
      >
        {
          label && (
            <Text
              size='xxs'
              color={ Colors.white.default }
              mb={ 5 }
            >{ label }</Text>
          )
        }
        <Text weight={ 700 } color={ Colors.white.default }>{ value }</Text>
      </Box>
    </TooltipStyle>
  );
};

export default CustomTooltip;
