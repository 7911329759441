/* eslint-disable no-undef */
export const setItem = (key: string, value: any) => {
  return localStorage.setItem(`${ key }`, JSON.stringify(value));
};

export const getItem = (key: string) => {
  const jsonValue = localStorage.getItem(`${ key }`);

  return jsonValue !== null && jsonValue !== 'undefined' ? JSON.parse(jsonValue) : null;
};

export const clearItem = (key: string) => {
  return localStorage.removeItem(`${ key }`);
};

export const clearStorage = () => {
  return localStorage.clear();
};

export const clearToken = () => {
  const keysToRemove = ['access_token', 'refresh_token'];

  return keysToRemove.forEach(k => clearItem(k));
};

export const getToken = (tokenType: 'access_token' | 'refresh_token') => {
  return getItem(tokenType);
};

export const setToken = (accessToken: string, refreshToken: string) => {
  const objToSet = [
    {
      key: 'access_token',
      value: accessToken
    }, {
      key: 'refresh_token',
      value: refreshToken
    }
  ];

  return objToSet.forEach(item => setItem(item.key, item.value));
};
