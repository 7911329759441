import React, { useRef, useEffect } from 'react';

import { TextAreaStyle } from './style';

type TextAreaProps = {
  isFocused: boolean;
  placeholder?: string;
  value?: string | number | readonly string[];
  name?: string;
  onChange?: any;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  fontSize?: number;
  fontFamily?: string;
  lineHeight?: number;
  backgroundColor?: string;
  disabled?: boolean;
  autoResize?: boolean;
  iconName?: any;
  iconPosition?: 'left' | 'right';
  inputClassName?: string;
};

const TextArea: React.FC<TextAreaProps> = ({
  isFocused,
  placeholder,
  value,
  name,
  onChange,
  onFocus,
  onBlur,
  onClick,
  fontSize,
  fontFamily,
  lineHeight,
  backgroundColor,
  disabled,
  autoResize,
  iconName,
  iconPosition,
  inputClassName
}) => {
  const textareaRef = useRef<any>(null);

  useEffect(() => {
    if (autoResize) {
      textareaRef.current.style.height = '44px'; // min-height
      // resize text area height
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    } else {
      // if autoResize false, set text area height to max-height
      textareaRef.current.style.height = '90px';
    }
  }, [value, autoResize]);

  return (
    <TextAreaStyle
      ref={ textareaRef }
      isFocused={ isFocused }
      value={ value }
      name={ name || `textarea${ Math.floor(Math.random() * 100) }` }
      placeholder={ placeholder }
      onChange={ onChange }
      onFocus={ onFocus }
      onBlur={ onBlur }
      onClick={ onClick }
      fontSize={ fontSize }
      fontFamily={ fontFamily }
      lineHeight={ lineHeight }
      backgroundColor={ backgroundColor }
      disabled={ disabled }
      iconName={ iconName }
      iconPosition={ iconPosition }
      className={ inputClassName }
    />
  );
};

export default TextArea;