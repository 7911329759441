import React, { useEffect } from 'react';

import {
  Box,
  Select,
  BarChart,
  Text,
  EmptyState
} from 'components';
import { hooks } from 'helpers';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';
import { Colors, Images } from 'consts';

import { renderTitleBox } from '../../index';
import {
  convertFilterToReportDate,
  convertLabelTooltip,
  convertSingleDate
} from './helpers';

type BoxSwapLocProps = {
  widthBox: number;
  filterMenu: {
    activeFilter: any;
    visibleFilter: any;
    onVisibleChange: (visible: boolean, key: string) => void;
    onClickFilterMenu: (params?: any) => void;
  };
};

const BoxSwapLoc: React.FC<BoxSwapLocProps> = ({ widthBox, filterMenu }) => {
  const dispatch = hooks.useAppDispatch();

  const getSwapLocation = dispatch(actions.getSwapLocation);
  const swapLocation = hooks.useAppSelector(selectors.stats.swapLocation);
  const report_date = convertFilterToReportDate(filterMenu.activeFilter.swapLoc);
  
  useEffect(() => {
    getSwapLocation({ report_date });
  }, [filterMenu.activeFilter.swapLoc]);
  
  const renderFilterMenu = (key: string) => {
    return (
      <Select
        keySelect={ key }
        visible={ filterMenu.visibleFilter[key] }
        onVisibleChange={ filterMenu.onVisibleChange }
        selectTitle={ filterMenu.activeFilter[key] }
        onClickSelectItem={ filterMenu.onClickFilterMenu }
        selectOptions={ [
          'Daily',
          'Weekly',
          'Monthly'
        ] }
      />
    );
  };

  const renderSubTitlePeriod = () => {
    return convertSingleDate(
      report_date,
      'YYYY-MM-DD',
      'YYYY MMM DD'
    ) + ' - ' + convertSingleDate(
      new Date(),
      'YYYY-MM-DD',
      'YYYY MMM DD'
    );
  };

  const renderEmptyData = () => {
    return (
      <div className='mt4' style={ { 'width': widthBox } }>
        <EmptyState
          background={ { height: 285 } }
          image={ {
            src: Images.emptyState.batteryStations,
            width: 56,
            height: 56
          } }
          text={ 'No Data ' + filterMenu.activeFilter.swapLoc }
        />
      </div>
    );
  };

  return (
    <Box
      mt={ 20 }
      padding={ 20 }
      width={ widthBox }
    >
      <div className='justify-align-center mb4'>
        <div>
          { renderTitleBox('Popular Swap Location', 8) }
          <Text
            color={ Colors.grey.isGrey }
            spacing={ -0.5 }
            lineHeight={ 19 }
          > { renderSubTitlePeriod() } </Text>
          
        </div>
        { renderFilterMenu('swapLoc') }
      </div>
      <div className='col center-content'>
      { Object.values(swapLocation)?.length ?
        <BarChart
          data={ swapLocation }
          unit=' Unit'
          dataKey={ {
            xAxis: 'station_name',
            bar: 'total'
          } }
          convertLabel={ () => convertLabelTooltip(String(renderSubTitlePeriod())) }
          width={ widthBox - 40 }
        /> : renderEmptyData() }
      </div>
    </Box>
  );
};

export default BoxSwapLoc;
