import Styled from 'styled-components';

import { screen } from 'helpers';

export const LiveTrackingStyle: any = Styled.div`
  .btn-submit {
    padding: 8px 20px;
  }

  .left-navbar {
    padding-left: 40px;
  }

  .content {
    padding: 40px;
    padding-bottom: 200px;
    min-height: ${ screen.height - 40 }px;
    display: flex;
    flex-direction: row;
  }

  .empty-wrap {
    height: 200px;
    margin-top: 20px;
  }

  .container-form-date {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .spacer-form {
    margin-left: 20px;
  }

  .btn-send-loc {
    padding: 12px 30px;
    height: 100%;
  }

  .dot {
    float: left;
    position: relative;
    display: block;
  }

  .outer-container-right {
    max-height: 790px;
  }

  .spacer-section {
    margin-right: 20px;
  }

  .container-img-scooter {
    width: 40%;
  }

  .container-info-scooter {
    width: 55%;
  }

  @media ${ screen.sizesMax.md2 } {
    .spacer-section {
      margin-right: 0px;
      margin-bottom: 20px;
    }

    .content {
      flex-direction: column;
      padding-bottom: 180px;
    }

    .container-info-scooter {
      width: 40%;
    }
  }

  @media ${ screen.sizesMax.sm } {
    .container-form-date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .spacer-form {
      margin-left: 0px;
      margin-top: 10px;
    }
  }

  @media ${ screen.sizesMax.md2 } ${ screen.sizesMin.xs } {
    .img-scooter {
      width: 168px;
      height: 161px;
    }
  }

  @media ${ screen.sizesMax.xs } {
    .left-navbar {
      padding-left: 20px;
    }

    .floatting-form-date {
      top: 80px;
      left:0;
      right:0;

      margin-left: auto;
      margin-right: auto;

      position: fixed;
      width: 90%;
      z-index: 99;
    }
  }
`;

type BoxUserInfoStyleProps = {
  containerTextWidth: number;
};

export const BoxUserInfoStyle: any = Styled.div < BoxUserInfoStyleProps > `
  .user-data-container {
    width: ${ props => props.containerTextWidth }px;
  }
  
  @media ${ screen.sizesMax.xs } {
    .img-user-info {
      width: 60px;
      height: 60px;
    }
  }

  @media ${ screen.sizesMax.xxxs } {
    .img-user-info {
      width: 40px;
      height: 40px;
    }
  }
`;

export const BottomSheetStyle: any = Styled.div`
  .container-header {
    padding: 20px 10px;
  }

  .container-content {
    padding: 20px;
  }

  .container-img-scooter {
    width: 40%;
  }

  .container-info-scooter {
    width: 50%;
  }

  @media ${ screen.sizesMax.xs } {
    .title-missing {
      font-size: 16px;
    }

    .note-missing {
      font-size: 12px;
    }
  }

  @media ${ screen.sizesMax.xxs } {
    .title-missing {
      font-size: 14px;
    }

    .note-missing {
      font-size: 10px;
    }

    .image-thief {
      width: 50px;
      height: 50px;
    }
  }
`;

type BoxMissingStateStyleProps = {
  top: number;
};

export const BoxMissingStateStyle: any = Styled.div < BoxMissingStateStyleProps > `
  .box-container {
    position: absolute;
    top: ${ props => props.top }px;
    left: 50px;
  }
`;
