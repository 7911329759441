import { Pagination } from 'interfaces/common';
import {
  BatteryPack,
  BatteryPackBatteryUsage,
  BatteryPackStatistic,
  DischargeTempStatistic,
  VinLog
} from 'interfaces/battery';

import {
  GET_ALL_BATTERY_PACK,
  GET_BATTERY_PACK_DETAIL,
  GET_BATTERY_PACK_SOC,
  GET_BATTERY_PACK_SOH,
  GET_BATTERY_PACK_DISCHARGE,
  GET_BATTERY_PACK_TEMPERATURE,
  GET_BATTERY_VIN_LOG,
  GET_BATTERY_PACK_BATTERY_USAGES,
  LOGOUT,
  BatteryActionTypes
} from './actionTypes';

interface BatteryStatistic {
  data: BatteryPackStatistic[];
  filter_time: string;
  filter_level?: string;
}

interface BatteryState {
  batteryPacks: {
    data: BatteryPack[];
    pagination: Pagination;
  };
  batteryPackDetail: BatteryPack;
  listSoc: BatteryStatistic;
  listSoh: BatteryStatistic;
  listDischarge: { data: DischargeTempStatistic[]; };
  listTemp: { data: DischargeTempStatistic[]; };
  vinLog: {
    data: VinLog[];
    pagination: Pagination;
  };
  batteryPackBatteryUsage: BatteryPackBatteryUsage[];
}

const initState: BatteryState = {
  batteryPacks: {
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
      order: '',
      page: 0,
      page_total: 0,
      search: '',
      sort: 'asc'
    }
  },
  batteryPackDetail: {
    id: 0,
    soc: 0,
    capacity_remaining: 0,
    status: '',
    location: '',
    soh: 0,
    vin: 0,
    created_date: ''
  },
  listSoc: {
    data: [],
    filter_time: ''
  },
  listSoh: {
    data: [],
    filter_time: ''
  },
  listDischarge: { data: [] },
  listTemp: { data: [] },
  vinLog: {
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
      order: '',
      page: 0,
      page_total: 0,
      search: '',
      sort: 'asc'
    }
  },
  batteryPackBatteryUsage: [],
};

const batteryReducer = (state = initState, action: BatteryActionTypes): BatteryState => {
  switch (action.type) {
    case GET_ALL_BATTERY_PACK:
      return {
        ...state,
        batteryPacks: action.data
      };

    case GET_BATTERY_PACK_DETAIL:
      return {
        ...state,
        batteryPackDetail: action.data
      };

    case GET_BATTERY_PACK_SOC:
      return {
        ...state,
        listSoc: action.data
      };

    case GET_BATTERY_PACK_SOH:
      return {
        ...state,
        listSoh: action.data
      };

    case GET_BATTERY_PACK_DISCHARGE:
      return {
        ...state,
        listDischarge: action.data
      };

    case GET_BATTERY_PACK_TEMPERATURE:
      return {
        ...state,
        listTemp: action.data
      };

    case GET_BATTERY_VIN_LOG:
      return {
        ...state,
        vinLog: {
          ...state?.vinLog,
          data: action?.data?.pagination?.page === 1
            ? action?.data?.data
            : [...state?.vinLog?.data, ...action?.data?.data],
          pagination: action?.data?.pagination
        }
      };

    case GET_BATTERY_PACK_BATTERY_USAGES:
      return {
        ...state,
        batteryPackBatteryUsage: action.data
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

export default batteryReducer;
