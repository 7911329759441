import React from 'react';

import { Colors } from 'consts';

import { LineStyle } from './style';

type LineStyleType = 'dashed' | 'dotted' | 'double' | 'groove' | 'hidden' | 'inset' | 'none' | 'outset' | 'ridge' | 'solid';

type LineWidth = number | 'medium' | 'thick' | 'thin';

export type LineProps = {
  margin?: string;
  width?: number;
  height?: number;
  lineWidth?: LineWidth;
  lineStyle?: LineStyleType;
  lineColor?: string;
  borderRadius?: number;
  style?: React.CSSProperties;
  className?: string;
};

const Line: React.FC<LineProps> = ({
  style,
  className,
  ...restProps
}) => {
  return (
    <LineStyle
      style={ style }
      className={ className }
      { ...restProps }
    />
  );
};

Line.defaultProps = {
  margin: '0',
  width: 150,
  height: 0,
  lineWidth: 6,
  lineStyle: 'solid',
  lineColor: Colors.blue.isBlue,
  borderRadius: 0
};

export default Line;
