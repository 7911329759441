import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  EmptyState,
  PieChart,
  Text
} from 'components';

import { Images } from 'consts';
import { hooks } from 'helpers';

import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { BoxVisitorsStyle } from './style';
import { convertPercentage } from './helpers';

type BoxVisitorsProps = {
  widthBox: number;
};

interface RouteParams {
  stationId: string;
}

const BoxVisitors: React.FC<BoxVisitorsProps> = ({ widthBox }) => {
  const params = useParams<RouteParams>();
  const dispatch = hooks.useAppDispatch();

  const getStationVisitor = dispatch(actions.getStationVisitor);
  const stationVisitor = hooks.useAppSelector(selectors.station.stationVisitor);

  const pieChartProps = {
    width: widthBox - 40,
    height: 225,
    legendWrapper: { left: (widthBox - 40) / 1.8 }
  };

  useEffect(() => {
    if (params?.stationId) {
      getStationVisitor(params?.stationId || '');
    }
  }, [params.stationId]);

  const chartConverted = () => {
    const dataChart = stationVisitor || [];
    return convertPercentage(dataChart);
  };

  const renderEmptyData = () => {
    return (
      <div className='mt4' style={ { 'width': widthBox } }>
        <EmptyState
          background={ { height: 285 } }
          image={ {
            src: Images.emptyState.batteryStations,
            width: 56,
            height: 56
          } }
          text='No Battery Station Visitor Data'
        />
      </div>
    );
  };

  const dataChartConverted = chartConverted();

  return (
    <>
      <Box
        mt={ 20 }
        padding={ 20 }
        width={ widthBox }
      >
        <BoxVisitorsStyle>
          <div className='mb3'>
            <Text
              size='m'
              weight={ 700 }
              lineHeight={ 19 }
            >Battery Station Visitors</Text>
          </div>
          <div className='col center-content pie-chart-outer'>
            { stationVisitor?.length ?
              <PieChart
                data={ dataChartConverted }
                dataKey={ {
                  category: 'city',
                  value: 'percentage'
                } }
                outerRadiusData='110%'
                { ...pieChartProps }
              /> : renderEmptyData() }
          </div>
        </BoxVisitorsStyle>
      </Box>
    </>
  );
};

export default BoxVisitors;
