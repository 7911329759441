import Styled from 'styled-components';

export const UserDetailStyle: any = Styled.div `
  display: flex;
  flex-direction: column;

  .outer-container-right {
    max-height: 878px;
  }
`;
