import { Dispatch } from 'redux';

import { AppActions } from 'store';
import { ApiData, CommonResponseData } from 'interfaces/common';
import {
	FormLogin,
	FormForgotPassword,
	FormResetPassword,
	AdminLogin
} from 'interfaces/auth';
import {
	navigation,
	toastify,
	auth,
	roleHelper
} from 'helpers';
import * as authServices from 'services/auth';

import { LOGIN, LOGOUT } from './actionTypes';
import { loadingStart, loadingEnd } from '../Misc/action';

const loginSuccess = (data: AdminLogin, saveToken: boolean): AppActions => ({
	type: LOGIN,
	data: {
		user: {
			...data,
			permissions: roleHelper.setPermissions(data.role_name) // todo: jika sudah memperoleh response dari API, remove line ini.
		},
		saveToken: saveToken
	}
});

export const login = (dispatch: Dispatch<AppActions>) => (saveToken: boolean, formLogin: FormLogin) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(loadingStart('login'));

			const { code, data }: ApiData<AdminLogin> = await authServices.login(formLogin);

			if (code === 200) {
				await dispatch(loginSuccess(data, saveToken));

				await auth.setAuthCredentials(data.access_token, data.refresh_token);

				navigation.push('/');

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();
			reject(error);
		}

		dispatch(loadingEnd('login'));
	});
};

export const forgotPassword = (dispatch: Dispatch<AppActions>) => (formForgotPass: FormForgotPassword, callback: () => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(loadingStart('forgotPassword'));

			const { code, data }: ApiData<CommonResponseData> = await authServices.forgotPassword(formForgotPass);

			if (code === 200) {
				toastify.success(data.message);

				callback ? callback() : null;

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();
			reject(error);
		}

		dispatch(loadingEnd('forgotPassword'));
	});
};

export const resetPassword = (dispatch: Dispatch<AppActions>) => (formResetPassword: FormResetPassword) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(loadingStart('resetPassword'));

			const { code, data }: ApiData<CommonResponseData> = await authServices.resetPassword(formResetPassword);

			if (code === 200) {
				await dispatch(loadingEnd('resetPassword'));

				toastify.success(`${ data.message }. Silakan login`);

				navigation.push('/');

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();
			reject(error);
		}

		dispatch(loadingEnd('resetPassword'));
	});
};

export const logout = (dispatch: Dispatch<AppActions>) => () => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await auth.removeAuthCredentials();

			dispatch({ type: LOGOUT });

			navigation.push('/');

			resolve();
		} catch (error) {
			reject(error);
		}
	});
};