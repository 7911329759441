import React, { useState } from 'react';
import { Table } from 'antd';

import { Colors } from 'consts';
import {
  hooks,
  misc,
  screen,
  roleHelper
} from 'helpers';
import { SorterInfo } from 'interfaces/common';
import { SortOrder } from 'typings';
import { selectors } from 'store/selectors';

import {
  ButtonActionTable,
  DropdownCell,
  TagTable,
  TooltipTable
} from './components';
import { DataTableStyle } from './style';
import { convertSortOrderColumn, convertColorStatus } from './helpers';

import Box from '../Box';
import Loader from '../Loader';
import Text from '../Text';
import Skeleton from '../Skeleton';
import { ButtonProps } from '../Button';

export type ButtonActionProps = {
  iconName?: string;
  color?: string;
  onClick?: (record?: any) => void;
  permission?: string | string[] | boolean;
  button?: ButtonProps;
};

type DataTableProps = {
  columns: any;
  currentData: any;
  permissionClickRow?: string | string[] | boolean;
  onClickRow?: (record: any, rowIndex?: number) => void;
  onChange?: (sorterInfo: SorterInfo) => void;
  buttonAction?: (record: any) => JSX.Element; // eslint-disable-line no-undef
  buttons?: ButtonActionProps[];
  type?: string;
  name?: string;
  loading?: boolean;
  rowKey?: string;
};

export const renderText = (
  text: string,
  color: string = Colors.black.isTextBlack2,
  weight = 500,
  mb = 0,
  ellipsis?: boolean,
  align?: string,
  key?: number,
) => {
  return (
    <Text
      key={ key }
      size='xs'
      weight={ weight }
      lineHeight={ 17 }
      color={ color }
      mb={ mb }
      ellipsis={ ellipsis }
      align={ align }
    >{ text === '' ? '-' : text }</Text>
  );
};

export const renderTag = (
  text: string,
  mb = 0,
  keyIndex?: number
) => {
  return (
    <TagTable
      text={ text }
      mb={ mb }
      keyIndex={ keyIndex }
    />
  );
};

export const renderTooltip = (
  text: string,
  align = 'left',
  color: string = Colors.black.isTextBlack2,
  weight = 500,
  mb = 0,
) => {
  return (
    <TooltipTable
      text={ text }
      align={ align }
      color={ color }
      weight={ weight }
      mb={ mb }
    />
  );
};

export const renderButtonAction = (buttons: ButtonActionProps[], record: any) => {
  return <ButtonActionTable buttons={ buttons } record={ record } />;
};

const DataTable: React.FC<DataTableProps> = ({
  columns,
  currentData,
  permissionClickRow,
  onClickRow,
  onChange,
  buttonAction,
  buttons,
  type,
  name,
  loading,
  rowKey
}) => {
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);

  const [sorterInfo, setSorterInfo] = useState<SorterInfo>({
    sort: null,
    order: ''
  });
  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const onChangeTable = (
    _,
    __,
    sorter
  ) => {
    let sort: SortOrder | null = null;
    switch (sorter.order) {
      case 'descend':
        sort = 'desc';
        break;
      case 'ascend':
        sort = 'asc';
        break;
      default:
        sort = null;
    }

    const sortOrder = {
      sort: sort,
      order: sort ? sorter.columnKey : ''
    };

    setSorterInfo(sortOrder);

    onChange && onChange(sortOrder);
  };

  const renderDataTable = () => {
    const isPermissibleClickRow = roleHelper.isPermissible(adminPermissions, permissionClickRow);

    if (type === 'table' && windowDimensions.width > screen.isMobile) {
      const components = { body: { cell: DropdownCell } };
      const updatedColumns = columns.map(column => {
        return {
          ...column,
          sortOrder: column.sorter ? convertSortOrderColumn(column.key, sorterInfo) : undefined
        };
      });

      return (
        <DataTableStyle>
          <Table
            key={ `data-table-${ name }` }
            rowKey={ rowKey || (name === 'scooters' ? 'vin' : 'id') }
            components={ components }
            columns={ updatedColumns }
            pagination={ false }
            dataSource={ currentData }
            onRow={ (record, rowIndex) => {
              return {
                onClick: isPermissibleClickRow
                  ? () => onClickRow && onClickRow(record, rowIndex)
                  : undefined
              };
            } }
            rowClassName={ isPermissibleClickRow ? 'pointer' : '' }
            scroll={ { x: 600 } }
            loading={ {
              indicator: <Loader className='loader-spin' size={ 50 } />,
              wrapperClassName: 'spin-wrapper',
              spinning: loading
            } }
            onChange={ onChangeTable }
          />
        </DataTableStyle>
      );
    } else if (type === 'box' && windowDimensions.width <= screen.isMobile) {
      const filteredColumns = columns.filter((column, index) => index > 0 && column.key !== 'action');
      const dummyData = misc.createDummyData(5);
      const data = loading ? (dummyData || []) : (currentData || []);

      return data?.map((dataItem, index) => {
        return (
          <Box
            key={ `box-data-table-${ index }` }
            shadow={ `0 5px 20px 0 ${ Colors.black.isShadow2 }` }
            padding={ 20 }
            mt={ 15 }
            onClick={ isPermissibleClickRow
              ? () => onClickRow && onClickRow(dataItem)
              : undefined }
            hover
          >
            <Skeleton loading={ (loading || false) } paragraph={ { rows: 3 } }>
              <div>
                <div className='justify-align-center mb5'>
                  <Text
                    size='xs'
                    weight={ 700 }
                    lineHeight={ 15 }
                    color={ Colors.black.isTextBlack }
                  >{ dataItem[columns[0]?.key] }</Text>

                  <div className='flex align-center'>
                    { buttonAction ? buttonAction(dataItem) : null }
                    { buttons ? renderButtonAction(buttons, dataItem) : null }
                  </div>
                </div>
                <div className='justify-between'>
                  <div>
                    {
                      filteredColumns.map((column, columnIndex) => {
                        const mb = columnIndex >= filteredColumns.length - 1 ? 0 : 16;
                        return (
                          <Text
                            key={ `box-column-data-table-${ columnIndex }` }
                            size='xs'
                            weight={ 400 }
                            lineHeight={ 17 }
                            color={ Colors.grey.isDarkGrey2 }
                            mb={ mb }
                          >{ column.title }</Text>
                        );
                      })
                    }
                  </div>
                  <div className='col col-end'>
                    {
                      filteredColumns.map((column, keyIndex) => {
                        const mb = keyIndex >= filteredColumns.length - 1 ? 0 : 16;

                        if (name === 'users' && column.key === 'is_completed') {
                          return !loading && renderTag(
                            dataItem[column.key],
                            8,
                            keyIndex
                          );
                        }

                        if (name === 'users' && column.key === 'status') {
                          return !loading && renderText(
                            misc.titleCase(dataItem[column.key].toString()),
                            Colors.black.isBlack,
                            500,
                            mb,
                            false,
                            'right',
                            keyIndex
                          );
                        }

                        if ((name === 'scooters' || name === 'batteryPacks') && column.key === 'status') {
                          return !loading && renderText(
                            dataItem[column.key],
                            convertColorStatus(dataItem[column.key]),
                            500,
                            mb,
                            false,
                            'right',
                            keyIndex
                          );
                        }

                        if (column.key === 'address') {
                          return !loading && (
                            <div key={ keyIndex } style={ { maxWidth: 150 } }>
                              { renderTooltip(dataItem[column.key], 'right') }
                            </div>
                          );
                        }

                        if (name === 'subscriptions') {
                          if (!loading && column?.render) {
                            return (
                              <span
                                key={ keyIndex }
                                className='inline-flex'
                                style={ { marginBottom: mb } }
                              >
                                { column.render(dataItem[column.key], dataItem) }
                              </span>
                            );
                          }
                        }

                        return !loading && renderText(
                          dataItem[column.key]?.toString(),
                          Colors.black.isBlack,
                          500,
                          mb,
                          false,
                          'right',
                          keyIndex
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </Skeleton>
          </Box>
        );
      });
    }

    return null;
  };

  return renderDataTable();
};

DataTable.defaultProps = {
  type: 'table',
  name: '',
  loading: false
};

export default DataTable;
