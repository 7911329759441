import { Dispatch } from 'redux';

import { toastify } from 'helpers';
import { ApiData } from 'interfaces/common';
import {
	Subscription,
	FormSubscription,
	SubscriptionApiParams
} from 'interfaces/subscription';
import { AppActions } from 'store';
import * as subscriptionServices from 'services/subscription';

import { GET_SUBSCRIPTIONS } from './actionTypes';

import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';
import { setPaginationData } from '../Pagination/action';

export const getAllSubscription = (dispatch: Dispatch<AppActions>) => (params: SubscriptionApiParams, callback?: (subscriptions: Subscription[]) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('allSubscription'));

			const {
				code,
				data,
				pagination
			}: ApiData<Subscription[]> = await subscriptionServices.getAllSubscription(params);

			if (code === 200) {
				await dispatch({
					type: GET_SUBSCRIPTIONS,
					data: {
						data,
						pagination
					}
				});

				await dispatch(setPaginationData('subscriptions', pagination));

				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('allSubscription'));
		}
	});
};

export const addSubscription = (dispatch: Dispatch<AppActions>) => (formAddSubscription: FormSubscription, callback?: (message: string) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			dispatch(lazyLoadStart('addSubscription'));

			const { code, stat_msg }: ApiData<any> = await subscriptionServices.addSubscription(formAddSubscription);

			if (code === 200) {
				callback ? callback(stat_msg) : null;

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('addSubscription'));
		}
	});
};

export const editSubscription = (dispatch: Dispatch<AppActions>) => (
	subsId: string,
	formEditSubscription: FormSubscription,
	callback?: (message: string) => void
) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('editSubscription'));

			const { code, stat_msg }: ApiData<any> = await subscriptionServices.editSubscription(subsId, formEditSubscription);

			if (code === 200) {
				callback ? callback(stat_msg) : null;

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('editSubscription'));
		}
	});
};

export const deleteSubscription = (dispatch: Dispatch<AppActions>) => (subsId: string, callback?: (message: string) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			await dispatch(lazyLoadStart('deleteSubscription'));

			const { code, stat_msg }: ApiData<any> = await subscriptionServices.deleteSubscription(subsId);

			if (code === 200) {
				callback ? callback(stat_msg) : null;

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		} finally {
			dispatch(lazyLoadEnd('deleteSubscription'));
		}
	});
};