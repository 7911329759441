import Styled from 'styled-components';

type LegendStyleProps = {
  bgColor: string;
};

export const LegendStyle: any = Styled.div < LegendStyleProps > `
  display: flex;
  flex-direction: column;
  
  .circle {
    width: 16px;
    height: 16px;
    background-color: ${props => props.bgColor};
    margin-right: 10px;
    border-radius: 100%;
  }
`;