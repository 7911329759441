import * as authSelectors from './Auth/selector';
import * as adminSelectors from './Admin/selector';
import * as batterySelectors from './Battery/selector';
import * as miscSelectors from './Misc/selector';
import * as paginationSelectors from './Pagination/selector';
import * as roleSelectors from './Role/selector';
import * as scooterSelectors from './Scooters/selector';
import * as showroomSelectors from './Showroom/selector';
import * as subscriptionSelectors from './Subscriptions/selector';
import * as userSelectors from './Users/selector';
import * as casesSelector from './Case/selector';
import * as stationSelectors from './Station/selector';
import * as notifSelectors from './Notification/selector';
import * as statsSelectors from './Stats/selector';

const selectors = {
  // Misc
  misc: miscSelectors,
  // Auth
  auth: authSelectors,
  // Admin (Admin Management)
  admin: adminSelectors,
  // Battery Packs
  battery: batterySelectors,
  // Pagination
  pagination: paginationSelectors,
  // Role Admin
  role: roleSelectors,
  // Inventory (Scooter)
  scooter: scooterSelectors,
  // Showroom
  showroom: showroomSelectors,
  // Subscriptions
  subscription: subscriptionSelectors,
  // User Profile
  user: userSelectors,
  // Case
  case: casesSelector,
  // Station
  station: stationSelectors,
  // Notification
  notif: notifSelectors,
  // Stats
  stats: statsSelectors
};

export { selectors };
