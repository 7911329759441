import React from 'react';
import { Modal } from 'antd';

import { Colors } from 'consts';
import { hooks, screen } from 'helpers';

import { ModalStyle } from './style';

import Icon from '../Icon';

type CustomModalProps = {
  visible: boolean;
  onCloseModal: () => void;
  children: React.ReactNode;
  width?: number;
  bodyStyle?: React.CSSProperties;
  modalType?: 'fixed-scroll' | 'long-scroll' | 'loader';
  padding?: number;
  afterClose?: () => void;
  backButton?: boolean;
  onClickBackButton?: () => void;
  title?: () => React.JSX.Element;
  subtitle?: () => React.JSX.Element;
  footer?: () => React.JSX.Element;
  fixedContent?: () => React.JSX.Element;
};

const CustomModal: React.FC<CustomModalProps> = ({
  visible,
  onCloseModal,
  children,
  width,
  padding,
  bodyStyle,
  modalType,
  afterClose,
  backButton,
  onClickBackButton,
  title,
  subtitle,
  footer,
  fixedContent
}) => {
  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();
  const isMobile = windowDimensions.width <= screen.isMobile;
  const widthModal = (isMobile && modalType !== 'loader') ? windowDimensions.width - 30 : width;

  let style;

  if (modalType === 'fixed-scroll') {
    style = {
      padding,
      // height: isMobile ? '65vh' : '80vh',
      // overflowY: 'auto',
    };
  } else {
    style = { padding };
  }

  return (
    <Modal
      visible={ visible }
      title={ null }
      footer={ null }
      onCancel={ onCloseModal }
      closeIcon={ <></> }
      bodyStyle={ {
        ...style,
        ...bodyStyle,
        borderRadius: 20
      } }
      width={ widthModal }
      afterClose={ afterClose }
      centered
    >
      <ModalStyle>
        {
          title ? (
            <div className={ subtitle ? 'justify-align-center mb1' : 'justify-align-center mb4' }>
              { backButton
                ? (
                  <Icon
                    size={ 16 }
                    iconName='long_arrow'
                    fill={ Colors.grey.isGrey }
                    onClick={ onClickBackButton }
                  />
                ) : null }

              { title() }

              <Icon
                size={ 16 }
                iconName='close'
                fill={ Colors.grey.isGrey }
                onClick={ onCloseModal }
              />
            </div>
          ) : null
        }
				{ subtitle ? (<div className='mb3'> { subtitle() } </div>) : null }
        { fixedContent ? fixedContent() : null }
        <div className={ (isMobile && modalType !== 'loader') || modalType === 'fixed-scroll' ? 'inner-container' : '' }>
          { children }
        </div>

        { footer ? footer() : null }
      </ModalStyle>
    </Modal>
  );
};

CustomModal.defaultProps = {
  width: 600,
  padding: 20,
  bodyStyle: {},
  modalType: 'fixed-scroll'
};

export default CustomModal;
