import { createSelector } from 'reselect';

import { RootState } from '../index';

const stationSelector = (state: RootState) => state.stats;

export const statsAge = createSelector(stationSelector, stats => stats.statsAge);
export const batteryCondition = createSelector(stationSelector, stats => stats.batteryCondition);
export const swapLocation = createSelector(stationSelector, stats => stats.swapLocation);
export const batteryRetired = createSelector(stationSelector, stats => stats.batteryRetired);
