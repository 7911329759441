import React from 'react';

import { ContentStyle } from './style';

type ContentProps = {
  children: React.ReactNode;
};

const Content: React.FC<ContentProps> = ({ children }) => {
  return (
    <ContentStyle>
      <div className='cont' id='app-content'>
        { children }
      </div>
    </ContentStyle>
  );
};

export default Content;
