import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { onValue, ref } from 'firebase/database';

import {
  Container,
  Spacer,
  Text
} from 'components';
import { Colors } from 'consts';
import {
  navigation,
  hooks,
  screen,
  toastify
} from 'helpers';
import { selectors } from 'store/selectors';
import { Permission } from 'interfaces/role';
import { MapsLocation } from 'typings';
import { db } from 'utils/firebase';

import {
  BoxLocation,
  BoxScooterInfo,
  BoxStations,
  BoxStats,
  BoxTripHistory,
} from './components';
import { ScooterDetailStyle } from './style';

interface RouteParams {
  scooterId: string;
}

type WidthBox = {
  scooterInfo: number | string;
  batteryStats: number | string;
  stations: number | string;
  tripHistory: number | string;
  location: number | string;
};

type BoxScooterConfig = { mr: number; };

export const renderTitleSubContent = (
  titleLeft: string,
  titleRight?: string,
  action?: () => void
) => {
  return (
    <div className='justify-align-center'>
      <Text
        size='m'
        weight={ 700 }
        lineHeight={ 19 }
      >{ titleLeft }</Text>
      {
        titleRight ? (
          <Text
            weight={ 700 }
            color={ Colors.blue.isBlue }
            align='right'
            onClick={ action }
          >{ titleRight }</Text>
        ) : null
      }
    </div>
  );
};
export const renderSubTitleSubContent = (subTitle: string) => {
  return (
    <div className='justify-align-center'>
      <Text
        size='sm'
        color={ Colors.grey.isGrey }
      >{ subTitle }</Text>
    </div>
  );
};

const ScooterDetail: React.FC = () => {
  const params = useParams<RouteParams>();

  const scooterDetail = hooks.useAppSelector(selectors.scooter.scooterDetail);

  const [scooterLocation, setScooterLocation] = useState<MapsLocation | null>(null);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  useEffect(() => {
    const query = ref(db, `VIN-${ params?.scooterId }/location`);
    const unsubscribe = onValue(
      query, snapshot => {
        const data = snapshot.val();

        if (snapshot.exists()) {
          const lat = data?.latitude;
          const lng = data?.longitude;

          if (lat !== 0 && lng !== 0) {
            setScooterLocation({
              lat: data?.latitude,
              lng: data?.longitude,
            });
          }
        }

        unsubscribe();
      }, error => {
        // eslint-disable-next-line no-undef, no-console
        console.log('error subscribe realtime database', JSON.stringify(error));
      }
    );

    return unsubscribe;
  }, []);

  const onClickGoBack = () => navigation.goBack();

  const renderBoxScooterInfo = widthBox => {
    return <BoxScooterInfo widthBox={ widthBox } routeId={ params?.scooterId } />;
  };

  const renderBoxStations = widthBox => {
    return <BoxStations widthBox={ widthBox } routeId={ params?.scooterId } />;
  };

  const renderBoxTripHistory = widthBox => {
    return (
      <BoxTripHistory widthBox={ widthBox } routeId={ params?.scooterId } />
    );
  };

  const renderBoxLocation = widthBox => {
    return (
      <BoxLocation
        widthBox={ widthBox }
        onClickLiveTracking={ onClickLiveTracking }
        scooterLocation={ scooterLocation }
      />
    );
  };

  const renderBoxStats = widthBox => {
    return <BoxStats widthBox={ widthBox } routeId={ params?.scooterId } />;
  };

  const renderContent = () => {
    const { width: screenWidth } = windowDimensions;
    const widthContent = screenWidth - 240;

    let widthBox: WidthBox = {
      scooterInfo: '100%',
      batteryStats: screenWidth - 60,
      stations: '100%',
      tripHistory: '100%',
      location: '100%'
    };

    if (screenWidth <= screen.sizes.sx) {
      return (
        <div>
          { renderBoxScooterInfo(widthBox.scooterInfo) }
          { renderBoxStats(widthBox.batteryStats) }
          { renderBoxStations(widthBox.stations) }
          { renderBoxLocation(widthBox.location) }
          { renderBoxTripHistory(widthBox.tripHistory) }
        </div>
      );
    } else {
      const boxScooterConfig: BoxScooterConfig = { mr: 0 };

      if (screenWidth >= screen.sizes.md2) {
        boxScooterConfig.mr = 20;

        widthBox = {
          scooterInfo: widthContent / 3.1,
          batteryStats: widthContent / 2.7,
          stations: widthContent / 4,
          tripHistory: widthContent / 4,
          location: widthContent / 2.7
        };
      } else if (screenWidth > screen.sizes.sx && screenWidth < screen.sizes.md2) {
        widthBox = {
          scooterInfo: '100%',
          batteryStats: widthContent / 1.6,
          stations: widthContent / 3.1,
          tripHistory: widthContent / 3.1,
          location: widthContent / 1.6
        };
      }

      return (
        <div className='content-container'>
          <Spacer mr={ boxScooterConfig.mr }>
            { renderBoxScooterInfo(widthBox.scooterInfo) }
          </Spacer>
          <div>
            <div className='flex row'>
              { renderBoxStats(widthBox.batteryStats) }
              <div className='ml4'>
                { renderBoxStations(widthBox.stations) }
              </div>
            </div>
            <div className='flex row'>
              <div className='mr4'>
                { renderBoxTripHistory(widthBox.tripHistory) }
              </div>
              { renderBoxLocation(widthBox.location) }
            </div>
          </div>
        </div>
      );
    }
  };

  const onClickLiveTracking = () => {
    if (scooterDetail.parent || scooterDetail.childs?.length) {
      navigation.push(`/dashboard/inventory/${ params?.scooterId }/live-tracking`);
    } else {
      toastify.error('Live tracking tidak tersedia');
    }
  };

  return (
    <Container
      navbar={ {
        backButton: 'circle',
        onClickBack: onClickGoBack
      } }
      headerContent={ {
        textHeader: 'SAVART Details',
        textBtn: 'Live Tracking',
        iconNameBtn: 'location',
        btnDisabled: !scooterDetail?.parent && !scooterDetail?.childs?.length,
        permissionBtn: [Permission.inventory_detail],
        onClickBtn: onClickLiveTracking
      } }
      selectedMenu={ 3 }
      withDrawer
      openSubmenu
    >
      <ScooterDetailStyle>
        { renderContent() }
      </ScooterDetailStyle>
    </Container>
  );
};

export default ScooterDetail;
