import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { auth } from 'helpers';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = auth.getAuthCredentials('access_token');

  return (
    <Route
      { ...rest }
      render={ props => {
        return token
        ? <Component { ...props } />
        : <Redirect to={ { pathname: '/login' } } />;
      } }
    />
  );
};

export default PrivateRoute;
