import React, { useState, useRef } from 'react';
import { Select } from 'antd';
import { RefSelectProps } from 'antd/es/select';

import { Colors } from 'consts';

import { SearchInputStyle, LoaderStyle } from './style';

import Loader from '../../Loader';
import Text from '../../Text';

const { Option } = Select;

export type DataOption = {
  value: any;
  text: string;
};

type SearchInputProps = {
  data: DataOption[];
  label?: string;
  labelSize?: string;
  labelColor?: string;
  value: any;
  placeholder?: string;
  style?: React.CSSProperties;
  backgroundColor?: string;
  disabled?: boolean;
  loading?: boolean;
  searchValue?: string;
  onSearch?: (value: any) => void;
  onChange: (value: any) => void;
  errorMessage?: string;
  onDropdownVisibleChange?: (open: boolean) => void;
};

const SearchInput: React.FC<SearchInputProps> = ({
  data,
  value,
  label,
  labelSize,
  labelColor,
  placeholder,
  style,
  backgroundColor,
  disabled,
  loading,
  searchValue,
  onSearch,
  onChange,
  errorMessage,
  onDropdownVisibleChange
}) => {
  const inputRef = useRef<RefSelectProps>(null);
  const [focus, setFocus] = useState<boolean>(false);

  const renderTextOption = (text: string) => {
    return <Text lineHeight={ 24 }>{ text }</Text>;
  };

  const options = data.map(option => <Option key={ option.value } value={ option.value }>{ renderTextOption(option.text) }</Option>);

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  const renderLabel = () => {
    if (label) {
      const textColor = focus ? Colors.blue.isBlue : labelColor;

      return (
        <Text
          size={ labelSize }
          mb={ 10 }
          lineHeight={ 17 }
          weight={ 500 }
          color={ textColor }
          text={ label }
        />
      );
    }

    return null;
  };

  const renderLoader = () => {
    return (
      <LoaderStyle>
        <Loader className='col center-content' style={ { height: 100 } } />
      </LoaderStyle>
    );
  };

  const renderErrorText = () => {
    if (errorMessage) {
      return (
        <Text
          size='xxs'
          mt={ 5 }
          color={ Colors.red.isTextRed }
        >{ errorMessage }</Text>
      );
    }

    return null;
  };

  return (
    <SearchInputStyle
      focus={ focus }
      backgroundColor={ backgroundColor }
      disabled={ disabled }
    >
      { renderLabel() }

      <Select
        ref={ inputRef }
        showSearch
        value={ (value || undefined) }
        placeholder={ <Text color={ Colors.grey.isGrey } mt={ 13 }>{ placeholder }</Text> }
        style={ {
          width: '100%',
          ...style
        } }
        listHeight={ 100 }
        defaultActiveFirstOption={ false }
        showArrow={ false }
        filterOption={ false }
        searchValue={ searchValue }
        onSearch={ onSearch }
        onChange={ onChange }
        onFocus={ onFocus }
        onBlur={ onBlur }
        onDropdownVisibleChange={ onDropdownVisibleChange }
        className='search-input'
        dropdownStyle={ { borderRadius: 10 } }
        disabled={ disabled }
        getPopupContainer={ (triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement }
      >
        {
          loading
            ? renderLoader()
            : options
        }
      </Select>

      { renderErrorText() }
    </SearchInputStyle>
  );
};

SearchInput.defaultProps = {
  value: '',
  placeholder: '',
  labelSize: 's',
  labelColor: Colors.black.isTextBlack,
  backgroundColor: Colors.white.default
};

export default SearchInput;
