import React, { useState } from 'react';

import { Colors, Images } from 'consts';
import { hooks, roleHelper } from 'helpers';
import { selectors } from 'store/selectors';
import { Topic } from 'interfaces/common';

import Text from '../Text';
import Icon from '../Icon';

import { TopicMenuStyle, MenuListStyle } from './style';

type TopicMenuProps = {
  topics: Topic[],
  selectedKey: number;
  changeSelectedKey: (topic?: any) => void;
  openSubmenu?: boolean;
};

const TopicMenu: React.FC<TopicMenuProps> = ({
  topics,
  selectedKey,
  changeSelectedKey,
  openSubmenu
}) => {
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);

  const [openSubmenuList, setOpenSubmenuList] = useState<boolean>(openSubmenu || false);

  const renderIconArrowSubmenu = (lengthSubmenu: number) => {
    if (lengthSubmenu) {
      return (
        <Icon
          iconName='arrowDown'
          size={ 13 }
          fill={ Colors.blue.isBlue }
          className='ic-arrow'
        />
      );
    }
  };

  const onClickSubmenuList = (submenu: Topic) => {
    changeSelectedKey(submenu);
  };

  const renderSubmenuList = (submenus: Topic[]) => {
    if (submenus.length && openSubmenuList) {
      const filteredSubmenus = submenus.filter((submenu: Topic) => roleHelper.isPermissible(adminPermissions || [], submenu.permissions || []));

      return filteredSubmenus.map((submenu: Topic, index: number) => {
        return (
          <MenuItem
            key={ index }
            topic={ submenu }
            onClick={ () => onClickSubmenuList(submenu) }
          />
        );
      });
    }
  };

  const onClickMenuList = (topic: Topic) => {
    if (topic.submenus.length) {
      setOpenSubmenuList(!openSubmenuList);
    }

    changeSelectedKey(topic);
  };

  const MenuItem = props => {
    let isSelected = Number(selectedKey) === props.topic.key;
    if (props.topic.title === 'SAVART') {
      isSelected =
        Number(selectedKey) === 3 ||
        Number(selectedKey) === 4 ||
        Number(selectedKey) === 5;
    }

    const textColor = isSelected
      ? props.topic.title === 'SAVART'
        ? Colors.black.isBlack
        : Colors.blue.isBlue
      : Colors.grey.isGrey;
    const Icon = Images.icons[props.topic.icName];

    return (
      <MenuListStyle
        isSelected={ isSelected }
        title={ props.topic.title }
        openSubmenu={ openSubmenuList }
      >
        <div className='ic-container' onClick={ props.onClick }>
          {
            props.topic.icName
              ? <Icon className='ic-menu-list' />
              : <div className='ic-menu-list' />
          }
        </div>
        <div className='menu-list' onClick={ props.onClick }>
          <Text
            weight={ isSelected ? 700 : 500 }
            color={ textColor }
            lineHeight={ 19 }
            className='p1 text-menu'
          >{ props.topic.title }</Text>
          { renderIconArrowSubmenu(props.topic.submenus.length) }
        </div>
      </MenuListStyle>
    );
  };

  const renderTopicMenus = () => {
    return topics.map((topic: Topic, index: number) => {
      return (
        <div key={ index }>
          <MenuItem topic={ topic } onClick={ () => onClickMenuList(topic) } />
          { renderSubmenuList(topic.submenus) }
        </div>
      );
    });
  };

  return (
    <TopicMenuStyle>
      { renderTopicMenus() }
    </TopicMenuStyle>
  );
};

TopicMenu.defaultProps = { openSubmenu: false };

export default TopicMenu;