import { Notification } from 'interfaces/notification';

export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const LOGOUT = 'LOGOUT';

export interface SetAllNotification {
	type: typeof GET_NOTIFICATION_LIST;
	data: Notification[];
}

export interface SetNotification {
	type: typeof ADD_NOTIFICATION;
	data: Notification;
}

export interface Logout {
	type: typeof LOGOUT;
}

export type NotifActionTypes = SetAllNotification
	| SetNotification
	| Logout;
