import Styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { Colors, Fonts } from 'consts';

export const StyledContainer: any = Styled(ToastContainer)`
	&&&.Toastify__toast-container {}
	.Toastify__toast {
		width: 400px;
		min-height: 40px;
		border-radius: 15px;
		cursor: pointer;
		left: 50%;
		margin-left: -200px;
	}

	.Toastify__toast--error {
		background: ${ Colors.toast.error.primary };
		border: 1px solid ${ Colors.red.isRedOpacity('0.2') };
		box-shadow: 0px 4px 10px ${ Colors.toast.error.secondary('0.15') };
		color: ${ Colors.red.isRed };
	}

	.Toastify__toast--success {
		background-color: ${ Colors.toast.success.secondary };
		border: 1px solid ${ Colors.toast.success.primary('1') };
		box-shadow: 0px 4px 10px ${ Colors.toast.success.primary('0.15') };
		color: ${ Colors.toast.success.primary('1') };
	}

	.Toastify__toast-body {
		font-family: ${ Fonts.primary.interBold };
		font-size: 16px;
		line-height: 19px;
	}

	.Toastify__toast-icon {
		width: 30px;
	}

	@media only screen and (max-width: 480px) {
		.Toastify__toast {
			width: 100vw;
			left: 0;
			margin-left: 0;
		}

		.Toastify__toast-body {
			font-size: 14px;
			line-height: 15px;
		}
	}
`;