import { Pagination } from 'interfaces/common';
import { Admin, AdminDetail } from 'interfaces/admin';

export const GET_ALL_ADMIN = 'GET_ALL_ADMIN';
export const GET_ADMIN_DETAIL = 'GET_ADMIN_DETAIL';
export const LOGOUT = 'LOGOUT';

export interface SetAllAdmin {
	type: typeof GET_ALL_ADMIN;
  data: {
    data: Admin[];
    pagination: Pagination;
  }
}

export interface SetAdmin {
  type: typeof GET_ADMIN_DETAIL;
  data: AdminDetail;
}

export interface Logout {
	type: typeof LOGOUT;
}

export type AdminActionTypes = SetAllAdmin | SetAdmin | Logout;