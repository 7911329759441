import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { convertToFormData } from 'helpers/api';
import { ApiData, CommonResponseData } from 'interfaces/common';
import {
  FormUser,
  FormShowroomAssignment,
  FormScooterAssignment,
  User,
  FormUploadLegal,
  UserApiParams
} from 'interfaces/user';

export const getAllUser = async(params: UserApiParams) => {
  try {
    const queryParams: UserApiParams = {
      ...params,
      is_active: params.is_active || '',
      sort: params.sort || 'asc',
      order: params.order || 'name'
    };

    const response: ApiData<User[]> = await customFetch(Endpoints.getAllUser(), queryParams);

    return response;
  } catch (error) {
    throw error;
  }
};

export const addUser = async(formAddUser: FormUser) => {
  try {
    const response: ApiData<CommonResponseData> = await customFetch(Endpoints.addUser(), formAddUser);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editUser = async(userId: string, formEditUser: FormUser,) => {
  try {
    const body = await convertToFormData(formEditUser);
    const response: ApiData<string> = await customFetch(Endpoints.editUser(userId), body);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserDetail = async(userId: string) => {
  try {
    const response: ApiData<User> = await customFetch(Endpoints.getUserDetail(userId));

    return response;
  } catch (error) {
    throw error;
  }
};

export const showroomAssignment = async(userId: string, formShowroom: FormShowroomAssignment) => {
  try {
    const response: ApiData<string> = await customFetch(Endpoints.showroomAssignment(userId), formShowroom);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editShowroomAssignment = async(userId: string, formShowroom: FormShowroomAssignment) => {
  try {
    const response: ApiData<string> = await customFetch(Endpoints.editShowroomAssignment(userId), formShowroom);

    return response;
  } catch (error) {
    throw error;
  }
};

export const scooterAssignment = async(parentId: string, formScooterAssignment: FormScooterAssignment) => {
  try {
    const response: ApiData<string> = await customFetch(Endpoints.scooterAssignment(parentId), formScooterAssignment);

    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadLegalInfo = async(userId: string, formUploadLegal: FormUploadLegal) => {
  try {
    const body = await convertToFormData(formUploadLegal);
    const response: ApiData<string> = await customFetch(Endpoints.uploadLegalInfo(userId), body);

    return response;
  } catch (error) {
    throw error;
  }
};

export const verifyLegalInfo = async(userId: string) => {
  try {
    const response: ApiData<string> = await customFetch(Endpoints.verifyLegalInfo(userId), { is_verify: true });

    return response;
  } catch (error) {
    throw error;
  }
};
