import Styled from 'styled-components';

import { screen } from 'helpers';

export const BoxVisitorsStyle: any = Styled.div `
  .pie-chart-outer {
    height: 280px;
  }

  @media ${screen.sizesMax.xs} {
    .pie-chart-outer {
      height: 200px;
    }
  }
`;