import Styled from 'styled-components';

type BadgeStyleProps = {
  color: string;
  border: string;
  offset: [number, number]; // [left, top]
  size: number;
};

export const BadgeStyle: any = Styled.div < BadgeStyleProps > `
  background-color: ${props => props.color};
  width: ${props => props.size }px;
  height: ${props => props.size }px;
  border-radius: 100%;
  border: ${props => props.border};
  margin-left: ${props => -10 + props.offset[0]}px;
  margin-top: ${props => props.offset[1]}px;
  z-index: 100 !important;
`;