import React from 'react';
import { Layout } from 'antd';

import Logo from '../Logo';

import { SidebarStyle } from './style';

type SidebarProps = {
  menu: React.ReactNode;
};

const SideBar: React.FC<SidebarProps> = ({ menu }) => {
  return (
    <SidebarStyle>
      <Layout.Sider
        className='sidebar'
        breakpoint='lg'
        theme='light'
        collapsedWidth={ 0 }
        trigger={ null }
      >
        <div className='logo-container'>
          <Logo
            width={ 146 }
            height={ 40 }
            type='logo_text'
          />
        </div>
        { menu }
      </Layout.Sider>
    </SidebarStyle>
  );
};

export default SideBar;