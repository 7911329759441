import React from 'react';
import { Space } from 'antd';

import { Colors } from 'consts';

import Modal from '../../Modal';
import Text from '../../Text';
import Button from '../../Button';
import Icon from '../../Icon';

type ModalConfirmProps = {
  visible: boolean;
  onCloseModal: () => void;
  confirmText?: string;
  confirmButtonWidth?: number;
  confirmButtonColor?: 'black' | 'green' | 'blue' | 'red' | 'pink' | 'yellow' | 'grey';
  loadingConfirm?: boolean;
  loaderColor?: 'black' | 'blue' | 'lightGrey';
  title?: string;
  content?: string;
  onClickConfirm?: () => void;
  withButtonCancel?: boolean;
};

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  visible,
  onCloseModal,
  confirmButtonWidth,
  confirmButtonColor,
  confirmText,
  loadingConfirm,
  loaderColor,
  title,
  content,
  onClickConfirm,
  withButtonCancel = true
}) => {
  return (
    <Modal
      visible={ visible }
      onCloseModal={ onCloseModal }
      modalType='long-scroll'
      padding={ 30 }
      bodyStyle={ {
        marginTop: 50,
        marginBottom: 30
      } }
      width={ 500 }
    >
      <div className='col col-end mb1'>
        <Icon
          size={ 16 }
          iconName='close'
          fill={ Colors.grey.isGrey }
          onClick={ onCloseModal }
        />
      </div>
      <div className='col center-content'>
        <Space
          size='large'
          direction='vertical'
          align='center'
        >
          <Icon type='swal-icon' iconName='warning' />
          <Text
            size='xl'
            weight={ 700 }
            align='center'
            color={ Colors.black.isBlackOpacity('0.7') }
          >{ title }</Text>
          <Text color={ Colors.grey.isGrey }>{ content }</Text>
        </Space>
      </div>
      <div className='col center-content mt6'>
        <div className='flex row align-center'>
          <Space size='middle'>
            <Button
              height={ 43 }
              width={ confirmButtonWidth }
              bgColor={ confirmButtonColor }
              fontWeight={ 700 }
              size='m'
              onClick={ onClickConfirm }
              isLoading={ loadingConfirm }
              loaderColor={ loaderColor }
              text={ confirmText }
            />
            { withButtonCancel && (
              <Button
                height={ 43 }
                width={ 100 }
                bgColor='black'
                fontWeight={ 700 }
                size='m'
                onClick={ onCloseModal }
                text='Cancel'
              />
            ) }
          </Space>
        </div>
      </div>
    </Modal>
  );
};

ModalConfirm.defaultProps = {
  confirmText: 'Ya',
  confirmButtonWidth: 70,
  confirmButtonColor: 'red',
  title: 'Are you sure?',
  content: 'Apakah anda yakin ingin menghapus data ini?',
  loaderColor: 'black',
};

export default ModalConfirm;
