import React from 'react';
import { Popover } from 'antd';

import { Colors } from 'consts';
import { misc } from 'helpers';

import ButtonContact from '../Button/ButtonContact';
import Text from '../Text';
import Image from '../Image';
import Badge from '../Badge';

type LinkedUsersProps = {
  mt?: number;
  mb?: number;
  name: string;
  status: string;
  image?: any;
  phone?: string;
  styleType?: 'primary' | 'secondary';
};

const LinkedUsers: React.FC<LinkedUsersProps> = ({
  mt = 0,
  mb = 0,
  name,
  status,
  image,
  phone,
  styleType = 'primary'
}) => {
  const renderAvatar = () => {
    return (
      <Badge offset={ [0, 25] } color={ Colors.blue.isBlue }>
        <Image
          src={ `https://api-dev.savart-ev.com/v1/profiles/photo/${image}` }
          width={ 40 }
          height={ 40 }
          className='cover'
          type='circle'
        />
      </Badge>
    );
  };

  const renderUserStylePrimary = () => {
    return (
      <div className={ `flex row align-center mt${ mt } mb${ mb }` }>
        <div className='flex row'>
          { renderAvatar() }

          <div className='ml2 wrap' style={ { width: '100%' } }>
            <Text
              weight={ 700 }
              mb={ 5 }
              className='p1 wrap'
            >{ name }</Text>
            <Text
              size='xs'
              color={ Colors.grey.isGrey }
              className='p1 wrap'
            >{ misc.titleCase(status ? status : '-') }</Text>
          </div>
        </div>
      </div>
    );
  };

  const overlayUser = () => {
    return (
      <div className='flex align-center gap4'>
        { renderUserStylePrimary() }

        <ButtonContact
          phone={ phone || '' }
          withText={ false }
          mt={ 0 }
        />
      </div>
    );
  };

  const renderDataUser = () => {
    if (styleType === 'primary') {
      return renderUserStylePrimary();
    }

    return (
      <Popover
        placement='top'
        content={ overlayUser }
        trigger='click'
        overlayInnerStyle={ { borderRadius: 10 } }
      >
        <div className='pointer'>
          { renderAvatar() }
        </div>
      </Popover>
    );
  };

  return renderDataUser();
};

export default LinkedUsers;
