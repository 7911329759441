import React, { useEffect } from 'react';

import { BoxChart, LineChart } from 'components';
import { FilterMenuChartProps } from 'components/Box/BoxChart';
import { hooks, misc } from 'helpers';
import { DischargeTempApiParams, DischargeTempStatistic } from 'interfaces/battery';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { setFilterTimeApiParams, convertLabelTooltip } from '../../helpers';

import { getDataConverted } from './helpers';

type BoxDischargeProps = {
  widthBox: number;
  filterMenu: FilterMenuChartProps;
  routeId?: string;
};

const BoxDischarge: React.FC<BoxDischargeProps> = ({
  widthBox,
  filterMenu,
  routeId
}) => {
  const dispatch = hooks.useAppDispatch();
  const getDischargeBatteryPack = dispatch(actions.getDischargeBatteryPack);

  const listDischarge = hooks.useAppSelector(selectors.battery.listDischarge);
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);

  const loadingDischarge = misc.isLazyLoading(lazyLoad, 'dischargeBatteryPack');
  const activeFilterTime = filterMenu.activeFilter.discharge_time;

  const getListDataDischarge = (callback?: (listDischarge: DischargeTempStatistic[]) => void) => {

    const params: DischargeTempApiParams = { report_date: setFilterTimeApiParams(activeFilterTime) };

    getDischargeBatteryPack(
      routeId ?? '',
      params,
      callback
    );
  };

  const convertDataChart = arr => {
    return getDataConverted(arr, filterMenu.activeFilter.discharge_time);
  };

  useEffect(() => {
    if (routeId) {
      getListDataDischarge();
    }
  }, [filterMenu.activeFilter.discharge_time]);

  const renderLineChart = () => {

    const dataChartConvert = convertDataChart(listDischarge);
    const maxValue = Math.max(...dataChartConvert?.map(o => o.current));
    
    return (
      <LineChart
        width={ widthBox - 40 }
        data={ dataChartConvert }
        dataKey={ {
          xAxis: 'hours',
          line: 'current'
        } }
        unit=' Amp'
        convertLabel={ (_, payload) => convertLabelTooltip(activeFilterTime, payload) }
        valueDomain={ [0, maxValue ? maxValue : 0.5] }
        margin={ { left: 10 } }
        maxCharsLabel={ 5 }
        yAxisWidth={ 70 }
      />
    );
  };

  return (
    <BoxChart
      title='Discharge'
      width={ widthBox }
      filterMenu={ filterMenu }
      chart={ renderLineChart() }
      mt={ 20 }
      mr={ 20 }
      loading={ loadingDischarge }
      empty={ !Object.values(listDischarge)?.length }
    />
  );
};

export default BoxDischarge;
