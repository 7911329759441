import Styled, { keyframes } from 'styled-components';

import { Colors } from 'consts';

type LabelProps = {
  disabled?: boolean;
  checked?: boolean;
};

type IndicatorProps = {
  checked?: boolean;
};

export const Input: any = Styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

export const Label: any = Styled.label < LabelProps > `
  position: relative;
  display: inline-block;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: 0 1.6em;

  .text-checkbox {
    color: ${props => props.checked ? Colors.black.isBlack : Colors.grey.isGrey};
  }
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const Indicator: any = Styled.div < IndicatorProps > `
  width: 1.2em;
  height: 1.2em;
  background: ${props => props.checked ? Colors.blue.isBlue : Colors.white.default};
  position: absolute;
  top: 0em;
  left: -1.6em;
  border: 1.5px solid ${Colors.grey.isLightGrey};
  border-radius: 0.2em;

  ${Input}:not(:disabled):checked & {
    background: #d1d1d1;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0.1em;
    left: 0.35em;
    width: 35%;
    height: 70%;
    border: solid ${Colors.white.default};
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;
