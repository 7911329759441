import {
  Scooter,
  ScooterInfo,
  VariantScooter,
  ScooterLocation,
  BatteryUsage,
  DataTripHistory,
  DataStationsVisited
} from 'interfaces/scooter';
import { Pagination } from 'interfaces/common';

export const GET_SCOOTERS = 'GET_SCOOTERS';
export const GET_SCOOTER_DETAIL = 'GET_SCOOTER_DETAIL';
export const GET_VARIANT_SCOOTERS = 'GET_VARIANT_SCOOTERS';
export const GET_SCOOTER_LOCATION = 'GET_SCOOTER_LOCATION';
export const GET_SCOOTER_BATTERY_USAGES = 'GET_SCOOTER_BATTERY_USAGES';
export const GET_TRIP_HISTORY_SCOOTER = 'GET_TRIP_HISTORY_SCOOTER';
export const GET_VISITED_STATION = 'GET_VISITED_STATION';
export const LOGOUT = 'LOGOUT';

export interface SetAllScooter {
  type: typeof GET_SCOOTERS;
  data: {
    data: Scooter[],
    pagination: Pagination;
  };
}

export interface SetScooter {
  type: typeof GET_SCOOTER_DETAIL;
  data: ScooterInfo;
}

export interface SetAllVariantScooter {
  type: typeof GET_VARIANT_SCOOTERS;
  data: {
    data: VariantScooter[];
    pagination: Pagination;
  };
}

export interface SetScooterLocation {
  type: typeof GET_SCOOTER_LOCATION;
  data: ScooterLocation[];
}

export interface SetBatteryUsage {
  type: typeof GET_SCOOTER_BATTERY_USAGES;
  data: BatteryUsage;
}

export interface SetTripHistory {
  type: typeof GET_TRIP_HISTORY_SCOOTER;
  data: {
    data: DataTripHistory[];
    pagination: Pagination;
  };
}

export interface SetStationVisited {
  type: typeof GET_VISITED_STATION;
  data: {
    data: DataStationsVisited[];
    pagination: Pagination;
  };
}

export interface Logout {
  type: typeof LOGOUT;
}

export type ScooterActionTypes =
  SetAllScooter |
  SetScooter |
  SetAllVariantScooter |
  SetScooterLocation |
  SetBatteryUsage |
  SetTripHistory |
  SetStationVisited |
  Logout;
