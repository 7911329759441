import React from 'react';
import { Empty } from 'antd';

import {
	Text,
	Button,
	Modal as CustomModal,
	Image as CustomImage
} from 'components';
import { convertColorStatus as convertColorScooterStatus } from 'components/DataTable/helpers';
import { Colors, Images } from 'consts';
import { hooks, toastify } from 'helpers';
import { DataTicketing, ImageCase } from 'interfaces/ticketing';
import { DetailNavProps } from 'typings';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { ModalDetailStyles } from './style';

type ModalDetailCaseProps = {
	record: DataTicketing;
	visible: boolean;
	closeModal: () => void;
	setGoTo: React.Dispatch<React.SetStateAction<DetailNavProps>>;
};

type ItemDetail = {
	title: string;
	value: string;
};

const RenderModalDetailCase: React.FC<ModalDetailCaseProps> = ({
	visible,
	closeModal,
	record,
	setGoTo
}) => {
	const dispatch = hooks.useAppDispatch();

	const getUserDetail = dispatch(actions.getUserDetail);
	const getScooterDetail = dispatch(actions.getScooterDetail);

	const detailCase = hooks.useAppSelector(selectors.case.detailCase);

	const onClickUserDetail = () => {
		setGoTo({
			progress: 0,
			id: record?.cust_id?.toString(),
			route: `/dashboard/users/${ record?.cust_id?.toString() }`
		});

		getUserDetail(record?.cust_id?.toString(), () => {
			setGoTo(prevState => ({
				...prevState,
				progress: 100
			}));
		});
	};

	const onClickScooterDetail = () => {
		setGoTo({
			progress: 0,
			id: `${ record?.vin_id }`, // VIN is Scooter ID
			route: `/dashboard/inventory/${ record?.vin_id }`,
		});

		getDataBeforeToDetailPage(`${ record?.vin_id }`);
	};

	const handleReadyGoToScooterDetail = () => {
		setGoTo(prevState => ({
			...prevState,
			progress: 100
		}));
	};

	const getDataBeforeToDetailPage = async(vin: string) => {
		try {
			getScooterDetail(vin, () => {
				handleReadyGoToScooterDetail();
			});
		} catch (error) {
			toastify.errorDefaultApi();
		}
	};

	const renderScooterImage = () => {
		return (
			<CustomImage
				src={ record?.scooter_images
					? record?.scooter_images
					: Images.scooter1 }
				width={ 120 }
				height={ 120 }
				external={ !!record?.scooter_images }
			/>
		);
	};

	const setTitleModal = () => {
		switch (record?.status) {
			case 'solved':
				return 'Solved';
			case 'unsolved':
				return 'Unsolve Issue';
			case 'pending':
			default:
				return 'Ticket Details';
		}
	};

	const renderCaseUserInfo = () => {
		const dataScooter: ItemDetail[] = [
			{
				title: 'ID',
				value: `${ record?.vin_id ?? '-' }`
			},
			{
				title: 'Type',
				value: `${ record?.scooter_type ?? '-' }`
			},
			{
				title: 'Color',
				value: `${ record?.scooter_color ?? '-' }`
			},
			{
				title: 'Plat Number',
				value: `${ record?.plat_number ?? '-' }`
			},
			{
				title: 'Status',
				value: `${ record?.scooter_status ?? '-' }`
			}
		];

		return (
			<>
				<div>
					<Text
						text={ `ID: ${ record?.case_code }` }
						size='m'
						weight={ 700 }
						color={ Colors.grey.isGreyGreen }
						mt={ 5 }
					/>

					<Text
						text={ record?.category_name }
						size='m'
						weight={ 700 }
						mt={ 20 }
					/>

					<Text
						text={ record?.description }
						size='s'
						weight={ 500 }
						mt={ 10 }
					/>
				</div>

				<div className='customer' >
					<div className='flex'>
						<div>
							<CustomImage
								src={ record?.cust_image ?? Images.avatar }
								borderRadius={ 100 }
								width='80px'
								height='80px'
								external
							/>
						</div>
						<div>
							<Text
								text={ record?.cust_name ?? 'Uknown user' }
								weight={ 700 }
								size='m'
							/>
							<Text
								text={ record?.cust_email ?? '-' }
								weight={ 500 }
								size='s'
								color={ Colors.grey.isGrey }
								mt={ 10 }
							/>
							<Text
								text={ record?.cust_phone ?? '-' }
								weight={ 500 }
								size='s'
								color={ Colors.blue.isBlue }
								mt={ 10 }
							/>
						</div>
					</div>

					<Button
						width={ 151 }
						styleType='inverse'
						onClick={ onClickUserDetail }
						text='View User Details'
						size='s'
						fontWeight={ 700 }
						borderRadius={ 5 }
					/>
				</div>

				<div className='scooter-image'>
					{ renderScooterImage() }
				</div>

				<div className='list scooter-information'>
					<Text
						text='Information'
						weight={ 700 }
						size='m'
					/>

					{ dataScooter.map((item: ItemDetail, index: number) => {
						return (
							<div key={ index } className='item'>
								<Text text={ item.title } color={ Colors.grey.isGrey } />
								<Text text={ item.value } color={ item.title === 'Status'
									? convertColorScooterStatus(item.value)
									: Colors.black.isBlack } />
							</div>
						);
					}) }
				</div>

				<div className='btn'>
					<Button
						styleType='inverse'
						onClick={ onClickScooterDetail }
						text='View SAVART Details'
						size='s'
						fontWeight={ 700 }
						borderRadius={ 5 }
					/>
				</div>
			</>
		);
	};

	const renderDetailCase = () => {
		const dataCase: ItemDetail[] = [
			{
				title: 'Ticket ID',
				value: record?.case_code
			},
			{
				title: 'Problem',
				value: record?.category_name
			},
			{
				title: 'Customer',
				value: record?.cust_name || '-'
			}
		];

		return (
			<div className='list'>
				{ dataCase.map((item: ItemDetail, index: number) => {
					return (
						<div key={ index } className='item'>
							<Text
								text={ item.title }
								color={ Colors.grey.isGrey }
								size='xs'
							/>
							<Text text={ item.value || '-' } size='xs' />
						</div>
					);
				}) }
			</div>
		);
	};

	const renderSolvedCase = () => {
		const filteredSolvedImages = detailCase?.images?.filter((image: ImageCase) => image && image.image_url);

		return (
			<>
				{ renderDetailCase() }

				<div className='mt4 mb4'>
					<Text
						size='m'
						weight={ 700 }
						text='Upload Prove'
					/>
				</div>

				{ filteredSolvedImages?.length
					? (
						<div className='solved-images'>
							{ filteredSolvedImages.map((image: ImageCase, index: number) => (
								<div key={ index }>
									<CustomImage
										src={ image.image_url }
										width={ 100 }
										height={ 100 }
										type='square'
										borderRadius={ 5 }
										className='cover'
										external
										preview
									/>
								</div>
							)) }
						</div>
					)
					: <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } description='Tidak ada gambar yang diunggah' /> }

				<div className='mt4 case-solving-steps'>
					<Text color={ Colors.grey.isGrey } text='Case Solving Steps (Optional)' />

					<div className='container-solving-description'>
						<Text text={ detailCase?.note || '-' } />
					</div>
				</div>
			</>
		);
	};

	const renderContentModalDetail = () => {
		switch (record?.status) {
			case 'solved':
				return renderSolvedCase();
			case 'unsolved':
				return renderDetailCase();
			case 'pending':
			default:
				return renderCaseUserInfo();
		}
	};

	return (
		<CustomModal
			visible={ visible }
			onCloseModal={ closeModal }
			title={ () => (
				<Text
					text={ setTitleModal() }
					size='xl'
					weight={ 700 }
				/>
			) }
			bodyStyle={ {
				marginTop: 50,
				marginBottom: 30
			} }
			width={ 500 }
			modalType='long-scroll'
			padding={ record?.status === 'solved' || record?.status === 'unsolved' ? 30 : 20 }
		>
			<ModalDetailStyles>
				{ renderContentModalDetail() }
			</ModalDetailStyles>
		</CustomModal>
	);
};

export default RenderModalDetailCase;
