/* eslint-disable no-undef */
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import moment, { Moment } from 'moment';
import {
  DatePicker,
  Radio,
  RadioChangeEvent,
  TimePicker
} from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import { Colors, Themes } from 'consts';
import { currencyFormat } from 'helpers/misc';
import { ReactComponent as Clock } from 'assets/images/icon/clock.svg';
import { ReactComponent as Calendar } from 'assets/images/icon/calendar.svg';

import Text from '../Text';
import Icon from '../Icon';

import TextArea from './TextArea';

import {
  InputStyle,
  IconInputStyle,
  DatePickerStyle,
  RadioGroupStyle
} from './style';

export type InputProps = {
  inputFocus?: boolean;
  value?: string | number;
  placeholder?: string;
  label?: string;
  labelSize?: string;
  labelColor?: string;
  backgroundColor?: string;
  type?: string;
  onClick?: () => void;
  onChangeFocus?: (focused: boolean) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDate?: (value: string) => void;
  onChangeRadio?: (e: RadioChangeEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  defaultPickerValue?: Moment;
  name?: string;
  mb?: number;
  borderRadius?: number;
  width?: number | string;
  iconName?: string;
  iconClassName?: string;
  iconPosition?: 'left' | 'right';
  size?: string;
  weight?: number;
  themes?: string;
  lineHeight?: number;
  errorMessage?: string;
  disabled?: boolean;
  disabledDateTime?: boolean;
  textAreaAutoResize?: boolean;
  radioValue?: string[] | undefined;
  weightLabel?: number;
  renderSuffixLabel?: () => JSX.Element; // eslint-disable-line no-undef
  renderPrefixInput?: () => JSX.Element; // eslint-disable-line no-undef
  renderSuffixInput?: () => JSX.Element; // eslint-disable-line no-undef
  labelClassName?: string;
  inputClassName?: string;
};

const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm:ss';

const disabledDate = (current: Moment) => {
  // Can not select days after today
  return current > moment().endOf('day');
};

const range = (start: number, end: number): number[] => {
  const result: number[] = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const disabledHours = (
  disabledDateTime: boolean,
  hour: number,
  minute: number
) => {
  if (disabledDateTime) {
    if (hour < 23) {
      let start = hour;
      if (minute !== 0) {
        start += 1;
      }

      return range(start, 24);
    }
  }

  return [];
};

const disabledMinutes = (
  disabledDateTime: boolean,
  selectedHour: number,
  hour: number,
  minute: number
) => {
  if (selectedHour === hour && disabledDateTime) {
    return range(minute + 1, 60);
  }

  return [];
};

const disabledSeconds = (
  disabledDateTime: boolean,
  selectedHour: number,
  selectedMinute: number,
  hour: number,
  minute: number,
  second: number
) => {
  if (
    selectedHour === hour &&
    selectedMinute === minute &&
    disabledDateTime
  ) {
    return range(second + 1, 60);
  }

  return [];
};

const PickerWithType = ({
  type,
  onChange,
  value,
  format,
  defaultPickerValue,
  disabledDateTime,
  ...restProps
}) => {
  if (type === 'time') {
    const timeNow = moment(Date.now())
      .format(timeFormat)
      .split(':');
    const hour = + timeNow[0];
    const minute = + timeNow[1];
    const second = + timeNow[2];

    return (
      <TimePicker
        value={ value ? moment(value, format) : null }
        onChange={ (_, timeString: string) => onChange(timeString) }
        showNow={ false }
        suffixIcon={ <Clock /> }
        disabledHours={ () => disabledHours(
          disabledDateTime,
          hour,
          minute
        ) }
        disabledMinutes={ (selectedHour: number) => disabledMinutes(
          disabledDateTime,
          selectedHour,
          hour,
          minute
        ) }
        disabledSeconds={ (selectedHour: number, selectedMinute: number) => disabledSeconds(
          disabledDateTime,
          selectedHour,
          selectedMinute,
          hour,
          minute,
          second
        ) }
        // use12Hours
        { ...restProps }
      />
    );
  }

  if (type === 'date')
    return (
      <DatePicker
        value={ value ? moment(value, format) : null }
        defaultPickerValue={ defaultPickerValue ? defaultPickerValue : moment('2000-01-01') }
        format={ format }
        onChange={ (_, dateString: string) => onChange(dateString) }
        showToday={ false }
        disabledDate={ disabledDate }
        suffixIcon={ <Calendar /> }
        { ...restProps }
      />
    );

  return null;
};

const Input: React.FC<InputProps> = ({
  inputFocus,
  value,
  placeholder,
  label,
  labelSize,
  labelColor,
  backgroundColor,
  type,
  onClick,
  onChangeFocus,
  onChange,
  onChangeDate,
  onChangeRadio,
  onKeyDown,
  defaultPickerValue,
  name,
  mb,
  borderRadius,
  width,
  iconName,
  iconClassName,
  iconPosition,
  size,
  weight,
  themes,
  lineHeight,
  errorMessage,
  disabled,
  disabledDateTime,
  textAreaAutoResize,
  radioValue,
  weightLabel,
  renderSuffixLabel,
  renderPrefixInput,
  renderSuffixInput,
  labelClassName,
  inputClassName
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [focus, setFocus] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>(type || 'text');

  useEffect(() => {
    if (type === 'searchBoxMaps') {
      setInputType('text');
    } else {
      setInputType(type || 'text');
    }
  }, [type]);

  const onFocus = () => {
    setFocus(true);
    onChangeFocus ? onChangeFocus(true) : null;
  };

  const onBlur = () => {
    setFocus(false);
    onChangeFocus ? onChangeFocus(false) : null;
  };

  useEffect(() => {
    if (inputFocus !== undefined) {
      if (inputFocus) {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      } else {
        if (inputRef.current) {
          inputRef.current.blur();
        }
      }
    }
  }, [inputFocus]);

  const renderTextLabel = () => {
    if (label) {
      const textColor = focus ? Colors.blue.isBlue : labelColor;

      return (
        <div className={ `flex align-center mb2 ${ labelClassName }` }>
          <Text
            size={ labelSize }
            lineHeight={ 17 }
            weight={ weightLabel || 500 }
            text={ label }
            color={ textColor }
          />

          { renderSuffixLabel && renderSuffixLabel() }
        </div>
      );
    }

    return null;
  };

  const renderIconLeft = () => {
    if ((iconName && iconPosition === 'left') || renderPrefixInput) {
      return (
        <IconInputStyle type={ inputType } iconPosition={ renderPrefixInput ? 'left' : iconPosition }>
          { renderPrefixInput
            ? renderPrefixInput()
            : (
              <Icon
                fill={ focus ? Colors.blue.isBlue : Colors.grey.isGrey }
                iconName={ iconName }
                className={ iconClassName }
              />
            ) }
        </IconInputStyle>
      );
    }

    return null;
  };

  const onClickIconShowPassword = () => {
    const nextShowPassword = !showPassword;

    setShowPassword(nextShowPassword);
    setInputType(nextShowPassword ? 'text' : 'password');
  };

  const renderIconRight = () => {
    if ((iconName && iconPosition === 'right') || renderSuffixInput) {
      return (
        <IconInputStyle type={ inputType } iconPosition={ renderSuffixInput ? 'right' : iconPosition }>
          { renderSuffixInput
            ? renderSuffixInput()
            : iconName === 'password'
              ? (
                <div className='pointer' onClick={ onClickIconShowPassword }>
                  {
                    showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                </div>
              )
              : (
                <Icon
                  fill={ focus ? Colors.blue.isBlue : Colors.grey.isGrey }
                  iconName={ iconName }
                  className={ iconClassName }
                />
              ) }
        </IconInputStyle>
      );
    }

    return null;
  };

  const renderErrorText = () => {
    if (errorMessage) {
      return (
        <Text
          size='xxs'
          mt={ 5 }
          color={ Colors.red.isTextRed }
        >{ errorMessage }</Text>
      );
    }

    return null;
  };

  const renderInput = () => {
    if (type === 'textArea') {
      return (
        <div className='relative'>
          { renderIconLeft() }

          <TextArea
            isFocused={ focus }
            value={ value }
            name={ name }
            placeholder={ placeholder }
            onChange={ onChange }
            onFocus={ onFocus }
            onBlur={ onBlur }
            onClick={ onClick }
            fontSize={ Themes.fontSizes[size || 's'] }
            fontFamily={ Themes.fontThemes(weight, themes) }
            lineHeight={ lineHeight }
            backgroundColor={ backgroundColor }
            disabled={ disabled }
            autoResize={ textAreaAutoResize }
            iconName={ iconName || !!renderSuffixInput }
            iconPosition={ iconPosition }
            inputClassName={ inputClassName }
          />

          { renderIconRight() }
        </div>
      );
    } else if (type === 'date' || type === 'time') {
      return (
        <DatePickerStyle
          isFocus={ disabled ? false : focus }
          backgroundColor={ backgroundColor }
          borderRadius={ borderRadius }
          width={ width }
          fontSize={ Themes.fontSizes[size || 's'] }
          fontFamily={ Themes.fontThemes(weight, themes) }
          lineHeight={ lineHeight }
          disabled={ disabled }
        >
          <PickerWithType
            type={ type }
            value={ value }
            onFocus={ onFocus }
            onBlur={ onBlur }
            onChange={ onChangeDate }
            placeholder={ placeholder }
            format={ type === 'date' ? dateFormat : timeFormat }
            defaultPickerValue={ defaultPickerValue }
            disabled={ disabled }
            disabledDateTime={ disabledDateTime }
          />
        </DatePickerStyle>
      );
    } else if (type === 'radio_group') {
      return (
        <RadioGroupStyle
          fontSize={ Themes.fontSizes[size || 's'] }
          fontFamily={ Themes.fontThemes(weight, themes) }
        >
          <Radio.Group
            size='large'
            onChange={ onChangeRadio }
            name={ name }
            value={ value }
            disabled={ disabled }
          >
            {
              radioValue?.map(opt => {
                return (
                  <Radio
                    key={ opt }
                    value={ opt }
                    className='radio-label'
                  >{ opt }</Radio>
                );
              })
            }
          </Radio.Group>
        </RadioGroupStyle>
      );
    }

    return (
      <div className='relative'>
        { renderIconLeft() }

        <InputStyle
          ref={ inputRef }
          isFocus={ focus }
          onFocus={ onFocus }
          onBlur={ onBlur }
          placeholder={ placeholder }
          type={ inputType === 'currency' ? 'text' : inputType }
          min={ inputType === 'number' ? '0' : undefined }
          max={ inputType === 'number' ? '100' : undefined }
          name={ name || `input${ Math.floor(Math.random() * 100) }` }
          onChange={ onChange }
          onKeyDown={ onKeyDown }
          onClick={ onClick }
          value={ inputType === 'currency' ? currencyFormat(value?.toString() ?? '') : value }
          backgroundColor={ backgroundColor }
          borderRadius={ borderRadius }
          width={ width }
          iconName={ iconName || !!renderSuffixInput }
          iconPosition={ iconPosition }
          fontSize={ Themes.fontSizes[size || 's'] }
          fontFamily={ Themes.fontThemes(weight, themes) }
          lineHeight={ lineHeight }
          disabled={ disabled }
          className={ inputClassName }
        />

        { renderIconRight() }
      </div>
    );
  };

  return (
    <>
      <div style={ { marginBottom: `${ mb || 0 }px` } }>
        { renderTextLabel() }

        { renderInput() }

        { renderErrorText() }
      </div>
    </>
  );
};

Input.defaultProps = {
  value: '',
  placeholder: '',
  labelSize: 's',
  labelColor: Colors.black.isTextBlack,
  backgroundColor: Colors.grey.isBgLightGrey,
  type: 'text',
  borderRadius: 10,
  width: '100%',
  iconName: '',
  iconClassName: '',
  iconPosition: 'left',
  size: 's',
  weight: 500,
  themes: 'primary',
  lineHeight: 17,
  errorMessage: '',
  disabled: false,
  textAreaAutoResize: false,
  labelClassName: ''
};

export default Input;
