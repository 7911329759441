import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { Empty } from 'antd';

import {
  Box,
  Button,
  Text,
  Icon,
  Image,
  Divider,
  Modal,
  Spacer,
  MenuMore,
  Skeleton,
  Input,
  RadioButton,
  Pagination,
  LinkedUsers,
  ButtonContact,
  SelectSearchBar,
  ModalForm,
  MapsLeaflet
} from 'components';
import { MenuEntity } from 'components/Dropdown';
import {
  Colors,
  Endpoints,
  Images,
  MapConfig
} from 'consts';
import { ValueType } from 'typings';
import {
  misc,
  hooks,
  toastify,
  validation,
  roleHelper,
  moment
} from 'helpers';
import {
  FormPagination,
  ReducerList,
  CurrentPagination,
  SelectOptionValue
} from 'interfaces/common';
import {
  Showroom,
  ShowroomApiParams,
} from 'interfaces/showroom';
import {
  FormShowroomAssignment,
  FormUser,
  PairedScooter,
  ShowroomUser,
  UserScooter
} from 'interfaces/user';
import { Permission } from 'interfaces/role';
import { SubscriptionType } from 'interfaces/subscription';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';
import { language } from 'language';

import {
  BoxUserInfoStyle,
  ModalAssignShowroom,
  SubsStyle,
  WrapperLinkedUsersStyle,
  ItemDropdown
} from './style';

import { renderTitleSubContent } from '../../index';

import { inputPropsData } from '../../../Users/data';
import { Cities, CitiesApiParams } from 'interfaces/location';

type BoxUserInfoProps = {
  widthBox: number | string;
  activeScooter: PairedScooter;
  refreshing?: boolean;
  setRefreshing?: React.Dispatch<React.SetStateAction<boolean>>;
};
type Visible = 'allUsers' | 'assignShowroom' | 'form';
type ModalVisible = {
  [key in Visible]: boolean;
};

type IconPasswordProps = {
  iconName: string;
  iconPosition: 'left' | 'right';
} | Record<string, never>;

type FilterCity = 'city';

type VisibleFilter = {
  [key in FilterCity]: boolean;
};
const { errorFormMessage } = language;

const BoxUserInfo: React.FC<BoxUserInfoProps> = ({
  widthBox,
  activeScooter,
  setRefreshing
}) => {
  const dispatch = hooks.useAppDispatch();
  const getAllShowroom = dispatch(actions.getAllShowroom);
  const getAllCities = dispatch(actions.getAllCities);
  const getShowroomDetail = dispatch(actions.getShowroomDetail);
  const showroomAssignment = dispatch(actions.showroomAssignment);
  const editShowroomAssignment = dispatch(actions.editShowroomAssignment);
  const editUser = dispatch(actions.editUser);

  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);
  const userDetail = hooks.useAppSelector(selectors.user.userDetail);
  const showrooms = hooks.useAppSelector(selectors.showroom.showrooms);
  const userShowroom = hooks.useAppSelector(selectors.user.userShowroom);

  const [visibleFilter, setVisibleFilter] = useState<VisibleFilter>({ city: false });
  const [dataSelectedProvinces, setDataSelectedProvinces] = useState<SelectOptionValue[]>([]);
  const [isContentPinpoint, setIsContentPinpoint] = useState<boolean>(false);
  const [searchShowroom, setSearchShowroom] = useState<string>('');
  const [valueGeosearch, setValueGeosearch] = useState<ValueType>({
    shortLabel: '',
    label: '',
    value: '',
    postcode: '',
    valueDetail: MapConfig.defaultCenter
  });
  const [modalVisible, setModalVisible] = useState<ModalVisible>({
    allUsers: false,
    assignShowroom: false,
    form: false
  });
  const [radioShowroom, setRadioShowroom] = useState<string>('');
  const [formPaginationShowroom, setFormPaginationShowroom] = useState<FormPagination>({
    limit: 10,
    page: 1
  });
  const [isEmptyShowrooms, setIsEmptyShowrooms] = useState<boolean>(false);
  const [dataAllShowroom, setDataAllShowroom] = useState<Showroom[]>([]);
  const [showPaginationShowroom, setShowPaginationShowroom] = useState<boolean>(false);
  const [dataLinkedUsers, setDataLinkedUsers] = useState<UserScooter[]>([]);
  const [form, setForm] = useState<FormUser>({
    name: '',
    username: '',
    email: '',
    phone_number: '',
    dob: '',
    address: '',
    x_player: 'xplayer1',
    provider: 'local',
    zip_code: '',
    city: '',
  });
  const [errorForm, setErrorForm] = useState<FormUser>({
    name: '',
    username: '',
    email: '',
    phone_number: '',
    dob: '',
    address: '',
    zip_code: '',
    city: '',
  });

  const defaultOptionsProvinces = [
    {
      label: 'Choose City',
      value: ''
    },
  ];

  const [showroomRemoved, setShowroomRemoved] = useState<boolean>(false);
  const [showroomAssigned, setShowroomAssigned] = useState<boolean>(false);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();
  const debouncedSearchShowroom = hooks.useDebounce(searchShowroom, 1200);
  const loadingUserDetail = misc.isLazyLoading(lazyLoad, 'userDetail');
  const loadingGetShowroom = misc.isLazyLoading(lazyLoad, 'allShowroom');

  useEffect(() => {
    const pairedScooters = userDetail?.showroom?.filter((showroom: ShowroomUser) => showroom?.scooter)?.map(showroom =>
      showroom?.scooter?.map((scooter: PairedScooter) => ({
        ...scooter,
        user_scooter: scooter.user_scooter || []
      })));

    if (pairedScooters) {
      const flattenScooters = misc.flatten(pairedScooters).map((scooter: PairedScooter) => {
        return scooter?.user_scooter?.filter(userScooter => userScooter?.user_id !== userDetail?.id);
      });
      const uniqueLinkedUsers = misc.flatten(flattenScooters).filter((
        value: UserScooter,
        index: number,
        self: UserScooter[]
      ) => {
        return self.map((user: UserScooter) => user?.user_id).indexOf(value?.user_id) === index;
      });

      setDataLinkedUsers(uniqueLinkedUsers);
    } else {
      setDataLinkedUsers([]);
    }

  }, [userDetail?.showroom]);

  useEffect(() => {
    if (userDetail?.showroom_assigned > 0) {
      getShowroomDetail(
        userDetail?.showroom_assigned?.toString(),
        'userShowroom',
        () => {
          setShowroomRemoved(false);
          setShowroomAssigned(true);
        },
        () => {
          setShowroomRemoved(true);
          setShowroomAssigned(true);
        }
      );
    } else {
      setShowroomRemoved(false);
      setShowroomAssigned(false);
    }
  }, [userDetail?.showroom_assigned]);

  useEffect(() => {
    if (modalVisible.assignShowroom) {
      const params: ShowroomApiParams = {
        ...formPaginationShowroom,
        search: debouncedSearchShowroom,
        sort: 'asc'
      };

      getAllShowroom(params, (updatedShowrooms: ReducerList<Showroom[]>) => {
        if (updatedShowrooms?.data) {
          if (!debouncedSearchShowroom) {
            setDataAllShowroom(prevState => ([...prevState, ...updatedShowrooms?.data]));

            if (updatedShowrooms?.pagination?.page < updatedShowrooms?.pagination?.page_total) {
              setShowPaginationShowroom(true);
            }
          } else {
            setDataAllShowroom(updatedShowrooms?.data);
          }

          setIsEmptyShowrooms(false);
        } else {
          setDataAllShowroom([]);
          setIsEmptyShowrooms(true);
        }
      });
    }
  }, [modalVisible.assignShowroom, formPaginationShowroom.page, debouncedSearchShowroom]); // eslint-disable-line array-bracket-newline, array-element-newline

  useEffect(() => {
    setFormPaginationShowroom(prevPagination => ({
      ...prevPagination,
      page: 1
    }));

    setDataAllShowroom([]);
  }, [debouncedSearchShowroom]);

  useEffect(() => {
    if (loadingGetShowroom) {
      setShowPaginationShowroom(false);
    }
  }, [loadingGetShowroom]);

  const renderButtonContact = (phone: string, mt?: number) => {
    return <ButtonContact phone={ phone } mt={ mt } />;
  };

  const onClickMenuEdit = (params: MenuEntity) => {
    if (params.key === 'edit') {
      onOpenModal('form');
    }
  };

  const renderUserInfo = () => {
    const isPermissibleEditUser = roleHelper.isPermissible(adminPermissions, Permission.user_update);

    return (
      <Skeleton
        loading={ loadingUserDetail }
        paragraph={ { rows: 2 } }
        avatar={ {
          size: 80,
          shape: 'circle'
        } }
      >
        <div className='justify-align-center'>
          <div className='flex row' style={ { width: '70%' } }>
            <Image
              src={ userDetail?.profile_img }
              width={ 80 }
              height={ 80 }
              className='img-user-detail cover'
              type='circle'
              preview
              external
            />
            <Spacer ml={ 10 } className='user-data-container wrap'>
              <Text
                size='m'
                weight={ 700 }
                lineHeight={ 17 }
                mb={ 10 }
                className='p1 user-data wrap'
              >{ userDetail?.name }</Text>
              <Text
                color={ Colors.grey.isGrey }
                mb={ 10 }
                className='p1 user-data wrap'
              >{ userDetail?.email }</Text>
              <Text color={ Colors.blue.isBlue }>{ userDetail?.phone_number }</Text>
            </Spacer>
          </div>
          <div className='col col-end'>
            { isPermissibleEditUser && <MenuMore onClickMenu={ onClickMenuEdit } /> }

            { renderButtonContact(userDetail?.phone_number, 15) }
          </div>
        </div>
      </Skeleton>
    );
  };

  // todo: feedback from qa, sementara ditakeout dulu saja sampai API nya ready
  // const renderBoxLastTrip = () => {
  //   return (
  //     <Skeleton
  //       loading={ loadingUserDetail }
  //       mt={ 30 }
  //       mb={ 30 }
  //       multiple={ false }
  //     >
  //       <Box
  //         bgColor={ Colors.grey.isBgGrey }
  //         height={ 42 }
  //         mt={ 30 }
  //         mb={ 30 }
  //         borderRadius={ 8 }
  //         border={ `1px solid ${ Colors.grey.isBgGrey }` }
  //         padding={ 12 }
  //       >
  //         <div className='col justify-center'>
  //           <div className='justify-align-center'>
  //             <div className='flex row align-center'>
  //               <Icon iconName='scooter' fill={ Colors.blue.isBlue } />
  //               <Text
  //                 weight={ 700 }
  //                 color={ Colors.blue.isBlue }
  //                 ml={ 15 }
  //               >Last Trip</Text>
  //             </div>
  //             <Text align='right'>May 17th 2021</Text>
  //           </div>
  //         </div>
  //       </Box>
  //     </Skeleton>
  //   );
  // };

  const onClickOpenMaps = () => {
    window.open(Endpoints.googleMaps(userDetail?.address), '_blank');
  };

  const renderSubsStatus = () => {
    const allSubscriptions = userDetail?.showroom?.filter((showroom: ShowroomUser) => showroom?.scooter)?.map((showroom: ShowroomUser) => showroom.scooter?.filter((scooter: PairedScooter) => scooter?.subscription && scooter?.subscription?.name));
    const subscriptionScooter = activeScooter?.subscription;
    const isUserDoesntHaveSubscription = !(misc.flatten(allSubscriptions)?.length);
    const isScooterHaveSubscription = !!(subscriptionScooter && subscriptionScooter?.name);
    const isTimeBase = subscriptionScooter?.subscription_type === SubscriptionType.time_base;
    const endDate = subscriptionScooter?.end_date
      ? moment.dateConverted(
        subscriptionScooter?.end_date,
        'YYYY-MM-DD',
        'DD MMM YYYY'
      )
      : '-';
    const availability = isTimeBase
      ? `until ${ endDate }`
      : `for ${ (subscriptionScooter?.swap_amount || 0) - (subscriptionScooter?.used_amount || 0) } times swap`;
    const typeDescription = isTimeBase
      ? 'Unlimited Battery Swap'
      : `${ subscriptionScooter?.swap_amount }x Battery Swap`;

    return (
      <Skeleton
        loading={ loadingUserDetail }
        paragraph={ { rows: 2 } }
        multiple={ false }
        mt={ 30 }
        mb={ 30 }
      >
        <Box
          height='100%'
          mt={ 30 }
          mb={ 30 }
          borderRadius={ 10 }
          border={ `1px solid ${ Colors.grey.isLightGrey }` }
          padding={ 0 }
        >
          <SubsStyle isActiveSubs={ isScooterHaveSubscription } className='col justify-center'>
            <div className='justify-align-center subs-status-title'>
              <Text weight={ 500 } color={ Colors.grey.isGrey }>Subscription</Text>
              { isScooterHaveSubscription && (
                <Text
                  weight={ 500 }
                  color={ Colors.grey.isGrey }
                  size='xs'
                >Available { availability }</Text>
              ) }
            </div>
            <div className='subs-status-desc'>
              <div className='flex subs-status-name'>
                <Icon
                  iconName='subscription'
                  size={ 18 }
                  fill={ isScooterHaveSubscription
                    ? Colors.orange.isOrange
                    : Colors.blue.isBlue
                  }
                  cursor='default'
                />
                <Text
                  weight={ 700 }
                  ml={ 10 }
                  color={ isScooterHaveSubscription
                    ? Colors.orange.isOrange
                    : Colors.black.isBlack
                  }
                >{ subscriptionScooter?.name || `This ${ isUserDoesntHaveSubscription ? 'user' : 'scooter' } doesn’t have any subscription :(` }</Text>
              </div>

              { isScooterHaveSubscription
                ? (
                  <div className='subs-status-name'>
                    <Text
                      weight={ 700 }
                      ml={ 15 }
                      size='m'
                      align='right'
                    >{ typeDescription }</Text>
                  </div>
                )
                : (
                  <Text color={ Colors.grey.isGreyGreen } size='xs'>You can direct this user to buy a subscription and enjoy unlimited battery swap</Text>
                ) }
            </div>
          </SubsStyle>
        </Box>
      </Skeleton>
    );
  };

  const renderAddress = () => {
    return (
      <div>
        { renderTitleSubContent(
          'Address',
          'Open in Map',
          () => onClickOpenMaps()
        ) }
        <Skeleton
          loading={ loadingUserDetail }
          paragraph={ { rows: 2 } }
          mt={ 10 }
          mb={ 10 }
        >
          <Text
            mt={ 10 }
            mb={ 25 }
            align='justify'
          >{ userDetail?.address }</Text>
        </Skeleton>
      </div>
    );
  };

  const renderTextShowroomExist = (
    textLeft: string,
    rightText: string,
    divider = true
  ) => {
    return (
      <>
        <div className='justify-align-center'>
          <Text color={ Colors.grey.isGrey }>{ textLeft }</Text>
          <Text align='right' className='p1 text-showroom-right'>{ rightText }</Text>
        </div>
        { divider && <Divider marginVertical={ 15 } /> }
      </>
    );
  };

  const renderContentShowroom = () => {
    const isShowroomEmpty = userDetail?.showroom_assigned === 0;
    const isShowroomRemoved = userDetail?.showroom_assigned > 0 && showroomRemoved;
    const isPermissibleAssignShowroom = roleHelper.isPermissible(adminPermissions, Permission.user_assign_showroom);

    if (isShowroomEmpty || isShowroomRemoved) {
      let heightEmptyShowroom = 175;

      if (dataLinkedUsers.length === 1) {
        heightEmptyShowroom = 272;
      } else if (dataLinkedUsers.length === 2) {
        heightEmptyShowroom = 212;
      } else if (dataLinkedUsers.length === 3) {
        heightEmptyShowroom = 150;
      }
      const text = isShowroomRemoved
        ? 'The Assigned Showroom is No Longer Available'
        : 'No Showroom Assigned to this User';

      return (
        <div className='col center-content' style={ { minHeight: heightEmptyShowroom } }>
          <Image
            src={ Images.icons.store }
            width={ 60 }
            height={ 60 }
            className='mb3'
          />
          <Text
            size='m'
            weight={ 700 }
            color={ Colors.grey.isGreyGreen }
            mb={ 15 }
            align='center'
          >{ text }</Text>
          {
            isShowroomRemoved ? (
              <Text
                color={ Colors.grey.isGrey }
                align='center'
                mb={ 15 }
              >Please update the showroom for this user</Text>
            ) : null
          }

          { isPermissibleAssignShowroom && (
            <Button
              bgColor='black'
              fontWeight={ 700 }
              borderRadius={ 10 }
              mb={ 15 }
              onClick={ () => onOpenModal('assignShowroom') }
              // disabled={ !userDetail?.is_completed } // todo: need confirmation
              className='btn-assign-showroom'
              text='Assign Showroom'
            />
          ) }
        </div>
      );
    } else {
      return (
        <>
          { renderTextShowroomExist('Name', userShowroom.name) }
          { renderTextShowroomExist('PIC', userShowroom.pic_name) }
          { renderTextShowroomExist('Contact', userShowroom.phone_number) }
          { renderTextShowroomExist(
            'Address',
            userShowroom.address,
            false
          ) }
          <Divider marginVertical={ 15 } />
        </>
      );
    }
  };

  const renderShowroom = () => {
    const loadingShowroom = loadingUserDetail || misc.isLazyLoading(lazyLoad, 'userShowroom');

    return (
      <div>
        { showroomAssigned
          ? renderTitleSubContent(
            'Showroom',
            'Change Showroom',
            () => onOpenModal('assignShowroom')
          )
          : renderTitleSubContent('Showroom') }

        <Spacer mt={ 20 } mb={ 5 }>
          <Skeleton loading={ loadingShowroom } paragraph={ { rows: 3 } }>
            { renderContentShowroom() }
          </Skeleton>
        </Spacer>
      </div>
    );
  };

  const renderLinkedUser = (data: UserScooter, styleType: 'primary' | 'secondary') => {
    return (
      <LinkedUsers
        name={ data?.name }
        status={ data?.status }
        image={ data?.selfie_image }
        phone={ data?.phone_number }
        styleType={ styleType }
      />
    );
  };

  const renderLinkedUsers = (useAllData: boolean) => {
    const dummyData = {
      parent_id: 0,
      user_id: 0,
      name: '',
      username: '',
      status: '',
      selfie_image: '',
      phone_number: ''
    };
    const endSlice = windowDimensions.width < 320
      ? 3
      : 4;
    const dataUsers: UserScooter[] = loadingUserDetail ? misc.createDummyData(2, dummyData) :
      useAllData || dataLinkedUsers.length <= (endSlice + 1)
        ? dataLinkedUsers
        : dataLinkedUsers.slice(0, endSlice);
    const subtitle = !dataUsers.length ? '' : 'See All';

    return (
      <>
        {
          !useAllData && (
            <div className='mt4'>
              { renderTitleSubContent(
                'Linked Users',
                subtitle,
                () => onOpenModal('allUsers')
              ) }
            </div>
          )
        }

        {
          !dataUsers.length
            ? (
              <div className='mt4' style={ { minHeight: 142 } }>
                <Empty description={
                  <Text
                    size='m'
                    weight={ 700 }
                    color={ Colors.grey.isGrey }
                    align='center'
                    mt={ 22 }
                  >No Linked Users</Text>
                } />
              </div>
            )
            : (
              <WrapperLinkedUsersStyle useAllData={ useAllData }>
                { dataUsers.map((data: UserScooter, index: number) => {
                  return (
                    <Skeleton
                      key={ index }
                      loading={ loadingUserDetail }
                      paragraph={ { rows: 2 } }
                      avatar={ {
                        size: 40,
                        shape: 'circle'
                      } }
                      title={ false }
                      mt={ 20 }
                    >
                      <div className={ useAllData ? 'justify-align-center mt4' : '' }>
                        { renderLinkedUser(data, useAllData ? 'primary' : 'secondary') }

                        { useAllData && renderButtonContact(data?.phone_number, 0) }
                      </div>
                    </Skeleton>
                  );
                }) }
              </WrapperLinkedUsersStyle>
            )
        }
      </>
    );
  };

  const onCloseModalForm = () => {
    setForm(prevForm => ({
      ...prevForm,
      name: '',
      username: '',
      email: '',
      phone_number: '',
      dob: '',
      address: '',
      zip_code: '',
      city: '',
    }));

    setErrorForm({
      name: '',
      username: '',
      email: '',
      phone_number: '',
      dob: '',
      address: '',
      zip_code: '',
      city: '',
    });
  };

  const onVisibleChange = (visible: boolean, key: string) => {
    setVisibleFilter(prevFilter => ({
      ...prevFilter,
      [key]: visible
    }));
  };

  const fetchSearchProvinces = async(search: string): Promise<SelectOptionValue[]> => {
    const params: CitiesApiParams = {
      search: search,
      sort: 'asc',
      limit: 50,
      offset: '',
      order: '',
      page: ''
    };

    return getCities(params);

  };

  const getCities = async params => {
    let dataCities;

    await getAllCities(params,
      (cities: ReducerList<Cities[]>) => {
        if (cities?.data) {

          dataCities = cities?.data?.map(list => ({
            key: list.city,
            value: list.city,
            label: list.province_name + '-' + list.city
          }));
        } else {
          dataCities = [];
        }
      });

    return dataCities;
  };

  const onCloseModal = useCallback(modalType => {
    setModalVisible(prevVisible => ({
      ...prevVisible,
      [modalType]: false
    }));

    if (modalType === 'assignShowroom') {
      setRadioShowroom('');
      setFormPaginationShowroom({
        limit: 10,
        page: 1
      });
      setSearchShowroom('');
      setDataAllShowroom([]);
    } else if (modalType === 'form') {
      onCloseModalForm();
    }
  }, []);

  const findProvinceByCity = async(city: string) => {
    const params: CitiesApiParams = {
      search: city ? city : '',
      sort: 'asc',
      limit: 50,
      offset: '',
      order: '',
      page: ''
    };

    const newOptions = await getCities(params);
    const index = newOptions?.findIndex(item => item?.value === city);
    return newOptions[index]?.label;
  };

  const onOpenModal = (modalType: Visible) => {
    if (modalType === 'assignShowroom') {
      setDataAllShowroom([]);
      setShowPaginationShowroom(false);
    } else if (modalType === 'form') {

      setForm({
        name: userDetail?.name,
        username: userDetail?.username,
        email: userDetail?.email,
        phone_number: userDetail?.phone_number,
        dob: userDetail?.dob,
        address: userDetail?.address,
        zip_code: userDetail?.zip_code,
        city: userDetail?.city,
      });

      findProvinceByCity(userDetail?.city).then(newOptions => {
        setDataSelectedProvinces([
          {
            value: userDetail?.city,
            key: userDetail?.city,
            label: newOptions
          }
        ]);
      });

    }

    setModalVisible(prevVisible => ({
      ...prevVisible,
      [modalType]: true
    }));
  };

  const renderModalSeeAllUsers = () => {
    return (
      <Modal
        visible={ modalVisible.allUsers }
        onCloseModal={ () => onCloseModal('allUsers') }
        width={ 500 }
        modalType='long-scroll'
        title={ () => renderTitleSubContent('Linked Users') }
      >
        <BoxUserInfoStyle>
          { renderLinkedUsers(true) }
        </BoxUserInfoStyle>
      </Modal>
    );
  };

  const onChangeSearchShowroom = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchShowroom(e.target.value);
  }, []);

  const renderSearch = () => {
    return (
      <Input
        placeholder='Search'
        value={ searchShowroom }
        name='search'
        onChange={ onChangeSearchShowroom }
        borderRadius={ 8 }
        weight={ 400 }
        iconName='search'
        iconClassName='ic-search'
      />
    );
  };

  const onChangeRadioShowroom = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioShowroom(e.target.value);
  }, []);

  const getCurrentPaginationShowroom = (currentPagination: CurrentPagination<Showroom[]>) => {
    if (currentPagination.currentPage <= currentPagination.pagesCount) {
      setFormPaginationShowroom(prevPagination => ({
        ...prevPagination,
        page: currentPagination.currentPage
      }));
    }
  };

  const renderPaginationLoadingShowroom = () => {
    if (showPaginationShowroom) {
      return (
        <Pagination
          type='lazyLoad'
          data={ showrooms.data }
          getCurrentPagination={ getCurrentPaginationShowroom }
        />
      );
    }
  };

  const renderShowroomList = () => {
    const dummyShowroom = Array.from(Array(3).keys()).map(i => ({
      id: i,
      name: 'showroom name',
      address: 'showroom address'
    }));

    if (isEmptyShowrooms) {
      return (
        <div className='col center-content' style={ { height: '40vh' } }>
          <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
        </div>
      );
    }

    return (
      <div className='flex col'>
        {
          dataAllShowroom.map((showroom, index) => {
            const isLastIndex = index === dataAllShowroom.length - 1;

            return (
              <div key={ showroom.id } className='flex col'>
                <RadioButton
                  label={ showroom.name }
                  labelSubtitle={ showroom.address }
                  name={ `showroom-${ showroom.id }` }
                  value={ showroom.id.toString() }
                  checked={ radioShowroom }
                  onChange={ onChangeRadioShowroom }
                />

                { !isLastIndex && <Divider marginVertical={ 20 } /> }
              </div>
            );
          })
        }
        {
          loadingGetShowroom && dummyShowroom.map(showroom => {
            return (
              <RadioButton
                key={ showroom.id }
                label={ showroom.name }
                labelSubtitle={ showroom.address }
                name={ `showroom-${ showroom.id }` }
                value={ showroom.id.toString() }
                checked={ radioShowroom }
                loading={ loadingGetShowroom }
                onChange={ onChangeRadioShowroom }
              />
            );
          })
        }

        { renderPaginationLoadingShowroom() }
      </div>
    );
  };

  const onClickAssignShowroom = () => {
    const formShowroom: FormShowroomAssignment = { showroom_id: Number(radioShowroom) };

    if (showroomAssigned) {
      editShowroomAssignment(
        userDetail?.id.toString(),
        formShowroom,
        handleCbShowroomAssignment
      );
    } else {
      showroomAssignment(
        userDetail?.id.toString(),
        formShowroom,
        handleCbShowroomAssignment
      );
    }
  };

  const handleCbShowroomAssignment = async(message: string) => {
    await onCloseModal('assignShowroom');
    await toastify.success(message);

    if (setRefreshing) setRefreshing(true);
  };

  const renderButtonForm = () => {
    const loadingShowroomAssignment = misc.isLazyLoading(lazyLoad, 'showroomAssignment');

    return (
      <Button
        bgColor='black'
        loaderColor='black'
        fontWeight={ 700 }
        borderRadius={ 10 }
        mt={ 20 }
        onClick={ onClickAssignShowroom }
        isLoading={ loadingShowroomAssignment }
        disabled={ !radioShowroom }
        text='Assign Showroom'
      />
    );
  };

  const renderSearchShowroom = () => {
    return (
      <ModalAssignShowroom>
        <div>
          { renderSearch() }
        </div>
      </ModalAssignShowroom>
    );
  };

  const renderModalAssignShowroom = () => {
    return (
      <Modal
        visible={ modalVisible.assignShowroom }
        onCloseModal={ () => onCloseModal('assignShowroom') }
        title={ () => renderTitleSubContent('Assign Showroom') }
        footer={ () => renderButtonForm() }
        fixedContent={ () => renderSearchShowroom() }
      >
        <ModalAssignShowroom>
          <div className='content'>
            { renderShowroomList() }
          </div>
        </ModalAssignShowroom>
      </Modal>
    );
  };

  const onChangeFormText = useCallback((e: any) => {
    setForm(prevForm => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      [e.target.name]: ''
    }));
  }, []);

  const onChangeDate = (dateString: string) => {
    setForm(prevForm => ({
      ...prevForm,
      dob: dateString
    }));

    setErrorForm(prevErrorForm => ({
      ...prevErrorForm,
      dob: ''
    }));
  };

  const changeCities = (val: React.SetStateAction<SelectOptionValue[]>) => {
    setDataSelectedProvinces(val);

    setForm(prevForm => ({
      ...prevForm,
      city: Object.values(val)[1],
    }));
  };

  const renderSelectSearch = (
    label: string,
    placeholder: string,
    key: string
  ) => {
    return (
      <>
        <Text
          lineHeight={ 17 }
          weight={ 500 }
          text={ label }
          color={ Colors.grey.isGrey }
          mb={ 10 }
        />
        <SelectSearchBar
          value={ dataSelectedProvinces }
          defaultOptions={ defaultOptionsProvinces }
          fetchOptions={ fetchSearchProvinces }
          placeholder={ placeholder }
          onChange={ newValue => {
            changeCities(newValue);
          } }
          backgroundColor={ Colors.white.default }
          width={ '100%' }
          open={ visibleFilter[key] }
          onDropdownVisibleChange={ (visible: boolean) => onVisibleChange(visible, key) }
          totalItems={ 2 }
          wrapperStyle={ {
            marginBottom: 0,
            width: '100% !important',
          } }
        />
      </>
    );
  };

  const renderInput = (
    label: string,
    placeholder: string,
    key: string,
    type: string
  ) => {
    const isAddressInputEnabled = key === 'address';

    const iconPasswordProps: IconPasswordProps = type === 'password'
      ? {
        iconName: 'password',
        iconPosition: 'right'
      }
      : type === 'textArea' ? {
        iconName: 'pinLocation',
        iconPosition: 'left'
      } : {};

    if (type === 'selectSearch') {
      return renderSelectSearch(
        label,
        placeholder,
        key
      );
    }
    return (
      <Input
        type={ type }
        label={ label }
        placeholder={ placeholder }
        labelColor={ Colors.grey.isGrey }
        value={ form[key] }
        errorMessage={ errorForm[key] }
        name={ key }
        onChange={ onChangeFormText }
        onChangeDate={ onChangeDate }
        mb={ 20 }
        backgroundColor={ Colors.white.default }
        renderSuffixInput={ isAddressInputEnabled ? () => (
          <Text
            weight={ 700 }
            color={ Colors.blue.isBlue }
            onClick={ () => setIsContentPinpoint(true) }
          >Ubah</Text>
        ) : undefined }
        onClick={ isAddressInputEnabled && !form.address
          ? () => setIsContentPinpoint(true)
          : undefined }
        { ...iconPasswordProps }
      />
    );
  };

  const renderItemSearchAddress = (title: string, address?: string) => {
    return (
      <ItemDropdown>
        <div className='container-icon'>
          <Icon iconName='pinLocation' fill={ Colors.grey.isGrey } />
        </div>

        <div className='item-text-wrapper'>
          <Text size='m' weight={ 700 }>{ title }</Text>

          <div>
            <Text color={ Colors.grey.isGreyGreen } className='p1 description-text'>{ address || '' }</Text>
          </div>
        </div>
      </ItemDropdown>
    );
  };

  const renderContentModalForm = () => {
    if (isContentPinpoint) {
      return (
        <MapsLeaflet
          mapKey='Search-Maps-Modal'
          width='100%'
          height='220px'
          containerStyle={ {
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10
          } }
          onChangeLocation={ (result: ValueType) => setValueGeosearch(result) }
          renderItemSearchBox={ renderItemSearchAddress }
          center={ {
            lat: + MapConfig.defaultCenter.lat,
            lng: + MapConfig.defaultCenter.lng
          } }
          initValueGeoSearch={ {
            label: '',
            shortLabel: '',
            value: '',
            postcode: '',
            valueDetail: {
              lat: + MapConfig.defaultCenter.lat,
              lng: + MapConfig.defaultCenter.lng
            }
          } }
          searchBox
          draggable
        />
      );
    }

    const filteredInputProps = inputPropsData.filter(input => input.type !== 'password');

    return filteredInputProps.map((input, index) => {
      return (
        <div key={ index }>
          { renderInput(
            input.label,
            input.placeholder,
            input.key,
            input.type
          ) }
        </div>
      );
    });
  };

  const handleFormValidation = () => {
    const validationUsername = validation.username(form.username);
    const validationEmail = validation.email(form.email);
    const validationPhone = validation.phoneNumber(misc.phoneNumberFormat(form.phone_number));
    const emptyForm = misc.getMultipleKeyByValue(form, '');

    if (emptyForm.length ||
      !validationEmail ||
      !validationPhone ||
      !validationUsername
    ) {
      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        username: !validationUsername ? errorFormMessage.username : '',
        email: !validationEmail ? errorFormMessage.email : '',
        phone_number: !validationPhone ? errorFormMessage.phone : ''
      }));

      emptyForm.forEach(key => {
        inputPropsData.forEach(input => {
          if (key === input.key) {
            setErrorForm(prevErrorForm => ({
              ...prevErrorForm,
              [key]: errorFormMessage.form(input.label.toLowerCase())
            }));
          }
        });
      });

      return false;
    }

    return true;
  };

  const onClickButtonModalForm = () => {
    if (isContentPinpoint) {

      setForm(prevForm => ({
        ...prevForm,
        address: valueGeosearch.value,
        zip_code: valueGeosearch.postcode,
      }));

      setErrorForm(prevErrorForm => ({
        ...prevErrorForm,
        address: '',
        zip_code: '',
        city: '',
      }));

      setIsContentPinpoint(false);
    } else {
      if (handleFormValidation()) {
        const formPayload = {
          ...form,
          phone_number: misc.phoneNumberFormat(form.phone_number)
        };

        editUser(
          userDetail?.id.toString(),
          formPayload,
          handleCbFormUser
        );
      }
    }
  };

  const handleCbFormUser = async(message: string) => {
    await onCloseModal('form');

    await toastify.success(message);

    if (setRefreshing) setRefreshing(true);
  };

  const renderModalForm = () => {
    return (
      <ModalForm
        title='user'
        visible={ modalVisible.form }
        actionModal='edit'
        onCloseModal={ () => onCloseModal('form') }
        footer={ {
          fieldName: 'user',
          onClickButtonSubmit: onClickButtonModalForm,
        } }
        contentModal={ renderContentModalForm() }
      />
    );
  };

  return (
    <BoxUserInfoStyle>
      <Box
        mt={ 20 }
        padding={ 20 }
        width={ widthBox }
      >
        { renderUserInfo() }

        { /* { renderBoxLastTrip() } */ }

        { renderSubsStatus() }

        { renderAddress() }

        { renderShowroom() }

        { renderLinkedUsers(false) }
      </Box>
      { renderModalSeeAllUsers() }
      { renderModalAssignShowroom() }
      { renderModalForm() }
    </BoxUserInfoStyle>
  );
};

export default BoxUserInfo;
