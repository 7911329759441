import { Analytic } from 'interfaces';

const data: Analytic.DataAnalytic[] = [
  {
    key: '1',
    name: 'TBD',
    substatus: 'TBD',
    contact: 'TBD',
    motorcycles: 'TBD',
    locHistory: 'TBD',
    riding: 'TBD',
    prevCase: 'TBD'
  }
];

for (let i = 2; i < 51; i++) {
  data.push({
    key: `${i}`,
    name: 'TBD',
    substatus: 'TBD',
    contact: 'TBD',
    motorcycles: 'TBD',
    locHistory: 'TBD',
    riding: 'TBD',
    prevCase: 'TBD'
  });
}

export { data };