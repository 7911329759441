import { Loading, LazyLoadField } from 'interfaces/misc';

export const LOADING = 'LOADING';
export const LOADING_MSG = 'LOADING_MSG';
export const LAZY_LOAD = 'LAZY_LOAD';
export const RESET_ALL_LOADING = 'RESET_ALL_LOADING';
export const LOGOUT = 'LOGOUT';

export interface SetLoading {
	type: typeof LOADING;
	data: Loading;
}

export interface SetLazyLoad {
	type: typeof LAZY_LOAD;
	data: LazyLoadField;
}

export interface SetResetLoading {
	type: typeof RESET_ALL_LOADING;
}

export interface Logout {
	type: typeof LOGOUT;
}

export type MiscActionTypes =
	| SetResetLoading
	| SetLoading
	| SetLazyLoad
	| Logout;
