import Styled from 'styled-components';

import { Colors } from 'consts';
import { screen } from 'helpers';

import { handleBackgroundColor, handleBorder } from '../Input/style';

type SelectStyleProps = {
  openSelect?: boolean;
  backgroundColor?: string;
  isDisabled?: boolean;
  totalItems?: number;
  defaultWidth?: string;
};

const setWidthFilter = (
  totalItems: number,
  length2: string,
  length3: string
) => {
  if (totalItems === 1) {
    return '200px';
  } else if (totalItems === 2) {
    return length2;
  } else {
    return length3;
  }
};

export const SelectStyle: any = Styled.div < SelectStyleProps > `
  .select-form-container {
    cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
    width: 100%;
    height: 44px;
    border-radius: 8px;
    border: 1px solid ${props => handleBorder(props.openSelect)};
    padding: 0px 10px;
    outline: none;
    background-color: ${props => handleBackgroundColor(
      props.openSelect,
      props.backgroundColor,
      props.isDisabled
    )};
  }

  .select-filter-container {
    cursor: pointer;
    background-color: ${Colors.grey.isBgLightGrey};
    width: ${props => props.defaultWidth || '200px'};
    height: 43px;
    padding: 13px 20px;
    border-radius: 8px;
  }

  .ic-arrow {
    ${props => props.openSelect ? 'transform: rotateX(180deg);' : ''}
  }

  @media ${screen.sizesMax.md2} {
    .select-filter-container {
      width: ${props => setWidthFilter(
        (props.totalItems || 2),
        (props.defaultWidth || '200px'),
        '155px'
        )};
    }

    .select-text-item {
      width: ${props => setWidthFilter(
        (props.totalItems || 2),
        (`${+ (props.defaultWidth || '200px').slice(0, -2) - 55}px`),
        '100px'
        )};
    }
  }

  @media ${screen.sizesMax.xs} {
    .select-filter-container, .select-text-item {
      width: ${props => setWidthFilter(
        (props.totalItems || 2),
        (props.defaultWidth || '200px'),
        '140px'
        )};
    }

    .select-text-item {
      width: ${props => setWidthFilter(
        (props.totalItems || 2),
        (`${+ (props.defaultWidth || '200px').slice(0, -2) - 55}px`),
        '85px'
        )};
    }
  }

  @media screen and (max-width: 500px) {
    .select-filter-container, {
      width: ${props => setWidthFilter(
        (props.totalItems || 2),
        '160px',
        '120px'
        )};
    }

    .select-text-item {
      width: ${props => setWidthFilter(
        (props.totalItems || 2),
        '105px',
        '65px'
        )};
    }
  }

  @media screen and (max-width: 450px) {
    .select-filter-container {
      width: ${props => setWidthFilter(
        (props.totalItems || 2),
        '140px',
        '110px'
        )};
    }

    .select-text-item {
      width: ${props => setWidthFilter(
        (props.totalItems || 2),
        '75px',
        '45px'
        )};
    }
  }

  @media ${screen.sizesMax.xxs} {
    .select-filter-container, .select-text-item {
      width: ${props => setWidthFilter(
        (props.totalItems || 2),
        '100px',
        '70px'
        )};
    }

    .select-text-item {
      width: ${props => setWidthFilter(
        (props.totalItems || 2),
        '45px',
        '15px'
        )};
    }
  }
`;
