import Styled from 'styled-components';

export const TooltipStyle: any = Styled.div`
  .box-tooltip-linechart {
    min-width: 84px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    padding: 5px 10px;
  }
`;