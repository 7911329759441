import React from 'react';
import {
  LineChart,
  Line,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis
} from 'recharts';

import { Colors } from 'consts';
import { hooks, screen } from 'helpers';

import CustomTooltip from '../Tooltip';
import CustomVerticalGrid from '../VerticalGrid';
import CustomizedAxisTick from '../AxisTick';
import ScrollContainer from '../ScrollContainer';
import { propsFont, propsDefaultAxis } from '../index';

import { LineChartStyle } from './style';

type AxisType = 'number' | 'category';

export type LineChartProps = {
  width?: number;
  height?: number;
  data: any;
  valueDomain?: number[] | string[];
  unit?: string;
  convertLabel?: (label: string, payload: any) => void;
  convertLabelValue?: (value: string) => string;
  dataKey: {
    xAxis: string;
    line: string;
  };
  margin?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  axisType?: {
    x?: AxisType;
    y?: AxisType;
  };
  tickFormatter?: {
    x?: (value: any, index: number) => string;
    y?: (value: any, index: number) => string;
  };
  ticks?: {
    x?: (string | number)[];
    y?: (string | number)[];
  };
  maxCharsLabel?: number | false;
  transformLabel?: string;
  heightAxis?: number;
  tickInterval?: number;
  yAxisWidth?: number;
  sizeFont?: number;
};

const CustomLineChart: React.FC<LineChartProps> = ({
  width,
  height,
  data,
  valueDomain,
  unit,
  convertLabel,
  convertLabelValue,
  dataKey,
  margin,
  axisType,
  tickFormatter,
  ticks,
  maxCharsLabel,
  transformLabel,
  heightAxis,
  tickInterval,
  yAxisWidth,
  sizeFont
}) => {
  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  const renderCustomTooltip = (props: any) => {
    const {
      active,
      payload,
      label
    } = props;

    if (active && payload) {
      return (
        <CustomTooltip
          value={ convertLabelValue
            ? convertLabelValue(`${ payload[0]?.value }${ unit }`)
            : `${ payload[0]?.value }${ unit }`
          }
          label={ convertLabel
            ? convertLabel(label, payload[0]?.payload)
            : label
          }
        />
      );
    }

    return null;
  };

  const renderVerticalGrid = (props: any) => {
    const { chartWidth } = props;

    const divider = data.length > 1 ? (data.length - 1) : data.length;
    const dotSpacing = chartWidth / divider;
    const offsetPoint = dotSpacing / 2.4;

    return (
      <CustomVerticalGrid
        key={ props.key }
        props={ props }
        offsetPoint={ offsetPoint }
        lengthData={ data.length }
      />
    );
  };

  return (
    <LineChartStyle>
      <LineChart
        width={ yAxisWidth }
        height={ height }
        data={ data }
        margin={ margin }
      >
        <YAxis
          domain={ valueDomain }
          unit={ unit }
          padding={ {
            top: 10,
            bottom: (heightAxis || 40) + 5
          } }
          type={ (axisType?.y || 'number') }
          tickFormatter={ tickFormatter?.y }
          ticks={ ticks?.y }
          { ...propsFont }
          { ...propsDefaultAxis }
        />
      </LineChart>

      <ScrollContainer
        width={ width }
        yAxisWidth={ yAxisWidth }
        heightAxis={ heightAxis }
      >
        <LineChart
          { ...{ overflow: 'visible' } }
          width={ (Math.max(data?.length * 62, width || 200)) - (yAxisWidth || 60) }
          height={ height }
          data={ data }
        >
          <CartesianGrid
            horizontal={ false }
            stroke={ Colors.grey.isLightGrey }
            strokeWidth={ 0.5 }
            vertical={ renderVerticalGrid }
          />

          <YAxis
            domain={ valueDomain }
            unit={ unit }
            padding={ { top: 5 } }
            type={ (axisType?.y || 'number') }
            tickFormatter={ tickFormatter?.y }
            ticks={ ticks?.y }
            { ...propsFont }
            { ...propsDefaultAxis }
            hide
          />

          <XAxis
            dataKey={ dataKey.xAxis }
            padding={ {
              left: (windowDimensions.width < screen.isMobile ? 18 : 35) / (tickInterval || 1),
              right: (windowDimensions.width < screen.isMobile ? 18 : 35) / (tickInterval || 1),
            } }
            interval={ tickInterval }
            height={ heightAxis }
            tick={ props => <CustomizedAxisTick
              props={ props }
              maxChars={ maxCharsLabel }
              customTransform={ transformLabel }
              fontSize={ sizeFont }
            /> }
            type={ (axisType?.x || 'category') }
            { ...propsFont }
            { ...propsDefaultAxis }
          />

          <Tooltip
            wrapperStyle={ { outline: 'none' } }
            content={ renderCustomTooltip }
            cursor={ {
              fill: Colors.grey.isLightGrey,
              strokeDasharray: '5 5'
            } }
          />

          <Line
            dataKey={ dataKey.line }
            stroke={ Colors.blue.isBlue }
            strokeWidth='2'
            dot={ false }
            type='monotone'
          />
        </LineChart>
      </ScrollContainer>
    </LineChartStyle>
  );
};

CustomLineChart.defaultProps = {
  valueDomain: [0, 100],
  unit: '',
  width: 200,
  height: 300,
  axisType: {
    x: 'category',
    y: 'number'
  },
  heightAxis: 40,
  tickInterval: 0,
  yAxisWidth: 60
};

export default CustomLineChart;