import React, { useState } from 'react';
import { Empty } from 'antd';

import {
	Text,
	Modal as CustomModal,
	Image,
	Icon,
	Divider,
} from 'components';
import { Colors } from 'consts';
import { DataTicketing, PastSimiliarCase } from 'interfaces/ticketing';
import { hooks, moment } from 'helpers';
import { selectors } from 'store/selectors';

import { ArrowContainerStyle, ModalStepStyles } from './style';

import { convertDateToLocal } from '../../helpers';

type ModalStepCaseProps = {
	caseDetail: DataTicketing,
	visible: boolean;
	closeModal: () => void;
};

const RenderModalStepCase: React.FC<ModalStepCaseProps> = ({
	caseDetail,
	visible,
	closeModal,
}) => {
	const pastSimiliarCase = hooks.useAppSelector(selectors.case.pastSimiliarCase);

	const [selectedStepId, setSelectedStepId] = useState<number>(0);

	const onToggleStep = (isSelectedStep: boolean, currentStepId: number) => {
		if (isSelectedStep) setSelectedStepId(0);
		else setSelectedStepId(currentStepId);
	};

	return (
		<CustomModal
			visible={ visible }
			onCloseModal={ closeModal }
			title={ () => (
				<Text
					text={ caseDetail?.category_name ? `${ caseDetail.category_name } Solving Steps` : '' }
					size='xl'
					weight={ 700 }
				/>
			) }
			width={ 500 }
			padding={ 30 }
		>
			<ModalStepStyles>
				{ pastSimiliarCase?.length && pastSimiliarCase?.length > 0
					? pastSimiliarCase?.map((step: PastSimiliarCase, index: number) => {
						const isSelectedStep = + (step?.id) === + selectedStepId;

						return (
							<div key={ step.id }>
								{ index > 0 && <Divider marginVertical={ 20 } /> }

								<div className='step justify-between' onClick={ () => onToggleStep(isSelectedStep, + (step?.id)) }>
									<div className='flex'>
										<Image
											src={ step?.admin_image }
											width={ 34 }
											height={ 34 }
											borderRadius={ 100 }
											external
										/>
										<div className='name'>
											<Text
												text={ step?.admin_name || '-' }
												weight={ 600 }
												size='s'
											/>
											<Text
												text={ `Solved in ${ moment.fromNow((moment.utcToLocal(step?.created_date)) ?? new Date()) }` }
												weight={ 500 }
												size='xs'
												color={ Colors.grey.isGrey }
												mt={ 5 }
											/>
										</div>
									</div>
									<div className='flex'>
										<Text
											text={ step?.created_date ? convertDateToLocal(step?.created_date)?.split(' ')[0] : '' }
											size='s'
											mr={ 20 }
										/>

										<ArrowContainerStyle isOpen={ isSelectedStep }>
											<Icon
												iconName='arrowDown'
												size={ 13 }
												fill={ Colors.blue.isBlue }
												className='ic-arrow'
											/>
										</ArrowContainerStyle>
									</div>
								</div>

								{ isSelectedStep && (
									<div className='desc'>
										<Text
											text={ step?.notes }
											weight={ 500 }
											size='s'
											lineHeight={ 16.94 }
										/>
									</div>
								) }
							</div>
						);
					})
					: <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } /> }
			</ModalStepStyles>
		</CustomModal>
	);
};

export default RenderModalStepCase;
