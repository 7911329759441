import React, {
  useState,
  useRef,
  useEffect
} from 'react';
import {
  Carousel,
  Popover,
  Space
} from 'antd';
import { CarouselRef } from 'antd/lib/carousel/index';

import {
  Image,
  Icon,
  Text,
  Box,
  Divider,
  Button,
  Modal,
  Skeleton,
  Loader,
  DebounceSelect,
  LinkedUsers
} from 'components';
import { Colors, Images } from 'consts';
import {
  hooks,
  misc,
  toastify,
  screen,
  roleHelper
} from 'helpers';
import { FormPagination, SelectOptionValue } from 'interfaces/common';
import {
  Scooter,
  ScooterApiParams,
  ScooterInfo
} from 'interfaces/scooter';
import {
  FormScooterAssignment,
  PairedScooter,
  ShowroomUser,
  UserScooter
} from 'interfaces/user';
import { Showroom } from 'interfaces/showroom';
import { Permission } from 'interfaces/role';
import { language } from 'language';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import {
  BoxScooterStyle,
  ImageUsersStyle,
  IndicatorStyle,
  LinkedUsersStyle
} from './style';

import { renderTitleSubContent } from '../../index';

interface ShowSelectedScooter {
  vin: string;
  type: string;
  color?: string;
  image: any;
  showroom_id?: number;
  showroom_name?: string;
}

type BoxScooterProps = {
  widthBox: number | string;
  onActiveScooter: (scooter: PairedScooter) => void;
  refreshing?: boolean;
  setRefreshing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const userScooterLang = language.userDetail?.scooter;

const BoxScooter: React.FC<BoxScooterProps> = ({
  widthBox,
  onActiveScooter,
  setRefreshing
}) => {
  const dispatch = hooks.useAppDispatch();
  const getAllScooter = dispatch(actions.getAllScooter);
  const getScooterDetail = dispatch(actions.getScooterDetail);
  const scooterAssignment = dispatch(actions.scooterAssignment);
  const getShowroomDetail = dispatch(actions.getShowroomDetail);

  const userDetail = hooks.useAppSelector(selectors.user.userDetail);
  const lazyLoad = hooks.useAppSelector(selectors.misc.lazyLoad);
  const adminPermissions = hooks.useAppSelector(selectors.auth.adminPermissions);

  const slider = useRef<CarouselRef>(null);
  const sliderModal = useRef<CarouselRef>(null);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activeIndexModal, setActiveIndexModal] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [dataSelectedScooter, setDataSelectedScooter] = useState<SelectOptionValue[]>([]);
  const [showSelectedScooter, setShowSelectedScooter] = useState<ShowSelectedScooter[]>([]);
  const [openSelect, setOpenSelect] = useState<boolean>(false);
  const [dataAssignedScooters, setDataAssignedScooters] = useState<PairedScooter[]>([]);

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  useEffect(() => {
    const pairedScooters = userDetail?.showroom?.filter((showroom: ShowroomUser) => showroom?.scooter)?.map((showroom: ShowroomUser) =>
      showroom?.scooter?.map((scooter: PairedScooter) => ({
        ...scooter,
        user_scooter: scooter?.user_scooter || []
      })));

    if (pairedScooters?.length) {
      const flattenScooters = misc.flatten(pairedScooters);

      setDataAssignedScooters(flattenScooters);
    } else {
      setDataAssignedScooters([]);
    }
  }, [userDetail?.showroom]);

  useEffect(() => {
    if (dataAssignedScooters?.length) {
      onActiveScooter(dataAssignedScooters[activeIndex]);
    }
  }, [dataAssignedScooters, activeIndex]);

  const handleCbGetShowroomDetail = (scooterDetail: ScooterInfo, showroomName?: string) => {
    const newScooter: ShowSelectedScooter = {
      vin: scooterDetail?.vin.toString(),
      type: scooterDetail?.type,
      color: scooterDetail?.color ?? '',
      image: scooterDetail?.images,
      showroom_id: scooterDetail?.showroom_id,
      showroom_name: showroomName || '-'
    };

    setShowSelectedScooter(prevScooter => {
      if (prevScooter.length > dataSelectedScooter.length) {
        const vinSelectedScooter = dataSelectedScooter.map(scooter => scooter.value.toString());
        const remainScooter = prevScooter.filter(item => vinSelectedScooter.includes(item.vin));

        return remainScooter;
      } else {
        const addScooter = prevScooter.find(prev => prev.vin === newScooter.vin);

        if (addScooter) {
          return prevScooter;
        } else {
          return [...prevScooter, newScooter];
        }
      }
    });
  };

  useEffect(() => {
    if (dataSelectedScooter.length) {
      setActiveIndexModal(0);
      setOpenSelect(false);

      const vin = dataSelectedScooter[dataSelectedScooter.length - 1].value;

      getScooterDetail(vin.toString(), (scooterDetail: ScooterInfo) => {
        getShowroomDetail(
          scooterDetail?.showroom_id?.toString(),
          'showroomDetail',
          (showroom: Showroom) => {
            handleCbGetShowroomDetail(scooterDetail, showroom?.name);
          },
          () => handleCbGetShowroomDetail(scooterDetail)
        );

        setOpenSelect(true);
      });
    } else {
      setShowSelectedScooter([]);
      setOpenSelect(false);
    }
  }, [dataSelectedScooter]);

  const renderIconPrevNext = (className: string, onClick: () => void) => {
    return (
      <Icon
        size={ 12 }
        iconName='arrowDown'
        className={ className }
        fill={ Colors.grey.isGrey }
        container='circle'
        containerColor={ Colors.grey.isLighterGrey }
        containerSize={ 32 }
        onClick={ onClick }
      />
    );
  };

  const goToSlide = (index: number) => {
    if (slider.current && !modalVisible) {
      slider.current.goTo(index);
    }

    if (sliderModal.current && modalVisible) {
      sliderModal.current.goTo(index);
    }
  };

  const renderIndicator = (data: any, activeIndicator: number) => {
    return Array.from(Array(data.length).keys())?.map(i => {
      return (
        <IndicatorStyle key={ i } isActiveCarousel={ activeIndicator === i }>
          <div className='indicator-scooter pointer' onClick={ () => goToSlide(i) } />
        </IndicatorStyle>
      );
    });
  };

  const renderCarouselList = (type: 'modal' | 'box') => {
    if (type === 'box') {
      return dataAssignedScooters?.map((data: PairedScooter, index: number) => {
        return (
          <div key={ index }>
            <div className='img-scooter-container'>
              <Image
                src={ data?.scooter_image
                  ? data?.scooter_image
                  : Images.scooter1 }
                className='img-scooter'
                preview
                external={ !!data?.scooter_image }
              />
            </div>
          </div>
        );
      });
    } else {
      return showSelectedScooter?.map((data: ShowSelectedScooter) => {
        return (
          <div key={ data.vin }>
            <Image
              src={ data.image ? data.image : Images.scooter1 }
              width={ 212 }
              height={ 200 }
              external={ !!data.image }
            />
            <Text
              size='l'
              weight={ 700 }
              mt={ 20 }
              align='center'
            >{ data.vin }</Text>
            <Text
              size='xs'
              mt={ 5 }
              align='center'
              color={ Colors.grey.isGrey }
            >{ data?.type } - { data?.color }</Text>
            <Text mt={ 10 } align='center'>{ data?.showroom_name }</Text>
          </div>
        );
      });
    }
  };

  const renderTextInfoScooter = (title: string, value: string) => {
    return (
      <>
        <div className='justify-align-center'>
          <Text size='xs' color={ Colors.grey.isGrey }>{ title }</Text>
          <Text size='xs' align='right'>{ value }</Text>
        </div>
        <Divider marginVertical={ 10 } />
      </>
    );
  };

  const renderInformationScooter = () => {
    if (dataAssignedScooters?.length) {
      return (
        <div className='mt3'>
          { renderTextInfoScooter('VIN', dataAssignedScooters[activeIndex]?.vin.toString()) }
          { renderTextInfoScooter('Type', dataAssignedScooters[activeIndex]?.variant_type ?? '-') }
          { renderTextInfoScooter('Color', dataAssignedScooters[activeIndex]?.scooter_color ?? '-') }
          { renderTextInfoScooter('Plat', dataAssignedScooters[activeIndex]?.plat_number ?? '-') }
        </div>
      );
    }
  };

  const renderUserScooterList = () => {
    if (dataAssignedScooters?.length) {
      const dataUsers = dataAssignedScooters[activeIndex]?.user_scooter;

      return dataUsers?.map((user: UserScooter, index: number) => (
        <ImageUsersStyle key={ user.user_id } indexUser={ index }>
          <Image
            src={ user.selfie_image }
            width={ 34 }
            height={ 34 }
            type='circle'
            className='image-users cover'
            external
          />
        </ImageUsersStyle>
      ));
    }
  };

  const overlayUsersScooter = () => {
    if (dataAssignedScooters?.length) {
      const isMobile = windowDimensions.width <= screen.isMobile;
      const dataUsers = dataAssignedScooters[activeIndex]?.user_scooter;
      const width = dataAssignedScooters?.length <= 5 || isMobile ? 200 : 400;

      return dataUsers?.map((user: UserScooter) => (
        <LinkedUsersStyle
          key={ user?.user_id }
          width={ width }
          isMobile={ isMobile }
        >
          <Space size={ [15, dataUsers?.length] } wrap>
            <LinkedUsers
              name={ user?.name }
              status={ user?.status }
              image={ user?.selfie_image }
              mt={ 2 }
              mb={ 2 }
            />
          </Space>
        </LinkedUsersStyle>
      ));
    }
  };

  const renderPopOverUsers = () => {
    return (
      <Popover
        placement='top'
        content={ overlayUsersScooter }
        trigger='click'
        overlayInnerStyle={ {
          borderRadius: 10,
          marginRight: 20
        } }
      >
        <div className='flex row align-center ml3 pointer'>
          { renderUserScooterList() }
        </div>
      </Popover>
    );
  };

  const renderContent = () => {
    const loadingUserDetail = misc.isLazyLoading(lazyLoad, 'userDetail');
    const loadingScooterDetail = misc.isLazyLoading(lazyLoad, 'scooterDetail');
    const loading = (loadingUserDetail || loadingScooterDetail) && !modalVisible;
    const isScooterExist = dataAssignedScooters?.length > 0;
    const isPermissiblePairingScooter = roleHelper.isPermissible(adminPermissions, Permission.user_pairing_scooter);

    return (
      <div className={ isScooterExist || loading ? 'col justify-center' : 'col center-content' } style={ { minHeight: isPermissiblePairingScooter ? 350 : 250 } }>
        <div className={ loading ? 'center-content' : '' }>
          <Skeleton
            loading={ loading }
            avatar={ { shape: 'square' } }
            className='img-scooter-skeleton'
            multiple={ false }
          >
            {
              isScooterExist ? (
                <>
                  <div className='justify-align-center'>
                    { renderIconPrevNext('ic-arrow-left', () => goToSlide(activeIndex - 1)) }
                    <div className='carousel-outer'>
                      <Carousel
                        dots={ false }
                        ref={ slider }
                        className='center-content'
                        beforeChange={ (from, to) => setActiveIndex(to) }
                      >
                        { renderCarouselList('box') }
                      </Carousel>
                    </div>
                    { renderIconPrevNext('ic-arrow-right', () => goToSlide(activeIndex + 1)) }
                  </div>
                  <div className='center-content mt3 mb3'>
                    <div className='flex row align-center wrapper-indicator'>
                      { renderIndicator(dataAssignedScooters, activeIndex) }
                    </div>
                  </div>
                </>
              ) : (
                <Image
                  src={ Images.emptyState.scooter }
                  width={ 100 }
                  height={ 100 }
                />
              )
            }
          </Skeleton>
        </div>
        <Skeleton
          loading={ loading }
          paragraph={ { rows: 4 } }
          mt={ 30 }
        >
          {
            isScooterExist ? (
              <>
                { renderTitleSubContent('Information') }

                { renderInformationScooter() }

                <div className='justify-align-center'>
                  <Text size='xs' color={ Colors.grey.isGrey }>{ userScooterLang?.users }</Text>
                  { renderPopOverUsers() }
                </div>
              </>
            ) : (
              <>
                <Text
                  size='m'
                  weight={ 700 }
                  align='center'
                  mt={ 22 }
                >{ userScooterLang.emptyScooter.title }</Text>

                { isPermissiblePairingScooter && (
                  <>
                    <Text
                      lineHeight={ 21 }
                      color={ Colors.grey.isGrey }
                      align='center'
                      mt={ 20 }
                    >{ userScooterLang.emptyScooter.instruction }</Text>
                    <div className='col align-center'>
                      <Button
                        bgColor='black'
                        fontWeight={ 700 }
                        borderRadius={ 10 }
                        mt={ 20 }
                        width={ 200 }
                        onClick={ onOpenModal }
                        text={ userScooterLang.pairing }
                      />
                    </div>
                  </>
                ) }
              </>
            )
          }
        </Skeleton>
      </div>
    );
  };

  const onOpenModal = () => {
    setModalVisible(true);
  };

  const onCloseModal = () => {
    setModalVisible(false);

    setDataSelectedScooter([]);
    setShowSelectedScooter([]);

    setActiveIndexModal(0);
    setOpenSelect(false);
  };

  const handleCbScooterAssignment = async(message: string) => {
    await onCloseModal();

    toastify.success(message);

    if (setRefreshing) setRefreshing(true);
  };

  const onClickPairScooter = () => {
    const vins = dataSelectedScooter.map(scooter => scooter?.value?.toString());
    const payload: FormScooterAssignment = { vins };

    scooterAssignment(
      userDetail?.id?.toString(),
      payload,
      handleCbScooterAssignment
    );
  };

  const fetchSearchScooter = async(search: string): Promise<SelectOptionValue[]> => {
    const showroomId = userDetail?.showroom_assigned > 0 ? userDetail?.showroom_assigned.toString() : '';
    const formPaginationScooter: FormPagination = {
      limit: 7,
      page: 1
    };
    const params: ScooterApiParams = {
      ...formPaginationScooter,
      search: search,
      status: '',
      sort: 'asc'
    };

    let dataScooter;

    await getAllScooter(
      params,
      showroomId,
      (updatedScooters: Scooter[]) => {
        // Filter from paired scooter (have username field)
        dataScooter = updatedScooters?.filter(scooter => !scooter?.username)?.map(scooter => ({
          value: scooter?.vin,
          label: scooter?.vin
        }));
      }
    );

    return dataScooter;
  };

  const renderSearchInputVin = () => {
    return (
      <DebounceSelect
        mode='multiple'
        value={ dataSelectedScooter }
        fetchOptions={ fetchSearchScooter }
        onChange={ newValue => {
          setDataSelectedScooter(newValue);
        } }
        label='VIN'
        labelColor={ Colors.grey.isGrey }
        placeholder='Enter VIN scooter'
        backgroundColor={ Colors.white.default }
        open={ openSelect }
        onDropdownVisibleChange={ visible => setOpenSelect(visible) }
        dependenciesFetch={ [userDetail?.showroom_assigned] }
      />
    );
  };

  const renderSelectedScooterData = () => {
    const loadingGetScooterData = misc.isLazyLoading(lazyLoad, 'scooterDetail') || misc.isLazyLoading(lazyLoad, 'allVariantScooter');

    if (loadingGetScooterData) {
      return (
        <div className='col center-content mb6' style={ { minHeight: 258 } }>
          <Loader />
        </div>
      );
    }

    if (showSelectedScooter.length) {
      return (
        <BoxScooterStyle>
          <div className='col center-content mt6' style={ { minHeight: 204 } }>
            <div className='justify-align-center' style={ { marginBottom: 25 } }>
              { renderIconPrevNext('ic-arrow-left', () => goToSlide(activeIndexModal - 1)) }
              <div className='carousel-outer-modal'>
                <Carousel
                  dots={ false }
                  ref={ sliderModal }
                  className='center-content'
                  beforeChange={ (from, to) => setActiveIndexModal(to) }
                >
                  { renderCarouselList('modal') }
                </Carousel>
              </div>
              { renderIconPrevNext('ic-arrow-right', () => goToSlide(activeIndexModal + 1)) }
            </div>
            <div className='center-content indicator-outer mb1'>
              <div className='flex row align-center wrapper-indicator'>
                { renderIndicator(showSelectedScooter, activeIndexModal) }
              </div>
            </div>
          </div>
        </BoxScooterStyle>
      );
    }

    return null;
  };

  const renderButtonPairScooter = () => {
    const loadingPairScooter = misc.isLazyLoading(lazyLoad, 'scooterAssignment');

    if (showSelectedScooter.length) {
      return (
        <Button
          bgColor='black'
          loaderColor='black'
          fontWeight={ 700 }
          onClick={ onClickPairScooter }
          isLoading={ loadingPairScooter }
          mt={ 30 }
          text='Pair Scooter'
        />
      );
    }

    return <></>;
  };

  const renderModalPairScooter = () => {
    const isMobile = windowDimensions.width <= screen.isMobile;
    const widthModal = isMobile ? windowDimensions.width - 30 : 500;

    return (
      <Modal
        visible={ modalVisible }
        onCloseModal={ onCloseModal }
        width={ widthModal }
        modalType='long-scroll'
        title={ () => renderTitleSubContent('Pair New Scooter') }
        footer={ () => renderButtonPairScooter() }
      >
        { renderSearchInputVin() }
        { renderSelectedScooterData() }
      </Modal>
    );
  };

  const renderActionPairingScooter = () => {
    const isPermissiblePairingScooter = roleHelper.isPermissible(adminPermissions, Permission.user_pairing_scooter);

    return isPermissiblePairingScooter
      ? (
        <div className='flex row'>
          <Text
            weight={ 700 }
            lineHeight={ 17 }
            color={ Colors.blue.isBlue }
            mr={ 10 }
            onClick={ onOpenModal }
          >+ { userScooterLang.pairing }</Text>
        </div>
      ) : null;
  };

  return (
    <BoxScooterStyle>
      <Box
        mt={ 20 }
        padding={ 20 }
        width={ widthBox }
      >
        <div className='justify-align-center mb4'>
          { renderTitleSubContent(userScooterLang.title) }

          { renderActionPairingScooter() }
        </div>

        { renderContent() }
      </Box>

      { renderModalPairScooter() }
    </BoxScooterStyle>
  );
};

export default BoxScooter;
