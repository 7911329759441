import { Dispatch } from 'redux';

import { AppActions } from 'store';
import { Pagination } from 'interfaces/common';
import { SET_PAGINATION, SetPagination } from './actionTypes';

export const setPaginationData = (fieldName: string, pagination: Pagination): SetPagination => {
  return {
    type: SET_PAGINATION,
    data: {
      fieldName,
      pagination
    }
  };
};

export const setPagination = (fieldName: string, pagination: Pagination) => {
	return async(dispatch: Dispatch<AppActions>) => {
		dispatch(setPaginationData(fieldName, pagination));
	};
};
