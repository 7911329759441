import Styled from 'styled-components';

import { Colors } from 'consts';

export const SidebarStyle: any = Styled.div`
  background: ${Colors.white.default};
  border-right: 1px solid ${Colors.grey.isLighterGrey};

  .sidebar {
    background: ${Colors.white.default};
  }

  .logo-container {
    margin-top: 8px;
    margin-bottom: 30px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;