import { Pagination } from 'interfaces/common';

import {
  SET_PAGINATION,
  LOGOUT,
  PaginationActionTypes
} from './actionTypes';

interface PaginationState {
  fieldName: string;
  pagination: Pagination;
}

const initState: PaginationState = {
  fieldName: '',
	pagination: {
    count: 0,
    limit: 10,
    offset: 0,
    order: '',
    page: 1,
    page_total: 1,
    search: '%%',
    sort: 'asc'
  }
};

const paginationReducer = (state = initState, action: PaginationActionTypes): PaginationState => {
	switch (action.type) {
		case SET_PAGINATION:
			return {
        ...state,
        fieldName: action.data.fieldName,
        pagination: action.data.pagination
      };

		case LOGOUT:
			return initState;

		default:
			return state;
	}
};

export default paginationReducer;
