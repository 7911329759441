import React, { useEffect } from 'react';

import {
  Box,
  BarChart,
  Select,
  EmptyState,
} from 'components';
import { Colors, Images } from 'consts';
import { hooks, misc } from 'helpers';

import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { renderTitleBox } from '../../index';

type BoxBatteryConditionProps = {
  widthBox: number;
  filterMenu: {
    activeFilter: any;
    visibleFilter: any;
    onVisibleChange: (visible: boolean, key: string) => void;
    onClickFilterMenu: (params?: any) => void;
  };
};

const setColorBatteryHealth = (value: number) => {
  if (value >= 85) {
    return [Colors.green.isGreenAlertOpacity('0.2'), Colors.green.isGreenAlert];
  } else if (value > 70 && value < 85) {
    return [Colors.orange.isOrangeOpacity('0.2'), Colors.orange.isOrange];
  } else {
    return [Colors.red.isRedOpacity('0.2'), Colors.red.isRed];
  }
};

const locationOptions = [
  'All Location',
  'SAVART',
  'Station',
  'Factory',
];

const BoxBatteryCondition: React.FC<BoxBatteryConditionProps> = ({ widthBox, filterMenu }) => {
    
  const dispatch = hooks.useAppDispatch();
  
  const getBatteryCondition = dispatch(actions.getBatteryCondition);
  const batteryCondition = hooks.useAppSelector(selectors.stats.batteryCondition);
  
  useEffect(() => {
  
    const paramFilter = filterMenu.activeFilter.batteryCondition.toLowerCase();
    getBatteryCondition({ location: paramFilter === 'all location' ? '' : paramFilter });

  }, [filterMenu.activeFilter.batteryCondition]);

  const renderEmptyData = () => {
    return (
      <div className='mt4' style={ { 'width': widthBox } }>
        <EmptyState
          background={ { height: 285 } }
          image={ {
            src: Images.emptyState.batteryStations,
            width: 56,
            height: 56
          } }
          text='No Battery Condition Data'
        />
      </div>
    );
  };

  const renderFilterMenu = (key: string) => {
    return (
      <Select
        keySelect={ key }
        visible={ filterMenu.visibleFilter[key] }
        onVisibleChange={ filterMenu.onVisibleChange }
        selectTitle={ filterMenu.activeFilter[key] }
        onClickSelectItem={ filterMenu.onClickFilterMenu }
        selectOptions={ locationOptions }
        wrapperStyle={ { marginBottom: 0 } }
      />
    );
  };

  const convertDataChart = () => {
    return batteryCondition?.map(element => {
      return {
        ...element,
        status: misc.capitalizeFirstLetter(String(element?.status))
      };
    });
  };
  
  return (
    <Box
      mt={ 20 }
      padding={ 20 }
      width={ widthBox }
    >
      <div className='justify-align-center mb4'>
        { renderTitleBox('Battery Condition') }
        { renderFilterMenu('batteryCondition') }
      </div>
      <div className='col center-content'>
        { Object.values(batteryCondition)?.length ?
        <BarChart
          layout='vertical'
          height={ 150 }
          data={ convertDataChart() }
          dataKey={ {
            yAxis: 'status',
            bar: 'total'
          } }
          margin={ {
            left: 20,
            top: -20
          } }
          barSize={ 25 }
          multipleFill={ setColorBatteryHealth }
          width={ widthBox - 80 }
        /> : renderEmptyData() }
      </div>
    </Box>
  );
};

export default BoxBatteryCondition;
