import { Dispatch } from 'redux';

import { toastify } from 'helpers';
import { ApiData, ApiParams } from 'interfaces/common';
import { Role } from 'interfaces/role';
import { AppActions } from 'store';
import * as roleServices from 'services/role';

import { GET_ALL_ROLE } from './actionTypes';
import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';

export const getAllRole = (dispatch: Dispatch<AppActions>) => (params: ApiParams, callback?: (roles: Role[]) => void) => {
  return new Promise<void>(async(resolve, reject) => {
    try {
      await dispatch(lazyLoadStart('allRole'));

      const {
        code,
        data,
        pagination
      }: ApiData<Role[]> = await roleServices.getAllRole(params);

      if (code === 200) {
        await dispatch({
          type: GET_ALL_ROLE,
          data: {
            data,
            pagination
          }
        });

        callback ? callback(data) : null;

        resolve();
      }
    } catch (error) {
      toastify.errorDefaultApi();

      reject(error);
    }

    dispatch(lazyLoadEnd('allRole'));
  });
};
