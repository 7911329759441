import { Endpoints } from 'consts';
import { customFetch } from 'helpers';
import { ApiData } from 'interfaces/common';
import { Notification } from 'interfaces/notification';

export const getAllNotification = async() => {
	try {
		const response: ApiData<Notification[]> = await customFetch(Endpoints.getAllNotification());
		
		return response;
	} catch (error) {
		throw error;
	}
};

export const readAllNotification = async() => {
	try {
		const response: ApiData<any> = await customFetch(Endpoints.readAllNotification()); // todo: update interface any
		
		return response;
	} catch (error) {
		throw error;
	}
};