import React, {
	useCallback,
	useEffect,
	useState
} from 'react';
import { AutoComplete, Empty } from 'antd';

import { Colors } from 'consts';
import { hooks } from 'helpers';
import { ValueType } from 'typings';

import { AutocompleteStyle } from './style';

import Text from '../Text';
import Loader from '../Loader';

type CustomAutoCompleteProps = {
	placeholder?: string | React.ReactNode;
	withPrefix?: boolean;
	loadOptions?: (
		inputValue: string,
		callback: (options?: ValueType[]) => void
	) => Promise<ValueType[]> | void;
	defaultOptions?: ValueType[];
	loading?: boolean;
	onSelect?: (value: any, option: ValueType) => void;
	renderItem?: (title: string, description?: string) => void;
};

const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({
	placeholder,
	withPrefix,
	loadOptions: propsLoadOptions,
	defaultOptions: propsDefaultOptions,
	loading,
	onSelect,
	renderItem
}) => {
	const [query, setQuery] = useState<string>('');
	const [loadedOptions, setLoadedOptions] = useState<ValueType[]>([]);
	const [defaultOptions, setDefaultOptions] = useState<ValueType[]>([]);

	const options: ValueType[] = query
		? loadedOptions
		: (defaultOptions || []);
	const debouncedSearch = hooks.useDebounce(query, 1500);

	const loadOptions = useCallback((inputValue: string, callback: (options?: ValueType[]) => void) => {
		if (!propsLoadOptions) return callback();
		const loader = propsLoadOptions(inputValue, callback);
		if (loader && typeof loader.then === 'function') {
			loader.then(callback, () => callback());
		}
	}, [propsLoadOptions]);

	useEffect(() => {
		if (propsDefaultOptions) {
			setDefaultOptions(propsDefaultOptions || []);
		}
	}, [loading]);

	useEffect(() => {
		loadOptions(debouncedSearch, (options?: ValueType[]) => {
			setLoadedOptions(options || []);
		});

		return () => {
			setLoadedOptions([]);
		};
	}, [debouncedSearch]);

	const onChangeQuery = useCallback((inputValue: string) => {
		setQuery(inputValue);
	}, []);

	return (
		<AutocompleteStyle withPrefix={ withPrefix }>
			<AutoComplete
				style={ { width: '100%' } }
				options={ options?.map((option: ValueType, index: number) => ({
					...option,
					key: index,
					label: renderItem ? renderItem(option.shortLabel || option.label, option.label) : option.label,
					value: option.value
				})) }
				placeholder={ (
					<div className='align-center full-height'>
						<Text color={ Colors.grey.isGrey }>{ placeholder }</Text>
					</div>
				) }
				notFoundContent={ loading
					? <Loader className='col center-content' style={ { height: 100 } } />
					: <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
				}
				onSelect={ onSelect }
				onSearch={ onChangeQuery }
				dropdownStyle={ {
					background: Colors.white.default,
					border: `1px solid ${ Colors.grey.isLightGrey }`,
					borderRadius: 10,
					boxShadow: 'none',
				} }
				filterOption={ (inputValue, option) => true } // eslint-disable-line @typescript-eslint/no-unused-vars
			/>
		</AutocompleteStyle>
	);
};

export default CustomAutoComplete;