import { Pagination } from 'interfaces/common';
import { Role } from 'interfaces/role';

export const GET_ALL_ROLE = 'GET_ALL_ROLE';
export const LOGOUT = 'LOGOUT';

export interface SetAllRole {
  type: typeof GET_ALL_ROLE;
  data: {
    data: Role[];
    pagination: Pagination;
  }
}

export interface Logout {
	type: typeof LOGOUT;
}

export type RoleActionTypes = SetAllRole | Logout;