import Styled from 'styled-components';

import { screen } from 'helpers';

export const RoleManagementStyle: any = Styled.div`
  .divider-vertical {
    height: 40px;
    border-width: 1.5px;
  }

  .edit-table-container {
    margin-left: 40px;
  }

  .text-status-container {
    width: 80px;
    margin-left: 10px;
  }

  .btn-add {
    padding: 12px 20px;
  }

  .content-section, .top-section {
    width: 150px;
  }

  @media ${ screen.sizesMax.sx } {
    .edit-table-container {
      margin-left: 25px;
    }
  }

  @media ${ screen.sizesMax.sm } {
    .edit-table-container {
      margin-left: 15px;
    }

    .web-edit-profile {
      display: none;
    }

    .text-status-container {
      width: 60px;
    }

    .content-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      width: 100%;
    }

    .top-section {
      width: 60%;
    }
  }

  @media ${ screen.sizesMax.xs } {
    .btn-add {
      padding: 9px 18px;
    }
  }
`;
