import { Subscription } from 'interfaces/subscription';
import { Pagination } from 'interfaces/common';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const LOGOUT = 'LOGOUT';

export interface SetAllSubscription {
	type: typeof GET_SUBSCRIPTIONS;
	data: {
		data: Subscription[];
		pagination: Pagination;
	}
}

export interface Logout {
	type: typeof LOGOUT;
}

export type SubscriptionActionTypes = SetAllSubscription | Logout;