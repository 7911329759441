import React, {
  useState,
  useCallback,
  useEffect
} from 'react';

import {
  Container,
  Box,
  Image,
  Logo,
  Text,
  Input,
  Button,
  Checkbox
} from 'components';
import { Colors, Images } from 'consts';
import {
  hooks,
  misc,
  navigation,
  toastify,
  screen
} from 'helpers';
import { FormLogin } from 'interfaces/auth';
import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import { LoginStyle } from './style';

const Login: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const login = dispatch(actions.login);

  const loading = hooks.useAppSelector(selectors.misc.loading);

  const [checked, setChecked] = useState<boolean>(false);
  const [form, setForm] = useState<FormLogin>({
    username: '',
    password: ''
  });
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [inputFocused, setInputFocused] = useState<string>('');

  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();

  useEffect(() => {
    if (windowDimensions.width <= screen.isMobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowDimensions.width]);

  const onPressEnter = () => {
    if (inputFocused === 'username') {
      setInputFocused('password');
    } else if (inputFocused === 'password' && !form.username) {
      setInputFocused('username');
    } else if (inputFocused === 'password') {
      /**
       * if the input is focused on the password input and the username is filled in, click login
       * (empty password can be handled at onClickLogin function).
       * otherwise, change the focus input
       */
      onClickLogin();
    }
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent): void => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        onPressEnter();
      }
    };

    document.addEventListener('keydown', listener);
    
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [inputFocused, form, checked]); // eslint-disable-line array-bracket-newline, array-element-newline

  const onChangeCheckbox = useCallback(() => setChecked(!checked), [checked]);

  const onClickForgotPassword = useCallback(() => navigation.push('/forgot-password'), []);

  const onChangeForm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevForm => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }));
  }, []);

  const onClickLogin = () => {
    const emptyForm = misc.getMultipleKeyByValue(form, '');

    if (!emptyForm.length) {
      login(checked, form);
    } else {
      toastify.error('Username / Password cannot be empty');
    }
  };

  const onChangeFocus = (focused: boolean, key: string) => {
    if (focused) {
      setInputFocused(key);
    }
  };

  const renderForm = () => {
    const isLoading = misc.isLoading(loading, 'login');

    return (
      <div className='form-container'>
        <Input
          placeholder='Enter your username'
          label='Username'
          value={ form.username }
          name='username'
          onChangeFocus={ (focused: boolean) => onChangeFocus(focused, 'username') }
          onChange={ onChangeForm }
          inputFocus={ inputFocused === 'username' }
          mb={ 20 }
        />
        <Input
          placeholder='Enter your password'
          label='Password'
          value={ form.password }
          name='password'
          type='password'
          onChange={ onChangeForm }
          onChangeFocus={ (focused: boolean) => onChangeFocus(focused, 'password') }
          inputFocus={ inputFocused === 'password' }
          iconName='password'
          iconPosition='right'
          mb={ 20 }
        />
        <Text
          color={ Colors.blue.isBlue }
          lineHeight={ 17 }
          mb={ isMobile ? 43.5 : 31.5 }
          className='p1 text-underline'
          text='Forgot your password?'
          onClick={ onClickForgotPassword }
        />
        <Checkbox
          label='Remember me next time'
          checked={ checked }
          onChange={ onChangeCheckbox }
        />
        <Button
          height={ 43 }
          text='Log In'
          fontWeight={ 700 }
          size='m'
          onClick={ onClickLogin }
          isLoading={ isLoading }
        />
      </div>
    );
  };

  return (
    <Container backgroundColor={ isMobile ? Colors.white.default : Colors.grey.isBgGrey }>
      <LoginStyle windowDimensions={ windowDimensions }>
        <div className='content'>
          <div className='center-content half-container'>
            <div className='inner-container'>
              <Logo width={ 61.72 } height={ 65 } />
              <Text
                color={ Colors.black.isTextBlack }
                size={ isMobile ? 'xl' : 'xl3' }
                weight={ 700 }
                lineHeight={ 39 }
                text='Welcome Back!'
                className='p1 text-welcome'
              />
              <Text
                weight={ isMobile ? 400 : 500 }
                color={ Colors.grey.isGrey }
                lineHeight={ 17 }
                mt={ 10 }
                text='Log in to your account to continue'
              />
              {
                isMobile ? (
                  renderForm()
                ) : (
                <div className='center-content'>
                  <Box
                    mt={ 30 }
                    width={ 418 }
                    height={ 407 }
                    adjustWidth
                  >
                    { renderForm() }
                  </Box>
                </div>
                )
              }
            </div>
          </div>
          <div className='half-container'>
            <Image src={ isMobile ? Images.bgLoginMobile : Images.bgLogin } className='half-background-image' />
          </div>
        </div>
      </LoginStyle>
    </Container>
  );
};

export default Login;
