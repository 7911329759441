import { Subscription } from 'interfaces/subscription';
import { Pagination } from 'interfaces/common';

import {
	GET_SUBSCRIPTIONS,
	LOGOUT,
	SubscriptionActionTypes,
} from './actionTypes';

interface SubscriptionState {
	subscriptions: {
		data: Subscription[];
		pagination: Pagination;
	};
}

const initPagination: Pagination = {
	count: 0,
	limit: 0,
	offset: 0,
	order: '',
	page: 0,
	page_total: 0,
	search: '',
	sort: 'asc'
};

const initState: SubscriptionState = {
	subscriptions: {
		data: [],
		pagination: initPagination
	}
};

const subscriptionReducer = (state = initState, action: SubscriptionActionTypes): SubscriptionState => {
	switch (action.type) {
		case GET_SUBSCRIPTIONS:
			return {
				...state,
				subscriptions: action.data
			};

		case LOGOUT:
			return initState;

		default:
			return state;
	}
};

export default subscriptionReducer;
