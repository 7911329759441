import { Dispatch } from 'redux';

import { AppActions } from 'store';
import { toastify } from 'helpers';
import { ApiData } from 'interfaces/common';
import {
	BatteryCondition,
	BatteryConditionParams,
	StatsAge,
	SwapLocation,
	SwapLocationParams
} from 'interfaces/stats';

import * as statsServices from 'services/stats';

import {
	GET_BATTERY_CONDITION,
	GET_STATS_AGE,
	GET_SWAP_LOCATION,
	GET_BATTERY_RETIRED
} from './actionTypes';

import { lazyLoadStart, lazyLoadEnd } from '../Misc/action';

export const getStatsAge = (dispatch: Dispatch<AppActions>) => (callback?: (statsAge: StatsAge[]) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			dispatch(lazyLoadStart('statsAge'));

			const { code, data }: ApiData<StatsAge[]> = await statsServices.getStatsAge();

			if (code === 200) {
				dispatch({
					type: GET_STATS_AGE,
					data
				});

				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		}

		dispatch(lazyLoadEnd('statsAge'));
	});
};

export const getBatteryCondition = (dispatch: Dispatch<AppActions>) => (params: BatteryConditionParams, callback?: (BatteryCondition: BatteryCondition[]) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			dispatch(lazyLoadStart('batteryCondition'));
			const { code, data }: ApiData<BatteryCondition[]> = await statsServices.getBatteryCondition(params);

			if (code === 200) {
				dispatch({
					type: GET_BATTERY_CONDITION,
					data
				});

				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		}

		dispatch(lazyLoadEnd('batteryCondition'));
	});
};

export const getBatteryRetired = (dispatch: Dispatch<AppActions>) => (params: BatteryConditionParams, callback?: (BatteryCondition: BatteryCondition[]) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			dispatch(lazyLoadStart('batteryRetired'));
			const { code, data }: ApiData<BatteryCondition[]> = await statsServices.getBatteryCondition(params);

			if (code === 200) {
				dispatch({
					type: GET_BATTERY_RETIRED,
					data
				});

				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		}

		dispatch(lazyLoadEnd('batteryRetired'));
	});
};

export const getSwapLocation = (dispatch: Dispatch<AppActions>) => (params: SwapLocationParams, callback?: (SwapLocation: SwapLocation[]) => void) => {
	return new Promise<void>(async(resolve, reject) => {
		try {
			dispatch(lazyLoadStart('swapLocation'));

			const { code, data }: ApiData<SwapLocation[]> = await statsServices.getSwapLocation(params);

			if (code === 200) {
				dispatch({
					type: GET_SWAP_LOCATION,
					data
				});

				if (callback) callback(data);

				resolve();
			}
		} catch (error) {
			toastify.errorDefaultApi();

			reject(error);
		}

		dispatch(lazyLoadEnd('swapLocation'));
	});
};